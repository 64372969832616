/**
 * Component for escalating the invoices to collections.
 * Triggered when clicking the `Action` button for collection type items selected.
 */

import { Button, Col, Modal, Row } from 'antd';
import { capitalize, get, isEmpty, map } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../../components/common/DateFormatHandler';
import ModalWithSpinner from '../../../components/common/ModalWithSpinner';
import { ApplicationState } from '../../../store';
import { escalateToCollectionsAction } from '../../../store/tasks/actions';
import { getTranslatedText } from '../../../utils/commonFunctions';


interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly getSelectedTasksValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
        sortBy: string,
        sortAscending: boolean,
        recordLimit?: number,
    };
}

const TaskEscalateCollectionsPanel: React.FC<IProps> = ({
    closePanel,
    getSelectedTasksValues,
}: IProps) => {
    const dispatch = useDispatch();
    
    const usingCustomerWorkflow: boolean = useSelector(
        (state: ApplicationState) =>
            get(
                state.companies.selectedUserCompany,
                'Company.UsingCustomerWorkflow'
            )
    );
    
    const isPaymentPlanEnabled: boolean = useSelector(
        (state: ApplicationState) =>
            get(
                state.companies.selectedUserCompany,
                'Company.CompanyPaymentPlan.IsEnabled'
            )
    );

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    /**
     * Function called to close the panel when `No` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function called when `Yes` button is clicked.
     * Escalates the items selected to collections.
     */
    const handleSubmitForm = () => {
        const {
            allExcept, keysToUse, filterObject,
            recordLimit, sortAscending, sortBy
        } = getSelectedTasksValues();

        setSubmitLoading(true);
        dispatch(
            escalateToCollectionsAction({
                filter: filterObject,
                taskIds: keysToUse,
                excludeTasks: allExcept,
                sortBy,
                sortAscending,
                recordLimit,
                isPaymentPlanEnabled,
                usingCustomerWorkflow,
                callback: escalateToCollectionResponseModal,
            })
        );
    };

    let itemText = 'invoice';
    if (usingCustomerWorkflow) {
        itemText = 'customer';
    }

    /**
     * Function called after calling the API for escalating to collections.
     * Either success/error.
     * @param param0
     */
    const escalateToCollectionResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);

        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText(`${capitalize(
                    itemText
                )}(s) escalated to collections successfully!`),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                okText: getTranslatedText('OK'),
            });
        } else {
            let errorMessageContent:
                | string
                | JSX.Element[] = getTranslatedText(`Failed to escalate ${itemText}(s) to collection!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                okText: getTranslatedText('OK'),
            });
        }
    };

    /**
     * Function to populate the content (confirmation message) of this panel.
     */
    const populatePanelContent = () => {
        const { allExcept, keysToUse } = getSelectedTasksValues();
        let phraseToUse = 'these items';
        if (!allExcept && keysToUse.length === 1) {
            phraseToUse = 'this item';
        }
        return (
            <Row>
                <Col>
                    {getTranslatedText(`Do you want to escalate ${phraseToUse} to your collection team?`)}
                </Col>
            </Row>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            {getTranslatedText('Yes')}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText('No')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <ModalWithSpinner
                    modalTitle={getTranslatedText("Escalating to collection")}
                    modalVisible={submitLoading}
                    displayMessage={getTranslatedText(`Please wait while escalating ${itemText}(s) to collections . . .`)}
                />
            )}
        </Row>
    );
};

export default withRouter(withDateFormatHandler(TaskEscalateCollectionsPanel));
