/**
 * Component that handles if the user will leave the route/current address/path that he's currently in.
 * Either by clicking the `Back` button or by clicking a link that routes to other address.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Modal } from 'antd';
import { get } from 'lodash';
import {
    confirmModalOkText,
    confirmModalCancelText,
} from '../../config/config';
import { getTranslatedText } from '../../utils/commonFunctions';

const { confirm } = Modal;

interface IProps {
    shouldBlockNavigation: (path?: string) => boolean;
    navigate: (path: string) => void;
    when: boolean;
}
export class RouteLeavingGuard extends React.Component<IProps> {
    state = {
        lastLocation: null,
        confirmedNavigation: false,
    };
    
    /**
     * Function that shows a confirmation modal asking if you want to discard the changes made
     * in the page that you're currently in.
     */
    showModal = (location: string) =>
        this.setState(
            {
                lastLocation: location,
            },
            () => {
                confirm({
                    className: 'modal-swapped-buttons',
                    title: getTranslatedText('Do you want to discard your changes?'),
                    content: (
                        <div dangerouslySetInnerHTML={{ __html: getTranslatedText(`When you click the <b>${confirmModalOkText}</b> button, all the changes you made will be gone.`) }}></div>
                    ),
                    onOk: this.handleConfirmNavigationClick,
                    onCancel: this.closeModal,
                    okText: getTranslatedText(confirmModalOkText),
                    cancelText: getTranslatedText(confirmModalCancelText),
                });
            }
        );

    /**
     * Function called to destroy all the existing shown modals.
     */
    closeModal = () => {
        Modal.destroyAll();
    };

    /**
     * Function to determine if the navigation to other path will be blocked.
     * Returns a boolean.
     */
    handleBlockedNavigation = (nextLocation: string) => {
        const { confirmedNavigation } = this.state;
        const { shouldBlockNavigation } = this.props;
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation);
            return false;
        }

        return true;
    };

    /**
     * Function that will be clicked if the `Confirm` button will be clicked in the confirmation modal.
     */
    handleConfirmNavigationClick = () => {
        Modal.destroyAll();
        const { navigate } = this.props;
        const { lastLocation } = this.state;
        if (lastLocation) {
            this.setState(
                {
                    confirmedNavigation: true,
                },
                () => {
                    // Navigate to the previous blocked location with your navigate function
                    const lastPathName = get(lastLocation, 'pathname');
                    if (lastPathName) navigate(lastPathName);
                }
            );
        }
    };

    render() {
        const { when } = this.props;

        return <Prompt when={when} message={this.handleBlockedNavigation} />;
    }
}

export default RouteLeavingGuard;
