import {
    all, call, delay, fork, put, select, takeLatest,
} from 'redux-saga/effects';
import { ContentTemplateConfiguration, ContentsActionTypes, ContentsState, GetContentTemplatePayload, GetContentTemplatePreviewPayload, GetManualCommunicationTemplatesRequestPayload, WorkflowContentConfiguration } from './types';
import { DynamicObject } from '../../utils/commonInterfaces';
import { API, graphqlOperation } from 'aws-amplify';
import queries from '../../graphql/queries.graphql';
import { get } from 'lodash';
import { getContentTemplateAction, getContentTemplateConfigurationAction, getContentTemplateConfigurationError, getContentTemplateConfigurationSuccess, getContentTemplateError, getContentTemplatePreviewAction, getContentTemplatePreviewError, getContentTemplatePreviewSuccess, getContentTemplateSuccess, getManualCommunicationTemplatesActionError, getManualCommunicationTemplatesActionSuccess, getWorkflowContentConfigurationAction, getWorkflowContentConfigurationError, getWorkflowContentConfigurationSuccess, saveContentTemplateError, saveContentTemplateSuccess } from './actions';
import { API_NAME, maxAPIRefetchCount, refetchAPIDelay } from '../../config/config';
import { checkShouldRequestRefetch, getTranslatedText } from '../../utils/commonFunctions';
import { ApplicationState } from '..';
import { getPaymentPlanEnabled } from '../companies/sagas';
import { Modal } from 'antd';
import { getTemplateContentType, isHtml } from '../../utils/contentFunctions';

/**
 * Selectors
 */
export const getWorkflowStateConfigs = (state: ApplicationState) =>
    state.contents.workflowContents.contentConfigs;

export const getContentTemplateConfig = (state: ApplicationState) =>
    state.contents.otherContents.contentConfig;

export const getManualTemplates = (state: ApplicationState) =>
    state.contents.manualContents.templates;

export const getContentSelection = (state: ApplicationState) =>
    state.contents.activeData.selection;

export const getSelectedContentTemplate = (state: ApplicationState) =>
    state.contents.activeData.record;

export const getPreview = (state: ApplicationState) =>
    state.contents.activeData.preview;

/**
 * Handlers
 */
function* handleGetWorkflowContentConfiguration() {
    let refetchCount = 0;
    const errorMessage = 'Error fetching content list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_WORKFLOW_CONTENT_CONFIGURATION)
        );

        const WorkflowContentConfigurations: WorkflowContentConfiguration[] = get(res.data, 'GetWorkflowContentConfigurationForCompany');
        if (WorkflowContentConfigurations) {
            refetchCount = 0;
            yield put(getWorkflowContentConfigurationSuccess(WorkflowContentConfigurations));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getWorkflowContentConfigurationAction());
        } else {
            yield put(getWorkflowContentConfigurationError([errorMessage]));
        }
    }
}

function* handleGetContentTemplateConfiguration() {
    let refetchCount = 0;
    const errorMessage = 'Error fetching content list. Please try again later.';
    const PaymentPlanEnabled: boolean = yield select(getPaymentPlanEnabled);
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONTENT_TEMPLATE_CONFIGURATION, {
                PaymentPlanEnabled
            })
        );

        const ContentTemplateConfiguration: ContentTemplateConfiguration = get(res.data, 'GetContentTemplateConfigurationForCompany');
        if (ContentTemplateConfiguration) {
            refetchCount = 0;
            yield put(getContentTemplateConfigurationSuccess(ContentTemplateConfiguration));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getContentTemplateConfigurationAction());
        } else {
            yield put(getContentTemplateConfigurationError([errorMessage]));
        }
    }
}

function* handleGetContentTemplate(sagaPayload: any) {
    const { payload }: { payload: GetContentTemplatePayload } = sagaPayload;
    const TemplateKey = get(payload, 'TemplateKey');
    const ContentType = getTemplateContentType(TemplateKey);
    let refetchCount = 0;
    const errorMessage = 'Error fetching content template. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONTENT_TEMPLATE, {
                ...payload,
                ContentType
            })
        );

        const ContentTemplate = get(res.data, 'GetContentTemplateForCompany');
        if (ContentTemplate) {
            refetchCount = 0;
            yield put(getContentTemplateSuccess({
                ...ContentTemplate,
                IsHtml: isHtml(TemplateKey),
                ContentType
            }));
        } else {
            throw new Error('Content not found!');
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getContentTemplateAction(payload));
        } else {
            yield put(getContentTemplateError([errorMessage]));
        }
    }
}

function* handleGetContentTemplatePreview({ payload: sagaPayload }: any) {
    const { payload, version }: { payload: GetContentTemplatePreviewPayload, version: string } = sagaPayload;
    const selectedContentTemplate: ContentsState['activeData']['record'] = yield select(getSelectedContentTemplate);
    let refetchCount = 0;
    const errorMessage = `Error rendering the preview, please check to make sure there aren't any invalid changes in the template`;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONTENT_TEMPLATE_PREVIEW, {
                ...payload,
                ContentType: get(selectedContentTemplate, 'ContentType')
            })
        );

        const Preview: string | undefined = get(res.data, 'GetContentTemplatePreview.Preview');
        if (Preview) {
            refetchCount = 0;
            yield put(getContentTemplatePreviewSuccess({
                content: Preview,
                version
            }));
        } else {
            throw new Error('Preview not found!');
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getContentTemplatePreviewAction(payload, version));
        } else {
            yield put(getContentTemplatePreviewError([errorMessage], version));
        }
    }
}

function* handleSaveContentTemplate({ payload: sagaPayload }: any) {
    const { callback, payload } = sagaPayload;

    try {
        const { NewTemplateKey } = yield call(
            [API, 'post'],
            API_NAME,
            '/content/save/content-template',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                ...payload,
                NewTemplateKey
            };
            yield put(saveContentTemplateSuccess(response));
            callback({
                ...response,
                IsSuccess: true
            });
        }
    } catch (err) {
        const error: any = err;
        yield put(saveContentTemplateError());
        if (callback) {
            const returnData = get(error.response, 'data')
                ? error.response.data
                : { Messages: [error.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (error instanceof Error) {
            console.log('Error', error);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* handleGetManualCommunicationTemplateOptions({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload as GetManualCommunicationTemplatesRequestPayload;
    const errorMessage = 'Failed to fetch manual communication template options!';
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS, {})
        );
        const { Templates } = get(res, 'data.GetManualCommunicationTemplateOptions');

        yield put(getManualCommunicationTemplatesActionSuccess(Templates));

        if (callback) callback(Templates);
    } catch (err) {
        yield put(getManualCommunicationTemplatesActionError([errorMessage]));

        const onCloseCallback = () => {
            if (callback) callback([]);
        };

        Modal.error({
            title: getTranslatedText("Error"),
            content: getTranslatedText(errorMessage),
            onOk: onCloseCallback,
            onCancel: onCloseCallback,
            okText: getTranslatedText("OK")
        });

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetWorkflowContentConfiguration() {
    yield takeLatest(ContentsActionTypes.GET_WORKFLOW_CONTENT_CONFIGURATION, handleGetWorkflowContentConfiguration);
}

function* watchGetContentTemplateConfiguration() {
    yield takeLatest(ContentsActionTypes.GET_CONTENT_TEMPLATE_CONFIGURATION, handleGetContentTemplateConfiguration);
}

function* watchGetContentTemplate() {
    yield takeLatest(ContentsActionTypes.GET_CONTENT_TEMPLATE, handleGetContentTemplate);
}

function* watchGetContentTemplatePreview() {
    yield takeLatest(ContentsActionTypes.GET_CONTENT_TEMPLATE_PREVIEW, handleGetContentTemplatePreview);
}

function* watchSaveContentTemplate() {
    yield takeLatest(ContentsActionTypes.SAVE_CONTENT_TEMPLATE, handleSaveContentTemplate);
}

function* watchGetManualCommunicationTemplateOptions() {
    yield takeLatest(
        ContentsActionTypes.GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS,
        handleGetManualCommunicationTemplateOptions
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* contentsSaga() {
    yield all([
        fork(watchGetWorkflowContentConfiguration),
        fork(watchGetContentTemplateConfiguration),
        fork(watchGetContentTemplate),
        fork(watchGetContentTemplatePreview),
        fork(watchSaveContentTemplate),
        fork(watchGetManualCommunicationTemplateOptions)
    ]);
}

export default contentsSaga;
