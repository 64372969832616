import { Button, Checkbox, Col, Empty, Form, Input, Modal, Row, Spin, Tag, Tooltip, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import QueueAnim from 'rc-queue-anim';
import { debounce, find, get, includes, isEmpty, map, some } from 'lodash';
import { CompanyUserRole } from '../../store/companies/types';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { getRolePermissions } from '../../store/roles/sagas';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import FormItem from 'antd/lib/form/FormItem';
import { decodeHtmlEntitiesInLiquidSections, getAllTags, getTagInnerContent, getTagPropertyValue, insertContent, replaceCustomizationTags, replaceTagContent } from '../../utils/contentFunctions';
import { getPreview, getSelectedContentTemplate } from '../../store/contents/sagas';
import { ContentsState, SaveContentTemplatePayload } from '../../store/contents/types';
import { changeContentSelectionAction, changePreviewVersionAction, getContentTemplatePreviewAction, saveContentTemplateAction } from '../../store/contents/actions';
import { DANGER_COLOR } from '../../constants/common';
import { DynamicObject } from '../../utils/commonInterfaces';
import ReactQuillMemoized from '../../components/common/ReactQuillMemoized';
import ContentPreview from '../../components/contents/ContentPreview';
import { CONTENT_CUSTOMIZATION_EDITOR_FORMAT } from '../../constants/contents';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FontAwesome from '../../components/common/FontAwesome';
import { getTranslatedText } from '../../utils/commonFunctions';


const { Title } = Typography;
const { confirm } = Modal;
const { TextArea } = Input;

interface IProps {
    form: WrappedFormUtils;
    formTitle: string;
    valueChanges: any;
    formFulfilled: boolean;
    contentSelectionFields: string[];
    dataErrors: string[];
    setValueChanges: (valueChanges: any) => void;
    generatePayload: (currentTemplate: string) => SaveContentTemplatePayload;
    generateContentsFilter: (clearData: () => void) => JSX.Element;
    refreshCurrentContent: () => void;
}

interface IEditableField {
    id: string;
    label: string;
    description: string;
    type: 'rich' | 'raw';
    initialValue: string;
    defaultContent: string;
    defaultTagExists: boolean;
    wrap: boolean;
    wrapElement?: string;
    wrapElementStyle?: string;
}

interface IField {
    id: string;
    value: string;
    useDefault: boolean;
}

const reactQuillSettings = {
    modules: {
        toolbar: [['bold', 'italic', 'underline', 'link']],
    },

    formats: ['bold', 'italic', 'underline', 'link', CONTENT_CUSTOMIZATION_EDITOR_FORMAT],
};

let delayUpdateTemplateRef = (version: string) => { };
const delayUpdateTemplate = debounce((version: string) => delayUpdateTemplateRef(version), 3000)

const ContentsManagementForm: React.FC<IProps> = ({
    form, formFulfilled, contentSelectionFields, formTitle, dataErrors,
    valueChanges, setValueChanges, generatePayload, generateContentsFilter, refreshCurrentContent
}) => {
    const dispatch = useDispatch();
    
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const userRole = get(selectedUserCompany, 'Role.Name');
    const rolePermissions = useSelector(getRolePermissions);
    const canUpdateContent = includes(rolePermissions.CONTENTS_CUSTOMIZATION, userRole);
    const formDisabled = !canUpdateContent;
    const {
        loading: contentLoading,
        errorMessages: contentErrors,
        previewErrors,
        rendering: previewBackendRendering
    }: ContentsState['activeData'] = useSelector((state: ApplicationState) => state.contents.activeData);
    const errorMessages = [...dataErrors, ...contentErrors];
    const selectedContentTemplate: ContentsState['activeData']['record'] = useSelector(getSelectedContentTemplate);
    const { content: previewContent, version: previewVersion }: ContentsState['activeData']['preview'] = useSelector(getPreview);
    const { saveLoading }: ContentsState = useSelector((state: ApplicationState) => state.contents);
    const { getFieldDecorator, getFieldsValue, getFieldValue, resetFields, setFieldsValue } = form;
    const [formHasChanged, setFormHasChanged] = useState(false);
    const [editableFields, setEditableFields] = useState<IEditableField[]>([]);
    const [editorTouched, setEditorTouched] = useState<{ [key: string]: boolean }>({});
    const [fieldChanged, setFieldChanged] = useState<{ [key: string]: boolean }>({});
    const [rendering, setRendering] = useState(false);
    const [fields, setFields] = useState<IField[]>([]);
    const [currentTemplate, setCurrentTemplate] = useState<string | undefined>();
    const [currentPreview, setCurrentPreview] = useState<string | undefined>();
    const [generalDescription, setGeneralDescription] = useState<string | undefined>();

    const requestRenderPreview = (template: string, version: string, payload?: string) => {
        if (template) {
            dispatch(getContentTemplatePreviewAction({
                Content: template,
                Payload: payload || '{}'
            }, version));
        }
    };

    const intializeForm = () => {
        resetFields();
        clearData();
    }

    useEffect(intializeForm, [selectedUserCompany]);

    const isDefaultContent = (editableField: IEditableField, currentValue: string) =>
        editableField.defaultContent === currentValue;

    const constructInitialFields = (editableFields: IEditableField[]) => map(editableFields, f => ({
        id: f.id, value: f.initialValue, useDefault: isDefaultContent(f, f.initialValue)
    }));

    const initializeTemplateAndFields = () => {
        if (selectedContentTemplate) {
            const content = get(selectedContentTemplate, 'Content');
            const allDescriptions = map(getAllTags(content, 'description'), description => {
                return {
                    forId: getTagPropertyValue(description, 'for-id'),
                    content: getTagInnerContent(description)
                }
            });
            const allDefaultContents = map(getAllTags(content, 'default'), defaultContent => {
                return {
                    forId: getTagPropertyValue(defaultContent, 'for-id'),
                    content: getTagInnerContent(defaultContent) || ''
                }
            });
            const generalDescription = find(allDescriptions, d => !d.forId);
            const allEditableElements = getAllTags(content, 'editable');
            const allEditableFields: IEditableField[] = [];
            allEditableElements.forEach(element => {
                const fieldId = getTagPropertyValue(element, 'id') || '';
                if (!some(allEditableFields, f => f.id === fieldId)) {
                    const editableField: IEditableField = {
                        id: fieldId,
                        description: '',
                        label: getTagPropertyValue(element, 'label') || '',
                        type: (getTagPropertyValue(element, 'type') as any) || 'rich',
                        initialValue: getTagInnerContent(element) || '',
                        defaultContent: '',
                        defaultTagExists: false,
                        wrap: getTagPropertyValue(element, 'wrap') === 'true',
                        wrapElement: getTagPropertyValue(element, 'wrapElement'),
                        wrapElementStyle: getTagPropertyValue(element, 'wrapElementStyle')
                    };
                    const fieldDescription = find(allDescriptions, d => d.forId === editableField.id);
                    const description = get(fieldDescription, 'content') || getTagPropertyValue(element, 'description') || '';
                    editableField.description = description;
                    const fieldDefault = find(allDefaultContents, c => c.forId === editableField.id);
                    if (fieldDefault) {
                        const defaultValue = get(fieldDefault, 'content');
                        editableField.defaultContent = defaultValue;
                        editableField.defaultTagExists = true;
                    } else {
                        editableField.defaultContent = editableField.initialValue;
                        editableField.defaultTagExists = false;
                    }
                    allEditableFields.push(editableField);
                }
            });
            setGeneralDescription(generalDescription && generalDescription.content);
            setEditableFields(allEditableFields);
            setEditorTouched({});
            setFieldChanged({});
            setFields(constructInitialFields(allEditableFields));
            setCurrentTemplate(get(selectedContentTemplate, 'Content'));
        }
    };

    useEffect(initializeTemplateAndFields, [selectedContentTemplate]);

    const processAndUpdateTemplate = (version: string) => {
        if (version === previewVersion && selectedContentTemplate) {
            let template = get(selectedContentTemplate, 'Content');
            const payload = get(selectedContentTemplate, 'Payload');
            const newFields = [...fields];

            editableFields.forEach(editableField => {
                if (!fieldChanged[editableField.id]) return;

                const editingField = find(newFields, f => f.id === editableField.id);
                if (!editingField) return;

                let content = editingField.value;

                if (editableField.type === 'rich' && editorTouched[editableField.id]) {
                    content = decodeHtmlEntitiesInLiquidSections(content);
                    content = replaceCustomizationTags(content, editableField.wrap, editableField.wrapElement, editableField.wrapElementStyle);
                }

                const useDefault = isDefaultContent(editableField, content);
                if (editingField.useDefault !== useDefault) {
                    changeUseDefault(editingField.id, useDefault);
                }

                editingField.value = content;
            })

            setFields(newFields);

            newFields.forEach(field => {
                template = replaceTagContent(template, 'editable', field.id, field.value);
            });

            const htmlTag = '<html>';
            let afterHtmlIndex = template.indexOf(htmlTag);
            if (afterHtmlIndex === -1) {
                afterHtmlIndex = 0;
            } else {
                afterHtmlIndex = afterHtmlIndex + htmlTag.length;
            }

            editableFields.forEach(field => {
                if (!field.defaultTagExists) {
                    template = insertContent(template, afterHtmlIndex, `<default for-id="${field.id}">${field.defaultContent}</default>`);
                }
            });

            setCurrentTemplate(template);
            requestRenderPreview(template, version, payload);
        }
    }
    delayUpdateTemplateRef = processAndUpdateTemplate;

    const handlePreviewRendered = () => {
        setRendering(false);
        if (previewContent) {
            setCurrentPreview(previewContent);
        }
    };

    useEffect(handlePreviewRendered, [previewContent]);

    useEffect(() => {
        if (!previewBackendRendering) {
            setRendering(previewBackendRendering);
        }
    }, [previewBackendRendering]);

    const changeUseDefault = (id: string, useDefault: boolean) => {
        setFields(prev => {
            const field = find(prev, f => f.id === id);
            if (field) {
                field.useDefault = useDefault;
                return [...prev];
            }
            return prev;
        });
    };

    const triggerUpdateContent = () => {
        setRendering(true);
        setFormHasChanged(true);
        const newVersion = changePreviewVersion();
        delayUpdateTemplate(newVersion);
    };

    const handleValueChanges = () => {
        if (valueChanges) {
            if (contentSelectionFields.some(f => f in valueChanges)) {
                clearData();
                const selectionValues = getFieldsValue(contentSelectionFields);
                dispatch(changeContentSelectionAction(selectionValues));
            }
            let hasChanged = false;
            const newFields = [...fields];
            editableFields.forEach(editableField => {
                if (editableField.id in valueChanges) {
                    let currentFieldChanged = get(editableField, 'type') !== 'rich' || editorTouched[editableField.id];
                    if (currentFieldChanged) {
                        fieldChanged[editableField.id] = true;
                        hasChanged = true;
                        const editingField = find(newFields, f => f.id === editableField.id);
                        if (editingField) {
                            editingField.value = valueChanges[editableField.id];
                        }
                    }
                }
            });
            if (hasChanged) {
                setFields(newFields);
                triggerUpdateContent();
            }
        }
    };

    useEffect(handleValueChanges, [valueChanges]);

    const saveCompleteCallback = ({
        IsSuccess,
        Messages,
    }: DynamicObject) => {
        if (IsSuccess) {
            clearData();
            refreshCurrentContent();
            Modal.success({
                title: getTranslatedText("Success"),
                content: getTranslatedText("Saved content succesfully!"),
                okText: getTranslatedText("OK")
            });
        } else {
            let errorMessageContent: any = getTranslatedText("Failed to save content!");
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }
            Modal.error({
                title: getTranslatedText("Error"),
                content: errorMessageContent,
                okText: getTranslatedText("OK")
            });
        }
    };

    const onSave = () => {
        if (currentTemplate) {
            const saveTemplate = () => {
                const payload = generatePayload(currentTemplate);
                dispatch(saveContentTemplateAction(payload, saveCompleteCallback));
            };

            confirm({
                className: 'modal-swapped-buttons',
                title: getTranslatedText("Confirm"),
                content: (
                    <div>
                        {getTranslatedText("Are you sure you want to save the content of this template?")}
                    </div>
                ),
                onOk: saveTemplate,
                onCancel: undefined,
                okText: getTranslatedText(confirmModalOkText),
                cancelText: getTranslatedText(confirmModalCancelText),
            });
        }
    };

    const changePreviewVersion = () => {
        const version = `${new Date().getTime()}`;
        dispatch(changePreviewVersionAction(version));
        return version;
    }

    const resetForm = () => {
        resetFields();
        setFormHasChanged(false);
        setFields(constructInitialFields(editableFields));
        setEditableFields(prev => [...prev]);
        setEditorTouched({});
        setFieldChanged({});
        setCurrentTemplate(get(selectedContentTemplate, 'Content'));
        setCurrentPreview(get(selectedContentTemplate, 'Preview'));
        setRendering(false);
        changePreviewVersion();
    };

    const clearData = () => {
        setCurrentTemplate(undefined);
        setFormHasChanged(false);
        setCurrentPreview(undefined);
        setFields([]);
        setGeneralDescription(undefined);
        setEditableFields([]);
        setEditorTouched({});
        setFieldChanged({});
        setRendering(false);
        changePreviewVersion();
    };

    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText("Continue"),
            content: (
                <div dangerouslySetInnerHTML={{ __html: getTranslatedText(`When you click the <b>${confirmModalOkText}</b> button, all the data will be reverted to the last saved values`) }} />
            ),
            onOk: resetForm,
            onCancel: undefined,
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    const handleUseDefaultChanged = (editableField: IEditableField) => (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        changeUseDefault(editableField.id, checked);
        if (checked) {
            editorTouched[editableField.id] = false;
            fieldChanged[editableField.id] = true;
            const defaultContent = editableField.defaultContent;
            setFieldsValue({
                [editableField.id]: defaultContent
            });
            setFields(prev => {
                const editingField = find(prev, f => f.id === editableField.id);
                if (editingField) {
                    editingField.value = defaultContent
                    return [...prev];
                }
                return prev;
            });
            triggerUpdateContent();
        }
    };

    const populateEditableFieldsSection = () => {
        const populateEditor = (field: IEditableField) => {
            if (field.type === 'raw') {
                return <TextArea
                    readOnly={formDisabled}
                    rows={7}
                />;
            }

            return <ReactQuillMemoized
                rerenderTrigger={getFieldValue(field.id)}
                theme="snow"
                {...reactQuillSettings}
                readOnly={formDisabled}
                onFocus={(_) => editorTouched[field.id] = true}
            />
        };

        return editableFields.length === 0
            ? <Col span={24}>{getTranslatedText("There is nothing to customize")}</Col>
            : map(editableFields, (field, idx) => {
                const editingField = find(fields, f => f.id === field.id);
                return <Col key={field.id} xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Row key={field.id} gutter={[8, 8]}>
                        <Col span={24}>
                        </Col>
                        <Col span={24}>
                            <p><strong>{getTranslatedText(field.label)}</strong></p>
                            <p dangerouslySetInnerHTML={{ __html: getTranslatedText(field.description) }}></p>
                            {field.type === 'rich' && <p>
                                {getTranslatedText("Wrap mode")}&nbsp;{field.wrap
                                    ? <Tooltip
                                        title={getTranslatedText("This is a wrapped field, each block of text will be wrapped by a predefined HTML tag")}
                                        placement="topLeft">
                                        <Tag color="green">{getTranslatedText("On")}</Tag>
                                    </Tooltip>
                                    : <Tooltip
                                        title={getTranslatedText("This is a no-wrap field, you must add an extra line-break to break a line in the template")}
                                        placement="topLeft">
                                        <Tag color="red">{getTranslatedText("Off")}</Tag>
                                    </Tooltip>}
                            </p>}
                            <Checkbox
                                style={{ padding: 0, marginBottom: 10 }}
                                checked={get(editingField, 'useDefault')}
                                onChange={handleUseDefaultChanged(field)}>
                                {getTranslatedText("Use default content")}
                            </Checkbox>
                            <FormItem>
                                {getFieldDecorator(
                                    field.id,
                                    {
                                        initialValue: field.initialValue
                                    }
                                )(populateEditor(field))}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>;
            })
    };

    const previewErrorsSection = previewErrors.length > 0
        && map(previewErrors, (error, idx) => (<div key={idx} style={{ color: DANGER_COLOR }}>
            {getTranslatedText(error)}
        </div>))

    const populateCustomizationSection = () => {
        if (errorMessages.length > 0) {
            return <Empty description={getTranslatedText(get(errorMessages, 0))} />;
        }

        if (!formFulfilled) {
            return <>{getTranslatedText("Please fill in the above values")}</>;
        }

        return <>
            <Row gutter={[30, 10]}>
                <Col span={24}>
                    <Title level={4}>{getTranslatedText("Customization")}&nbsp;
                        <Tooltip
                            title={getTranslatedText("Rich text editor will replace the default styling. Therefore you should carefully review the modified content template before saving")}
                            placement="topLeft">
                            <FontAwesome
                                icon={['fas', 'info-circle']}
                                className='primary-blue'
                            />
                        </Tooltip>
                    </Title>
                </Col>
                {generalDescription && <Col span={24}><p dangerouslySetInnerHTML={{ __html: generalDescription }}></p></Col>}
                {populateEditableFieldsSection()}
            </Row>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <Title level={4}>{getTranslatedText("Preview")}</Title>
                    {previewErrorsSection}
                </Col>
                <Col span={24}>
                    <ContentPreview rendering={rendering}
                        previewContent={currentPreview}
                        isHtml={get(selectedContentTemplate, 'IsHtml') || false} />
                </Col>
            </Row>
        </>
    };

    return <Row>
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText(formTitle)}</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                <div className="h-100">
                    <Col span={24}>
                        <Form>
                            <QueueAnim type={['right', 'left']} leaveReverse>
                                <Row key="heading-container" align="middle">
                                    <Col md={16} xs={24}>
                                        {generateContentsFilter(clearData)}
                                    </Col>
                                    <Col md={8} xs={24} className="ta-right">
                                        <div>
                                            <Button
                                                className="mr-10 w-100px"
                                                type="primary"
                                                onClick={onSave}
                                                disabled={formDisabled || !formHasChanged || previewErrors.length > 0 || rendering}
                                                loading={saveLoading || rendering}
                                            >
                                                {getTranslatedText("Save")}
                                            </Button>
                                            <Button
                                                className="buttonGrey w-100px"
                                                onClick={onCancelButtonClick}
                                                disabled={formDisabled || !formHasChanged}
                                            >
                                                {getTranslatedText("Cancel")}
                                            </Button>
                                        </div>
                                        {previewErrorsSection}
                                    </Col>
                                </Row>
                                <Spin spinning={contentLoading}>
                                    {populateCustomizationSection()}
                                </Spin>
                            </QueueAnim>
                        </Form>
                        {saveLoading && (
                            <ModalWithSpinner
                                modalTitle={getTranslatedText("Saving content data")}
                                modalVisible={saveLoading}
                                displayMessage={getTranslatedText("Please wait while saving content data. . .")}
                            />
                        )}
                    </Col>
                </div>
            </QueueAnim>
        </Col>
    </Row>
};

export default ContentsManagementForm;