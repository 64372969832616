/**
 * File responsible for the content when clicking `Add comment` in management pages.
 */

import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Row } from 'antd';
import { capitalize, get, map } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadToExcelModalResponseHandler, formatDateToDateObjectUTC, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject, ResponseModalObject } from '../../utils/commonInterfaces';
import { dateFormatDDMMMYYYYSpace, dateFormatYYYYMMDDDash, dateFormatYYYYMMDDTHHmmssDash } from '../../constants/dateFormats';
import moment from 'moment-timezone';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    activeTasksSummaryPageQuery,
    activeTasksSummaryQueryName,
    archiveTasksSummaryPageQuery,
    archiveTasksSummaryQueryName,
    creditsSummaryPageQuery,
    creditsSummaryQueryName,
    customerAssistanceSummaryPageQuery,
    customerAssistanceSummaryQueryName,
    customersSummaryPageQuery,
    customersSummaryQueryName,
    historyDeliveryReportPageQuery,
    historyDeliveryReportQueryName,
    inactiveTasksSummaryPageQuery,
    inactiveTasksSummaryQueryName,
    invoicesSummaryPageQuery,
    invoicesSummaryQueryName,
    paymentPlansSummaryPageQuery,
    paymentPlansSummaryQueryName,
    paymentsSummaryPageQuery,
    paymentsSummaryQueryName,
    remittanceAdvicesSummaryQueryName,
    remittanceAdvicessSummaryPageQuery,
    taskHistorySummaryPageQuery,
    taskHistorySummaryQueryName
} from '../../constants/downloadToExcel';
import { PAGE_NAMES_FOR_VIEW } from '../../config/tableAndPageConstants';
import { ReportType } from '../../constants/reportSortAndFilters';
import { CompanyUserRole } from '../../store/companies/types';
import { ApplicationState } from '../../store';
import { withAccountingSystemHandler } from './AccountingSystemHandler';
import CreateCompleteScheduledReportDrawer from './CreateCompleteScheduledReportDrawer';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Item: FormItem } = Form;

interface IProps {
    readonly containerRef?: any;
    readonly visible: any;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly dispatchAction?: (payload: any, callback: any) => void;
    readonly isUsingCloudImportType: boolean;
}

const DownloadCollectionPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    dispatchAction,
    isUsingCloudImportType
}: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const isPaymentPlanEnabled = get(
        selectedUserCompany,
        'Company.CompanyPaymentPlan.IsEnabled'
    );
    const supportCashAllocation = get(
        selectedUserCompany,
        'Company.SupportCashAllocation'
    );
    const lockedDeliveryMethod = get(
        selectedUserCompany,
        'Company.LockedDeliveryMethod'
    );
    const usingMultipleWorkflow = get(
        selectedUserCompany,
        'Company.UsingMultipleWorkflow'
    );

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [reportTypesToFetch, setReportTypesToFetch] = useState<any[]>([]);
    const [isFilterByCreatedDateChecked, setFilterByCreatedDateChecked] = useState<boolean>(false);
    const [isReportTypeChecked, setReportTypeChecked] = useState<boolean>(false);
    const { getFieldDecorator, getFieldValue, validateFields, resetFields } = form;
    const [displayCreateScheduledReport, setDisplayCreateScheduledReport] = useState<boolean>(false);

    const [createCompleteScheduledReportPayload, setCreateCompleteScheduledReportPayload] = useState<{
        Type: number;
        Description: string;
        ReportsForComplete: any[];
    }>({
        Type: 3,
        Description: '',
        ReportsForComplete: []
    });


    const isStartReportButtonDisable = !((isReportTypeChecked && !isFilterByCreatedDateChecked) ||
        (isReportTypeChecked && (isFilterByCreatedDateChecked && getFieldValue('FromDate') && getFieldValue('ToDate'))));

    /**
     * Function called when `Cancel` button is clicked inside Add comment panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function init ReportTypesToFetch.
     */
    const initReportTypesToFetch = () => {
        if (visible) {
            const bankingChildren: DynamicObject[] = [{
                pageName: PAGE_NAMES_FOR_VIEW.PAYMENTS_PAGE,
                query: paymentsSummaryPageQuery,
                operationName: paymentsSummaryQueryName,
                displayName: getTranslatedText("Payments"),
                type: ReportType.Page,
                defaultVariables: { "SortField": "Paid date", "Ascending": false }
            }];

            if (isPaymentPlanEnabled) {
                bankingChildren.push({
                    pageName: PAGE_NAMES_FOR_VIEW.PAYMENT_PLANS_PAGE,
                    query: paymentPlansSummaryPageQuery,
                    operationName: paymentPlansSummaryQueryName,
                    displayName: getTranslatedText("Payment Plans"),
                    type: ReportType.Page,
                    defaultVariables: { "SortField": "Company name", "Ascending": false }
                });
            }

            if (supportCashAllocation) {
                bankingChildren.push({
                    pageName: PAGE_NAMES_FOR_VIEW.REMITTANCE_ADVICES_PAGE,
                    query: remittanceAdvicessSummaryPageQuery,
                    operationName: remittanceAdvicesSummaryQueryName,
                    displayName: getTranslatedText("Remittance Advices"),
                    type: ReportType.Page,
                    defaultVariables: { "SortField": "received date", "Ascending": false }
                });
            }

            setReportTypesToFetch([
                {
                    displayName: getTranslatedText("Tasks"),
                    id: 1,
                    children: [
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.TASKS_PAGE,
                            query: activeTasksSummaryPageQuery,
                            operationName: activeTasksSummaryQueryName,
                            displayName: getTranslatedText("Active Tasks"),
                            type: ReportType.Page,
                            defaultVariables: {
                                "Status": 1,
                                "SortField": "ActionDate",
                                "ThenSortField": "",
                                "Ascending": true,
                                "IsCloudImportType": isUsingCloudImportType,
                                "LockedDeliveryMethod": lockedDeliveryMethod,
                                "IsMultipleWorkflow": usingMultipleWorkflow
                            }
                        },
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.TASK_HISTORY_PAGE,
                            query: taskHistorySummaryPageQuery,
                            operationName: taskHistorySummaryQueryName,
                            displayName: getTranslatedText("Task History"),
                            type: ReportType.Page,
                            defaultVariables: {
                                "SortField": "BatchActionDate",
                                "Ascending": false
                            },
                            createdDateProperty: 'ActionDate'
                        },
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.TASK_DELIVERY_REPORT_PAGE,
                            query: historyDeliveryReportPageQuery,
                            operationName: historyDeliveryReportQueryName,
                            displayName: getTranslatedText("Delivery History"),
                            defaultVariables: { "SortField": "CustomerCode", "Ascending": true },
                            type: ReportType.Page
                        },
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.INACTIVE_TASKS_PAGE,
                            query: inactiveTasksSummaryPageQuery,
                            operationName: inactiveTasksSummaryQueryName,
                            displayName: getTranslatedText("Inactive Tasks"),
                            type: ReportType.Page,
                            defaultVariables: {
                                "SortField": "ActionDate",
                                "ThenSortField": "",
                                "Ascending": true,
                                "IsCloudImportType": isUsingCloudImportType,
                                "LockedDeliveryMethod": lockedDeliveryMethod,
                                "IsMultipleWorkflow": usingMultipleWorkflow
                            }
                        },
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.ARCHIVE_TASKS_PAGE,
                            query: archiveTasksSummaryPageQuery,
                            operationName: archiveTasksSummaryQueryName,
                            displayName: getTranslatedText("Archive Tasks"),
                            type: ReportType.Page,
                            defaultVariables: {
                                "SortField": "ActionDate",
                                "ThenSortField": "",
                                "Ascending": true,
                                "IsCloudImportType": isUsingCloudImportType,
                                "LockedDeliveryMethod": lockedDeliveryMethod,
                                "IsMultipleWorkflow": usingMultipleWorkflow
                            }
                        }
                    ]
                },
                {
                    displayName: getTranslatedText("Sales"),
                    id: 2,
                    children: [
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.CUSTOMERS_PAGE,
                            query: customersSummaryPageQuery,
                            operationName: customersSummaryQueryName,
                            displayName: getTranslatedText(`${capitalize(customerLabel)}s`),
                            type: ReportType.Page,
                            defaultVariables: { "Customer": "", "InclusiveContact": true, "Contact": 0, "AmountType": "", "AmountValue": "", "AmountOperator": "", "ATBType": 2, "SortField": "Company name", "Ascending": true, "IsCloudImportType": isUsingCloudImportType, "IsMultipleWorkflow": usingMultipleWorkflow, "UsingCustomerWorkflow": false }
                        },
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.INVOICES_PAGE,
                            query: invoicesSummaryPageQuery,
                            operationName: invoicesSummaryQueryName,
                            displayName: getTranslatedText("Invoices"),
                            type: ReportType.Page,
                            defaultVariables: { "InvoiceNumber": "", "Customer": "", "CustomerCode": "", "CreatedDateMin": "", "CreatedDateMax": "", "DueDateMin": "", "DueDateMax": "", "AmountType": "", "AmountValue": "", "AmountOperator": "", "InvoiceState": "Open", "SortField": "Invoice number", "Ascending": true, "IsCloudImportType": isUsingCloudImportType, "IsMultipleWorkflow": usingMultipleWorkflow, "UsingCustomerWorkflow": false }
                        },
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.CREDITS_PAGE,
                            query: creditsSummaryPageQuery,
                            operationName: creditsSummaryQueryName,
                            displayName: getTranslatedText("Credits"),
                            type: ReportType.Page,
                            defaultVariables: { "CreditState": "Open", "SortField": "Credit number", "Ascending": true }
                        }
                    ]
                },
                {
                    displayName: `${capitalize(customerLabel)} Enquires`,
                    id: 3,
                    children: [
                        {
                            pageName: PAGE_NAMES_FOR_VIEW.CUSTOMER_ASSISTANCE_PAGE,
                            query: customerAssistanceSummaryPageQuery,
                            operationName: customerAssistanceSummaryQueryName,
                            displayName: getTranslatedText("Tickets"),
                            type: ReportType.Page,
                            defaultVariables: { "DisplayName": "", "CustomerCode": "", "CreatedDateMin": "", "CreatedDateMax": "", "ClosedDateMin": "", "ClosedDateMax": "", "LastChatDateMin": "", "LastChatDateMax": "", "Status": -1, "TicketOptionReasons": [], "SortField": "OpenDate", "ThenSortField": "", "Ascending": false }
                        }
                    ]
                },
                {
                    displayName: getTranslatedText("Banking"),
                    id: 4,
                    children: bankingChildren
                }
            ]);
        }
    };

    useEffect(initReportTypesToFetch, [
        visible, customerLabel, isPaymentPlanEnabled, supportCashAllocation,
        isUsingCloudImportType]);

    /**
     * Function called when start downloading the complete report click.
     */
    const handleStartDownloadForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                if (dispatchAction) {
                    const ReportsToFetch: any[] = [];
                    let minDate: string | moment.Moment = getFieldValue('FromDate');
                    let maxDate: string | moment.Moment = getFieldValue('ToDate');
                    minDate = minDate && moment(minDate).format(dateFormatYYYYMMDDDash);
                    maxDate = maxDate && moment(maxDate).format(dateFormatYYYYMMDDDash);
                    const minDateWithTimeLocal = minDate ? minDate + 'T00:00:00' : null;
                    const maxDateWithTimeLocal = maxDate ? maxDate + 'T23:59:59' : null;

                    if (formatDateToDateObjectUTC) {
                        if (minDateWithTimeLocal) {
                            minDate = formatDateToDateObjectUTC(
                                minDateWithTimeLocal,
                                dateFormatYYYYMMDDTHHmmssDash
                            );
                        }

                        if (maxDateWithTimeLocal) {
                            maxDate = formatDateToDateObjectUTC(
                                maxDateWithTimeLocal,
                                dateFormatYYYYMMDDTHHmmssDash
                            );
                        }
                    }

                    reportTypesToFetch.map((r) => {
                        if (r.children && r.children.length > 0) {
                            r.children.map((c: any) => {
                                const variables = {
                                    ...(c.defaultVariables || {}),
                                    [`${c.createdDateProperty || 'CreatedDate'}Min`]: minDate,
                                    [`${c.createdDateProperty || 'CreatedDate'}Max`]: maxDate
                                };
                                if (c.isChecked) {
                                    ReportsToFetch.push({
                                        Type: c.type,
                                        Query: c.query,
                                        OperationName: c.operationName,
                                        Variables: JSON.stringify(variables),
                                        PageName: c.pageName
                                    });
                                }
                            })
                        }
                    });

                    dispatch(
                        dispatchAction(
                            {
                                ReportsToFetch,
                                CreatedDateMin: minDate,
                                CreatedDateMax: maxDate,
                            },
                            downloadReportResponseModal
                        )
                    );
                } else {
                    if (closePanel) closePanel(true);
                }
            }
        });
    };

    /**
     * Function called when start downloading the complete report click.
     */
    const handleCreateScheduledReportForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                    const ReportsToFetch: any[] = [];
                    let minDate: string | moment.Moment = getFieldValue('FromDate');
                    let maxDate: string | moment.Moment = getFieldValue('ToDate');
                    minDate = minDate && moment(minDate).format(dateFormatYYYYMMDDDash);
                    maxDate = maxDate && moment(maxDate).format(dateFormatYYYYMMDDDash);
                    const minDateWithTimeLocal = minDate ? minDate + 'T00:00:00' : null;
                    const maxDateWithTimeLocal = maxDate ? maxDate + 'T23:59:59' : null;

                    if (formatDateToDateObjectUTC) {
                        if (minDateWithTimeLocal) {
                            minDate = formatDateToDateObjectUTC(
                                minDateWithTimeLocal,
                                dateFormatYYYYMMDDTHHmmssDash
                            );
                        }

                        if (maxDateWithTimeLocal) {
                            maxDate = formatDateToDateObjectUTC(
                                maxDateWithTimeLocal,
                                dateFormatYYYYMMDDTHHmmssDash
                            );
                        }
                    }

                    reportTypesToFetch.map((r) => {
                        if (r.children && r.children.length > 0) {
                            r.children.map((c: any) => {
                                const variables = {
                                    ...(c.defaultVariables || {}),
                                    [`${c.createdDateProperty || 'CreatedDate'}Min`]: minDate,
                                    [`${c.createdDateProperty || 'CreatedDate'}Max`]: maxDate
                                };
                                if (c.isChecked) {
                                    ReportsToFetch.push({
                                        Type: c.type,
                                        Query: c.query,
                                        OperationName: c.operationName,
                                        Variables: JSON.stringify(variables),
                                        PageName: c.pageName
                                    });
                                }
                            })
                        }
                    });

                    const payload = {
                        Type: 3, 
                        Description: 'Complete Report',
                        ReportsForComplete: ReportsToFetch
                    }
                    
                    setCreateCompleteScheduledReportPayload(payload);
                    setDisplayCreateScheduledReport(true);
                
                } else {
                    if (closePanel) closePanel(true);
                }
        });
    };

    /**
     * Callback function after calling the download complete report api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadReportResponseModal = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        setSubmitLoading(false);
        downloadToExcelModalResponseHandler(IsSuccess, Messages, () => {
            if (closePanel) closePanel(true);
        });
    };

    /**
     * Common function that update the selectedReportTypes object.
     */
    const update = (displayName: string, isChecked: boolean) => (reportTypeObj: any) => {
        if (reportTypeObj.displayName === displayName) {
            reportTypeObj.isChecked = isChecked;
            if (reportTypeObj.children) {
                reportTypeObj.children = reportTypeObj.children.map((c: any) => {
                    return { ...c, isChecked: isChecked }
                });
            }
        }
        else if (reportTypeObj.children) {
            if (reportTypeObj.children.find((c: any) => c.displayName === displayName)) {
                if (!isChecked || reportTypeObj.children.filter((c: any) => c.displayName !== displayName).some((c: any) => !c.isChecked)) {
                    reportTypeObj.isChecked = false;
                }
                else {
                    reportTypeObj.isChecked = true;
                }
            }

            reportTypeObj.children.forEach(update(displayName, isChecked));
        }
    };

    /**
     * Function triggered when checkbox is toggled.
     * @param e
     */
    const handleReportTypeCheck = (displayName: string, checked: boolean) => {
        reportTypesToFetch.forEach(update(displayName, checked));
        setReportTypesToFetch([...reportTypesToFetch]);
        setReportTypeChecked(reportTypesToFetch.some(r => r.isChecked || (r.children && r.children.some((c: any) => c.isChecked))));
    };

    /**
     * Function responsible for populating the children ReportTypesToFetch.
     * Form fields.
     */
    const populateChildrenReportTypesToFetch = (reportToFetch: DynamicObject) => {
        return (
            <Col key={reportToFetch.displayName} style={{ margin: '20px' }}>
                <Checkbox
                    value={reportToFetch.displayName}
                    checked={reportToFetch.isChecked}
                    onChange={(e) => handleReportTypeCheck(e.target.value, e.target.checked)}
                />
                <span className='ml-8'>{reportToFetch.displayName}</span>
            </Col>
        );
    };


    /**
     * Function responsible for populating the ReportTypesToFetch.
     * Form fields.
     */
    const populateReportTypesToFetch = (reportToFetch: DynamicObject) => {
        return (
            <Row key={'report-type-' + reportToFetch.id}>
                <div>
                    <Checkbox
                        value={reportToFetch.displayName}
                        checked={reportToFetch.isChecked}
                        onChange={(e) => handleReportTypeCheck(e.target.value, e.target.checked)}
                    />
                    <span style={{ marginLeft: '8px', fontWeight: 600 }}>{reportToFetch.displayName}</span>

                    <Row style={{ display: 'flex', marginLeft: '30px' }}>
                        {reportToFetch && map(reportToFetch.children, populateChildrenReportTypesToFetch)}
                    </Row>
                </div>
            </Row>
        );
    };

    /**
     * Function triggered when Filter By Created Date checkbox is toggled.
     * @param e
     */
    const handleFilterByCreatedDateCheck = (checked: boolean) => {
        setFilterByCreatedDateChecked(checked);
    };

    /**
     * Function that restricts the dates that can be selected by end date
     * @param dateValue
     */
    const restrictEndDate = (dateValue: any) => {
        return moment(moment(dateValue)).isBefore(
            moment(moment(getFieldValue('FromDate')))
        );
    };

    /**
     * Function responsible for populating the optional filter.
     * Form fields.
     */
    const populateOptionalFilter = () => {
        return (
            <div>
                <Checkbox
                    checked={isFilterByCreatedDateChecked}
                    onChange={(e) => handleFilterByCreatedDateCheck(e.target.checked)}
                />
                <span style={{ marginLeft: '8px', fontWeight: 600 }}>{getTranslatedText("Filter By Created Date")}</span>

                {isFilterByCreatedDateChecked && (
                    <Row style={{ display: 'flex', marginTop: '20px' }}>
                        <Col className="mb-10" span={12}>
                            <FormItem label={getTranslatedText("From")} labelAlign='left'>
                                {getFieldDecorator('FromDate', {
                                    initialValue: moment(),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('From date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("From")}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={getTranslatedText("To")} labelAlign='left'>
                                {getFieldDecorator('ToDate', {
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('To date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("To")}
                                        disabledDate={restrictEndDate}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>)
                }
            </div>
        );
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <div className="h-100">
                <Form
                    labelCol={{
                        xxl: { span: 5 },
                        xl: { span: 5 },
                        lg: { span: 4 },
                        md: { span: 4 },
                        sm: { span: 4 },
                        xs: { span: 4 },
                    }}
                    wrapperCol={{
                        xxl: { span: 19 },
                        xl: { span: 19 },
                        lg: { span: 20 },
                        md: { span: 20 },
                        sm: { span: 20 },
                        xs: { span: 20 },
                    }}
                >
                    <Card title={getTranslatedText("Step 1. Select which data to export from IODM")}>
                        {reportTypesToFetch && map(reportTypesToFetch, populateReportTypesToFetch)}
                    </Card>
                    <Divider />
                    <Card title={getTranslatedText("Step 2. As an option, select a date range")}>
                        {populateOptionalFilter()}
                    </Card>
                </Form>
            </div>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right mt-10" span={24}>
                    {/* create a schedule code on Monday */}
                    <Button
                            className="mr-10"
                            type="primary"
                            disabled={isStartReportButtonDisable}
                            onClick={handleCreateScheduledReportForm}
                        >
                            Create Scheduled Report
                        </Button>
                        <Button
                            className="mr-10"
                            type="primary"
                            disabled={isStartReportButtonDisable}
                            onClick={handleStartDownloadForm}
                        >
                            {getTranslatedText("Download report")}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText("Cancel")}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Downloading collection report")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while downloading collection report . . .")}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
             <CreateCompleteScheduledReportDrawer
                    visible={displayCreateScheduledReport}
                    onClose={(closePanel?: boolean)=> {
                        if(closePanel)
                            handleClosePanel();
                        else
                            setDisplayCreateScheduledReport(false);}
                    }
                    widgetQuery={createCompleteScheduledReportPayload}
            />
        </Row>
    );
};

const DownloadCollectionPanelForm = Form.create({
    name: 'download-collection-panel-form',
})(DownloadCollectionPanel);

export default withAccountingSystemHandler(
    DownloadCollectionPanelForm
);
