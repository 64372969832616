/**
 * File responsible for the content when clicking `Cancel plan` in management pages.
 */

import { Button, Checkbox, Col, Form, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get, isEmpty, map, toLower } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer } from '../../utils/commonFunctions';
import { actionPaymentPlanRequestAction, cancelPaymentPlanRequestAction } from '../../store/paymentPlans/actions';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getTranslatedText } from '../../utils/commonFunctions';
import { LOCATION_CHANGE } from 'connected-react-router';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Item: FormItem } = Form;

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly filterPayload?: {};
    readonly dispatchAction?: (payload: any) => void;
}

const PaymentPlanCancelPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    filterPayload,
    dispatchAction,
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { getFieldDecorator, validateFields, resetFields } = form;
    const customerLabel = useSelector(getCustomerUILabel);

    /**
     * Function called when `Cancel` button is clicked inside Add comment panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                if (dispatchAction) {
                    dispatch(
                        dispatchAction({
                            ...filterPayload,
                            comment: get(values, 'Comment'),
                            notifyCustomerCancellation: get(values, 'NotifyCustomer'),
                            callback: cancelPaymentPlanResponseModal,
                        })
                    );
                } else {
                    if (closePanel) closePanel(true);
                }

                // setTimeout(() => {
                //     addCommentResponseModal({
                //         IsSuccess: false,
                //         Messages: ['UI Simulation only'],
                //     });
                // }, 3000);
            }
        });
    };

    /**
     * Function responsible for showing the response modal after cancelling the payment plan.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const cancelPaymentPlanResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Payment plan/s cancelled successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent: any = getTranslatedText('Failed to cancel payment plan/s!');
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row>
                    <Col span={24} className="mb-10">
                        <div>
                            {getTranslatedText('You are about to cancel the selected payment plans. If there are amounts still owing on the associated invoices, reminders shall begin again.')}
                        </div>
                    </Col>
                    <Col span={24}>
                        {getTranslatedText('Provide a comment on why these plans are being cancelled')}
                    </Col>
                    <Col span={24}>
                        <FormItem>
                            {getFieldDecorator('Comment', {
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Comment required!'),
                                    },
                                ],
                            })
                            (<TextArea rows={4} />)}
                            {getFieldDecorator('NotifyCustomer', {
                                valuePropName: 'checked',
                            })(
                                <Checkbox>
                                    <span className="grey">
                                        {getTranslatedText(`Notify ${toLower(customerLabel)} on payment plan cancelation`)}
                                    </span>
                                </Checkbox>
                            )}
                            <div style={{ lineHeight: 'initial' }}>
                                {getTranslatedText(`Please note, your comment is included in the cancel notification to the ${toLower(customerLabel)}.`)}
                            </div>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            {getTranslatedText('Save')}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Adding comment")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while adding the comment . . .")}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const PaymentPlanCancelPanelForm = Form.create({
    name: 'payment-plan-cancel-panel-form',
})(PaymentPlanCancelPanel);

export default withRouter(PaymentPlanCancelPanelForm);
