import update from 'immutability-helper';
import { concat, get, isEqual, uniqWith } from 'lodash';
import { Reducer } from 'redux';
import { CREDITS_PAGE, DETAILS_TAB } from '../../config/tableAndPageConstants';
import { conversationTableFilterOptions } from '../../constants/common';
import {
    creditsSortByOptions,
    creditsStateFilterOptions,
    creditChangesTableFilterOptions,
    rebatesStateFilterOptions,
    rebatesSortByOptions,
} from '../../constants/creditsSortAndFilters';
import { CreditsActionTypes, CreditsState } from './types';

// Type-safe initialState!
export const initialState: CreditsState = {
    loading: false,
    saveCreditDataLoading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: CREDITS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {},
    tableFilter: undefined,
    creditState: get(creditsStateFilterOptions, '0.value'),
    sortBy: creditsSortByOptions[0].value, //0 for Credit number
    sortAscending: true,
    activeData: {
        record: {},
        conversation: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            conversationTableFilter: get(
                conversationTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        creditChanges: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            creditChangesTableFilter: get(
                creditChangesTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        rebates: {
            loading: false,
            saveCreditDataLoading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: CREDITS_PAGE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            tableFilter: undefined,
            creditState: get(rebatesStateFilterOptions, '0.value'),
            sortBy: rebatesSortByOptions[0].value, //0 for Credit number
            sortAscending: true,
        },
        loading: false,
        selectedId: null,
        errorMessages: [],
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<CreditsState> = (state = initialState, action) => {
    switch (action.type) {
        case CreditsActionTypes.GET_CREDITS_FOR_ORGANISATION_REQUEST:
        case CreditsActionTypes.GET_CREDITS_REQUEST: {
            return { ...state, loading: true };
        }
        case CreditsActionTypes.GET_CREDITS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.data, {
                    $set: action.payload.data,
                });
            } else {
                // update(state.data, {
                //     $push: action.payload.data,
                // });
                newDataState = uniqWith(
                    concat(state.data, action.payload.data),
                    isEqual
                );
            }
            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case CreditsActionTypes.GET_CREDITS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case CreditsActionTypes.GET_REBATES_REQUEST: {
            return { ...state, loading: true };
        }
        case CreditsActionTypes.GET_REBATES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.rebates.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.rebates.data, 
                           action.payload.data),
                    isEqual
                );
            }

            const newRebateState = update(state.activeData.rebates, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.rebates.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebateState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CreditsActionTypes.GET_REBATES_ERROR: {
            const newRebatesState = update(
                state.activeData.rebates,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeData.rebates.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebatesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CreditsActionTypes.UPDATE_CREDITS_FILTERS: {
            return { ...state, filters: action.payload };
        }

        case CreditsActionTypes.UPDATE_CREDITS_SORT_BY_AND_STATE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending,
                creditState: action.payload.creditState,
            };
        }

        case CreditsActionTypes.UPDATE_CREDITS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }

        case CreditsActionTypes.CLEAR_CREDITS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                creditState: initialState.creditState,
                tableFilter: initialState.tableFilter,
            };
        }

        case CreditsActionTypes.CLEAR_CREDITS_STATE_DATA: {
            return {
                ...initialState,
            };
        }
        case CreditsActionTypes.CLEAR_CREDITS_LIST_DATA: {
            return {
                ...state,
                loading: false,
                data: [],
                pageData: initialState.pageData,
                errorMessages: initialState.errorMessages,
            };
        }

        //Single Record
        case CreditsActionTypes.SET_CREDIT_SELECTED_ID_REQUEST: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: initialState.activeData.selectedId,
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.SET_CREDIT_SELECTED_ID_SUCCESS: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: action.payload,
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CreditsActionTypes.GET_ORGANISATION_CREDIT_DATA_REQUEST:
        case CreditsActionTypes.GET_CREDIT_DATA_REQUEST: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: true },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.GET_CREDIT_DATA_SUCCESS: {
            const newActiveData = update(state.activeData, {
                record: { $set: action.payload.record },
                loading: { $set: false },
                errorMessages: { $set: initialState.activeData.errorMessages },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.GET_CREDIT_DATA_ERROR: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: false },
                errorMessages: { $set: action.payload },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.CLEAR_CREDIT_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        // For conversation list reducers
        case CreditsActionTypes.GET_CREDIT_CONVERSATION_REQUEST: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.GET_CREDIT_CONVERSATION_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.conversation.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.conversation.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.conversation.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.GET_CREDIT_CONVERSATION_ERROR: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.conversation.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.UPDATE_CREDIT_CONVERSATION_FILTERS: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.UPDATE_CREDIT_CONVERSATION_TABLE_FILTER: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    conversationTableFilter: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.UPDATE_CREDIT_CONVERSATION_SORT_BY: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For credit changes list reducers
        case CreditsActionTypes.GET_CREDIT_CREDIT_CHANGES_REQUEST:
        case CreditsActionTypes.GET_ORGANISATION_CREDIT_CREDIT_CHANGES_REQUEST: {
            const newCreditChangesState = update(
                state.activeData.creditChanges,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                creditChanges: { $set: newCreditChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.GET_CREDIT_CREDIT_CHANGES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.creditChanges.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.creditChanges.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newCreditChangesState = update(
                state.activeData.creditChanges,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeData.creditChanges.errorMessages,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                creditChanges: { $set: newCreditChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.GET_CREDIT_CREDIT_CHANGES_ERROR: {
            const newCreditChangesState = update(
                state.activeData.creditChanges,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeData.creditChanges.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                creditChanges: { $set: newCreditChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.UPDATE_CREDIT_CREDIT_CHANGES_FILTERS: {
            const newCreditChangesState = update(
                state.activeData.creditChanges,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                creditChanges: { $set: newCreditChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.UPDATE_CREDIT_CREDIT_CHANGES_TABLE_FILTER: {
            const newCreditChangesState = update(
                state.activeData.creditChanges,
                {
                    $merge: {
                        creditChangesTableFilter: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                creditChanges: { $set: newCreditChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CreditsActionTypes.UPDATE_CREDIT_CREDIT_CHANGES_SORT_BY: {
            const newCreditChangesState = update(
                state.activeData.creditChanges,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                creditChanges: { $set: newCreditChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as creditsReducer };
