/**
 * Component for populating the TaskActionPaymentBehaviorInsightContent panel when clicking on `Action` button for TaskActionPaymentBehaviorInsightContent task items.
 */

import {
    Button,
    Col,
    Form,
    Row,
    Skeleton,
    Select,
    Modal
} from 'antd';
import { get, map, times,filter,forEach, includes,toLower,isEmpty } from 'lodash';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import ModalWithSpinner from '../../../components/common/ModalWithSpinner';
import {
    getTriggersForWorkflowRequestAction
} from '../../../store/tasks/actions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { ApplicationState } from '../../../store';
import { Task,WorkflowTransition } from '../../../store/tasks/types';
import {
    taskPaymentBehaviorInsightsRequestAction,
} from '../../../store/tasks/actions';
import './active_tasks.less';
import { CompanyUserRole } from '../../../store/companies/types';
import { getTranslatedText } from '../../../utils/commonFunctions';


interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly selectedTaskId: string;
    readonly customerLabel: string;
}
const { Item: FormItem } = Form;
const { Option } = Select;

const formFields = {
    SELECT_ACTION: 'Trigger',
    COMMENT: 'Comment',
    INCLUDE_COMMENT: 'IncludeComment',
};

const TaskActionPaymentBehaviorInsightContent: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    selectedTaskId,
    customerLabel
}: IProps) => {
    
    const [actionOptions, setActionOptions] = useState<{
        options: string[];
        workflowTransitionList: DynamicObject[];
        loading: boolean;
    }>({
        options: [],
        workflowTransitionList: [],
        loading: false,
    });
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const tasksList: Task[] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks.data
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const dispatch = useDispatch();
    const { getFieldDecorator, validateFields, resetFields } = form;

    /**
     * Function for closing the panel.
     * When `Cancel` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };


    /**
     * Listener function if the drawer visibility changes.
     * If drawer closes, resets the field values.
     * If drawer opens, gets action options.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        } else {
            getActionOptions();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when clicking the `Action item` button
     */
    const handleSubmitForm = () => {
        validateFields((err: any, value: DynamicObject) => {
            if (!err) {
                setSubmitLoading(true);
                dispatch(
                    taskPaymentBehaviorInsightsRequestAction({
                        Task: {
                            Id: selectedTaskId,
                        },
                        Action : value.Trigger,
                        callback: taskPaymentBehaviorInsightResponseModal,
                    })
                );
            }
        });
    };

    /**
     * Function for populating the response modal when ticket resolving action has been called.
     * Either success/error.
     * @param param0
     */
    const taskPaymentBehaviorInsightResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Payment behavior insight saved successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                okText: getTranslatedText('OK'),
            });
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to save payment behavior insight!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                okText: getTranslatedText('OK'),
            });
        }
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
    };

    /**
     * Function for updating `actionOptions` state.
     * @param actionOptionsObject - must conform to `actionOptions` state
     */
    const updateActionOptions = (actionOptionsObject: {}) => {
        setActionOptions({
            ...actionOptions,
            ...actionOptionsObject,
        });
    };

    /**
     * Function for fetching the action options to be used in this panel based on the workflowId and workflow statename.
     */
    const getActionOptions = () => {
        if (selectedTaskId) {
            updateActionOptions({
                loading: true,
            });
            const record = get(
                filter(tasksList, ['Id', selectedTaskId]),
                0
            );
            
            dispatch(
                getTriggersForWorkflowRequestAction(
                    get(selectedUserCompany, 'Company.CompanyId'),
                    get(record, 'Workflow.WorkflowId'),
                    get(record, 'Workflow.StateName'),
                    (res: WorkflowTransition[]) => {
                        const options: string[] = [];
                        const workflowTransitionList: DynamicObject = {};
                        forEach(res, (r: WorkflowTransition) => {
                            const trigger = get(r, 'Trigger');
                            if (!includes(options, trigger)) {
                                options.push(trigger);
                            }
                            workflowTransitionList[trigger] = r;
                        });
                        updateActionOptions({
                            options,
                            workflowTransitionList,
                            loading: false,
                        });
                    }
                )
            );
        }
    };
    /**
     * Function to populate the loading content.
     * Shows a loading skeleton if the content is being populated (API not finished fetching data for action options).
     */
    const populatePanelContent = () => {
        if (actionOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {         
            const record = get(
                filter(tasksList, ['Id', selectedTaskId]),
                0
            );
            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <Row className="mb-16" type="flex" align="middle">
                                <Col span={24}>
                                    {getTranslatedText(`This customer has been ${toLower(
                                        record.Customer.PayerState
                                    )} on payments across the last month.`)}
                                    <br/>
                                    {getTranslatedText(`Move to ${toLower(record.Customer.PayerState)} payment workflow?`)}
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-24" type="flex" align="middle">
                                <Col span={10}>
                                    <span className="grey">{getTranslatedText(`Mark customer as ${toLower(
                                        record.Customer.PayerState         
                                    )}`)}:</span>
                                </Col>
                                <Col span={14}>
                                <FormItem>
                                        {getFieldDecorator(
                                            formFields.SELECT_ACTION,
                                            {
                                                initialValue: get(
                                                    actionOptions.options,
                                                    '0'
                                                ),
                                            }
                                        )(
                                            <Select
                                                style={{ width: '100%' }}
                                                loading={actionOptions.loading}
                                            >
                                                {map(
                                                    actionOptions.options,
                                                    (option) => (
                                                        <Option
                                                            key={option}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>                            
                        </Col>
                    </Row>
                </Form>                
            );
        }
    };

    return (
        <Row>
            <Col className="dropdown-multiline-cont">
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            {getTranslatedText('Save')}
                        </Button>
                        <Button className="buttonGrey" onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <ModalWithSpinner
                    modalTitle={getTranslatedText("Actioning payment behavior insight")}
                    modalVisible={submitLoading}
                    displayMessage={getTranslatedText("Please wait while actioning the payment behavior insight . . .")}
                />
            )}
        </Row>
    );
};

const TaskResolveCustomerAssistanceTicketPanelContentForm = Form.create({
    name: 'task-resolve-ticket-panel-form',
})(TaskActionPaymentBehaviorInsightContent);

export default withRouter(
    withDateFormatHandler(TaskResolveCustomerAssistanceTicketPanelContentForm)
);
