import { insertAt } from '../utils/commonFunctions';

export const changeTypesFilter = {
    NewResource: 1,
    UpdatedResource: 2,
    Allocation: 4,
    Deallocation: 8,
    ResourceLink: 16,
    Verified: 32,
    Processed: 64,
    Disregarded: 128,
};

export const resourceTypesFilterOptions = (
    isPaymentPlanEnabled: any,
    supportCashAllocation: any
) => {
    let results = [
        { label: "Invoice", value: "inv" },
        { label: "Customer", value: "cus" },
        { label: "Ticket", value: "tkt" },
        { label: "Credit", value: "cre" },
        { label: "Payment", value: "pay" },
        { label: "Task", value: "tsk" },
    ];

    if (isPaymentPlanEnabled) {
        results = insertAt(
            results,
            results.length,
            [
                { label: "Payment Plan", value: "ppl" },
                { label: "Payment Schedule", value: "pps" },
            ]
        );
    }

    if (supportCashAllocation) {
        results = insertAt(
            results,
            results.length,
            [
                { label: "Remittance Advice", value: "rma" },
            ]
        );
    }
    
    return results;
}

export const changeTypesFilterOptions = (
    supportCashAllocation: any
) => {
    let results = [
        { label: "New Resource", value: 1 },
        { label: "Updated Resource", value: 2 },
        { label: "Allocation", value: 4 },
        { label: "Deallocation", value: 8 },
    ];

    if (supportCashAllocation) {
        results = insertAt(
            results,
            results.length,
            [
                { label: "Resource Link", value: 16 },
                { label: "Verified", value: 32 },
                { label: "Processed", value: 64 },
                { label: "Disregarded", value: 128 },
            ]
        );
    }

    return results;
}

export const sourceTypesFilterOptions = (
    supportCashAllocation: any
) => {
    let results = [
        { label: "Import", dbValue: "Import", value: 0 },
        { label: "Customer", dbValue: "Customer", value: 1 },
        { label: "User", dbValue: "User", value: 2 },
        { label: "Payment URL Click", dbValue: "PaymentURLClick", value: 3 },
        { label: "System", dbValue: "System", value: 4 },
    ];

    if (supportCashAllocation) {
        results = insertAt(
            results,
            results.length,
            [
                { label: "Email", dbValue: "Email", value: 5 },
            ]
        );
    }
    
    return results;
}