/**
 * File responsible for the content when clicking `Add comment` in management pages.
 */

import { Button, Checkbox, Col, Form, Modal, Row, Select, InputNumber, DatePicker, Table, Tooltip, Divider, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment-timezone';
import { filter, findIndex, first, get, isEmpty, map, toLower, every } from 'lodash';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateGUID, getNextMonth, getPopoverContainer, getTranslatedText, removeAppliedFiltersForApiRequest, roundNumberToDecimalDigits } from '../../utils/commonFunctions';
import SelectReadonly from '../../components/FormComponents/SelectReadonly';
import { paymentPlanDepositTypeOptions } from '../../constants/settings';
import { dateFormatDDMMMYYYYSpace, dateFormatMMMYYYYSpace, dateFormatYYYYMMDDTHHmmssSSSSSSDash } from '../../constants/dateFormats';
import FontAwesome from '../common/FontAwesome';
import { InstalmentPayment, PaymentPlan, PaymentPlanInvoice, PaymentPlanSchedule } from '../../store/paymentPlans/types';
import { invoiceStateMap } from '../../constants/invoicesSortAndFilters';
import InputAutoCompleteWithButton from '../common/InputAutoCompleteWithButton';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { DynamicObject } from '../../utils/commonInterfaces';
import ReviewCreatedNewPaymentPlanPanel from './ReviewCreatedNewPaymentPlanPanel';
import { limitOnlyNumber } from '../../utils/commonFunctions';
import { createPaymentPlanAction } from '../../store/paymentPlans/actions';
import { ApplicationState } from '../../store';
import {
    getInvoicesForPaymentPlanRequestAction
} from '../../store/invoices/actions';
import {
    GetInvoicesRequestPayload,
    Invoice,
    InvoicesState,
} from '../../store/invoices/types';
import {
    INVOICES_PAGE
} from '../../config/tableAndPageConstants';
import { CompanyUserRole } from '../../store/companies/types';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { naiveRound } from '../../utils/contentFunctions';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Item: FormItem } = Form;
const { Option } = Select;

interface PaymentPlanInvoiceVM extends PaymentPlanInvoice {
    _touched: boolean;
    SearchValue: string;
}
interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly formatCurrency?: (amount: number) => string;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly closePanel?: (
        refetchList?: boolean
    ) => void;
    readonly form?: any;
    readonly dispatchAction?: (payload: any) => void;
    readonly getSelectedInvoicesValues?: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
    };
    readonly invoiceId?: string | null;
    readonly customerId?: string | null;
    readonly isUsingCloudImportType?: boolean;
}
interface ICombinedProps extends IProps {
    valueChanges?: any;
    setValueChanges: (valueChanges?: any) => void;
}

const INSTALMENT_TYPE = {
    DEFAULT: 'Default',
    MONTHLY: 'Monthly',
}

const PAYMENT_DAY = {
    FIRST_DATE: 'First Day of the Month',
    LAST_DATE: 'Last Day of the Month',
    CUSTOM: 'Custom (Specify Below)',
}

const FORM_FIELDS = {
    CUSTOMER_ID: 'CustomerId',
    INVOICES: 'Invoices',
    PAYMENTPLANINSTALMENTS: 'PaymentPlanInstalments',
    INSTALMENT_AMOUNT: 'InstalmentAmount',
    INSTALMENT_DUE_DATE: 'InstalmentDueDate',
    REQUIRES_DEPOSIT: 'RequiresDeposit',
    DEPOSIT_TYPE: 'DepositType',
    DEPOSIT_PERCENTAGE: 'DepositPercentage',
    DEPOSIT_AMOUNT: 'DepositAmount',
    DEPOSIT_DUE_DATE: 'DepositDueDate',
    IS_SPLIT_INSTALMENT: 'IsSplitInstalment',
    INSTALMENT_TYPE: 'InstalmentType',
    SPLIT_INSTALMENT_DATE_RANGE: 'SplitInstalmentDateRange',
    FROM_MONTH: 'FromMonth',
    PAYMENT_DAY: 'PaymentDate',
    SPECIFIC_PAYMENT_DAY: 'SpecificPaymentDate',
    NUMBER_OF_INSTALMENT: 'NumberOfInstalment',
};

const invoicesTableColumns = [
    {
        title: 'Invoice Number',
        dataIndex: 'InvoiceNumber',
        width: '50%'
    },
    {
        title: 'Remaining Amount',
        dataIndex: 'RemainingAmount',
        width: '50%'
    }
];

const instalmentsTableColums = [
    {
        title: 'Instalment',
        dataIndex: 'InstalmentNumber',
        width: '25%'
    },
    {
        title: 'Instalment Amount',
        dataIndex: 'InstalmentAmount',
        width: '36%'
    },
    {
        title: 'Instalment Due Date',
        dataIndex: 'InstalmentDueDate',
        width: '39%'
    }
];

const CreateNewPaymentPlanPanel: React.FC<ICombinedProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    dispatchAction,
    formatCurrency,
    formatToParts,
    valueChanges,
    setValueChanges,
    getSelectedInvoicesValues,
    invoiceId,
    customerId,
    isUsingCloudImportType
}: ICombinedProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(true);
    const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
    const [invoiceList, setInvoiceList] = useState<Partial<PaymentPlanInvoiceVM>[]>([]);
    const [paymentPlanInstalmentList, setPaymentPlanInstalmentList] = useState<Partial<PaymentPlanSchedule>[]>([]);
    const [paymentPlan, setPaymentPlan] = useState<PaymentPlan>();
    const [isRequireDeposit, setIsRequireDeposit] = useState<boolean>(false);
    const [isDepositEnabled, setIsDepositEnabled] = useState<boolean>(true);
    const [isDepositPercentage, setIsDepositPercentage] = useState<boolean>(true);
    const [isReviewCreatedNewPaymentPlanPanelVisible, setIsReviewCreatedNewPaymentPlanPanelVisible] = useState<boolean>(false);
    const [isSplitInstalment, setIsSplitInstalment] = useState<boolean>(false);
    const [splitInstalmentPeriod, setSplitInstalmentPeriod] = useState<RangePickerValue | undefined>(undefined);
    const [instalmentType, setInstalmentType] = useState<string>(INSTALMENT_TYPE.DEFAULT);
    const [paymentDay, setPaymentDay] = useState<string | null>(null);
    const [fromMonthPaymentDay, setFromMonthPaymentDay] = useState<any | null>(null);
    const [customPaymentDay, setCustomPaymentDay] = useState<number | undefined>(undefined);
    const [instalments, setInstalments] = useState<number | undefined>(undefined);
    const { RangePicker, MonthPicker } = DatePicker;
    const invoicesState: InvoicesState = useSelector(
        (state: ApplicationState) => state.invoices
    );
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const companyTimezone = get(selectedUserCompany, 'Company.Timezone');

    const changeInvoiceList = useMemo(() => (value: React.SetStateAction<Partial<PaymentPlanInvoiceVM>[]>) => {
        setInvoiceList(value);
        setValueChanges({
            [FORM_FIELDS.INVOICES]: true
        });
    }, [setValueChanges]);

    const changePaymentPlanInstalmentList = useMemo(() => (value: React.SetStateAction<Partial<PaymentPlanSchedule>[]>) => {
        setIsSplitInstalment(false);

        
        setPaymentPlanInstalmentList(value);
        setValueChanges({
            [FORM_FIELDS.PAYMENTPLANINSTALMENTS]: true
        });
    }, [setValueChanges]);

    const clearSplitInstalmentData = () => {
        setFieldsValue({ [FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE]: '' });
        setFieldsValue({ [FORM_FIELDS.FROM_MONTH]: '' });
        setFieldsValue({ [FORM_FIELDS.PAYMENT_DAY]: '' });
        setFieldsValue({ [FORM_FIELDS.SPECIFIC_PAYMENT_DAY]: '' });
        setFieldsValue({ [FORM_FIELDS.NUMBER_OF_INSTALMENT]: '' });

        setSplitInstalmentPeriod(undefined);
        setPaymentDay(null);
        setFromMonthPaymentDay(null);
        setCustomPaymentDay(undefined);
        setInstalments(undefined);
    }

    useEffect(() => {
        if (!isSplitInstalment && !isDepositEnabled) {
            setIsDepositEnabled(true);
            setIsRequireDeposit(false);
        }

        if (!isSplitInstalment) {
            clearSplitInstalmentData();
        }
    }, [isSplitInstalment]);

    const isCreateBtnDisabled = submitLoading || hasError;

    /**
     * Function called when `Cancel` button is clicked inside Add comment panel.
     */
    const handleClosePanel = () => {
        if (closePanel) {
            closePanel();
        }
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    const totalInvoiceAmount = useMemo(() => {
        const totalValue = invoiceList.reduce((total, invoice) => invoice.AmountOwing ? total + invoice.AmountOwing : total, 0);
        return totalValue;
    }, [invoiceList]);

    const totalInstalmentAmount = useMemo(() => {
        const totalValue = paymentPlanInstalmentList.reduce((total, paymentPlanInstalment) => paymentPlanInstalment.TotalAmount ? total + paymentPlanInstalment.TotalAmount : total, 0);
        return totalValue;
    }, [paymentPlanInstalmentList]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                if (dispatchAction) {
                    dispatch(
                        dispatchAction({
                            InvoiceIds: invoiceList.map(i => i.Invoice && i.Invoice.Id),
                            InstalmentPayments: paymentPlanInstalmentList.map(p => {
                                return {
                                    Number: p.Number,
                                    TotalAmount: p.TotalAmount,
                                    LocalDueDateTime: p.LocalDueDateTime
                                } as InstalmentPayment;
                            }),
                            DepositPercentage: isRequireDeposit ? get(values, FORM_FIELDS.DEPOSIT_PERCENTAGE) : undefined,
                            DepositAmount: isRequireDeposit ? get(values, FORM_FIELDS.DEPOSIT_AMOUNT) : undefined,
                            DepositDueDate: isRequireDeposit ? (get(values, FORM_FIELDS.DEPOSIT_DUE_DATE)).format(dateFormatDDMMMYYYYSpace) : undefined,
                            callback: manualCreatePaymentPlanResponseModal,
                        })
                    );
                } else {
                    if (closePanel) closePanel(true);
                }
            }
        });
    };

    /**
     * Function responsible for showing the response modal after creating the payment plan.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const manualCreatePaymentPlanResponseModal = ({
        IsSuccess,
        Messages,
        PaymentPlan
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
        PaymentPlan: PaymentPlan;
        RefetchList: boolean;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            setPaymentPlan(PaymentPlan);
            setIsReviewCreatedNewPaymentPlanPanelVisible(true);
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to create payment plan!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }
    };

    const validateInvoice = useMemo(() => (invoice: Partial<PaymentPlanInvoiceVM>) => {
        const validation: {
            valid: boolean,
            warning: string[]
        } = { valid: true, warning: [] };

        const { warning } = validation;

        if (!invoice.Number) {
            validation.valid = false;
            warning.push(getTranslatedText('Invoice has no number!'));
        }
        else if (filter(invoiceList, inv => inv.Number === invoice.Number).length > 1) {
            validation.valid = false;
            warning.push(getTranslatedText('Invoice duplicated!'));
        }

        const uniqueCustomerIds = new Set(invoiceList.filter(i => i.Number && i.CustomerId).map(v => v.CustomerId));

        if (invoiceList.length > 1 && uniqueCustomerIds.size > 1) {
            validation.valid = false;
            warning.push(getTranslatedText(`Invoice must belongs to the same ${toLower(customerLabel)}!`));
        }

        return validation;

    }, [getFieldValue, invoiceList]);

    const validatePaymentPlanInstalment = useMemo(() => (instalment: Partial<PaymentPlanSchedule>) => {
        const validation: {
            valid: boolean,
            warning: string[]
        } = { valid: true, warning: [] };
        const { warning } = validation;

        if (!instalment.TotalAmount || instalment.TotalAmount <= 0) {
            validation.valid = false;
            warning.push(getTranslatedText('Instalment has no amount!'));
        }

        if (!instalment.LocalDueDateTime) {
            validation.valid = false;
            warning.push(getTranslatedText('Instalment has no due date!'));
        }
        else if (filter(paymentPlanInstalmentList, instal => instal.LocalDueDateTime === instalment.LocalDueDateTime).length > 1) {
            validation.valid = false;
            warning.push(getTranslatedText('Instalment due date duplicated!'));
        }

        return validation;
    }, [getFieldValue, paymentPlanInstalmentList, companyTimezone]);

    const validateInstalmentAmount = useMemo(() => () => {
        const validation: {
            valid: boolean,
            warning: string[]
        } = { valid: true, warning: [] };
        const { warning } = validation;

        if (paymentPlanInstalmentList.length > 0 && invoiceList.length > 0) {
            let instalmentTotalAmount: number = 0;
            let invoiceTotalOwingAmount: number = 0;
            paymentPlanInstalmentList.map(i => instalmentTotalAmount += i.TotalAmount ? i.TotalAmount : 0);
            invoiceList.map(i => invoiceTotalOwingAmount += i.AmountOwing ? i.AmountOwing : 0);
            if (isDepositEnabled && isRequireDeposit) {
                if (getFieldValue(FORM_FIELDS.DEPOSIT_PERCENTAGE)) {
                    let depositAmount: number = Number((instalmentTotalAmount * Number(getFieldValue(FORM_FIELDS.DEPOSIT_PERCENTAGE)) / 100.00).toFixed(2));
                    instalmentTotalAmount += Math.round(depositAmount);
                }
                else if (getFieldValue(FORM_FIELDS.DEPOSIT_AMOUNT)) {
                    instalmentTotalAmount += Number(getFieldValue(FORM_FIELDS.DEPOSIT_AMOUNT));
                }
            }

            instalmentTotalAmount = naiveRound(instalmentTotalAmount, 2);
            invoiceTotalOwingAmount = naiveRound(invoiceTotalOwingAmount, 2);

            if (instalmentTotalAmount > invoiceTotalOwingAmount) {
                validation.valid = false;
                validation.warning.push(getTranslatedText('The instalments amount must be less or equals to the invoice total owing amount!'));
            }
        }

        return validation;
    }, [getFieldValue, paymentPlanInstalmentList, invoiceList, isRequireDeposit]);

    const validateForm = useMemo(() => (options?: {
        success?: (values: any) => void
    }) => {
        validateFields((err: any, values: DynamicObject) => {
            const validInvoices = invoiceList.length > 0 ? every(invoiceList, inv => validateInvoice(inv).valid) : true;
            const validPaymentPlanInstalments = paymentPlanInstalmentList.length > 0 ? every(paymentPlanInstalmentList, instalment => validatePaymentPlanInstalment(instalment).valid) : true;

            const depositPercentage = get(values, FORM_FIELDS.DEPOSIT_PERCENTAGE);
            const depositAmount = get(values, FORM_FIELDS.DEPOSIT_AMOUNT);
            const depositDueDate = get(values, FORM_FIELDS.DEPOSIT_DUE_DATE);

            if (!err && validInvoices && validPaymentPlanInstalments && invoiceList.length > 0 && paymentPlanInstalmentList.length > 0 && validateInstalmentAmount().valid) {
                if (isRequireDeposit) {
                    if (!depositPercentage && !depositAmount && !depositDueDate) {
                        setHasError(true);
                    }
                    else {
                        setHasError(false);
                        if (options && options.success) options.success(values);
                    }
                }
                else {
                    setHasError(false);
                    if (options && options.success) options.success(values);
                }
            } else {
                setHasError(true);
            }
        });
    }, [validateFields, invoiceList, validateInvoice, validatePaymentPlanInstalment, paymentPlanInstalmentList, validateInstalmentAmount, isRequireDeposit]);

    useEffect(() => {
        if (!isEmpty(valueChanges)) {
            validateForm();
        }
    }, [valueChanges, validateForm]);

    useEffect(() => {

        if (isSplitInstalment && instalmentType != INSTALMENT_TYPE.DEFAULT) return;

        // Check if all three fields have values
        const dateRange: RangePickerValue | undefined = getFieldValue(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE);
        const instalmentsValue: number | undefined = getFieldValue(FORM_FIELDS.NUMBER_OF_INSTALMENT);

        if (totalInvoiceAmount && instalmentsValue && dateRange && dateRange[0] && dateRange[1]) {
            const startDate = dateRange[0].toDate();
            const endDate = dateRange[1].toDate();
            const instalmentAmount = naiveRound(totalInvoiceAmount / instalmentsValue, 2);
            const dateDiff = endDate.getTime() - startDate.getTime();
            const interval = dateDiff / instalmentsValue;
            const lastInstalmentAmount = naiveRound(totalInvoiceAmount - instalmentAmount * (instalmentsValue - 1), 2);

            // Trigger your event or perform any other action
            let result: Partial<PaymentPlanSchedule>[] = [];
            for (let i = 1; i <= instalmentsValue; i++) {
                result.push({
                    Id: generateGUID(),
                    TotalAmount: i < instalmentsValue ? instalmentAmount : lastInstalmentAmount,
                    LocalDueDateTime: moment(new Date(startDate.getTime() + i * interval)).format(dateFormatDDMMMYYYYSpace)
                });
            }
            setPaymentPlanInstalmentList([...result]);
        }
    }, [totalInvoiceAmount, isSplitInstalment, splitInstalmentPeriod, instalments]);

    useEffect(() => {
        // Check if all three fields have values
        if (isSplitInstalment && instalmentType != INSTALMENT_TYPE.MONTHLY) return;

        const instalmentsValue: number | undefined = getFieldValue(FORM_FIELDS.NUMBER_OF_INSTALMENT);
        const fromMonthValue: moment.Moment | undefined = getFieldValue(FORM_FIELDS.FROM_MONTH);
        const paymentDayValue: string = getFieldValue(FORM_FIELDS.PAYMENT_DAY);
        let specificPaymentDayValue: number | undefined = getFieldValue(FORM_FIELDS.SPECIFIC_PAYMENT_DAY);

        if (totalInvoiceAmount && instalmentsValue && fromMonthValue && paymentDayValue) {
            if (paymentDayValue == PAYMENT_DAY.CUSTOM && !specificPaymentDayValue) return;

            const resolvePayDate = (inputDate: moment.Moment) => {
                if (paymentDayValue === PAYMENT_DAY.FIRST_DATE) return inputDate.startOf('month');
                if (paymentDayValue === PAYMENT_DAY.LAST_DATE) return inputDate.endOf('month');

                // Get the number of days in the month
                let daysInMonth = inputDate.daysInMonth();
                let currentSpecificPaymentDayValue = specificPaymentDayValue;

                // If the specified day is greater than the number of days in the month, use the last day of the month
                if (!currentSpecificPaymentDayValue || currentSpecificPaymentDayValue > daysInMonth) {
                    currentSpecificPaymentDayValue = daysInMonth;
                }
                // If the specified day is less than 1, use the first day of the month
                else if (currentSpecificPaymentDayValue < 1) {
                    currentSpecificPaymentDayValue = 1;
                }

                return inputDate.date(currentSpecificPaymentDayValue);
            };

            const instalmentAmount = naiveRound(totalInvoiceAmount / instalmentsValue, 2);
            const lastInstalmentAmount = naiveRound(totalInvoiceAmount - instalmentAmount * (instalmentsValue - 1), 2);

            let currentDueDatetime = fromMonthValue;

            // Trigger your event or perform any other action
            let result: Partial<PaymentPlanSchedule>[] = [{
                Id: generateGUID(),
                TotalAmount: instalmentAmount,
                LocalDueDateTime: resolvePayDate(currentDueDatetime).format(dateFormatDDMMMYYYYSpace)
            }];
            for (let i = 2; i <= instalmentsValue; i++) {
                currentDueDatetime = getNextMonth(currentDueDatetime);

                result.push({
                    Id: generateGUID(),
                    TotalAmount: i < instalmentsValue ? instalmentAmount : lastInstalmentAmount,
                    LocalDueDateTime: resolvePayDate(currentDueDatetime).format(dateFormatDDMMMYYYYSpace)
                });
            }

            setPaymentPlanInstalmentList([...result]);
        }
    }, [totalInvoiceAmount, isSplitInstalment, instalmentType, fromMonthPaymentDay, paymentDay, customPaymentDay, instalments]);

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: CheckboxChangeEvent
    ) => {
        setIsRequireDeposit(e.target.checked);
    };

    const handleSplitInstalmentCheckboxClick = (
        e: CheckboxChangeEvent
    ) => {
        setIsSplitInstalment(e.target.checked);

        if (e.target.checked === true) {
            setIsDepositEnabled(false);
        }
    };

    function handleChangeInstalmentPeriod(dates: RangePickerValue) {
        setSplitInstalmentPeriod(dates);
    }

    function handleInstalmentType(value: any) {
        setInstalmentType(value);
    }

    function handleChangeInstalments(instalment: number | undefined) {
        if (instalment && instalment >= 2 && instalment <= 30) {
            setInstalments(instalment);
        }
    }

    const handleBlurInstalments = () => {
      const currentValue = getFieldValue(FORM_FIELDS.NUMBER_OF_INSTALMENT);
      if (currentValue && currentValue >= 2 && currentValue <= 30) {
        setInstalments(currentValue);;
      }
    };

    function handleSelectFromMonthPaymentDay(fromMonth: any) {
        setFromMonthPaymentDay(fromMonth);
    }

    function handleSelectPaymentDay(paymentDay: any) {
        setPaymentDay(paymentDay);
    }

    function handleSelectCustomPaymentDay(customPaymentDay: any) {
        setCustomPaymentDay(customPaymentDay);
    }

    /**
     * Function that populates the dropdown options for deposit type.
     */
    const populatePaymentPlanDepositTypeOptions = () =>
        map(
            paymentPlanDepositTypeOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );

    /**
     * Function that populates the dropdown options for payment day option.
     */
    const populatePaymentDayChildrenOptions = () =>
        map(
            PAYMENT_DAY,
            value => (
                <Option key={value} value={value}>
                    {value}
                </Option>
            )
        );

    /**
     * Function that populates the dropdown options for custom day option.
     */
    const populateCustomDayChildrenOptions = () =>
        map(
            Array.from({ length: 31 }, (_, i) => i + 1),
            i => (
                <Option key={i} value={i}>
                    {i}
                </Option>
            )
        );

    /**
     * Function called when DepositType change.
     */
    const handleOnDepositTypeChange = (value: any) => {
        if (value === "Percent") {
            setIsDepositPercentage(true);
        }
        else {
            setIsDepositPercentage(false);
        }
    }

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return {
            amountDisplay: formatCurrency ? formatCurrency(amount) : amount,
            currency: first(map(
                filter(
                    (formatToParts ? formatToParts(amount) : []), p => p.type === 'currency'
                ),
                p => p.value
            ))
        };
    };

    const updateInvoice = (invoice: Partial<PaymentPlanInvoiceVM>, newInvoice: Partial<PaymentPlanInvoiceVM>) => {
        changeInvoiceList(list => {
            const updatedInvoiceIdx = findIndex(list, inv => inv === invoice);
            if (updatedInvoiceIdx !== -1) {
                newInvoice._touched = true;
                list.splice(updatedInvoiceIdx, 1, newInvoice);
            }
            return [...list];
        });
    };

    const populateInvoiceTableDataSourceItem = (invoice: PaymentPlanInvoiceVM, index: number) => {
        const invoiceRemainingAmount = isNaN(invoice.AmountOwing) ? 0 : invoice.AmountOwing;

        const { currency } = handleFormatCurrency(invoice.AmountOwing);
        let { warning } = validateInvoice(invoice);
        const validate = validateInstalmentAmount()

        if (!validate.valid) {
            warning = [...warning, ...validate.warning];
        }

        return {
            key: index,
            InvoiceNumber: (<>
                <InvoiceAutoComplete
                    invoice={invoice}
                    customerId={customerId}
                    updateInvoice={updateInvoice} />
            </>),
            RemainingAmount: (<>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        {`${currency} `}
                        {roundNumberToDecimalDigits(invoiceRemainingAmount)}
                    </div>
                    {(warning.length > 0) && <Tooltip
                        title={warning.map((message, idx) => <div key={idx}>{message}</div>)}
                        placement="topRight">
                        <FontAwesome
                            icon={['fas', 'info-circle']}
                            style={{ color: '#ff5d00' }}
                            className="fs-18 mr-5"
                        />
                    </Tooltip>}
                    <div className="fs-16 cursor-p-div" onClick={handleDeleteInvoice(invoice)}>
                        <FontAwesome icon={['fas', 'trash']} />
                    </div>
                </div>
            </>)
        }
    };

    const handleAddInvoice = () => {
        // Check if the invoice belong different person
        changeInvoiceList(list => {
            return [...list, {}];
        })
    };

    const handleDeleteInvoice = useCallback((invoice: PaymentPlanInvoiceVM) => () => {
        changeInvoiceList(list => {
            const invoiceIdx = findIndex(list, inv => inv === invoice);
            if (invoiceIdx !== -1) {
                list.splice(invoiceIdx, 1);
            }
            return [...list];
        })
    }, [changeInvoiceList]);

    const updatePaymentPlanInstalment = (paymentPlanInstalment: Partial<PaymentPlanSchedule>, newPaymentPlanSchedule: Partial<PaymentPlanSchedule>) => {
        changePaymentPlanInstalmentList(list => {
            const updatedPaymentPlanInstalmentIdx = findIndex(list, instalment => instalment === paymentPlanInstalment);
            if (updatedPaymentPlanInstalmentIdx !== -1) {
                list.splice(updatedPaymentPlanInstalmentIdx, 1, newPaymentPlanSchedule);
            }

            // We want to display instalment due date ascending
            const sortList = list.sort((objA, objB) => { return moment(objA.LocalDueDateTime).valueOf() - moment(objB.LocalDueDateTime).valueOf() });

            return [...sortList];
        });
    };

    const populateInstalmentTableDataSourceItem = (instalment: PaymentPlanSchedule, index: number) => {
        const instalmentNumber = index + 1;

        const { currency } = handleFormatCurrency(instalment.TotalAmount);
        let { warning } = validatePaymentPlanInstalment(instalment);
        const validate = validateInstalmentAmount();

        if (!validate.valid) {
            warning = [...warning, ...validate.warning];
        }

        return {
            key: instalment.Id,
            InstalmentNumber: (<>
                <div style={{ flexGrow: 1 }} className="mr-5">
                    {instalmentNumber}
                </div>
            </>),
            InstalmentAmount: (<>
                {`${currency} `}
                <InputNumber
                    placeholder={getTranslatedText("Instalment amount")}
                    min={1}
                    style={{ width: 200 }}
                    onChange={(value) => {
                        const newInstalment = {
                            ...instalment,
                            TotalAmount: value || 0
                        };
                        updatePaymentPlanInstalment(instalment, newInstalment);
                    }}
                    defaultValue={instalment.TotalAmount}
                />
            </>),
            InstalmentDueDate: (<>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        <DatePicker
                            format={dateFormatDDMMMYYYYSpace}
                            placeholder={getTranslatedText("Instalment due date")}
                            style={{ width: 250 }}
                            onChange={(value) => {
                                const newInstalment = {
                                    ...instalment,
                                    LocalDueDateTime: (value || moment()).format(dateFormatDDMMMYYYYSpace)
                                };
                                updatePaymentPlanInstalment(instalment, newInstalment);
                            }}
                            defaultValue={instalment.LocalDueDateTime && moment(instalment.LocalDueDateTime) || null}
                        />
                    </div>
                    {(warning.length > 0) && <Tooltip
                        title={warning.map((message, idx) => <div key={idx}>{message}</div>)}
                        placement="topRight">
                        <FontAwesome
                            icon={['fas', 'info-circle']}
                            style={{ color: '#ff5d00' }}
                            className="fs-18 mr-5"
                        />
                    </Tooltip>}
                    <div className="fs-16 cursor-p-div" onClick={handleDeletePaymentPlanInstalment(instalment)}>
                        <FontAwesome icon={['fas', 'trash']} />
                    </div>
                </div>
            </>)
        }
    };

    const handleAddPaymentPlanInstalment = () => {
        changePaymentPlanInstalmentList(list => {
            return [...list, {
                Id: generateGUID(),
            }];
        })
    };

    const handleDeletePaymentPlanInstalment = useCallback((paymentPlanInstalment: Partial<PaymentPlanSchedule>) => () => {
        changePaymentPlanInstalmentList(list => {
            const paymentPlanInstalmentIdx = findIndex(list, inv => inv === paymentPlanInstalment);
            if (paymentPlanInstalmentIdx !== -1) {
                list.splice(paymentPlanInstalmentIdx, 1);
            }

            return [...list];
        })
    }, [changePaymentPlanInstalmentList]);

    /**
    * Function for calling when the ReviewCreatedNewPaymentPlanPanel is closed.
    * @param refreshList
    */
    const onReviewCreatedNewPaymentPlanPanelClose = (isPaymentPlanCreatedSuccessfully?: boolean) => {
        setIsReviewCreatedNewPaymentPlanPanelVisible(false);

        if (isPaymentPlanCreatedSuccessfully) {
            if (closePanel) {
                closePanel(true);
            };
        }
    };

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (currentPage: number,
        pageSize: number
    ) => {
        let allExcept: boolean = false;
        let InvoiceIds: string[] = [];
        let filterObject: any = {};

        if (getSelectedInvoicesValues) {
            const selectedInvoicesValues = getSelectedInvoicesValues();
            allExcept = selectedInvoicesValues.allExcept;
            InvoiceIds = selectedInvoicesValues.keysToUse;
            filterObject = selectedInvoicesValues.filterObject;
        }

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filterObject,
            true,
            'invoice'
        );

        if (invoiceId) {
            InvoiceIds = [invoiceId];
        }

        const usingCustomerWorkflow = get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        );

        const usingMultipleWorkflow = get(
            selectedUserCompany,
            'Company.UsingMultipleWorkflow'
        );

        const isPaymentPlanEnabled = get(
            selectedUserCompany,
            'Company.CompanyPaymentPlan.IsEnabled'
        );

        const payload: GetInvoicesRequestPayload = {
            ...cleanFilters,
            invoiceState: invoiceStateMap.OPEN,
            InvoiceIds: InvoiceIds,
            excludeInvoices: allExcept,
            sortBy: invoicesState.sortBy,
            sortAscending: invoicesState.sortAscending,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
            isPaymentPlanEnabled,
            pageSize,
            currentPage,
        };

        return payload;
    };


    /**
     * Function that calls an action which triggers a saga for fetching the Invoices data.
     * @param currentPage - current page to fetch data from
     * @param pageSize - number of items in page
     */
    const fetchInvoices = () => {
        // We only want to fetch invoice if user trying to create new payment play from invoice page
        if (getSelectedInvoicesValues || invoiceId) {
            setDataLoading(true);
            const payload = generatePayloadForRequest(1, INVOICES_PAGE.pageSize);
            dispatch(getInvoicesForPaymentPlanRequestAction(payload, initalizeInvoiceList));
        }
    };

    useEffect(fetchInvoices, []);

    const initalizeInvoiceList = (response: DynamicObject) => {
        if (response.IsSuccess) {
            let paymentPlanInvoices: Partial<PaymentPlanInvoiceVM>[] = [];

            response.Invoices.forEach((invoice: Invoice) => {
                const paymentPlanInvoice: Partial<PaymentPlanInvoiceVM> = {
                    Invoice: invoice,
                    Number: invoice.Number,
                    CustomerId: invoice.Customer.Id,
                    AmountOwing: invoice.AmountOwing,
                    SearchValue: invoice.Number
                };

                paymentPlanInvoices.push(paymentPlanInvoice);
            });

            setInvoiceList([...invoiceList, ...paymentPlanInvoices]);
        }
        else {
            let errorMessageContent: any = getTranslatedText('Fail to fetching invoices!');
            if (!isEmpty(response.Messages)) {
                errorMessageContent = map(
                    response.Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }

        setDataLoading(false);
    }

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form labelAlign='left' className="form-inline-mb-0" labelCol={{ span: 7 }}>
                <Row gutter={[16, 16]} type="flex" align="middle">
                    <Col span={24}>
                        <Table
                            className="app-pl-invoices-table"
                            columns={invoicesTableColumns.map(column => ({
                                ...column,
                                title: getTranslatedText(column.title)
                            }))}
                            dataSource={map(invoiceList, populateInvoiceTableDataSourceItem)}
                            pagination={false}
                            bordered={true}
                            footer={invoiceList.length && totalInvoiceAmount > 0 ? () =>
                            (<div style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                <span>Total:</span>
                                <span style={{ marginLeft: '8px' }}>
                                    {handleFormatCurrency(totalInvoiceAmount).amountDisplay}
                                </span>
                            </div>) : undefined
                            }
                        />
                    </Col>
                    <Col span={24} className="ta-right">
                        <Button
                            type="link"
                            onClick={handleAddInvoice}
                            style={{ padding: 0 }}
                        >
                            <FontAwesome
                                icon={['fa', 'plus']}
                                className="mr-8"
                            />
                            <span>{getTranslatedText("Add invoice")}</span>
                        </Button>
                    </Col>
                    <Col span={24}>
                        <FormItem label={getTranslatedText('Auto split payment plan')} id={FORM_FIELDS.IS_SPLIT_INSTALMENT}>
                            <Checkbox
                                onChange={handleSplitInstalmentCheckboxClick}
                                checked={isSplitInstalment}
                            />
                        </FormItem>
                    </Col>
                    {isSplitInstalment && (
                        <Col span={24}>
                            <Col span={24}>
                                <FormItem label={getTranslatedText('Instalment type')}>
                                    {getFieldDecorator(FORM_FIELDS.INSTALMENT_TYPE, {
                                        initialValue: instalmentType
                                    })(
                                        <Select onChange={handleInstalmentType} style={{ width: 200 }}>
                                            <Option value={INSTALMENT_TYPE.DEFAULT}>{INSTALMENT_TYPE.DEFAULT}</Option>
                                            <Option value={INSTALMENT_TYPE.MONTHLY}>{INSTALMENT_TYPE.MONTHLY}</Option>
                                        </Select>)}
                                </FormItem>
                            </Col>
                            {
                                instalmentType == INSTALMENT_TYPE.DEFAULT ? (
                                    <>
                                        <Col span={24}>
                                            <FormItem label={getTranslatedText('Instalment periods')}>
                                                {getFieldDecorator(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE, {
                                                    initialValue: null,
                                                    rules: [
                                                        {
                                                            required: isSplitInstalment,
                                                            message: getTranslatedText('Instalment periods required!'),
                                                        }
                                                    ]
                                                })(<RangePicker
                                                    format={dateFormatDDMMMYYYYSpace}
                                                    onChange={handleChangeInstalmentPeriod} />)}
                                            </FormItem>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col span={24}>
                                            <FormItem label={getTranslatedText('From Month')}>
                                            {getFieldDecorator(FORM_FIELDS.FROM_MONTH, {
                                                initialValue: null,
                                                rules: [
                                                    {
                                                        required: isSplitInstalment,
                                                        message: getTranslatedText('From month required!'),
                                                    }
                                                ]
                                            })(<MonthPicker
                                                onChange={handleSelectFromMonthPaymentDay}
                                                style={{ width: 200 }}
                                                placeholder={getTranslatedText('Select month')}
                                                format={dateFormatMMMYYYYSpace}/>)}
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem label={getTranslatedText('Choose Payment Day')}>
                                            {getFieldDecorator(FORM_FIELDS.PAYMENT_DAY, {
                                                initialValue: null,
                                                rules: [
                                                    {
                                                        required: isSplitInstalment,
                                                        message: getTranslatedText('Choose Payment Day required!'),
                                                    }
                                                ]
                                            })(
                                                <Select onChange={handleSelectPaymentDay} style={{ width: 200 }}>
                                                    {populatePaymentDayChildrenOptions()}
                                                </Select>)}
                                            </FormItem>
                                        </Col>
                                        {paymentDay === PAYMENT_DAY.CUSTOM && (
                                            <Col span={24}>
                                                <FormItem label={getTranslatedText("Specify Custom Day")}>
                                                    {getFieldDecorator(FORM_FIELDS.SPECIFIC_PAYMENT_DAY, {
                                                        initialValue: null,
                                                        rules: [
                                                            {
                                                                required: isSplitInstalment,
                                                                message: getTranslatedText('Specify Custom Day required!'),
                                                            }
                                                        ]
                                                    })(
                                                    <Select showSearch onChange={handleSelectCustomPaymentDay} style={{ width: 200 }}>
                                                        {populateCustomDayChildrenOptions()}
                                                    </Select>)}
                                                </FormItem>
                                            </Col>                              
                                        )}
                                    </>
                                )
                            }
                            <Col span={24}>
                                <FormItem label={getTranslatedText('Number of instalments')}>
                                    {getFieldDecorator(FORM_FIELDS.NUMBER_OF_INSTALMENT, {
                                        rules: [
                                            {
                                                required: isSplitInstalment,
                                                message: getTranslatedText('Number of instalments required!'),
                                            },
                                            {
                                                validator: (rule: any, value: any, callback: any) => {
                                                    if (value && (value < 2 || value > 30)) {
                                                        callback(getTranslatedText('The number of instalments should be between 2 and 30.'));
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            }
                                        ],
                                        initialValue: null,
                                    })(
                                        <InputNumber
                                            style={{ width: 200 }}
                                            min={2}
                                            max={30}
                                            defaultValue={2}
                                            onChange={handleChangeInstalments}
                                            onBlur={handleBlurInstalments}
                                            onKeyDown={limitOnlyNumber(false)}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Col>)}
                        <Col span={24}>
                            <Table
                                className="app-pl-instalments-table"
                                columns={instalmentsTableColums.map(column => ({
                                    ...column,
                                    title: getTranslatedText(column.title)
                                }))}
                                dataSource={map(paymentPlanInstalmentList, populateInstalmentTableDataSourceItem)}
                                pagination={false}
                                bordered={true}
                                footer={paymentPlanInstalmentList.length && totalInstalmentAmount > 0 ? () =>
                                (<div style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                    <span>Total:</span>
                                    <span style={{ marginLeft: '8px' }}>
                                        {handleFormatCurrency(totalInstalmentAmount).amountDisplay}
                                    </span>
                                </div>) : undefined
                                }
                            />
                        </Col>
                    <Col span={24} className="ta-right">
                        <div>You can add up to 30 instalments</div>
                        <Button
                            type="link"
                            onClick={handleAddPaymentPlanInstalment}
                            style={{ padding: 0 }}
                            disabled={paymentPlanInstalmentList.length >= 30}
                        >
                            <FontAwesome
                                icon={['fa', 'plus']}
                                className="mr-8"
                            />
                            <span>Add instalment</span>
                        </Button>
                    </Col>

                    {
                        isDepositEnabled && (
                            <>
                                <Divider orientation="center">
                                    {getTranslatedText('Payment plan deposit customization')}
                                </Divider>
                                <Col span={24}>
                                    <FormItem label={getTranslatedText("Requires deposit")}>
                                        {getFieldDecorator(FORM_FIELDS.REQUIRES_DEPOSIT, {
                                            initialValue: isRequireDeposit,
                                            valuePropName: "checked"
                                        })(
                                            <Checkbox
                                                onChange={handleCheckboxClick}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                {
                                    isRequireDeposit && (
                                        <>
                                            {
                                                getFieldValue(FORM_FIELDS.REQUIRES_DEPOSIT) && (
                                                    <Col span={24}>
                                                        <FormItem label={getTranslatedText("Deposit type")}>
                                                            {getFieldDecorator(FORM_FIELDS.DEPOSIT_TYPE, {
                                                                rules: [
                                                                    {
                                                                        required: isRequireDeposit,
                                                                        message: getTranslatedText('Deposit type required!'),
                                                                    }
                                                                ],
                                                                initialValue: "Percent"
                                                            })(
                                                                <SelectReadonly
                                                                    style={{ width: 180 }}
                                                                    onChange={handleOnDepositTypeChange}
                                                                >
                                                                    {populatePaymentPlanDepositTypeOptions()}
                                                                </SelectReadonly>
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                )
                                            }
                                            {
                                                getFieldValue(FORM_FIELDS.REQUIRES_DEPOSIT) && isDepositPercentage && (
                                                    <Col span={24}>
                                                        <FormItem label={getTranslatedText("Deposit percentage")}>
                                                            {getFieldDecorator(FORM_FIELDS.DEPOSIT_PERCENTAGE, {
                                                                rules: [
                                                                    {
                                                                        required: isRequireDeposit,
                                                                        message: getTranslatedText('Deposit percentage required!'),
                                                                    }
                                                                ],
                                                            })(
                                                                <InputNumber
                                                                    min={1}
                                                                    max={100}
                                                                    onKeyDown={limitOnlyNumber(false)}
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                )
                                            }
                                            {
                                                getFieldValue(FORM_FIELDS.REQUIRES_DEPOSIT) && !isDepositPercentage && (
                                                    <Col span={24}>
                                                        <FormItem label={getTranslatedText("Deposit amount")}>
                                                            {getFieldDecorator(FORM_FIELDS.DEPOSIT_AMOUNT, {
                                                                rules: [
                                                                    {
                                                                        required: isRequireDeposit,
                                                                        message: getTranslatedText('Deposit amount required!'),
                                                                    }
                                                                ],
                                                            })(
                                                                <InputNumber
                                                                    min={1}
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                )
                                            }
                                            {
                                                getFieldValue(FORM_FIELDS.REQUIRES_DEPOSIT) && (
                                                    <Col span={24}>
                                                        <FormItem label={getTranslatedText("Deposit due date")}>
                                                            {getFieldDecorator(FORM_FIELDS.DEPOSIT_DUE_DATE, {
                                                                rules: [
                                                                    {
                                                                        required: isRequireDeposit,
                                                                        message: getTranslatedText('Deposit due date required!'),
                                                                    },
                                                                    {
                                                                        validator: async (_rule: any, value: any) => {
                                                                            const depositDueDate = value || getFieldValue(FORM_FIELDS.DEPOSIT_DUE_DATE);
                                                                            if (depositDueDate) {
                                                                                const formatDepositDate = moment(depositDueDate).format(dateFormatYYYYMMDDTHHmmssSSSSSSDash);
                                                                                if (paymentPlanInstalmentList.some(p => p.LocalDueDateTime &&
                                                                                    (moment(formatDepositDate).isAfter(moment(moment(p.LocalDueDateTime).format(dateFormatYYYYMMDDTHHmmssSSSSSSDash)))))) {
                                                                                    throw new Error(getTranslatedText(`Deposit due date must be earlier than instalment due dates!`));
                                                                                }
                                                                            }
                                                                        }
                                                                    }],
                                                            })(
                                                                <DatePicker
                                                                    format={dateFormatDDMMMYYYYSpace}
                                                                    placeholder={getTranslatedText("Deposit due date")}
                                                                    style={{ width: 300 }}
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </Row>
            </Form>
        );
    };

    return (
        <Spin spinning={dataLoading} tip={getTranslatedText('Fetching invoices...')}>
            <Row>
                <Col>
                    <div>{populatePanelContent()}</div>
                    <br />
                    <Row>
                        <Col className="ta-right" span={24}>
                            <Button
                                className="mr-8"
                                type="primary"
                                onClick={handleSubmitForm}
                                disabled={isCreateBtnDisabled}
                            >
                                {getTranslatedText('Create')}
                            </Button>
                            <Button onClick={handleClosePanel}>Cancel</Button>
                        </Col>
                    </Row>
                </Col>
                {submitLoading && (
                    <Suspense fallback={null}>
                        <ModalWithSpinner
                            modalTitle={getTranslatedText("Creating new payment plan")}
                            modalVisible={submitLoading}
                            displayMessage={getTranslatedText("Please wait while creating new payment plan . . .")}
                            containerRef={containerRef}
                        />
                    </Suspense>
                )}
                <Suspense fallback={null}>
                    <div ref={null}>
                        <ReviewCreatedNewPaymentPlanPanel
                            visible={isReviewCreatedNewPaymentPlanPanelVisible}
                            paymentPlan={paymentPlan}
                            isRequireDeposit={isRequireDeposit}
                            customerLabel={getTranslatedText(customerLabel)}
                            closePanel={onReviewCreatedNewPaymentPlanPanelClose}
                            formatCurrency={formatCurrency}
                            dispatchAction={createPaymentPlanAction}
                        />
                    </div>
                </Suspense>
            </Row>
        </Spin>
    );
};

const CreateNewPaymentPlanPanelWrapper: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const CreateNewPaymentPlanPanelForm = Form.create({
            name: 'create-new-payment-plan-panel-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(CreateNewPaymentPlanPanel);

        return withNumberFormatHandler(
            withDateFormatHandler(CreateNewPaymentPlanPanelForm)
        );
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default CreateNewPaymentPlanPanelWrapper;


const InvoiceAutoComplete: React.FC<{
    invoice: PaymentPlanInvoiceVM,
    customerId?: string | null,
    updateInvoice: (invoice: Partial<PaymentPlanInvoiceVM>, newInvoice: Partial<PaymentPlanInvoiceVM>) => void
}> = ({ invoice, customerId, updateInvoice }) => {
    useEffect(() => {
        if (invoice && !invoice.SearchValue) {
            invoice.SearchValue = invoice.Number;
        }
    }, [invoice]);

    return <InputAutoCompleteWithButton
        updateField={(value: any) => {
            if (invoice.Number && invoice.Number !== value) {
                const newInvoice: Partial<PaymentPlanInvoiceVM> = {
                    ...invoice,
                    Number: '',
                    CustomerId: customerId || undefined,
                    AmountOwing: 0
                };
                invoice.SearchValue = value;
                updateInvoice(invoice, newInvoice);
            }
        }}
        onSelect={(value: any) => {
            const InvoiceId = get(value, 'Id');
            const CustomerId = get(value, 'Customer.Id');
            const Number = get(value, 'Number');
            const AmountOwing = get(value, 'AmountOwing');
            const newInvoice = {
                ...invoice,
                Invoice: {
                    ...invoice.Invoice,
                    Id: InvoiceId
                },
                CustomerId,
                Number,
                AmountOwing: AmountOwing
            };
            updateInvoice(invoice, newInvoice);
        }}
        minimumLength={1}
        placeholder={getTranslatedText("Search invoice")}
        stateValue={invoice.SearchValue}
        keyField={'Id'}
        queryName={'GET_INVOICES_DETAILS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
        filterField={'InvoiceNumber'}
        queryFilterName={'InvoiceNumber'}
        sortField={'Invoice number'}
        responseName={'GetInvoicesForCompany.Invoices'}
        labelField={'Number'}
        hasNoOkButton
        loading={false}
        hasNoContainerRef
        extraQueryVariables={{
            InvoiceState: invoiceStateMap.OPEN,
            CustomerId: customerId
        }}
    />;
}
