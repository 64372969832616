/**
 * File responsible for all the UI and actions for Settings>Company page - General tab item - `/app/settings/company`.
 */

import { Card, Col, Divider, Row, Select, Typography } from 'antd';
import { History as IHistory } from 'history';
import { isEqual } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { forwardRef, Ref, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import { saveUserCompanyRequestAction } from '../../../../store/companies/actions';
import { Company } from '../../../../store/companies/types';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../../utils/commonInterfaces';
import { ContactOptions } from '../../../../store/common/types';

const { Title } = Typography;
const { Option } = Select;

interface IProps {
    history: typeof IHistory;
    disabled: boolean;
    selectedUserCompany: Company;
    handleModalSaveResponse: (response: ResponseModalObject) => void;
    setFormHasChanges: () => void;
}
const CommunicationMethodPage: React.FC<IProps> = forwardRef(
    (
        {
            disabled,
            selectedUserCompany,
            handleModalSaveResponse,
            history,
            setFormHasChanges,
        }: IProps,
        ref: Ref<any>
    ) => {
        const dispatch = useDispatch();

        const [selectedItems, setSelectedItems] = useState<number[]>([]);
        const [communicationFormState, setCommunicationFormState] = useState<{}>({});

        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            cancel: () => {
                handleCancel();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        /**
         * Check if there are any changes in the form (unsaved).
         */
        const checkForChanges = () => {
            const formState: DynamicObject = { ...communicationFormState };

            const constructedState = {
                ...selectedUserCompany,
                ...formState,
            };

            const isChanged = !isEqual(selectedUserCompany, constructedState);

            if(isChanged) {
                setFormHasChanges();
            }

            return isChanged;
        };

        /**
         * Function responsible for syncing the data used inside this form from the redux state
         * where all the company data are saved.
         */
        const syncStateFromRedux = () => {
            const { CommunicationMethod } = selectedUserCompany;
            const formState = {
                CommunicationMethod
            };
            setCommunicationFormState(formState);

            const constructedState = {
                ...selectedUserCompany,
                ...formState,
            };

            const equalTo = isEqual(selectedUserCompany, constructedState)

            // Set initial checked values based on a predefined value (e.g., 7)
            const initialValue: number|undefined = CommunicationMethod || undefined;
           
            if (initialValue) {
                const binaryValue = initialValue.toString(2); // Convert to binary string
                const reversedBinary = binaryValue.split('').reverse().join('');
                const initialChecked = reversedBinary
                    .split('')
                    .map((bit, index) => (bit === '1' ? Math.pow(2, index) : 0))
                    .filter((val) => val !== 0);
                setSelectedItems(initialChecked);
            }
        };

        useEffect(syncStateFromRedux, [selectedUserCompany]);

        /**
         * Function called when the save button is called.
         * Specific to this form only and does not affect the other child tab items
         * that are side by side with this form.
         */
        const handleSave = () => {
            const formState: DynamicObject = { ...communicationFormState };

            formState.DetailType = 'CommunicationMethod';
            dispatch(
                saveUserCompanyRequestAction(formState, handleModalSaveResponse)
            );
        };

        /**
         * Function called when cancel is clicked.
         * Resets all the changes and set the data based on the one saved in redux (the original saved data).
         */
        const handleCancel = () => {
            syncStateFromRedux();
        };

        const handleChange = (values: any) => {
            const changedValues = values.map(Number);
            let updatedValues: number[] = [];
            let unselected: boolean = false;

            // unselected the list item
            if (!changedValues.includes(8)) {
                if (selectedItems.some(item => !changedValues.includes(item))) {
                    updatedValues = changedValues;
                    unselected = true;
                }
            }
            
            if (!unselected) {
                changedValues.forEach((value: number) => {
                    const intValue = value;

                    if (intValue === 8) {
                        updatedValues = selectedItems.includes(8) ? [] : [8];
                    } else {
                        updatedValues = selectedItems.includes(intValue)
                            ? selectedItems.filter((item) => item !== intValue)
                            : [...selectedItems.filter((item) => item !== 8), intValue];
                    }
                });
            }
            
            setSelectedItems(updatedValues);

            setCommunicationFormState((prevState: any) => ({
                ...prevState,
                CommunicationMethod: updatedValues.reduce((acc, curr) => acc + curr, 0),
            }));
            setFormHasChanges();
        };

        return (
            <Col span={24}>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => history.push(path)}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container">
                        <Col span={24}>
                            <Title level={3}>Communication Methods</Title>
                            <div className="grey">
                                Choose your preferred method of communication with clients
                            </div>
                        </Col>
                    </Row>
                    <Divider className="divider-thick" />
                    <Row key="company-region-container">
                        <Col span={24}>
                            <Card className="card-rounded-border">
                                <Row>
                                    <Col span={24}>
                                        Communication:&emsp;
                                        <Select
                                            mode="multiple"
                                            style={{ width: '40%' }}
                                            placeholder="Select communication"
                                            onChange={handleChange}
                                            value={selectedItems.map(String)} // Convert numbers to strings for Select
                                        >
                                            {ContactOptions.map(contact => (
                                                <Option key={contact.value} value={String(contact.value)}>{contact.label}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </QueueAnim>
            </Col>
        );
    }
);

export default CommunicationMethodPage;