/**
 * Component responsible for protecting the routes by using roles and permissions.
 */

import Cookies from 'js-cookie';
import { includes, isEmpty } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { withAuthHandler } from './components/common/AuthHandler';
import { TOKEN_NAME } from './config/config';
import { setCurrentRouteAction } from './store/storedRoute/actions';
import { getCurrentUser } from './store/users/sagas';
import { useUserRole } from './utils/commonHooks';

interface IProps {
    component: any;
    path: string;
    exact?: boolean;
    location: {
        pathname: string;
    };
    allowedRoles?: string[];
    logoutUser: () => void;
    history: {
        goBack: () => void;
    };
}

const ProtectedRoute: React.FC<IProps> = ({
    component: Component,
    allowedRoles,
    exact,
    ...otherProps
}) => {
    const currentUser = useSelector(getCurrentUser);
    const userRole = useUserRole();
    const token = Cookies.get(TOKEN_NAME);
    const dispatch = useDispatch();

    return (
        <Route
            exact={exact}
            render={(props: IProps) => {
                if (currentUser && token) {
                    if (!isEmpty(allowedRoles)) {
                        if (!includes(allowedRoles, userRole)) {
                            dispatch(setCurrentRouteAction('/app'));
                            return <Redirect to="/app" />;
                        }
                    }

                    return <Component />;
                } else {
                    const currentRoute = token
                        ? props.location.pathname
                        : '/app';
                    dispatch(setCurrentRouteAction(currentRoute));

                    return <Redirect to="/" />;
                }
            }}
            {...otherProps}
        />
    );
};

export default withRouter(withAuthHandler(ProtectedRoute));
