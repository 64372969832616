import { action } from "typesafe-actions";
import { GetScheduledReportsRequestPayload, ScheduledReportActionTypes } from "./types";
import { DynamicObject, ResponseModalObject } from "../../utils/commonInterfaces";
import { PageData } from "../common/types";

// Create schedule report
export const createScheduledReportRequest = (payload: {}) =>
  action(ScheduledReportActionTypes.CREATE_SCHEDULED_REPORTS, payload);

// Viewing scheduled report
export const getScheduledReportsRequestAction = (payload: GetScheduledReportsRequestPayload) =>
  action(ScheduledReportActionTypes.GET_SCHEDULED_REPORTS_REQUEST, payload);

export const getScheduledReportsForOrganisationRequestAction = (payload: GetScheduledReportsRequestPayload) =>
  action(ScheduledReportActionTypes.GET_SCHEDULED_REPORTS_FOR_ORGANISATION_REQUEST, payload);
export const getScheduledReportsSuccessAction = (payload: {
  data: DynamicObject[];
  pageData: PageData;
  mergeData?: boolean;
}) => action(ScheduledReportActionTypes.GET_SCHEDULED_REPORTS_SUCCESS, payload);
export const getScheduledReportsErrorAction = (payload: string[]) =>
  action(ScheduledReportActionTypes.GET_SCHEDULED_REPORTS_ERROR, payload);

export const updateScheduledReportsTableFilterAction = (tableFilter: string) =>
  action(ScheduledReportActionTypes.UPDATE_SCHEDULED_REPORTS_TABLE_FILTER_STATE, tableFilter);

export const updateScheduledReportsFiltersAction = (
  filters: GetScheduledReportsRequestPayload['filters']
) => action(ScheduledReportActionTypes.UPDATE_SCHEDULED_REPORTS_FILTERS, filters);

export const updateScheduledReportsSortByAndStateAction = (tableSortAndState: {
  sortBy: GetScheduledReportsRequestPayload['sortBy'];
  sortAscending: boolean;
}) =>
  action(
      ScheduledReportActionTypes.UPDATE_SCHEDULED_REPORTS_SORT_BY_AND_STATE,
      tableSortAndState
  );


// Select single schedule report
export const setSelectedScheduledReportIdRequestAction = (
  ScheduleId: string | null
) =>
  action(ScheduledReportActionTypes.SET_SCHEDULED_REPORT_SELECTED_ID, {
    ScheduleId,

  });
export const getScheduledReportDataRequestAction = (
  ScheduleId: string
) =>
  action(ScheduledReportActionTypes.GET_SCHEDULED_REPORT_DATA_REQUEST, {
    ScheduleId
  });
export const getScheduledReportDataForOrganisationRequestAction = (
  ScheduleId: string,
  Region: string
) =>
  action(ScheduledReportActionTypes.GET_SCHEDULED_REPORT_DATA_FOR_ORGANISATION_REQUEST, {
    ScheduleId,
    Region
  });

export const getScheduledReportDataSuccessAction = (payload: { record: {} }) =>
  action(ScheduledReportActionTypes.GET_SCHEDULED_REPORT_DATA_SUCCESS, payload);

export const getScheduledReportDataErrorAction = (payload: string[]) =>
  action(ScheduledReportActionTypes.GET_SCHEDULED_REPORT_DATA_ERROR, payload);

export const clearScheduledReportDataAction = () =>
  action(ScheduledReportActionTypes.CLEAR_SCHEDULED_REPORT_DATA);

// Delete schedules 
export const deleteScheuledReportsAction = (
  key: string[],
  CompanyId: string,
  callback?: (response: ResponseModalObject) => void
) =>
  action(ScheduledReportActionTypes.DELETE_SCHEDULED_REPORT, {
      key,
      CompanyId,
      callback,
  });