import { API, graphqlOperation } from 'aws-amplify';
import { filter, get, map, filter as lFilter, } from 'lodash';
import {
    all,
    call,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import { DynamicObject } from '../../utils/commonInterfaces';
import { getRemittanceAdviceDetailsError,
         getRemittanceAdviceDetailsSuccess, 
         getRemittanceAdvicesSuccessAction, 
         setRemittanceAdviceSelectedIdSuccessAction, 
         getRemittanceAdviceInvoicesErrorAction, 
         getRemittanceAdviceInvoicesSuccessAction, 
         getRemittanceAdviceConversationSuccessAction, 
         getRemittanceAdviceConversationErrorAction, 
         getRemittanceAdviceChangesSuccessAction, 
         getRemittanceAdviceChangesErrorAction, 
         getRemittanceAdvicePaymentsErrorAction, 
         getRemittanceAdvicePaymentsSuccessAction,
         getRemittanceAdviceCreditsSuccessAction,
         getRemittanceAdviceCreditsErrorAction,
         getRemittanceAdviceCustomersSuccessAction,
         getRemittanceAdviceCustomersErrorAction,
         reviewRemittanceAdviceSuccess,
         getRemittanceAdviceRebatesSuccessAction,
         getRemittanceAdviceRebatesErrorAction,
         getRemittanceAdviceSummarySuccess,
         getRemittanceAdviceSummaryError,
         getConfirmRemittanceAdviceInvoicesSuccessAction,
         getConfirmRemittanceAdviceCreditsSuccessAction,
         getConfirmRemittanceAdviceRebatesSuccessAction,
         getRAProcessingConfigurationSuccessAction,
         getRAProcessingConfigurationErrorAction,
         saveRAProcessingConfigurationResponseAction,
         saveRARebateMappingConfigurationResponseAction
        } from './actions';
import { RemittanceAdvicesActionTypes } from './types';
import queries from '../../graphql/queries.graphql';
import { API_NAME } from '../../config/config';
import { DETAILS_TAB, REMITTANCE_ADVICES_PAGE } from '../../config/tableAndPageConstants';
import { checkIfEmailIsValid, formatDateToDateObjectUTC, getSortFieldsWithCustomFields, removeAppliedFiltersForApiRequest } from '../../utils/commonFunctions';
import { getCurrentUser } from '../users/sagas';
import moment from 'moment-timezone';
import { FamilyNameAttribute, GivenNameAttribute } from '../../constants/authUserAttributes';
import { PageData } from '../common/types';
import { Payment } from '../payments/types';
import { Invoice } from '../invoices/types';
import { Credit } from '../credits/types';
import { appliedFilterIndicator } from '../../components/common/FilterBar';
import { populateSortFieldsParamSortParams } from '../tasks/sagas';

export const selectRemittanceAdviceData = (state: ApplicationState) =>
    state.remittanceAdvices.activeData;

export const getRemittanceAdviceSelectedId = (state: ApplicationState) =>
    state.remittanceAdvices.activeData.selectedId;

export const getRemittanceAdvicesPageData = (state: ApplicationState) =>
    state.remittanceAdvices.pageData;
    
export const getRAProcessingConfig = (state: ApplicationState) =>
    get(state.remittanceAdvices.activeData, 'raProcessingConfiguration');

const preparePayloadForTaskFilter = ({ payload, canExcludeTasks, parseCustomFields }: { 
    payload: any, 
    canExcludeTasks: boolean,
    parseCustomFields: boolean
 }) => {
    const {
        filter,
        filters,
        sortBy,
        sortAscending,
        usingMultipleWorkflow,
        isPaymentPlanEnabled,
        taskIds,
        excludeTasks,
        recordLimit
    } = payload;

    const finalPayload: any = {
        Ascending: sortAscending,
        ...(
            canExcludeTasks ? {
                TaskIds: taskIds,
                ExcludeTasks: excludeTasks,
                RecordLimit: recordLimit
            } : {}
        )
    };
    const finalFilter = filter || filters; // Handle different filter property names in models

    if (finalFilter) {
        const typeValue = isPaymentPlanEnabled
            ? finalFilter.Type
            : lFilter(finalFilter.Type, (ft: any) => ft !== 8);
        const usedFilters = {
            ...finalFilter,
            Type: typeValue,
            [`Type${appliedFilterIndicator}`]: typeValue,
        };
        const cleanFilters = removeAppliedFiltersForApiRequest(
            usedFilters,
            true,
            'task'
        );
        const assignedUserValue = cleanFilters.AssignedUserId;
        if (assignedUserValue) {
            if (checkIfEmailIsValid(assignedUserValue)) {
                delete cleanFilters.AssignedUserId;
                cleanFilters.AssignedEmailAddress = assignedUserValue;
            }
        }
        if (parseCustomFields) {
            if (cleanFilters.CustomFieldFilters) {
                cleanFilters.CustomFieldFilters = JSON.parse(
                    cleanFilters.CustomFieldFilters
                );
            }
        }
        if (!usingMultipleWorkflow) delete cleanFilters.WorkflowIds;
        Object.assign(finalPayload, cleanFilters);
    }

    if (sortBy) {
        const sortFields = populateSortFieldsParamSortParams(sortBy);
        if (parseCustomFields) {
            if (sortFields.CustomFieldSort) {
                sortFields.CustomFieldSort = JSON.parse(
                    sortFields.CustomFieldSort
                );
            }
        }
        Object.assign(finalPayload, sortFields);
    }

    return finalPayload;
}

 /**
 * Function calling the API for fetching the remittance advice data based on the given id.
 * @param param0
 */
function* handleGetRemittanceAdviceRequest({ payload }: any) {
    const errorMessage =
        'Error fetching remittance advice details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            ActiveState,
            pageSize,
            currentPage,
            CompanyId, 
            CustomerId,
            PaidDateMin, 
            PaidDateMax, 
            AmountType, 
            PaidAmountMin, 
            PaidAmountMax, 
            SortField,
            callback 
        } = payload;


        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true, 'remittanceAdvices');        
        const sortObject = getSortFieldsWithCustomFields(SortField);
        
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REMITTANCE_ADVICES_FOR_COMPANY, {
                ...cleanFilters,
                ...sortObject,
                Ascending: sortAscending,
                ActiveState: ActiveState,
                PageSize: pageSize,
                Skip: currentPage * REMITTANCE_ADVICES_PAGE.pageSize,
                CompanyId: CompanyId,
                CustomerId: CustomerId,
                PaidDateMin: isNaN(PaidDateMin) ? cleanFilters.PaidDateMin : PaidDateMin,
                PaidDateMax: isNaN(PaidDateMax) ? cleanFilters.PaidDateMax : PaidDateMax,
                PaidAmountMin: PaidAmountMin,
                PaidAmountMax: PaidAmountMax,
                AmountType: AmountType ? AmountType: cleanFilters.AmountType,
                //SortField: SortField,
                callback
            })
        );

        const RemittanceAdvice = get(res.data, 'GetRemittanceAdvicesForCompany.RemittanceAdvices');

        if (RemittanceAdvice) {
            const responsePayload = {
                data: RemittanceAdvice,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(RemittanceAdvice.length < pageSize) &&
                        !(pageSize < REMITTANCE_ADVICES_PAGE.pageSize),
                },
            };

            yield put(getRemittanceAdvicesSuccessAction(responsePayload));
        } else {
            yield put(getRemittanceAdviceDetailsError([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceDetailsError([errorMessage]));
    }
}   

/**
 * Function calling the API for fetching the remittance advice data based on the given id.
 * @param param0
 */
function* handleGetRemittanceAdviceDetailsRequest({
    payload: remittanceAdviceId,
}: any) {
    const errorMessage =
        'Error fetching remittance advice details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REMITTANCE_ADVICE_DETAILS, {
                RemittanceAdviceId: remittanceAdviceId
            })
        );

        const RemittanceAdvice = get(res.data, 'GetRemittanceAdviceDetailsForCompany');

        const paymentId = get(RemittanceAdvice, 'PaymentResource.Id');
        
        let Payment: any;

        if (paymentId) {
            const resPayment: DynamicObject = yield call(
                [API, 'graphql'],
                graphqlOperation(queries.GET_PAYMENT_DETAILS_FOR_COMPANY, {
                    PaymentId: paymentId
                })
            );
            
            Payment = get(resPayment.data, 'GetPaymentDetailsForCompany');
        }

        if (RemittanceAdvice) {
            const responsePayload = {
                record: RemittanceAdvice,
                payment: Payment
            };

            yield put(getRemittanceAdviceDetailsSuccess(responsePayload));
        } else {
            yield put(getRemittanceAdviceDetailsError([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceDetailsError([errorMessage]));
    }
}

/**
 * Function calling the API for fetching the remittance advice data based on the given id.
 * @param param0
 */
function* handleGetRemittanceAdviceSummaryRequest({
    payload: remittanceAdviceId,
}: any) {
    const errorMessage =
        'Error fetching remittance advice details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REMITTANCE_ADVICE_SUMMARY, {
                RemittanceAdviceId: remittanceAdviceId
            })
        );

        const RemittanceAdvice = get(res.data, 'GetRemittanceAdviceSummary');

        const paymentId = get(RemittanceAdvice, 'PaymentResource.Id');

        let Payment: any;

        if (paymentId) {
            const resPayment: DynamicObject = yield call(
                [API, 'graphql'],
                graphqlOperation(queries.GET_PAYMENT_DETAILS_FOR_COMPANY, {
                    PaymentId: paymentId
                })
            );
            
            Payment = get(resPayment.data, 'GetPaymentDetailsForCompany');
        }

        if (RemittanceAdvice) {
            const responsePayload = {
                record: RemittanceAdvice,
                payment: Payment
            };

            yield put(getRemittanceAdviceSummarySuccess(responsePayload));
        } else {
            yield put(getRemittanceAdviceSummaryError([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceSummaryError([errorMessage]));
    }
}

/**
 * Function called when confirming a remittance advice.
 * @param param0
 */
function* handleConfirmRemittanceAdviceRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
        const response: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/remittance-advice',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when confirming a remittance advice disregard tasks.
 * @param param0
 */
function* handleConfirmRemittanceAdviceDisregardTasksRequest({ payload: sagaPayload }: any) {
    const {
        CompanyId,
        callback,
    } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });
    const payload = {
        TaskManagementFilter: { ...commonTaskFilter, CompanyId },
    };

    try {
        const response: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/disregard/remittanceadvice',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}
/* Function that sets the selected remittanceadviceId id for reference.
 * @param param0
 */
function* handleSetRemittanceAdviceSelectedIdRequest({ payload }: any) {
    const { remittanceAdviceId, callback } = payload;
    yield put(setRemittanceAdviceSelectedIdSuccessAction(remittanceAdviceId));
    if (callback) callback();
}


/**
 * Function for adding comment to the remittance advice's conversation list.
 * @param param0
 */
function* handleRemittanceAdviceAddCommentRequest({ payload: sagaPayload }: any) {
    const { filter, remittanceAdviceIds, excludeRemittanceAdvices, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(filter, true, 'remittanceAdvices');

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }
    const payload = {
        RemittanceAdviceManagementFilter: {
            ...cleanFilters,
            RemittanceAdviceIds: remittanceAdviceIds,
            ExcludeRemittanceAdvices: excludeRemittanceAdvices,
        },
        Comment: comment,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/conversation/save/remittanceadvicecomment',
            {
                body: payload,
            }
        );
        if (callback) {
            let RefetchList = true;
            if (remittanceAdviceIds.length === 1 && excludeRemittanceAdvices === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const remittanceAdvicesUpdated: DynamicObject[] = map(
                    remittanceAdviceIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getRemittanceAdvicesPageData);
                yield put(
                    getRemittanceAdvicesSuccessAction({
                        data: remittanceAdvicesUpdated,
                        pageData,
                        mergeData: true
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function that gets the Customers list for a certain remittance advice.
 * @param param0
 */
function* handleGetRemittanceAdviceCustomersRequest({ payload }: any) {
    const errorMessage = `Error fetching remittance advice's invoice list. Please try again later.`;
    try {
        const {
            filters,
            customerState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            RemittanceAdviceId
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REMITTANCE_ADVICE_CUSTOMER, {
                ...cleanFilters,
                CustomerState: customerState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CUSTOMER_LIST_COMPLETE.pageSize,
                RemittanceAdviceId
            })
        );

        const { Customers } = get(res.data, 'GetCustomersForRemittanceAdvice');
        if (Customers) {
            const responsePayload = {
                data: Customers,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Customers.length < pageSize),
                },
            };

            yield put(getRemittanceAdviceCustomersSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceCustomersErrorAction([errorMessage]));
    }
}



/**
 * Function that gets the invoices list for a certain remittance advice.
 * @param param0
 */
function* handleGetRemittanceAdviceInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching remittance advice's invoice list. Please try again later.`;
    try {
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            type
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REMITTANCE_ADVICE_INVOICE, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
            })
        );

        const { RemittanceAdviceInvoices } = get(res.data, 'GetRemittanceAdviceInvoicesForRemittanceAdvice');
        if (RemittanceAdviceInvoices) {

            if (type == 'Confirm RA'){
                const responsePayload = {
                    data: RemittanceAdviceInvoices,
                    pageData: {
                        pageSize: pageSize,
                        currentPage: currentPage,
                        hasNextPage: !(RemittanceAdviceInvoices.length < pageSize),
                    },
                };
                yield put(getConfirmRemittanceAdviceInvoicesSuccessAction(responsePayload));
            } else {
                const Invoices: Invoice[] = RemittanceAdviceInvoices.map((inv: any) => inv.Invoice);
                const responsePayload = {
                    data: Invoices,
                    pageData: {
                        pageSize: pageSize,
                        currentPage: currentPage,
                        hasNextPage: !(Invoices.length < pageSize),
                    },
                };

                yield put(getRemittanceAdviceInvoicesSuccessAction(responsePayload));
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceInvoicesErrorAction([errorMessage]));
    }
}

/**
 * Function that gets the credits list for a certain customer.
 * @param param0
 */
function* handleGetRemittanceAdviceCreditsRequest({ payload }: any) {
    const errorMessage = `Error fetching customer's credit list. Please try again later.`;
    try {
        const {
            filters,
            creditState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            type
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'remittanceAdvices'
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REMITTANCE_ADVICE_CREDITS, {
                ...cleanFilters,
                CreditState: creditState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CREDIT_LIST_COMPLETE.pageSize,
            })
        );

        const { RemittanceAdviceCredits } = get(res.data, 'GetRemittanceAdviceCreditsForRemittanceAdvice');
        if (RemittanceAdviceCredits) {

            if(type == 'Confirm RA'){
                const responsePayload = {
                    data: RemittanceAdviceCredits,
                    pageData: {
                        pageSize: pageSize,
                        currentPage: currentPage,
                        hasNextPage: !(RemittanceAdviceCredits.length < pageSize),
                    },
                };
                yield put(getConfirmRemittanceAdviceCreditsSuccessAction(responsePayload));
            }

            else {
                const Credits: Credit[] = RemittanceAdviceCredits.map((cre: any) => cre.Credit);
                const responsePayload = {
                    data: Credits,
                    pageData: {
                        pageSize: pageSize,
                        currentPage: currentPage,
                        hasNextPage: !(Credits.length < pageSize),
                    },
                };
    
                yield put(getRemittanceAdviceCreditsSuccessAction(responsePayload));
            }
            
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceCreditsErrorAction([errorMessage]));
    }
}

/**
 * Function that gets the rebate list for a certain customer.
 * @param param0
 */
function* handleGetRemittanceAdviceRebatesRequest({ payload }: any) {
    const errorMessage = `Error fetching customer's credit list. Please try again later.`;
    try {
        const {
            filters,
            creditState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            type
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'remittanceAdvices'
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REMITTANCE_ADVICE_REBATE, {
                ...cleanFilters,
                CreditState: creditState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CREDIT_LIST_COMPLETE.pageSize,
            })
        );

        const { RemittanceAdviceRebates } = get(res.data, 'GetRemittanceAdviceRebatesForRemittanceAdvice');
        if (RemittanceAdviceRebates) {

            if(type == 'Confirm RA'){
                const responsePayload = {
                    data: RemittanceAdviceRebates,
                    pageData: {
                        pageSize: pageSize,
                        currentPage: currentPage,
                        hasNextPage: !(RemittanceAdviceRebates.length < pageSize),
                    },
                };
                yield put(getConfirmRemittanceAdviceRebatesSuccessAction(responsePayload));
            }

            else {
                const Rebate: Credit[] = RemittanceAdviceRebates.map((reb: any) => reb.Rebate);
                const responsePayload = {
                    data: Rebate,
                    pageData: {
                        pageSize: pageSize,
                        currentPage: currentPage,
                        hasNextPage: !(Rebate.length < pageSize),
                    },
                };
    
                yield put(getRemittanceAdviceRebatesSuccessAction(responsePayload));
            }
            
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceRebatesErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given remittance advice.
 * @param param0
 */
function* handleGetRemittanceAdviceConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching remittance advice's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            })
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getRemittanceAdviceConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceConversationErrorAction([errorMessage]));
    }
}


/**
 * Function for fetching the remittanceAdviceChanges list of a given remittance advice.
 * @param param0
 */
function* handleGetRemittanceAdviceChangesRequest({ payload }: any) {
    const errorMessage = `Error fetching remittance advice changes list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CHANGE_LINES_FOR_COMPANY, {
                ...cleanFilters,
                PageSize: pageSize,
                Skip:
                    currentPage * DETAILS_TAB.REMITTANCE_ADVICE_CHANGES_TIMELINE.pageSize,
            })
        );

        const { ChangeLines } = get(res.data, 'GetChangeLinesForCompany');

        // const res = yield call(
        //     [API, 'graphql'],
        //     graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
        //         ...cleanFilters,
        //         SortField: sortBy,
        //         Ascending: sortAscending,
        //         PageSize: pageSize,
        //         Skip:
        //             currentPage * DETAILS_TAB.REMITTANCE_ADVICE_CHANGES_TIMELINE.pageSize,
        //     })
        // );

        // const { ConversationLines: RemittanceAdviceChanges } = get(
        //     res.data,
        //     'GetConversationLinesForCompany'
        // );

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.REMITTANCE_ADVICE_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getRemittanceAdviceChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdviceChangesErrorAction([errorMessage]));
    }
}

/**
 * Function that gets the payments list for a certain customer.
 * @param param0
 */
function* handleGetRemittanceAdvicePaymentsRequest({ payload }: any) {
    const errorMessage = `Error fetching customer's payment list. Please try again later.`;
    try {
        const {
            filters,
            paymentState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'remittanceAdvices'
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENT_DETAILS_FOR_COMPANY, {
                ...cleanFilters,
                // PaymentState: paymentState,
                // SortField: sortBy,
                // Ascending: sortAscending,
                // PageSize: pageSize,
                // Skip: currentPage * DETAILS_TAB.PAYMENT_LIST_COMPLETE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const Payments: Payment[] = [];
        Payments.push(get(res.data, 'GetPaymentDetailsForCompany'));
        
        if (Payments) {
            const responsePayload = {
                data: Payments,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Payments.length < pageSize),
                },
            };

            yield put(getRemittanceAdvicePaymentsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getRemittanceAdvicePaymentsErrorAction([errorMessage]));
    }
}

 /** Function for reviewing RA.
 * @param param0
 */
function* handleReviewRemittanceAdviceRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
        const res: DynamicObject = yield call([API, 'post'], API_NAME, '/task/allocation-confirmation', {
            body: payload,
        });

        if (res) {
            yield put(
                reviewRemittanceAdviceSuccess({
                    AllocationErrors: res.AllocationErrors,
                    AllocationItems: res.AllocationItems,
                })
            );
        }
        
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }       

    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
} 

/**
 * Function for saving RA.
 * @param param0
 */
function* handleSaveRemittanceAdviceRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
       const res: DynamicObject = yield call([API, 'post'], API_NAME, '/remittanceadvice/save', {
            body: payload,
        });
        
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }       

    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}

/**
 * Function for save RA invoice.
 * @param param0
 */
function* handleSaveRemittanceAdviceInvoiceRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;
    const payload = {
        ...fields,
    };

    try {
       const res: DynamicObject = yield call([API, 'post'], API_NAME, '/remittanceadvice/save/remittanceadviceinvoices', {
            body: payload,
        });
        
        
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }       

    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for save RA invoice.
 * @param param0
 */
function* handleSaveRemittanceAdviceCreditRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;
    const payload = {
        ...fields,
    };

    try {
       const res: DynamicObject = yield call([API, 'post'], API_NAME, '/remittanceadvice/save/remittanceadvicecredits', {
            body: payload,
        });
        
        
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }       

    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for save RA invoice.
 * @param param0
 */
function* handleSaveRemittanceAdviceRebateRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;
    const payload = {
        ...fields,
    };

    try {
       const res: DynamicObject = yield call([API, 'post'], API_NAME, '/remittanceadvice/save/remittanceadvicerebates', {
            body: payload,
        });
        
        
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }       

    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}
/**
 * Function for getting the RA custom fields for a company.
 * @param param0
 */
function* handleGetRACustomFieldsForCompanyRequest({ payload }: any) {
    const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        const errorMessage =
        'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_RA_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );

        const CustomFieldValues  = get(res.data, 'GetRACustomFieldsForCompany');
        
        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}


/**
 * Function responsible for getting the RAProcessingConfiguration.
 */
function* handleGetRAProcessingConfigurationRequest() {
    const errorMessage = `Error fetching remittance advice processing configuration details. Please try again later.`;

    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_RA_PROCESSING_CONFIG)
        );

        const raProcessingConfigurationData = get(
            res.data,
            'GetRAProcessingConfig'
        );

        yield put(getRAProcessingConfigurationSuccessAction(raProcessingConfigurationData));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        yield put(getRAProcessingConfigurationErrorAction([errorMessage]));
    }
}

/**
 * Function for saving RA Customization settings.
 * @param param0
 */
function* handleSaveRAProcessingConfigurationRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject =  yield call(
            [API, 'post'],
            API_NAME,
            '/remittanceadvice/raprocessor/save',
            {
                body: payload
            }
        );

        yield put(saveRAProcessingConfigurationResponseAction());
        
        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }       

    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function responsible for getting the companies the user belongs to from API.
 */
function* handleGetRARebateMappingsRequest({payload: sagaPayload}:any) {
    const { callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_RA_REBATE_MAPPINGS)
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}

/**
 * Function for saving RA rebate mappings.
 * @param param0
 */
function* handleSaveRARebateMappingConfigurationRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    
    try {
        const res: DynamicObject =  yield call(
            [API, 'post'],
            API_NAME,
            '/remittanceadvice/rebatemappings/save',
            {
                body: payload
            }
        );

        yield put(saveRARebateMappingConfigurationResponseAction());
        
        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }       

    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        yield put(saveRARebateMappingConfigurationResponseAction());
    }
}


// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.

function* watchGetRemittanceAdviceRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICES_REQUEST,
        handleGetRemittanceAdviceRequest
    );
}

function* watchGetRemittanceAdviceDetailsRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_DETAILS_REQUEST,
        handleGetRemittanceAdviceDetailsRequest
    );
}

function* watchGetRemittanceAdviceSumamryRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_SUMMARY_REQUEST,
        handleGetRemittanceAdviceSummaryRequest
    );
}



function* watchConfirmRemittanceAdviceRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.CONFIRM_REMITTANCE_ADVICE_REQUEST,
        handleConfirmRemittanceAdviceRequest
    );
}

function* watchHandleConfirmRemittanceAdviceDisregardTasksRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.CONFIRM_REMITTANCE_ADVICE_DISREGARD_TASKS_REQUEST,
        handleConfirmRemittanceAdviceDisregardTasksRequest
    );
}
function* watchSetRemittanceAdviceSelectedIdRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.SET_REMITTANCE_ADVICE_SELECTED_ID_REQUEST,
        handleSetRemittanceAdviceSelectedIdRequest
    );
}

function* watchRemittanceAdviceAddCommentRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.REMITTANCE_ADVICE_ADD_COMMENT_REQUEST,
        handleRemittanceAdviceAddCommentRequest
    );
}

function* watchGetRemittanceAdviceCustomersRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CUSTOMERS_REQUEST,
        handleGetRemittanceAdviceCustomersRequest
    );
}

function* watchGetRemittanceAdviceInvoicesRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_INVOICES_REQUEST,
        handleGetRemittanceAdviceInvoicesRequest
    );
}

function* watchGetRemittanceAdviceCreditsRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CREDITS_REQUEST,
        handleGetRemittanceAdviceCreditsRequest
    );
}

function* watchGetRemittanceAdviceRebatesRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_REBATES_REQUEST,
        handleGetRemittanceAdviceRebatesRequest
    );
}

function* watchGetRemittanceAdviceConversationRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CONVERSATION_REQUEST,
        handleGetRemittanceAdviceConversationRequest
    );
}

function* watchGetRemittanceAdviceChangesRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CHANGES_REQUEST,
        handleGetRemittanceAdviceChangesRequest
    );
}

function* watchGetRemittanceAdvicePaymentsRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_PAYMENTS_REQUEST,
        handleGetRemittanceAdvicePaymentsRequest
    );
}

function* watchReviewRemittanceAdviceRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.REVIEW_REMITTANCE_ADVICE_REQUEST,
        handleReviewRemittanceAdviceRequest
    );
}
function* watchGetRACustomFieldsForCompanyRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_RA_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetRACustomFieldsForCompanyRequest
    );
}

function* watchSaveRemittanceAdviceRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.SAVE_REMITTANCE_ADVICE_REQUEST,
        handleSaveRemittanceAdviceRequest
    );
}

function* watchSaveInvoiceRemittanceAdviceRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.SAVE_INVOICE_REMITTANCE_ADVICE_REQUEST,
        handleSaveRemittanceAdviceInvoiceRequest
    );
}

function* watchSaveCreditRemittanceAdviceRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.SAVE_CREDIT_REMITTANCE_ADVICE_REQUEST,
        handleSaveRemittanceAdviceCreditRequest
    );
}

function* watchSaveRebateRemittanceAdviceRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.SAVE_REBATE_REMITTANCE_ADVICE_REQUEST,
        handleSaveRemittanceAdviceRebateRequest
    );
}

function* watchGetRAProcessingConfigurationRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_RA_PROCESSING_CONFIGURATION_REQUEST,
        handleGetRAProcessingConfigurationRequest
    );
}

function* watchSaveRAProcessingConfigurationRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.SAVE_RA_PROCESSING_CONFIGURATION_REQUEST,
        handleSaveRAProcessingConfigurationRequest
    )
}
function* watchSaveRARebateMappingConfigurationRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.SAVE_RA_REBATE_MAPPING_CONFIGURATION_REQUEST,
        handleSaveRARebateMappingConfigurationRequest
    );
}

function* watchGetRARebateMappingsRequest() {
    yield takeLatest(
        RemittanceAdvicesActionTypes.GET_RA_REBATE_MAPPINGS_REQUEST,
        handleGetRARebateMappingsRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* remittanceAdvicesSaga() {
    yield all([
        fork(watchGetRemittanceAdviceRequest),
        fork(watchGetRemittanceAdviceDetailsRequest),
        fork(watchGetRemittanceAdviceSumamryRequest),
        fork(watchConfirmRemittanceAdviceRequest),
        fork(watchHandleConfirmRemittanceAdviceDisregardTasksRequest),
        fork(watchSetRemittanceAdviceSelectedIdRequest),
        fork(watchRemittanceAdviceAddCommentRequest),
        fork(watchGetRemittanceAdviceCustomersRequest),
        fork(watchGetRemittanceAdviceInvoicesRequest),
        fork(watchGetRemittanceAdviceCreditsRequest),
        fork(watchGetRemittanceAdviceRebatesRequest),
        fork(watchGetRemittanceAdviceConversationRequest),
        fork(watchGetRemittanceAdviceChangesRequest),
        fork(watchGetRemittanceAdvicePaymentsRequest),
        fork(watchReviewRemittanceAdviceRequest),
        fork(watchGetRACustomFieldsForCompanyRequest),
        fork(watchSaveRemittanceAdviceRequest),
        fork(watchSaveInvoiceRemittanceAdviceRequest),
        fork(watchSaveCreditRemittanceAdviceRequest),
        fork(watchSaveRebateRemittanceAdviceRequest),
        fork(watchGetRAProcessingConfigurationRequest),
        fork(watchSaveRAProcessingConfigurationRequest),
        fork(watchSaveRARebateMappingConfigurationRequest),
        fork(watchGetRARebateMappingsRequest),
        fork(watchReviewRemittanceAdviceRequest)
    ]);
}

export default remittanceAdvicesSaga;