/**
 * File for the `Total owed` widget form.
 */

import { Col, Divider, Form, Input, Row, Select } from 'antd';
import { capitalize, filter, get, isEmpty, map, toLower } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { ATBStateOptions } from '../../constants/customersSortAndFilters';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { populatePopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { customFieldIndicator } from '../common/FilterBar';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';


const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
}

const TotalOwedWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    customFieldsFilterList,
    isOrgView,
    setFieldsValue,
    getFieldValue,
}: IProps) => {
    
    const customerLabel = useSelector(getCustomerUILabel);
    /**
     * Function for populating the state select options.
     */
    const populateInvoiceStateSelectOptions = () =>
        map(ATBStateOptions, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {label}
            </Option>
        ));

    /**
     * Common function for populating the custom fields filters.
     * @param customFieldType
     */
    const populateCustomFieldsFilters = (customFieldType: string) => {
        return filter(customFieldsFilterList, ['Type', customFieldType]).map(
            ({ Type, FieldName }: DynamicObject) => {
                const customFieldKey = `${customFieldIndicator}${Type}--${FieldName}`;
                return (
                    <Col span={12} key={customFieldKey}>
                        <FormItem label={getTranslatedText(FieldName)}>
                            {getFieldDecorator(customFieldKey, {
                                initialValue: get(
                                    widgetDetails,
                                    customFieldKey
                                ),
                            })(
                                <Input
                                    placeholder={getTranslatedText(FieldName)}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                );
            }
        );
    };

    /**
     * Function that populates the Invoice custom fields section.
     */
    const populateInvoiceCustomFieldsSection = () => {
        const invoiceCustomFields = populateCustomFieldsFilters(
            CUSTOM_FIELD_TYPES.INVOICE
        );
        if (!isEmpty(invoiceCustomFields)) {
            return (
                <>
                    <Divider />
                    <Row>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <h3>{getTranslatedText('Filter by invoice custom fields')}</h3>
                                </Col>
                            </Row>
                            <Row gutter={10}>{invoiceCustomFields}</Row>
                        </Col>
                    </Row>
                </>
            );
        }
    };

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});
    
     /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
     const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

    const populateCustomerFieldSection = () => {
        const filterNameUsed = !isOrgView
            ? capitalize(customerLabel)
            : 'Customer'

        const stateName = 'Customer';
        return (
            <Col span={24} key={stateName}>
                <FormItem label={getTranslatedText(filterNameUsed)}>
                    <InputAutoCompleteWithButtonDashboard
                        readOnly={formDisabled}
                        hasNoOkButton={true}
                        updateField={(value: string) => {
                            changeSearchFilter(stateName, value);
                        }}
                        stateValue={searchFilters[stateName]}
                        queryName={'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                        filterField={'Customer'}
                        sortField={'Company name'}
                        responseName={'GetCustomersForCompany.Customers'}
                        labelField={'DisplayName'}
                        getFieldDecorator={getFieldDecorator}
                        widgetDetails={widgetDetails}
                        filterFieldKey={'Customer'}
                    />
                </FormItem>
            </Col>
        );
    } ;

    /**
     * Function that populates the Customer custom fields section.
     */
    const populateCustomerCustomFieldsSection = () => {
        const customerCustomFields = populateCustomFieldsFilters(
            CUSTOM_FIELD_TYPES.CUSTOMER
        );
        if (!isEmpty(customerCustomFields)) {
            return (
                <>
                    <Divider />
                    <Row>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <h3>
                                        {getTranslatedText(`Filter by ${isOrgView ? 'customer' : toLower(customerLabel)} custom fields`)} 
                                    </h3>
                                </Col>
                            </Row>
                            <Row gutter={10}>{customerCustomFields}</Row>
                        </Col>
                    </Row>
                </>
            );
        }

        return null;
    };

    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on state of open invoices")}>
                            {getFieldDecorator('invoiceOpenState', {
                                initialValue:
                                    get(widgetDetails, 'invoiceOpenState') ||
                                    get(ATBStateOptions, '0.value'),
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Open invoice state required!'),
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateInvoiceStateSelectOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <h3>
                                        {getTranslatedText(`Filter by ${isOrgView ? 'customer' : toLower(customerLabel)}`)}
                                    </h3>
                                </Col>
                            </Row>
                            <Row gutter={10}>{populateCustomerFieldSection()}</Row>
                        </Col>
                </Row>
                {populateCustomerCustomFieldsSection()}
                {populateInvoiceCustomFieldsSection()}
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                    />
                )}
            </Col>
        </Row>
    );
};

export default TotalOwedWidgetFields;
