/**
 * File for the `Payment Behaviour Insights Breakdown` widget form.
 */

import { Col, Form, Row, Select } from 'antd';
import { get, map } from 'lodash';
import React from 'react';
import {
    populatePopoverContainer,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import {
    chartWidgetDisplayTypes
} from '../../constants/dashboards';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
}

export const displayColumnIndicator = 'DisplayColumn---';
export const showCustomFieldsIndicator = 'ShowCustomFields--';

export const displayColumnOptions = {
    Source: {
        label: 'Source',
        value: 'Source',
        defaultChecked: true,
        OrgViewOnly: false,
    }
};

export const displayRowCountOptions = [5, 10, 15, 20];

const CustomersPaymentBehaviourInsightsWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    isOrgView,
    getFieldValue,
    setFieldsValue,
}: IProps) => {

    /**
      * Function that populates the dropdown based on given list of options.
      */
    const populateDisplayTypeOptions = () =>
        map(chartWidgetDisplayTypes, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {label}
            </Option>
        ));

    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label="Filter on display type">
                            {getFieldDecorator('displayType', {
                                initialValue:
                                    get(widgetDetails, 'displayType') ||
                                    get(
                                        chartWidgetDisplayTypes,
                                        '1.value'
                                    ),
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateDisplayTypeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional
                    />
                )}
            </Col>
        </Row>
    );
};

export default withAccountingSystemHandler(CustomersPaymentBehaviourInsightsWidgetFields);
