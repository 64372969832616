import { Reducer } from 'redux';
import { WorkflowActionTypes, WorkflowsState } from './types';
import update from 'immutability-helper';
import { WORKFLOW_CONFIGURATIONS_PAGE } from '../../config/tableAndPageConstants';
import { concat, isEqual, keyBy, merge, uniqWith, values } from 'lodash';

// Type-safe initialState!
export const initialState: WorkflowsState = {
    loading: false,
    saveWorkflowDataLoading: false,
    errorMessages: [],
    data: [],
    filters: {},
    sortAscending: true,
    tableFilter: undefined,
    pageData: {
        pageSize: WORKFLOW_CONFIGURATIONS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    activeData: {
        record: undefined,
        loading: false,
        selectedId: null,
        errorMessages: [],
    }
};

// Type-safe initialState!
/*export const initialState: WorkflowDefinitionState = {
    saveLoading: false,
    selectedWorkflowId: null,
    workflowDefinition: {
        errorMessages: [],
        loading: false,
        workflowDefinitionConfigure: null
    }
};*/

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<WorkflowsState> = (state = initialState, action) => {
    switch (action.type) {
        case WorkflowActionTypes.GET_WORKFLOW_CONFIGURATIONS_REQUEST: {
            return { ...state, loading: true };
        }
        case WorkflowActionTypes.GET_WORKFLOW_CONFIGURATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case WorkflowActionTypes.GET_WORKFLOW_CONFIGURATIONS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        //Single Record
        case WorkflowActionTypes.SET_WORKFLOW_SELECTED_ID_REQUEST: {
            const newWorkflowState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return {
                ...newWorkflowState,
            };
        }

        case WorkflowActionTypes.SET_WORKFLOW_SELECTED_ID_SUCCESS: {
            const newWorkflowState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return {
                ...newWorkflowState,
            };
        }
        case WorkflowActionTypes.GET_WORKFLOW_DEFINITION_REQUEST: {
            const newActiveData = update(state.activeData, {
                loading: { $set: true }
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }
        case WorkflowActionTypes.GET_WORKFLOW_DEFINITION_SUCCESS: {
            const newActiveData = update(state.activeData, {
                loading: { $set: false },
                errorMessages: { $set: initialState.activeData.errorMessages },
                record: { $set: action.payload }
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }
        case WorkflowActionTypes.GET_WORKFLOW_DEFINITION_ERROR: {
            const newActiveData = update(state.activeData, {
                loading: { $set: false },
                errorMessages: { $set: action.payload },
                record: { $set: initialState.activeData.record }
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case WorkflowActionTypes.CLEAR_WORKFLOW_STATES: {
            return initialState;
        }
        case WorkflowActionTypes.CLEAR_WORKFLOW_ACTIVE_DATA: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as workflowsReducer };