/**
 * Component for populating the Actioning payment plan task panel when clicking on `Action` button for Payment plan items.
 */

import {
    Button,
    Checkbox,
    Col,
    Form,
    Modal,
    Row,
    Select,
    Skeleton,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get, isEmpty, map, times } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import ModalWithSpinner from '../../common/ModalWithSpinner';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import { populatePopoverContainer, getTranslatedText } from '../../../utils/commonFunctions';


interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly getActionOptions: (updateActionOptionsCallback: (settings: DynamicObject) => void) => void;
    readonly dispatchActionPaymentPlanTask: (payload: DynamicObject) => void;
    readonly includeCommentVisible: boolean;
}

const { Item: FormItem } = Form;
const { Option } = Select;

const formFields = {
    SELECT_ACTION: 'Trigger',
    COMMENT: 'Comment',
    INCLUDE_COMMENT: 'IncludeComment',
};

const TaskPaymentPlanActionPanelContent: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    getActionOptions,
    dispatchActionPaymentPlanTask,
    includeCommentVisible
}: IProps) => {
    
    const panelRef = useRef(null);
    const [actionOptions, setActionOptions] = useState<{
        options: string[];
        workflowTransitionList: DynamicObject[];
        loading: boolean;
    }>({
        options: [],
        workflowTransitionList: [],
        loading: false,
    });
    const customerLabel = useSelector(getCustomerUILabel);
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { getFieldDecorator, validateFields, resetFields } = form;

    /**
     * Function for closing the panel.
     * When `Cancel` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Listener function if the drawer visibility changes.
     * If drawer closes, resets the field values.
     * If drawer opens, gets action options.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        } else {
            getActionOptions(updateActionOptions);
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function for updating `actionOptions` state.
     * @param actionOptionsObject - must conform to `actionOptions` state
     */
    const updateActionOptions = (actionOptionsObject: {}) => {
        setActionOptions({
            ...actionOptions,
            ...actionOptionsObject,
        });
    };

    /**
     * Function called when clicking the `Action item` button
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: DynamicObject) => {
            if (!err) {
                setSubmitLoading(true);
                dispatchActionPaymentPlanTask({
                    ...values,
                    IncludeComment: includeCommentVisible ? get(
                        values,
                        formFields.INCLUDE_COMMENT,
                        false
                    ) : undefined,
                    callback: resolveTaskResponseModal,
                });
            }
        });
    };

    /**
     * Function for populating the response modal when task resolving action has been called.
     * Either success/error.
     * @param param0
     */
    const resolveTaskResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Payment plan task actioned successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                okText: getTranslatedText('OK'),
            });
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to action task!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                okText: getTranslatedText('OK'),
            });
        }
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const commentFormItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    const isSaveBtnDisabled =
        actionOptions.loading || isEmpty(actionOptions.workflowTransitionList);

    /**
     * Function to populate extra fields based on selected action.
     */
    const populateExtraFields = () => {
        return (
            <Row>
                <Col span={24}>
                    <Row className="mb-10" type="flex" align="middle">
                        <Col md={24}>
                            <span
                                className="ta-right"
                                style={{
                                    minWidth: 116,
                                    display: 'inline-block',
                                }}
                            >
                                {getTranslatedText('Comment')}:
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormItem {...commentFormItemLayout}>
                                {getFieldDecorator(formFields.COMMENT, {
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText(`Comment required!`),
                                        },
                                    ],
                                })(
                                    <TextArea
                                        autoSize={{
                                            minRows: 4,
                                        }}
                                        placeholder={getTranslatedText("Please provide some comment here")}
                                        allowClear
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                {
                    includeCommentVisible &&
                    <Col span={24}>
                        <FormItem className="f-no-lh">
                            {getFieldDecorator(formFields.INCLUDE_COMMENT, {
                                valuePropName: 'checked',
                            })(
                                <Checkbox>
                                    <span className="grey">
                                        {getTranslatedText(`Include this comment in the response to the ${customerLabel}`)}
                                    </span>
                                </Checkbox>
                            )}
                        </FormItem>
                    </Col>
                }
            </Row>
        );
    };

    /**
     * Function to populate the loading content.
     * Shows a loading skeleton if the content is being populated (API not finished fetching data for action options).
     */
    const populatePanelContent = () => {
        if (actionOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {
            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <Row className="mb-10">
                                <Col span={24}>
                                    {getTranslatedText(`Please select the resolution option and
                                    enter a comment on the actions you have
                                    taken.`)}
                                </Col>
                            </Row>
                            <Row className="mb-10" type="flex" align="middle">
                                <Col span={24}>
                                    <FormItem
                                        {...formItemLayout}
                                        label={getTranslatedText("Action")}
                                    >
                                        {getFieldDecorator(
                                            formFields.SELECT_ACTION,
                                            {
                                                initialValue: get(
                                                    actionOptions.options,
                                                    '0'
                                                ),
                                            }
                                        )(
                                            <Select
                                                style={{ width: '100%' }}
                                                loading={actionOptions.loading}
                                                getPopupContainer={populatePopoverContainer(panelRef)}
                                            >
                                                {map(
                                                    actionOptions.options,
                                                    (option) => (
                                                        <Option
                                                            key={option}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            {populateExtraFields()}
                        </Col>
                    </Row>
                </Form>
            );
        }
    };

    return (
        <div ref={panelRef}>
            <Row>
                <Col className="dropdown-multiline-cont">
                    <div>{populatePanelContent()}</div>
                    <br />
                    <Row>
                        <Col className="ta-right" span={24}>
                            <Button
                                className="mr-8"
                                type="primary"
                                onClick={handleSubmitForm}
                                disabled={isSaveBtnDisabled}
                            >
                                {getTranslatedText('Action item')}
                            </Button>
                            <Button onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                        </Col>
                    </Row>
                </Col>
                {submitLoading && (
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Actioning task")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while actioning the task . . .")}
                    />
                )}
            </Row>
        </div>
    );
};

const TaskPaymentPlanActionPanelContentForm = Form.create({
    name: 'task-payment-plan-action-panel-form',
})(TaskPaymentPlanActionPanelContent);

export default withRouter(
    withDateFormatHandler(TaskPaymentPlanActionPanelContentForm)
);
