export enum SmsProviderActionTypes {
    GET_SMS_CUSTOM_PROVIDER_REQUEST = '@@smsProviders/GET_SMS_CUSTOM_PROVIDER_REQUEST',
    GET_SMS_CUSTOM_PROVIDER_SUCCESS = '@@smsProviders/GET_SMS_CUSTOM_PROVIDER_SUCCESS',
    GET_SMS_CUSTOM_PROVIDER_FAIL = '@@smsProviders/GET_SMS_CUSTOM_PROVIDER_FAIL',
    SAVE_SMS_CUSTOM_PROVIDER_REQUEST = '@@smsProviders/SAVE_SMS_CUSTOM_PROVIDER_REQUEST',
    SAVE_SMS_CUSTOM_PROVIDER_RESPONSE = '@@smsProviders/SAVE_SMS_CUSTOM_PROVIDER_RESPONSE',
}

export enum SmsProviderOptions {
    Optus = 'Optus',
    Telstra = 'Telstra'
}

export interface RequestSaveCompanySmsCustomizationItemPayload {
    SmsCustomization: SmsCustomization;
    callback?: (response: any) => void;
}

export interface SmsCustomizationState {
    data: SmsCustomization;
    loading: boolean;
    saveLoading: boolean;
    errorMessages: string[];
}

export interface SmsCustomization {
    SelectedItemId: string;
    Items: SmsCustomizationItem[];
    IsCustomizeSms: boolean;
}

export interface SmsCustomizationItem {
    Id: string;
    Name: string;
    Type: SmsProviderOptions;
    Url: string;
    ApiKey: string;
    ApiSecret: string;
    OauthUrl: string;
    ClientId: string;
    ClientSecret: string;
}
