/**
 * File responsible for all the UI and actions for Settings>Companies page - `/app/settings/companies`.
 */

import { Button, Col, Modal, Row, Typography } from 'antd';
import {
    difference,
    filter,
    find,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from '../../../components/common/ActionBar';
import FilterBar from '../../../components/common/FilterBar';
import FontAwesome from '../../../components/common/FontAwesome';
import { withPageViewHandler } from '../../../components/common/PageViewHandler';
import VirtualizedList from '../../../components/common/VirtualizedList';
import CompanyItemComponent from '../../../components/organisation/CompanyItemComponent';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../../config/config';
import {
    ORGANISATION_COMPANIES_PAGE,
    PAGE_NAMES_FOR_VIEW,
} from '../../../config/tableAndPageConstants';
import {
    OrganisationIdAttribute,
    OrganisationRoleIdAttribute,
    SubAttribute,
} from '../../../constants/authUserAttributes';
import { companiesStatusOptions } from '../../../constants/organisationsSortAndFilters';
import '../../../less/organisation.less';
import { ApplicationState } from '../../../store';
import { getRegionKeyConfig } from '../../../store/auth/sagas';
import { getUserCompaniesRequestAction } from '../../../store/companies/actions';
import {
    closeCompanyRequestAction,
    getOrganisationCompaniesRequestAction,
    // closeCompanyRequestAction,
    updateOrganisationCompaniesFiltersAction,
    updateOrganisationCompaniesTableFilterAction,
} from '../../../store/organisations/actions';
import { initialState } from '../../../store/organisations/reducer';
import {
    GetOrganisationCompaniesRequestPayload,
    OrganisationsState,
} from '../../../store/organisations/types';
import { getRolePermissions } from '../../../store/roles/sagas';
import { getCurrentUser } from '../../../store/users/sagas';
import {
    computeTableScroll,
    emptyPredefinedFilterOnAppliedFilters,
    getTranslatedText
} from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { I18n } from 'aws-amplify';

const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);

const { Title } = Typography;
const { confirm } = Modal;

interface IProps {
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}

let lastSelectedOrganisationId: null | string = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchCompaniesRequired = false;
let skipListenToPreconfiguredFilter = false;
const pageName = PAGE_NAMES_FOR_VIEW.ORG_COMPANIES_PAGE;
let initialTableFilter: undefined | string = undefined;

const CompaniesManagementPage: React.FC<IProps> = ({
    handlePageViewSelection,
}) => {
    
    const regionKeyConfig = useSelector(getRegionKeyConfig);
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);
    const roleList = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );
    const orgUserRoleId = get(currentUser, OrganisationRoleIdAttribute);
    const orgUserRole = find(roleList, ['RoleId', orgUserRoleId]);
    const userRole = get(orgUserRole, 'Name');

    const currentUserId: string = get(currentUser, SubAttribute);
    const rolePermissions = useSelector(getRolePermissions);

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const companiesState = useSelector(
        (state: ApplicationState) => state.organisations.companies
    );

    const selectedUserOrganisation: OrganisationsState = useSelector(
        (state: ApplicationState) =>
            state.organisations.selectedUserOrganisation
    );

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(companiesState, 'pageData.currentPage', 0)
    );
    const [tableSortState, setTableSortState] = useState<{
        sortBy: GetOrganisationCompaniesRequestPayload['sortBy'];
        sortAscending: boolean;
    }>({
        sortBy: companiesState.sortBy,
        sortAscending: companiesState.sortAscending,
    });
    const [tableFilters, setTableFilters] = useState<
        GetOrganisationCompaniesRequestPayload['filters']
    >(companiesState.filters);

    const [companiesTableFilter, setCompaniesTableFilter] = useState<
        string | undefined
    >(
        isEqual(companiesState.filters, initialState.companies.filters)
            ? initialState.companies.tableFilter
            : companiesState.tableFilter
    );

    /**
     * This function checks if there are applied filters stored in redux.
     * If true, the FilterBar component will be shown.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(companiesState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        changeRolePopover: boolean;
        allSelected: boolean;
        downloadToExcel: boolean;
        closeCompany: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        changeRolePopover: false,
        allSelected: false,
        downloadToExcel: false,
        closeCompany: false,
    });

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: any[];
        unselectedRowKeys: any[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetOrganisationCompaniesRequestPayload = {
            filters: companiesState.filters,
            sortBy: tableSortState.sortBy,
            sortAscending: tableSortState.sortAscending,
            pageSize,
            currentPage,
            includeTotalUser: true,
        };

        return payload;
    };

    /**
     * Fetch the Companies from API.
     * @param currentPage - page where the scroll is at
     * @param pageSize - number of items in a page
     */
    const fetchCompanies = (
        currentPage = tableCurrentPage,
        pageSize = ORGANISATION_COMPANIES_PAGE.pageSize
    ) => {
        if (isEmpty(selectedUserOrganisation)) return;

        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getOrganisationCompaniesRequestAction(payload));
    };

    /**
     * Function that controls the page before fetch company is called.
     * This also sets the state table page that is used.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserOrganisation) ||
            companiesState.loading
        )
            return;

        if (!get(companiesState.pageData, 'hasNextPage', false)) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchCompanies(nextPage);
    };

    /**
     * Function that will be called upon initial loading of page, filter and sort changes, and company switch.
     */
    const callFetchCompanies = () => {
        if (!selectedUserOrganisation) return;
        const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
        const selectedOrganisationId = get(
            selectedUserOrganisation,
            'OrganisationId'
        );

        if (organisationIdCognito === selectedOrganisationId) {
            if (lastSelectedOrganisationId !== selectedOrganisationId) {
                lastSelectedOrganisationId = selectedOrganisationId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }
            fetchCompanies(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending } = companiesState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending,
            });
        }
    };

    useEffect(callFetchCompanies, [
        companiesState.sortBy,
        companiesState.sortAscending,
        companiesState.filters,
        selectedUserOrganisation,
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;

        if (companiesState.tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchCompaniesRequired) {
                fetchCompanies(0);
                fetchCompaniesRequired = false;
            }
        } else {
            handlePageViewSelection(
                companiesState.tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [companiesState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedOrganisationId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    /**
     * Function that changes the company filter (the dropdown menu right next to the sync/refresh button).
     * @param filter - dropdown view value
     */
    const changeCompaniesTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });

                resetAllSelectedRowKeys(true);
            } else {
                resetAllSelectedRowKeys();
            }
        }

        if (filter !== companiesState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setCompaniesTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === companiesState.tableFilter) {
            handleCompaniesFilterRefresh();
        } else {
            dispatch(updateOrganisationCompaniesTableFilterAction(filter));
        }
    };

    /**
     * A common function for updating tableRowSelection state object.
     * @param selectionObject - object to be updated. Key value pair must conform to tableRowSelection
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function triggered when checkbox for the row is selected.
     * @param record - the record/data for the said row - based on API
     * @param selected - if the checkbox is checked or unchecked
     * @param selectedRows - list of keys for the selected row
     * @param nativeEvent - native event for the Checkbox component / boolean
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: string[],
        nativeEvent: Event | boolean
    ) => {
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };

    /**
     * Checking if the current company role is allowed to change other company's role.
     * Other companies only - cause the checkbox for the current company is disabled.
     */
    const allowedCloseCompanyRole =
        isEmpty(rolePermissions.ORGANISATION_CLOSE_COMPANY) ||
        includes(rolePermissions.ORGANISATION_CLOSE_COMPANY, userRole)
            ? true
            : false;

    /**
     * Function called when the row is clicked.
     * @param record - record for the row - type is `any` because there may be some adjustments and additional keys that will be added
     */
    const onRowClick = (record: any) => {
        if (allowedCloseCompanyRole) {
            const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
            const isCurrentlySelected = includes(selectedRowKeys, record.key);
            const newSelectedRowKeys = !isCurrentlySelected
                ? [...selectedRowKeys, record.key]
                : remove(selectedRowKeys, (key: string) => key !== record.key);

            onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
        }
    };

    const selectedKeysName = 'CompanyId';

    /**
     * Function that cwill be called when company state data is changed.
     * Basically checks if select all is clicked, and make all the checkboxes checked.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(companiesState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            const idKeys = filter(
                selectedKeys,
                (key: string) => key !== currentUserId
            );

            updateTableRowSelection({
                selectedRowKeys: idKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [currentUserId, companiesState.data]);

    /**
     * Function that updates the visibility of showConditionsObject.
     * @param visible
     * @param filterName
     */
    const changeConditionVisibility = (
        visible: boolean,
        filterName: string
    ) => {
        updateShowConditionsObject({
            [filterName]: visible,
        });
    };

    /**
     * Function that resets the selected and unselected row keys to their initial values and also the allSelected flag
     * for detecting the status of Select All button.
     * @param excludeShowConditions - boolean indicator that checks if allSelected property in
     * `showConditions` state be excluded in resetting.
     */
    const resetAllSelectedRowKeys = (excludeShowConditions = false) => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        if (!excludeShowConditions) {
            updateShowConditionsObject({
                allSelected: false,
            });
        }
    };

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (companiesState.tableFilter !== initialTableFilter) {
            setCompaniesTableFilter(initialTableFilter);
            if (!isUndefined(initialTableFilter))
                changeCompaniesTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Function called when Apply filter is clicked inside the filter bar.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetOrganisationCompaniesRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    companiesTableFilter,
                    fromFilterBar,
                    () => {
                        changeCompaniesTableFilter('');
                    }
                );
            }
        }

        const appliedFilters = filters || initialState.companies.filters;

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(
            updateOrganisationCompaniesFiltersAction(appliedFilters)
        );
        resetAllSelectedRowKeys();
    };

    /**
     * Function called when Apply button is clicked after changing some values in the Sort by section found
     * upon clicking the 3 dots which shows a popover.
     */
    // const applySortedBy = async () => {
    //     await resetTableScrollAndPageData();
    //     await dispatch(updateOrganisationCompaniesSortByAction(tableSortState));
    //     await updateShowConditionsObject({
    //         filterEllipsis: false,
    //     });
    // };

    /**
     * Function responsible for updating the sort order and sortby - call to API.
     * @param sortAscending - boolean (true if ascending and false if descending)
     */
    // const changeSortOrder = async (sortAscending: boolean) => {
    //     await resetTableScrollAndPageData();
    //     dispatch(
    //         updateOrganisationCompaniesSortByAction({
    //             sortBy: tableSortState.sortBy,
    //             sortAscending,
    //         })
    //     );
    // };

    /**
     * Function that resets the current page and scroll location.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for updating the sort state.
     * @param tableSortStateObject
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function for updating the showConditions state.
     * @param showConditionObject
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    // UI Condition Updates on Render
    /**
     * Dropdown renderer UI near the sync button.
     * @param menu
     */
    const populateCompaniesFilterSelectDropdownRender = (menu: any) => (
        <div>
            {menu}
            {/* <Divider className="action-bar-divider" />
             <div
                 className="pa-12 cursor-p"
                 onMouseDown={() => console.log('Add Custom View Clicked')}
             >
                 Add custom view
             </div> */}
        </div>
    );

    const downloadDisabled =
        companiesState.loading || isEmpty(companiesState.data);

    /**
     * Function responsible for the upper section on the ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {/*getTranslatedText('Filter')*/
                    I18n.get('Filter')
                }
            </Button>
            {/* <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...tableSortState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            companiesState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                Change sort order
            </Button>
            {/* <br />
            <Button
                type="link"
                onClick={downloadToExcelHandler}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                Download to Excel
            </Button> */}
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    // const downloadToExcelHandler = () => {
    // updateShowConditionsObject({
    //     downloadToExcel: true,
    //     filterEllipsis: false,
    // });
    // const filterAndPageProps = generatePayloadForRequest(
    //     0,
    //     defaultPageSizeForReport
    // );
    // const { filters, sortBy, sortAscending } = filterAndPageProps;
    // const variables = {
    //     Name: filters.Name,
    //     Email: filters.Email,
    //     RoleIds: filters.Role,
    //     SortField: sortBy,
    //     Ascending: sortAscending,
    // };
    // const payload = {
    //     Query: companiesSummaryPageQuery,
    //     OperationName: companiesSummaryQueryName,
    //     Variables: JSON.stringify(variables),
    //     PageName: PAGE_NAMES_FOR_VIEW.ORGANISATION_COMPANIES,
    // };
    // dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    // };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    // const downloadToExcelModalResponse = ({
    //     IsSuccess,
    //     Messages,
    // }: ResponseModalObject) => {
    //     updateShowConditionsObject({
    //         downloadToExcel: false,
    //         filterEllipsis: false,
    //     });
    //     downloadToExcelModalResponseHandler(IsSuccess, Messages);
    // };

    /**
     * Function for displaying the lower section for ellipsis popover.
     */
    // const populateFilterEllipsisPopoverContent = () => (
    //     <div className="pop-action-content">
    //         <div className="mb-10">
    //             <span>Sort by</span>
    //         </div>
    //         <div>
    //             <Select
    //                 onChange={(sortBySelected: string) =>
    //                     updateTableSortStateObject({
    //                         sortBy: sortBySelected,
    //                     })
    //                 }
    //                 value={tableSortState.sortBy}
    //             >
    //                 {map(
    //                     companiesSortByOptions,
    //                     ({
    //                         label,
    //                         value,
    //                     }: {
    //                         label: string;
    //                         value: string;
    //                     }) => (
    //                         <Option key={value} value={value}>
    //                             {label}
    //                         </Option>
    //                     )
    //                 )}
    //             </Select>
    //         </div>
    //         <br />
    //         <div className="ta-right">
    //             <Button
    //                 type="primary"
    //                 disabled={
    //                     companiesState.loading ||
    //                     tableSortState.sortBy === companiesState.sortBy
    //                 }
    //                 onClick={applySortedBy}
    //             >
    //                 Apply
    //             </Button>
    //         </div>
    //     </div>
    // );

    /**
     * Function that controls the visibility of popover.
     * @param name
     * @param condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                changeConditionVisibility(visible, name);
            }
        };
    };

    /**
     * Function for populating the loading text for table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } companies`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function that handles the closing of filter bar.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function called when sync/refresh button is clicked.
     */
    const handleCompaniesFilterRefresh = () => {
        fetchCompaniesRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchCompanies(0);
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function for getting the selected companies values.
     */
    const getSelectedCompaniesValues = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        let allExcept = false;
        let keysToUse = selectedRowKeys || [
            ...tableRowSelection.selectedRowKeys,
        ];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = unselectedRowKeys || [
                ...tableRowSelection.unselectedRowKeys,
            ];
        }

        const filterObject = {
            SortField: tableSortState.sortBy,
            Ascending: tableSortState.sortAscending,
            CurrentPage: get(companiesState.pageData, 'currentPage'),
            PageSize: ORGANISATION_COMPANIES_PAGE.pageSize,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    /**
     * Function called when close company button is clicked which shows a confirmation modal for the action.
     */
    const confirmCloseCompany = () => {
        const payload = getCompaniesFilterPayload();
        const companyId = get(payload, 'companyIds.0');
        const companyDetails = find(companiesState.data, [
            'CompanyId',
            companyId,
        ]);
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Close company'),
            content: (
                <div dangerouslySetInnerHTML={{ __html: getTranslatedText("Are you sure you want to close the company <b className='red'>{Company.Name}</b>?").replace("{Company.Name}", get(companyDetails, 'Name')) }} />
            ),
            onOk: () => closeCompany(),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    /**
     * Function for populating the payload needed for company actions.
     */
    const getCompaniesFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedCompaniesValues();

        return {
            filter: filterObject,
            companyIds: keysToUse,
            excludeCompanies: allExcept,
        };
    };

    /**
     * Function called when close company is confirmed.
     */
    const closeCompany = () => {
        updateShowConditionsObject({
            closeCompany: true,
        });

        const payload = getCompaniesFilterPayload();
        const companyId = get(payload, 'companyIds.0');
        const companyRecord = find(companiesState.data, [
            'CompanyId',
            companyId,
        ]);
        const isClosed = get(companyRecord, 'IsClosed', false);
        dispatch(
            closeCompanyRequestAction({
                companyId,
                isClosed: !isClosed,
                callback: closeCompanyResponseModal,
            })
        );
    };

    /**
     * Function for populating the response modal after Close company action API call has finished.
     * @param param0
     */
    const closeCompanyResponseModal = ({
        IsSuccess,
    }: {
        IsSuccess: boolean;
    }) => {
        updateShowConditionsObject({
            closeCompany: false,
        });
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Company closed successfully!'),
                onOk: () => {
                    dispatch(getUserCompaniesRequestAction());
                },
                okText: getTranslatedText('OK'),
            });
        } else {
            Modal.error({
                title: getTranslatedText('Error'),
                content: (
                    <div>
                        <div>{getTranslatedText('Failed to close company!')}</div>
                    </div>
                ),
                okText: getTranslatedText('OK'),
            });
        }
    };

    /**
     * Function for populating the title and message for the loading popover when close companies action is executed.
     */
    const populateTitleAndMessageForCloseCompany = () => {
        let closeCompanyTitle = '';
        let closeCompanyMessage = '';
        if (showConditions.closeCompany) {
            closeCompanyTitle = getTranslatedText('Closing company');
            closeCompanyMessage = getTranslatedText(`Please wait while we close the selected company. . .`);
        }

        return {
            closeCompanyTitle,
            closeCompanyMessage,
        };
    };

    /**
     * Function for populating close company button
     */
    const populateCloseCompanyButtonContent = () => {
        return (
            <>
                <FontAwesome icon={['fas', 'minus-square']} />
                <span>{getTranslatedText('Close company')}</span>
            </>
        );
    };

    const { closeCompanyTitle, closeCompanyMessage } =
        populateTitleAndMessageForCloseCompany();

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText('Companies')}</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <FilterBar
                                pageName={pageName}
                                loading={companiesState.loading}
                                applyFilters={applyFilters}
                                filters={[
                                    {
                                        filterName: 'Company name',
                                        filterStateName: 'CompanyName',
                                        filterElement: 'input',
                                        filterType: 'text',
                                    },
                                    {
                                        filterName: 'Status',
                                        filterElement: 'checkbox-group',
                                        filterType: 'array',
                                        filterOptions: companiesStatusOptions,
                                    },
                                ]}
                                filterValues={companiesState.filters}
                                colDivision={7}
                                closeFilterBar={closeFilterBar}
                                appliedView={companiesTableFilter}
                                doesViewNameExist={doesViewNameExist}
                            />
                        </div>
                    )}
                </QueueAnim>
                {/* Action Bar */}
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={companiesState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'companies-filter',
                                actionType: 'select-with-button',
                                selectValue: companiesTableFilter,
                                selectDropdownRender:
                                    populateCompaniesFilterSelectDropdownRender,
                                onSelectChange: changeCompaniesTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText('Refresh')}</span>
                                    </>
                                ),
                                buttonDisabled: companiesState.loading,
                                onButtonClick: handleCompaniesFilterRefresh,
                            },
                            // {
                            //     actionKey: 'add-company',
                            //     actionType: 'protected-button',
                            //     allowedRoles:
                            //         rolePermissions.ORGANISATION_ADD_COMPANY,
                            //     onButtonClick: changeRoute('add-company'),
                            //     buttonContent: (
                            //         <>
                            //             <FontAwesome
                            //                 icon={['fas', 'plus-square']}
                            //             />
                            //             <span>Add company</span>
                            //         </>
                            //     ),
                            // },
                            {
                                actionKey: 'close-company',
                                actionType: 'protected-button',
                                allowedRoles:
                                    rolePermissions.ORGANISATION_CLOSE_COMPANY,
                                buttonDisabled:
                                    isEmpty(
                                        tableRowSelection.selectedRowKeys
                                    ) ||
                                    tableRowSelection.selectedRowKeys.length >
                                        1,
                                onButtonClick: confirmCloseCompany,
                                buttonContent:
                                    populateCloseCompanyButtonContent(),
                            },
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            // popoverContent:
                            //     populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={companiesState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                ORGANISATION_COMPANIES_PAGE.pageSize
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            hideCheckbox={!allowedCloseCompanyRole}
                            onRowClick={onRowClick}
                            loading={companiesState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText="No companies found"
                            hasNextPage={get(
                                companiesState.pageData,
                                'hasNextPage'
                            )}
                            itemComponent={CompanyItemComponent}
                            rerenderTrigger={{
                                dataSource: companiesState.data,
                                selectedRowKeys:
                                    tableRowSelection.selectedRowKeys,
                            }}
                            itemHeight={ORGANISATION_COMPANIES_PAGE.rowHeight}
                            idKeyName="CompanyId"
                            extraData={{
                                regionKeyConfig,
                            }}
                        />
                    </Col>
                </Row>
            </QueueAnim>
            {showConditions.closeCompany && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText(closeCompanyTitle)}
                        modalVisible={showConditions.closeCompany}
                        displayMessage={getTranslatedText(closeCompanyMessage)}
                    />
                </Suspense>
            )}
        </Col>
    );
};

export default withPageViewHandler(CompaniesManagementPage);
