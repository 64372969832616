import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withAuthHandler } from '../common/AuthHandler';
import './App.less';
import {
    Authenticator
} from 'aws-amplify-react';
import config from '../../AmplifyConfig';
import { AMPLIFY_THEME } from '../../config/AmplifyTheme';
import { AppLogo } from './AuthAndLogoContainer';
import { Button, Col, Divider, Modal, Row } from 'antd';
import OtpInput from '../common/OtpInput';
import { useSmsMfaAction, generateMfaSoftwareTokenAction, verifyMfaSoftwareTokenAction } from '../../store/auth/actions';
import ImageWithLoading from '../common/ImageWithLoading';
import { get, isEmpty, map } from 'lodash';
import {
    getPopoverContainer,
    getTranslatedText
} from '../../utils/commonFunctions';

const OTP_LENGTH = 6;

interface IProps {
}

const AppMfa: React.FC<IProps> = ({ }) => {
    const logoRef = useRef<any>(null);

    const map = (message: string) => {
        return message;
    };

    return <div
        className="vh-100 auth-container"
    >
        <Authenticator
            theme={AMPLIFY_THEME}
            hideDefault={true}
            amplifyConfig={config}
            usernameAttributes="email"
            errorMessage={map}
        >
            <AuthenticatorContainer ref={logoRef} />
        </Authenticator>
    </div>;
};

interface IAuthenticatorContainerProps {
    containerRef?: any;
}

const AuthenticatorContainer = React.forwardRef<HTMLDivElement, IAuthenticatorContainerProps>((props, ref) => {
    const { containerRef } = props;
    const [loadingData, setLoadingData] = useState({
        loading: false,
        isTotp: false
    });

    return <>
        <AppLogo ref={ref} />
        <div>
            <TotpSetup loadingData={loadingData} setLoadingData={setLoadingData} containerRef={containerRef} />
            <div style={{ padding: '0 24px' }}><Divider /></div>
            <SmsSetup loadingData={loadingData} setLoadingData={setLoadingData} containerRef={containerRef} />
        </div>
    </>
})

const TotpSetup: React.FC<{
    containerRef?: any,
    loadingData: any,
    setLoadingData: (loadingData: any) => void
}> = ({ containerRef, loadingData, setLoadingData }) => {
    const dispatch = useDispatch();
    const [otpValues, setOtpValues] = useState<string[]>([]);
    const [qrCode, setQrCode] = useState<string | undefined>();
    const otpValue = otpValues.join('');

    const setLoading = (loading: boolean) => setLoadingData({ loading, isTotp: true });

    useEffect(() => {
        setLoading(true);
        dispatch(generateMfaSoftwareTokenAction((response) => {
            setLoading(false);
            const IsSuccess = get(response, 'IsSuccess');
            const Messages = get(response, 'Messages');
            if (IsSuccess) {
                const QrCode = get(response, 'QrCode');
                setQrCode(QrCode);
            } else {
                let errorMessageContent: any = getTranslatedText('Failed to generate QR code for MFA setup');
                if (!isEmpty(Messages)) {
                    errorMessageContent = map(
                        Messages,
                        (error: string, index: number) => (
                            <div key={index}>{getTranslatedText(error)}</div>
                        )
                    );
                }

                Modal.error({
                    title: getTranslatedText("Error"),
                    content: errorMessageContent,
                    getContainer: () => getPopoverContainer(containerRef),
                    okText: getTranslatedText("OK")
                });
            }
        }));
    }, []);

    const submitOtp = () => {
        if (otpValue.length !== OTP_LENGTH) return;

        setLoading(true);
        dispatch(verifyMfaSoftwareTokenAction({
            UserCode: otpValue
        }, (response) => {
            const IsSuccess = get(response, 'IsSuccess');
            const Messages = get(response, 'Messages');
            if (IsSuccess) {
                setLoading(false);
            } else {
                setLoading(false);
                let errorMessageContent: any = getTranslatedText('Failed to generate QR code for MFA setup');
                if (!isEmpty(Messages)) {
                    errorMessageContent = map(
                        Messages,
                        (error: string, index: number) => (
                            <div key={index}>{getTranslatedText(error)}</div>
                        )
                    );
                }

                Modal.error({
                    title: getTranslatedText("Error"),
                    content: errorMessageContent,
                    getContainer: () => getPopoverContainer(containerRef),
                    okText: getTranslatedText("OK")
                });
            }
        }));
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, maxWidth: 500 }}>
            <Row gutter={[10, 10]} align='middle' justify='center'>
                <Col span={24} className="ta-center" style={{ fontSize: 18, fontWeight: 500 }}>
                    <span dangerouslySetInnerHTML={{ __html: getTranslatedText("Scan the QR code below then submit it to complete the MFA setup") }} />
                </Col>
                {<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <ImageWithLoading
                        src={qrCode || ''}
                        style={{
                            height: 250,
                            width: 'auto'
                        }}
                        alt={getTranslatedText("QR Code")}
                    />
                </Col>}
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <OtpInput
                        number={OTP_LENGTH}
                        readOnly={get(loadingData, 'loading')}
                        values={otpValues}
                        onChange={setOtpValues}
                        onPressEnter={submitOtp}
                    />
                </Col>
                <Col span={24} className="ta-center">
                    <Button
                        type="primary"
                        onClick={submitOtp}
                        loading={get(loadingData, 'isTotp') && get(loadingData, 'loading')}
                        disabled={otpValue.length !== OTP_LENGTH || !get(loadingData, 'isTotp')}
                    >
                        {getTranslatedText("Submit")}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

const SmsSetup: React.FC<{
    containerRef?: any,
    loadingData: any,
    setLoadingData: (loadingData: any) => void
}> = ({ containerRef, loadingData, setLoadingData }) => {
    const dispatch = useDispatch();

    const setLoading = (loading: boolean) => setLoadingData({ loading, isTotp: false });

    const useSmsMfa = () => {
        setLoading(true);
        dispatch(useSmsMfaAction((response) => {
            const IsSuccess = get(response, 'IsSuccess');
            const Messages = get(response, 'Messages');
            if (IsSuccess) {
                setLoading(false);
            } else {
                setLoading(false);
                let errorMessageContent: any = getTranslatedText('Failed to complete MFA setup');
                if (!isEmpty(Messages)) {
                    errorMessageContent = map(
                        Messages,
                        (error: string, index: number) => (
                            <div key={index}>{getTranslatedText(error)}</div>
                        )
                    );
                }

                Modal.error({
                    title: getTranslatedText("Error"),
                    content: errorMessageContent,
                    getContainer: () => getPopoverContainer(containerRef),
                    okText: getTranslatedText("OK")
                });
            }
        }));
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 24 }}>
            <Row style={{ width: '100%' }} gutter={[10, 10]} align='middle' justify='center'>
                <Col span={24} className="ta-center" style={{ fontSize: 18, fontWeight: 500 }}>
                    {getTranslatedText('Or receive OTP via SMS instead')}
                </Col>
                <Col span={24} className="ta-center">
                    <Button
                        type="primary"
                        onClick={useSmsMfa}
                        loading={!get(loadingData, 'isTotp') && get(loadingData, 'loading')}
                        disabled={get(loadingData, 'isTotp') && get(loadingData, 'loading')}
                    >
                        {getTranslatedText("Use SMS")}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(withAuthHandler(AppMfa));
