import Alert from 'antd/lib/alert';
import React from 'react';
import { getTranslatedText } from '../../utils/commonFunctions';

const PopupCheckerComponent = () => {
    return (
        <div style={{ padding: 20 }}>
            <Alert
                message={`Pop-up disabled`}
                description={<div>{getTranslatedText('You may close this page and continue.')}</div>}
                type="info"
                showIcon
            />
        </div>
    );
};

export default PopupCheckerComponent;
