import {
    PageData,
    CustomField,
    Attachment,
    ConversationLine,
    ConversationState,
} from '../common/types';
import {
    Invoice,
    GetInvoicesRequestPayload
} from '../invoices/types';
import { customersSortByOptions } from '../../constants/customersSortAndFilters';
import {
    AddCommentCommonFilterPayload,
    ResponseModalObject,
    AssignUserCommonFilterPayload
} from '../../utils/commonInterfaces';
import { Credit, GetCreditsRequestPayload, GetRebatesRequestPayload } from '../credits/types';
import { GetPaymentsRequestPayload } from '../payments/types';
import { Task } from '../tasks/types';
import { Company } from '../companies/types';

export enum CustomersActionTypes {
    GET_CUSTOMERS_REQUEST = '@@customers/GET_CUSTOMERS_REQUEST',
    GET_CUSTOMERS_FOR_ORGANISATION_REQUEST = '@@customers/GET_CUSTOMERS_FOR_ORGANISATION_REQUEST',
    GET_CUSTOMERS_SUCCESS = '@@customers/GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_ERROR = '@@customers/GET_CUSTOMERS_ERROR',
    UPDATE_CUSTOMERS_FILTERS = '@@customers/UPDATE_CUSTOMERS_FILTERS',
    UPDATE_CUSTOMERS_SORT_BY_AND_ATB = '@@customers/UPDATE_CUSTOMERS_SORT_BY_AND_ATB',
    UPDATE_CUSTOMERS_TABLE_FILTER_STATE = '@@customers/UPDATE_CUSTOMERS_TABLE_FILTER_STATE',
    CLEAR_CUSTOMERS_STATE_ALL_TABLE_FILTERS = '@@customers/CLEAR_CUSTOMERS_STATE_ALL_TABLE_FILTERS',
    CLEAR_CUSTOMERS_STATE_DATA = '@@customers/CLEAR_CUSTOMERS_STATE_DATA',
    GET_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@customers/GET_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',

    SET_CUSTOMER_SELECTED_ID_REQUEST = '@@customers/SET_CUSTOMER_SELECTED_ID_REQUEST',
    SET_CUSTOMER_SELECTED_ID_SUCCESS = '@@customers/SET_CUSTOMER_SELECTED_ID_SUCCESS',
    GET_CUSTOMER_DATA_REQUEST = '@@customers/GET_CUSTOMER_DATA_REQUEST',
    GET_ORGANISATION_CUSTOMER_DATA_REQUEST = '@@customers/GET_ORGANISATION_CUSTOMER_DATA_REQUEST',
    GET_CUSTOMER_DATA_SUCCESS = '@@customers/GET_CUSTOMER_DATA_SUCCESS',
    GET_CUSTOMER_DATA_ERROR = '@@customers/GET_CUSTOMER_DATA_ERROR',
    CLEAR_CUSTOMER_DATA_SUCCESS = '@@customers/CLEAR_CUSTOMER_DATA_SUCCESS',

    GET_CUSTOMER_INVOICES_REQUEST = '@@customers/GET_CUSTOMER_INVOICES_REQUEST',
    GET_ORGANISATION_CUSTOMER_INVOICES_REQUEST = '@@customers/GET_ORGANISATION_CUSTOMER_INVOICES_REQUEST',
    GET_CUSTOMER_INVOICES_SUCCESS = '@@customers/GET_CUSTOMER_INVOICES_SUCCESS',
    GET_CUSTOMER_INVOICES_ERROR = '@@customers/GET_CUSTOMER_INVOICES_ERROR',
    UPDATE_CUSTOMER_INVOICES_FILTERS = '@@customers/UPDATE_CUSTOMER_INVOICES_FILTERS',
    UPDATE_CUSTOMER_INVOICES_SORT_BY_AND_STATE = '@@customers/UPDATE_CUSTOMER_INVOICES_SORT_BY_AND_STATE',
    UPDATE_CUSTOMER_INVOICES_TABLE_FILTER_STATE = '@@customers/UPDATE_CUSTOMER_INVOICES_TABLE_FILTER_STATE',

    SAVE_CUSTOMER_UPDATE_REQUEST = '@@customers/SAVE_CUSTOMER_UPDATE_REQUEST',
    SAVE_ORGANISATION_CUSTOMER_UPDATE_REQUEST = '@@customers/SAVE_ORGANISATION_CUSTOMER_UPDATE_REQUEST',
    SAVE_CUSTOMER_UPDATE_RESPONSE = '@@customers/SAVE_CUSTOMER_UPDATE_RESPONSE',
    BULK_SAVE_CUSTOMER_UPDATE_REQUEST = '@@customers/BULK_SAVE_CUSTOMER_UPDATE_REQUEST',
    BULK_SAVE_CUSTOMER_UPDATE_RESPONSE = '@@customers/BULK_SAVE_CUSTOMER_UPDATE_RESPONSE',

    GET_CUSTOMER_CONVERSATION_REQUEST = '@@customers/GET_CUSTOMER_CONVERSATION_REQUEST',
    GET_ORGANISATION_CUSTOMER_CONVERSATION_REQUEST = '@@customers/GET_ORGANISATION_CUSTOMER_CONVERSATION_REQUEST',
    GET_CUSTOMER_CONVERSATION_SUCCESS = '@@customers/GET_CUSTOMER_CONVERSATION_SUCCESS',
    GET_CUSTOMER_CONVERSATION_ERROR = '@@customers/GET_CUSTOMER_CONVERSATION_ERROR',
    UPDATE_CUSTOMER_CONVERSATION_FILTERS = '@@customers/UPDATE_CUSTOMER_CONVERSATION_FILTERS',
    UPDATE_CUSTOMER_CONVERSATION_TABLE_FILTER = '@@customers/UPDATE_CUSTOMER_CONVERSATION_TABLE_FILTER',
    UPDATE_CUSTOMER_CONVERSATION_SORT_BY = '@@customers/UPDATE_CUSTOMER_CONVERSATION_SORT_BY',

    CUSTOMER_ADD_COMMENT_REQUEST = '@@customers/CUSTOMER_ADD_COMMENT_REQUEST',
    ORGANISATION_CUSTOMER_ADD_COMMENT_REQUEST = '@@customers/ORGANISATION_CUSTOMER_ADD_COMMENT_REQUEST',
    
    CUSTOMER_SEND_INFORMATION_REQUEST = '@@customers/CUSTOMER_SEND_INFORMATION_REQUEST',

    GET_CUSTOMER_CREDITS_REQUEST = '@@customers/GET_CUSTOMER_CREDITS_REQUEST',
    GET_ORGANISATION_CUSTOMER_CREDITS_REQUEST = '@@customers/GET_ORGANISATION_CUSTOMER_CREDITS_REQUEST',
    GET_CUSTOMER_CREDITS_SUCCESS = '@@customers/GET_CUSTOMER_CREDITS_SUCCESS',
    GET_CUSTOMER_CREDITS_ERROR = '@@customers/GET_CUSTOMER_CREDITS_ERROR',
    UPDATE_CUSTOMER_CREDITS_FILTERS = '@@customers/UPDATE_CUSTOMER_CREDITS_FILTERS',
    UPDATE_CUSTOMER_CREDITS_SORT_BY_AND_STATE = '@@customers/UPDATE_CUSTOMER_CREDITS_SORT_BY_AND_STATE',
    UPDATE_CUSTOMER_CREDITS_TABLE_FILTER_STATE = '@@customers/UPDATE_CUSTOMER_CREDITS_TABLE_FILTER_STATE',

    GET_CUSTOMER_PAYMENTS_REQUEST = '@@customers/GET_CUSTOMER_PAYMENTS_REQUEST',
    GET_ORGANISATION_CUSTOMER_PAYMENTS_REQUEST = '@@customers/GET_ORGANISATION_CUSTOMER_PAYMENTS_REQUEST',
    GET_CUSTOMER_PAYMENTS_SUCCESS = '@@customers/GET_CUSTOMER_PAYMENTS_SUCCESS',
    GET_CUSTOMER_PAYMENTS_ERROR = '@@customers/GET_CUSTOMER_PAYMENTS_ERROR',
    UPDATE_CUSTOMER_PAYMENTS_FILTERS = '@@customers/UPDATE_CUSTOMER_PAYMENTS_FILTERS',
    UPDATE_CUSTOMER_PAYMENTS_SORT_BY = '@@customers/UPDATE_CUSTOMER_PAYMENTS_SORT_BY',
    UPDATE_CUSTOMER_PAYMENTS_TABLE_FILTER = '@@customers/UPDATE_CUSTOMER_PAYMENTS_TABLE_FILTER',

    GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS = '@@customers/GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS',
    CUSTOMER_ASSIGN_USER_REQUEST = '@@customers/CUSTOMER_ASSIGN_USER_REQUEST',
    GET_CUSTOMER_FIRST_LETTER_VALUES_REQUEST = '@@customers/GET_CUSTOMER_FIRST_LETTER_VALUES_REQUEST'
}

export interface CustomersState {
    readonly loading: boolean;
    readonly saveCustomerDataLoading: boolean;
    readonly errorMessages: string[];
    readonly data: Customer[];
    readonly pageData: PageData;
    readonly filters: GetCustomersRequestPayload['filters'];
    readonly sortBy: GetCustomersRequestPayload['sortBy'];
    readonly atbType: number;
    readonly sortAscending: boolean;
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        invoices: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Invoice[];
            readonly pageData: PageData;
            readonly filters: GetInvoicesRequestPayload['filters'];
            readonly sortBy: GetInvoicesRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly invoiceState: string;
        };
        credits: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Credit[];
            readonly pageData: PageData;
            readonly filters: GetCreditsRequestPayload['filters'];
            readonly sortBy: GetCreditsRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly creditState: string;
        };
        rebates: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Credit[];
            readonly pageData: PageData;
            readonly filters: GetRebatesRequestPayload['filters'];
            readonly sortBy: GetRebatesRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly creditState: string;
        };
        payments: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Credit[];
            readonly pageData: PageData;
            readonly filters: GetPaymentsRequestPayload['filters'];
            readonly sortBy: GetPaymentsRequestPayload['sortBy'];
            readonly sortAscending: boolean;
        };
        conversation: ConversationState;
        selectedId: string | null;
        errorMessages: string[];
    };
}

export interface Contact {
    readonly Id: string;
    readonly AddressLine1: string;
    readonly AddressLine2: string;
    readonly City: string;
    readonly Country: string;
    readonly Email: string;
    readonly FirstName: string;
    readonly LastName: string;
    readonly MobileNumber: string;
    readonly Postcode: string;
    readonly State: string;
    readonly Landline:string;
}

export interface Customer {
    readonly Id: string;
    readonly CustomerCode: string;
    readonly CompanyName: string;
    readonly IsVip: boolean;
    readonly NewInvoiceSendMode: string;
    readonly AgedTrialBalance: AgedTrialBalance;
    readonly Contacts: Contact[];
    readonly DisplayName: string;
    readonly TicketCount: number;
    readonly ConversationLine: ConversationLine;
    readonly CustomFields: CustomField[];
    readonly Attachments: Attachment[];
    readonly LastCommunication: LastCommunication;
    readonly CurrentTask: Task;
    readonly Company: Company;
    readonly PayerState: string;
    readonly CreditLimit: number;
    readonly TotalCreditUsed: number;
    readonly WorkflowName: string;
}
export interface LastCommunication {
    WorkflowName: string;
    StateName: string;
    SentDateTime: string;
    DeliveryDetails: DeliveryDetails[];
}
export interface DeliveryDetails {
    Type: string;
    State: string;
    Comment: string;
    Reason: string;
}
interface AgedTrialBalanceFrom {
    readonly Current: number;
    readonly Period1: number;
    readonly Period2: number;
    readonly Period3Plus: number;
}

export interface AgedTrialBalance {
    readonly AgedTrialBalanceFromCreate: AgedTrialBalanceFrom & {
        NotDue: number;
    };
    readonly AgedTrialBalanceFromDue: AgedTrialBalanceFrom;
    readonly TotalOverdue: number;
    readonly TotalOwing: number;
}

export interface GetCustomersRequestPayload {
    filters: {
        Customer: string;
        InclusiveContact?: boolean;
        Contact?: number;
        AmountType: string;
        AmountValue: string;
        AmountOperator: string;
        CompanyIds?: string[];
    };
    tableFilter?: string;
    atbType: number;
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType?: boolean;
    usingMultipleWorkflow?: boolean;
    usingCustomerWorkflow?: boolean;
    region?: string;
    conversionCurrency?: number;
    organisationCurrencies?: CustomerOrganisationCurrency[]
}

export interface BulkSaveCustomerUpdateRequestPayload {
    filter: {};
    customerIds: string[] | number[];
    excludeCustomers: boolean;
    customerObject: {};
    callback?: (response: ResponseModalObject) => void;
}

export interface SaveCustomerUpdateRequestPayload {
    customerId: string;
    customerObject: {};
    callback?: (response: ResponseModalObject) => void;
}

export interface CustomerAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    customerIds: string[] | number[];
    excludeCustomers: boolean;
}

export interface CustomerSendInformationRequestPayload {
    TemplateName: string;
    filter: {};
    customerIds: string[] | number[];
    excludeCustomers: boolean;
    callback?: Function;
}

export interface GetManualCommunicationTemplatesRequestPayload {
    callback?: (templates: ManualCommunicationTemplate[]) => void
}

export interface ManualCommunicationTemplate {
    TemplateName: string;
    SentToVip: boolean;
}

export interface CustomerAssignUserRequestPayload
    extends AssignUserCommonFilterPayload {
    customerIds: string[] | number[];
    excludeCustomers: boolean;
    userObject: {};
    companyId: string;
}

export interface CustomerOrganisationCurrency {
    Value: string
    Rate: number
}
