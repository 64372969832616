import { Reducer } from "redux";
import { REPORTS_PAGE } from "../../config/tableAndPageConstants";
import { reportSortByOptions } from "../../constants/reportSortAndFilters";
import { ScheduledReportActionTypes, ScheduledReportState } from "./types";
import { concat, isEqual, keyBy, merge, uniqWith, values } from "lodash";
import update from 'immutability-helper';

// Type-safe initialState!
export const initialState: ScheduledReportState = {
    loading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: REPORTS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {
    },
    tableFilter: undefined,
    sortBy: reportSortByOptions[0].value,
    sortAscending: true,
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
    }
};

const reducer: Reducer<ScheduledReportState> = (state = initialState, action) => {
    switch (action.type) {
        // GET SCHEDULED REPORTS
        case ScheduledReportActionTypes.GET_SCHEDULED_REPORTS_FOR_ORGANISATION_REQUEST:
        case ScheduledReportActionTypes.GET_SCHEDULED_REPORTS_REQUEST: {
            return { ...state, loading: true };
        }
        case ScheduledReportActionTypes.GET_SCHEDULED_REPORTS_SUCCESS: {
            let newDataState = [];
            if (action.payload.mergeData === true) {
                newDataState = values(
                    merge(
                        keyBy(state.data, 'ScheduleId'),
                        keyBy(action.payload.data, 'ScheduleId')
                    )
                );
            } else {
                if (action.payload.pageData.currentPage === 0) {
                    newDataState = update(state.data, {
                        $set: action.payload.data,
                    });
                } else {
                    newDataState = uniqWith(
                        concat(state.data, action.payload.data),
                        isEqual
                    );
                }
            }

            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case ScheduledReportActionTypes.GET_SCHEDULED_REPORTS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }

        case ScheduledReportActionTypes.UPDATE_SCHEDULED_REPORTS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }

        case ScheduledReportActionTypes.UPDATE_SCHEDULED_REPORTS_SORT_BY_AND_STATE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending
            };
        }

        case ScheduledReportActionTypes.UPDATE_SCHEDULED_REPORTS_FILTERS: {
            return { ...state, filters: action.payload };
        }

        case ScheduledReportActionTypes.SET_SCHEDULED_REPORT_SELECTED_ID: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload.ScheduleId,
                    },
                },
            });

            return newState;
        }

        case ScheduledReportActionTypes.GET_SCHEDULED_REPORT_DATA_FOR_ORGANISATION_REQUEST:
        case ScheduledReportActionTypes.GET_SCHEDULED_REPORT_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        loading: true
                    },
                },
            });

            return newState;
        }
        case ScheduledReportActionTypes.GET_SCHEDULED_REPORT_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages
                    },
                },
            });

            return newState;
        }
        case ScheduledReportActionTypes.GET_SCHEDULED_REPORT_DATA_ERROR: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        loading: false,
                        record: initialState.activeData.record,
                        errorMessages: action.payload
                    },
                },
            });

            return newState;
        }
        case ScheduledReportActionTypes.CLEAR_SCHEDULED_REPORT_DATA: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as scheduledReportReducer };
