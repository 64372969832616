/**
 * File for the `Sales` widget form.
 */

import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Radio,
    Row,
    Select,
} from 'antd';
import { capitalize, filter, get, isEmpty, isUndefined, map, toLower } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import {
    salesDateRangeOptions,
    widgetDateRangeValues,
    salesDisplayOptions,
    salesDisplayOptionValues,
    salesGroupingOptions,
    salesViewOptions,
    chartWidgetDisplayTypes,
    historicalChartWidgetDisplayTypes,
} from '../../constants/dashboards';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { populatePopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { customFieldIndicator } from '../common/FilterBar';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';


const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    getFieldValue: any;
    setFieldsValue: any;
    isOrgView?: boolean;
}

export const defaultStartDate: any = moment().format('YYYY-01-01T00:00:00');
export const defaultEndDate: any = moment().format('YYYY-12-31T23:59:59');
const SalesWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    customFieldsFilterList,
    getFieldValue,
    setFieldsValue,
    isOrgView,
}: IProps) => {
    
    const customerLabel = useSelector(getCustomerUILabel);
    /**
     * Function that populates the date range options.
     */
    const populateSalesDateRangeOptions = () =>
        map(salesDateRangeOptions, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {label}
            </Option>
        ));

    /**
     * Common function for populating the custom fields filters.
     * @param customFieldType
     */
    const populateCustomFieldsFilters = (customFieldType: string) => {
        return filter(customFieldsFilterList, ['Type', customFieldType]).map(
            ({ Type, FieldName }: DynamicObject) => {
                const customFieldKey = `${customFieldIndicator}${Type}--${FieldName}`;
                return (
                    <Col span={12} key={customFieldKey}>
                        <FormItem label={getTranslatedText(FieldName)}>
                            {getFieldDecorator(customFieldKey, {
                                initialValue: get(
                                    widgetDetails,
                                    customFieldKey
                                ),
                            })(
                                <Input
                                    placeholder={getTranslatedText(FieldName)}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                );
            }
        );
    };

    /**
     * Function that populates the start and end date form input when custom date range is selected.
     */
    const populateCustomDateRangeFormItem = () => {
        if (
            getFieldValue('dateRangeSelected') ===
            widgetDateRangeValues.CUSTOM_DATE_RANGE
        ) {
            return (
                <Col span={24}>
                    <Row gutter={10} className="mt-10">
                        <Col span={12}>
                            <FormItem label={getTranslatedText("Start date")}>
                                {getFieldDecorator('customDateRangeStart', {
                                    initialValue: get(
                                        widgetDetails,
                                        'customDateRangeStart'
                                    )
                                        ? moment(
                                              get(
                                                  widgetDetails,
                                                  'customDateRangeStart'
                                              )
                                          )
                                        : moment(defaultStartDate),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('Start date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("Start date")}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={getTranslatedText("End date")}>
                                {getFieldDecorator('customDateRangeEnd', {
                                    initialValue: get(
                                        widgetDetails,
                                        'customDateRangeEnd'
                                    )
                                        ? moment(
                                              get(
                                                  widgetDetails,
                                                  'customDateRangeEnd'
                                              )
                                          )
                                        : moment(defaultEndDate),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('End date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("End date")}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            );
        } else {
            return null;
        }
    };

    const selectedDisplayOption = getFieldValue('displayOptions');

    /**
     * Function that populates the Invoice custom fields section.
     */
    const populateInvoiceCustomFieldsSection = () => {
        if (
            isUndefined(selectedDisplayOption) ||
            selectedDisplayOption === salesDisplayOptionValues.BOTH ||
            selectedDisplayOption === salesDisplayOptionValues.INVOICE
        ) {
            const invoiceCustomFields = populateCustomFieldsFilters(
                CUSTOM_FIELD_TYPES.INVOICE
            );
            if (!isEmpty(invoiceCustomFields)) {
                return (
                    <>
                        <Divider />
                        <Row>
                            <Col>
                                <Row>
                                    <Col span={24}>
                                        <h3>{getTranslatedText('Filter by invoice custom fields')}</h3>
                                    </Col>
                                </Row>
                                <Row gutter={10}>{invoiceCustomFields}</Row>
                            </Col>
                        </Row>
                    </>
                );
            }
        }

        return null;
    };

    /**
     * Function that populates the Customer custom fields section.
     */
    const populateCustomerCustomFieldsSection = () => {
        const customerCustomFields = populateCustomFieldsFilters(
            CUSTOM_FIELD_TYPES.CUSTOMER
        );
        if (!isEmpty(customerCustomFields)) {
            return (
                <>
                    <Divider />
                    <Row>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <h3>
                                        {getTranslatedText(`Filter by ${isOrgView ? 'customer' : toLower(customerLabel)} custom fields`)}
                                    </h3>
                                </Col>
                            </Row>
                            <Row gutter={10}>{customerCustomFields}</Row>
                        </Col>
                    </Row>
                </>
            );
        }

        return null;
    };

    /**
     * Function that populates the Credit custom fields section.
     */
    const populateCreditCustomFieldsSection = () => {
        if (
            isUndefined(selectedDisplayOption) ||
            selectedDisplayOption === salesDisplayOptionValues.BOTH ||
            selectedDisplayOption === salesDisplayOptionValues.CREDIT
        ) {
            const creditCustomFields = populateCustomFieldsFilters(
                CUSTOM_FIELD_TYPES.CREDIT
            );
            if (!isEmpty(creditCustomFields)) {
                return (
                    <>
                        <Divider />
                        <Row>
                            <Col>
                                <Row>
                                    <Col span={24}>
                                        <h3>{getTranslatedText('Filter by credit custom fields')}</h3>
                                    </Col>
                                </Row>
                                <Row gutter={10}>{creditCustomFields}</Row>
                            </Col>
                        </Row>
                    </>
                );
            }
        }
        return null;
    };

    /**
     * Function that populates the radio group buttons based on given list of options.
     * @param radioOptions
     */
    const populateRadioOptions = (radioOptions: DynamicObject[]) => {
        return map(radioOptions, (opt) => (
            <Radio.Button value={get(opt, 'value')} key={get(opt, 'value')}>
                {get(opt, 'label')}
            </Radio.Button>
        ));
    };

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});
    
     /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
     const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

    const populateCustomerFieldSection = () => {
        const filterNameUsed = !isOrgView
            ? capitalize(customerLabel)
            : 'Customer'

        const stateName = 'Customer';
        return (
            <Col span={24} key={stateName}>
                <FormItem label={getTranslatedText(filterNameUsed)}>
                    <InputAutoCompleteWithButtonDashboard
                        readOnly={formDisabled}
                        hasNoOkButton={true}
                        updateField={(value: string) => {
                            changeSearchFilter(stateName, value);
                        }}
                        stateValue={searchFilters[stateName]}
                        queryName={'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                        filterField={'Customer'}
                        sortField={'Company name'}
                        responseName={'GetCustomersForCompany.Customers'}
                        labelField={'DisplayName'}
                        getFieldDecorator={getFieldDecorator}
                        widgetDetails={widgetDetails}
                        filterFieldKey={'Customer'}
                    />
                </FormItem>
            </Col>
        );
    } ;

       /**
     * Function that populates the dropdown based on given list of options.
     */
       const populateWidgetDisplayTypeOptions = () =>
       map(historicalChartWidgetDisplayTypes, ({ label, value }: any) => (
           <Option key={value} value={value}>
               {label}
           </Option>
       ));
  

    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on display type")}>
                            {getFieldDecorator('displayType', {
                                initialValue:
                                    get(widgetDetails, 'displayType') ||
                                    get(
                                        chartWidgetDisplayTypes,
                                        '2.value'
                                    ),
                            })(
                            <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateWidgetDisplayTypeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>                     
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on date range")}>
                            {getFieldDecorator('dateRangeSelected', {
                                initialValue:
                                    get(widgetDetails, 'dateRangeSelected') ||
                                    widgetDateRangeValues.THIS_CALENDAR_YEAR,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Date range required!'),
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateSalesDateRangeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                    {populateCustomDateRangeFormItem()}
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on display options")}>
                            {getFieldDecorator('displayOptions', {
                                initialValue:
                                    get(widgetDetails, 'displayOptions') ||
                                    get(salesDisplayOptions, '0.value'),
                            })(
                                <Radio.Group className="btn-radio-group">
                                    {populateRadioOptions(salesDisplayOptions)}
                                </Radio.Group>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <div>{getTranslatedText('Filter on group options')}</div>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <FormItem>
                                {getFieldDecorator('groupingOptions', {
                                    initialValue: !isUndefined(
                                        get(widgetDetails, 'groupingOptions')
                                    )
                                        ? get(widgetDetails, 'groupingOptions')
                                        : true,
                                })(
                                    <Radio.Group className="btn-radio-group">
                                        {populateRadioOptions(
                                            salesGroupingOptions
                                        )}
                                    </Radio.Group>
                                )}
                            </FormItem>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <div>{getTranslatedText('Filter on view options')}</div>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <FormItem>
                                {getFieldDecorator('viewOptions', {
                                    initialValue: !isUndefined(
                                        get(widgetDetails, 'viewOptions')
                                    )
                                        ? get(widgetDetails, 'viewOptions')
                                        : true,
                                })(
                                    <Radio.Group className="btn-radio-group">
                                        {populateRadioOptions(salesViewOptions)}
                                    </Radio.Group>
                                )}
                            </FormItem>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <h3>
                                        {getTranslatedText(`Filter by ${isOrgView ? 'customer' : toLower(customerLabel)}`)}
                                    </h3>
                                </Col>
                            </Row>
                            <Row gutter={10}>{populateCustomerFieldSection()}</Row>
                        </Col>
                </Row>
                {populateCustomerCustomFieldsSection()}
                {populateInvoiceCustomFieldsSection()}
                {populateCreditCustomFieldsSection()}
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                    />
                )}
            </Col>
        </Row>
    );
};

export default SalesWidgetFields;
