import { Button, Col, Modal, Row, Select, InputNumber, Popover } from 'antd';
import {
    debounce,
    difference,
    every,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
    some,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withAccountingSystemHandler } from '../../../components/common/AccountingSystemHandler';
import ActionBar from '../../../components/common/ActionBar';
import { withDateFormatHandler } from '../../../components/common/DateFormatHandler';
import { customFieldIndicator } from '../../../components/common/FilterBar';
import FontAwesome from '../../../components/common/FontAwesome';
import { withPageViewHandler } from '../../../components/common/PageViewHandler';
import VirtualizedList from '../../../components/common/VirtualizedList';
import TaskItemComponent from '../../../components/tasks/active_tasks/TaskItemComponent';
import {
    PAGE_NAMES_FOR_VIEW,
    TASKS_PAGE,
} from '../../../config/tableAndPageConstants';
import { MAX_INT_VALUE, selectUserFilterMapping } from '../../../constants/common';
import {
    activeTasksSummaryOrganisationPageQuery,
    activeTasksSummaryOrganisationQueryName,
    defaultPageSizeForReport
} from '../../../constants/downloadToExcel';
import {
    invoicesAmountOperatorOptions,
    invoicesDueInOptions,
    invoicesSortValues,
} from '../../../constants/invoicesSortAndFilters';
import {
    newInvoiceEmailTaskType,
    paymentPlanTaskTypes,
    paymentAllocationTaskType,
    taskHistoryAutomation,
    taskHistoryBatchTypes,
    tasksAmountType,
    tasksReadyStateOptions,
    tasksSortByOptions,
    tasksStatusFilterOptions,
    tasksTypeOptions,
    ticketActionsOptions,
} from '../../../constants/tasksSortAndFilters';
import '../../../less/compact.less';
import { ApplicationState } from '../../../store';
import {
    downloadToExcelAction,
} from '../../../store/common/actions';
import {
    CompaniesState,
    Company,
    CompanyCustomFieldConfigure,
} from '../../../store/companies/types';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import {
    getTableCompactViewRequestAction,
    getTasksActionFilterOptionsRequestAction,
    setSelectedTaskIdRequestAction,
    setTableCompactViewRequestAction,
    setTaskExpandStatusAction,
    updateTasksFiltersAction,
    updateTasksSortByAndStatusAction,
    updateTasksTableFilterAction,
    getTaskViewerDetailForUserOrganisationRequestAction,
    getTasksForOrganisationRequestAction,
    getTaskDataForOrganisationRequestAction
} from '../../../store/tasks/actions';
import { initialState } from '../../../store/tasks/reducer';
import {
    getSelectedTaskId,
    populateSortFieldsParamSortParams,
} from '../../../store/tasks/sagas';
import {
    GetTaskViewerDetailForUserRequestPayload,
    ChangeTaskViewingRequestPayload,
    GetTasksRequestPayload,
    Task,
    TasksState
} from '../../../store/tasks/types';
import { UserPreference } from '../../../store/users/types';
import {
    checkIfEmailIsValid,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getIfIsLg,
    getUsedSortByOptionsIfCloud,
    insertAt,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    replaceInstancesOfCustomerString,
    getTranslatedText,
    useCompanyFlagValue
} from '../../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../utils/commonInterfaces';
import { getCurrentUser } from '../../../store/users/sagas';
import { OrganisationIdAttribute } from '../../../constants/authUserAttributes';
import { Organisation } from '../../../store/organisations/types';
import { AtbViewType } from '../../../constants/settings';

const TaskItemDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../components/tasks/active_tasks/TaskItemDetailsDrawerComponent'
        )
);
const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../../components/common/FilterBar'));

const { Option } = Select;

const GET_TASK_VIEWER_INTERVAL = 5000;

let lastSelectedOrgId: string | null = null;

interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
    readonly customerLabel: string;
    readonly customFieldsFilterList: any;
    readonly functionRefObj: any;
    readonly setSelectedTaskCount: any;
    readonly usingCustomerWorkflow: boolean;
    readonly region: string;
    readonly companies: Company[];
}
let resetTableScroll = false;
let fetchTasksRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = TASKS_PAGE.pageSize;
let isRefetching = false;
const pageName = PAGE_NAMES_FOR_VIEW.TASKS_PAGE;
let initialTableFilter: undefined | string = undefined;
const OrganisationActiveTasksContent: React.FC<IProps> = ({
    isUsingCloudImportType,
    handlePageViewSelection,
    customFieldsFilterList,
    functionRefObj,
    setSelectedTaskCount,
    usingCustomerWorkflow,
    region,
    companies: companies
}: IProps) => {
    
    const customerLabel = useSelector(getCustomerUILabel);
    const currentUser = useSelector(getCurrentUser);
    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );
    const userCompanies: CompaniesState['userCompanies'] = useSelector(
        (state: ApplicationState) => state.companies.userCompanies
    );
    const orgUserCompanies = filter(userCompanies, uc => companies.map(c => c.CompanyId).includes(uc.Company.CompanyId));
    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);
    const organisationLoading: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.loading
    );
    const dispatch = useDispatch();
    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();
    const selectedId = useSelector(getSelectedTaskId);

    const [usedRowHeight, setUsedRowHeight] = useState<number>(
        TASKS_PAGE.compactRowHeight
    );

    const toggledIds: string[] = useSelector(
        (state: ApplicationState) =>
            state.tasks.activeTasks.toggledExpandStatusIds
    );

    const userPreference: UserPreference = useSelector(
        (state: ApplicationState) => state.users.userPreference
    );

    const [filterItemList, setFilterItemList] = useState<DynamicObject[]>([]);

    const [taskDetailsDrawer, setTaskDetailsDrawer] = useState<{
        visible: boolean;
        drawerType: string;
        drawerAutomation: string;
    }>({
        visible: false,
        drawerType: taskHistoryBatchTypes.Communication,
        drawerAutomation: taskHistoryAutomation.Automatic,
    });

    const [getTaskViewerIntervalId, setGetTaskViewerIntervalId] = useState<number | undefined>();
    const [addTaskViewingIntervalId, setAddTaskViewingIntervalId] = useState<number | undefined>();

    const tasksState: TasksState['activeTasks'] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks
    );

    const [tasksTableFilter, setTasksTableFilter] = useState<
        string | undefined
    >(
        isEqual(tasksState.filters, initialState.activeTasks.filters)
            ? initialState.activeTasks.taskTableFilter
            : tasksState.taskTableFilter
    );

    const usingMultipleWorkflow: boolean = some(orgUserCompanies, uc => uc.Company.UsingMultipleWorkflow);
    const workflowFetchLoading: boolean = useSelector(
        (state: ApplicationState) => state.companies.workflowFetchLoading
    );
    const [tableFilters, setTableFilters] = useState<any>(tasksState.filters);
    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
        selectedUnreadyKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
        selectedUnreadyKeys: [],
    });
    const [actionBarItemsState, setActionBarItemsState] = useState<{
        totalTaskCount: number;
    }>({
        totalTaskCount: 0,
    });

    /**
     * Function to get used sort options
     */
    const getTaskUsedSortOptions = () => {
        let usedSortByOptions = getUsedSortByOptionsIfCloud(
            tasksSortByOptions,
            isUsingCloudImportType
        );
        if (usingCustomerWorkflow) {
            usedSortByOptions = filter(
                usedSortByOptions,
                (option) =>
                    option.value !== 'AmountOwing' && option.value !== 'Number'
            );
        }

        return usedSortByOptions;
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        const usedSortByOptions = getTaskUsedSortOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        forEach(
            customFieldsFilterList,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                usedSortValues.push(
                    `${customFieldIndicator}${Type}--${FieldName}`
                );
            }
        );
        if (!includes(usedSortValues, tasksState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortStatus,
                    sortBy: initialState.activeTasks.sortBy,
                };
                updateTableSortStatusObject(newSortValues);
                dispatch(updateTasksSortByAndStatusAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [tasksState.sortBy]);

    useEffect(() => {
        return () => {
            if (getTaskViewerIntervalId) clearInterval(getTaskViewerIntervalId);
        }
    }, [getTaskViewerIntervalId]);

    useEffect(() => {
        return () => {
            if (addTaskViewingIntervalId) clearInterval(addTaskViewingIntervalId);
        }
    }, [addTaskViewingIntervalId]);

    /**
     * Function for checking if there are applied filters currently save on redux.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(tasksState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        allSelected: boolean;
        downloadToExcel: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        allSelected: false,
        downloadToExcel: false,
    });

    const [tableSortStatus, setTableSortStatus] = useState<{
        sortBy: string;
        taskStatus: string;
        sortAscending: boolean;
    }>({
        sortBy: tasksState.sortBy,
        taskStatus: tasksState.taskStatus,
        sortAscending: tasksState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(tasksState, 'pageData.currentPage', 0)
    );

    const [recordLimit, setRecordLimit] = useState<number | undefined>(undefined);
    const [appliedRecordLimit, setAppliedRecordLimit] = useState<number | undefined>(undefined);
    const [recordLimitPopoverVisible, setRecordLimitPopoverVisible] = useState(false);
    const isAppliedRecordLimit = !!appliedRecordLimit && appliedRecordLimit > 0;
    const isAppliedSelectAllOrRecordLimit = showConditions.allSelected || isAppliedRecordLimit;
    const selectedTaskCount = isAppliedSelectAllOrRecordLimit
        ? actionBarItemsState.totalTaskCount
        : tableRowSelection.selectedRowKeys.length;

    useEffect(() => {
        setSelectedTaskCount(selectedTaskCount);
    }, [selectedTaskCount]);

    /**
     * Function for preparing the filters to be used as payload for API.
     */
    const readyAllFiltersSelected = () => {
        let allFilters: DynamicObject = {
            ...tasksState.filters,
            Status: tableSortStatus.taskStatus
        };

        if(companies && isEmpty(allFilters.CompanyIds)) {
            allFilters.CompanyIds = companies.map(c => c.CompanyId);
        }

        const assignedUserValue = allFilters.AssignedUserId;
        if (assignedUserValue) {
            if (checkIfEmailIsValid(assignedUserValue)) {
                delete allFilters.AssignedUserId;
                allFilters.AssignedEmailAddress = assignedUserValue;
            }
        }

        return allFilters;
    };

    // These values are used for showing/hiding specific filters
    const lockedDeliveryMethod = every(orgUserCompanies, uc => get(
        uc, 'Company.LockedDeliveryMethod'
    ));
    const isPaymentPlanEnabled = some(orgUserCompanies, uc => get(
        uc, 'Company.CompanyPaymentPlan.IsEnabled'
    ));
    const supportSendNewInvoice = some(orgUserCompanies, uc => get(
        uc, 'Company.SupportSendNewInvoice'
    ));
    const supportCashAllocation = some(orgUserCompanies, uc => get(
        uc, 'Company.SupportCashAllocation'
    ));

    useEffect(() => {
        const selectedId = tasksState.activeData.selectedId;
        if (selectedId) {
            if (taskDetailsDrawer.visible) {
                getTaskViewing(selectedId);
            }
        }
    }, [taskDetailsDrawer.visible, tasksState.activeData.selectedId]);

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetTasksRequestPayload = {
            filters: readyAllFiltersSelected(),
            sortBy: tableSortStatus.sortBy,
            sortAscending: tableSortStatus.sortAscending,
            pageSize,
            currentPage: currentPage,
            taskTableFilter: tasksTableFilter,
            lockedDeliveryMethod,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            isPaymentPlanEnabled,
            region
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for calling the fetch tasks API.
     * @param currentPage - current page for data to be fetched
     * @param pageSize - number of items in page
     */
    const fetchTasks = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getTasksForOrganisationRequestAction(payload));
    };

    /**
     * Function called in preparation to fetchTasks.
     * Manages the page to be used when calling the API.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            tasksState.loading
        )
            return;

        if (!tasksState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchTasks(nextPage);
    };

    /**
     * Function for fetching the action filter options.
     */
    const getActionFilterOptions = () => {
        dispatch(getTasksActionFilterOptionsRequestAction());
    };

    /**
     * Listener function called when changes on selected user company, filter values, sort values, and task status happens.
     */
    const callFetchTasks = () => {
        const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
        const selectedOrgId = get(selectedUserOrganisation, 'OrganisationId');

        if (organisationIdCognito === selectedOrgId) {
            if (lastSelectedOrgId !== selectedOrgId) {
                lastSelectedOrgId = selectedOrgId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
                getActionFilterOptions(); // [TODO]
            }
            if (!organisationLoading) fetchTasks(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending, taskStatus } = tasksState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortStatus.sortBy ||
            sortAscending !== tableSortStatus.sortAscending ||
            taskStatus !== tableSortStatus.taskStatus
        ) {
            updateTableSortStatusObject({
                sortBy,
                sortAscending,
                taskStatus,
            });
        }
    };

    useEffect(callFetchTasks, [
        tasksState.sortBy,
        tasksState.sortAscending,
        tasksState.filters,
        tasksState.taskStatus,
    ]);

    // on Unmount
    useEffect(() => {
        return () => {
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Function called on data update to check the selected keys if Select all button has been clicked.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(tasksState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        } else if (isAppliedRecordLimit) {
            const limitedSelection = tasksState.data.slice(0, appliedRecordLimit);
            const selectedKeys = difference(
                map(limitedSelection, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [tasksState.data]);

    /**
     * Function called when the filter value on upper left dropdown (next to refresh button) has been updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeTasksTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== tasksState.taskTableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setTasksTableFilter(filter);
        resetTableScrollAndPageData();
        if (
            refetch &&
            !isUndefined(tasksState.taskTableFilter) &&
            filter === tasksState.taskTableFilter
        ) {
            handleTaskFilterRefresh();
        } else {
            dispatch(updateTasksTableFilterAction(filter));
        }
    };

    /**
     * Function called when `Refresh` button is clicked or the upper left dropdown filter has been set to initial state.
     */
    const handleTaskFilterRefresh = () => {
        fetchTasksRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function for populating the menu items for the dropdown.
     * @param menu
     */
    const populateTaskFilterSelectDropdownRender = (menu: JSX.Element) => (
        <div>{menu}</div>
    );

    /**
     * Function that checks if `Select all` is in effect.
     * Returns a boolean.
     */
    const checkIfSelectAll = () => {
        const allDataLoaded = tasksState.pageData.hasNextPage === false;
        return (
            !isEmpty(tasksState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 && showConditions.allSelected)
                || (allDataLoaded &&
                    tasksState.data.length ===
                    tableRowSelection.selectedRowKeys.length))
            || (isAppliedRecordLimit)
        );
    };

    /**
     * Function for populating the `Select/Deselect all` label.
     */
    const populateSelectDeselectAllLabel = () => {
        let selectDeselectLabel = '';
        if (checkIfSelectAll()) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return `${selectDeselectLabel} all`;
    };

    /**
     * Function for populating the PopoverContent when `Select/Deselect all` label being clicked.
     */
    const populateSelectDeselectPopoverContent = () =>
    (
        <div onClick={(ev) => ev.stopPropagation()} className="pop-action-content">
            <div>
                {getTranslatedText('Select a specific number of tasks')}
            </div>
            <div>
                <InputNumber
                    type='number'
                    min={0}
                    max={MAX_INT_VALUE}
                    placeholder={getTranslatedText("Enter a positive number")}
                    style={{ width: '100%' }}
                    value={recordLimit}
                    onChange={(value) => setRecordLimit(value)} />
            </div>
            <div className="ok-button-container">
                <Button
                    onClick={applyRecordLimit}
                    disabled={!recordLimit || recordLimit <= 0}
                    type="primary"
                >
                    {getTranslatedText('Ok')}
                </Button>
            </div>
        </div>
    )

    /**
     * Function called when `Select/Deselect all` button has been clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition = !appliedRecordLimit
            && (
                isEmpty(tableRowSelection.selectedRowKeys) ||
                tableRowSelection.selectedRowKeys.length < tasksState.data.length
            );

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(tasksState.data, selectedKeysName);
            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
            updateActionBarItemsState({
                actionButtonLoading: true,
            });
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
                selectedUnreadyKeys: [],
            });

            updateActionBarItemsState({
                actionButtonDisabled: true,
                actionButtonLoading: false,
            });
        }

        resetRecordLimit();
    };

    /**
     * Function for resetting RecordLimit related states to default value
     */
    const resetRecordLimit = useMemo(() => () => {
        setRecordLimitPopoverVisible(false);
        setRecordLimit(undefined);
        setAppliedRecordLimit(undefined);
    }, []);

    useEffect(resetRecordLimit, [customFieldsFilterList]);

    /**
     * Function called when `Ok` button on the RecordLimit Popover was clicked
     */
    const applyRecordLimit = () => {
        const slicedTasksState = tasksState.data.slice(0, recordLimit);
        const idKeys: string[] = map(slicedTasksState, selectedKeysName);
        updateTableRowSelection({
            selectedRowKeys: idKeys,
            unselectedRowKeys: [],
        });
        updateActionBarItemsState({
            actionButtonLoading: true,
        });
        setAppliedRecordLimit(recordLimit);
        setRecordLimitPopoverVisible(false);
        updateShowConditionsObject({
            allSelected: false
        });
    };


    /**
     * Function for updating the sort order of the items in table.
     * @param sortAscending - boolean indicator if the sort order is ascending or not
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateTasksSortByAndStatusAction({
                ...tableSortStatus,
                sortAscending,
            })
        );
    };

    const downloadDisabled = tasksState.loading || isEmpty(tasksState.data);

    /**
     * Function for populating the ellipsis popover upper section.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText('Filter')}
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortStatus((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${tasksState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText('Change sort order')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={downloadToExcelHandler}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText('Download to Excel')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={setTableCompactViewHandler}
                disabled={downloadDisabled || tasksState.compactViewLoading}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        tasksState.compactView ? 'expand' : 'compress',
                    ]}
                    className="mr-10"
                />
                {getTranslatedText(`Switch to ${tasksState.compactView ? 'expanded' : 'collapsed'} view`)}
            </Button>
        </div>
    );

    const getTableCompactViewStatus = () => {
        dispatch(getTableCompactViewRequestAction());
    };

    useEffect(getTableCompactViewStatus, [userPreference]);

    const setTableCompactViewHandler = () => {
        const compactViewStatus = tasksState.compactView;
        dispatch(
            setTableCompactViewRequestAction(
                !compactViewStatus,
                (res: ResponseModalObject) => {
                    if (!res.IsSuccess) {
                        Modal.error({
                            title: getTranslatedText('Error'),
                            content:
                                getTranslatedText(get(res, 'Messages.0')) ||
                                getTranslatedText('Error encountered while setting the table compact view state!'),
                            okText: getTranslatedText('OK'),
                        });
                    }
                }
            )
        );
    };

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = () => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const {
            filters,
            sortBy,
            sortAscending,
            isUsingCloudImportType,
            lockedDeliveryMethod,
            usingMultipleWorkflow,
        } = filterAndPageProps;

        const sortFields = populateSortFieldsParamSortParams(sortBy);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task'
        );

        if (!usingMultipleWorkflow) delete cleanFilters.WorkflowIds;

        const variables = {
            ...cleanFilters,
            ...sortFields,
            Ascending: sortAscending,
            IsCloudImportType: isUsingCloudImportType,
            LockedDeliveryMethod: lockedDeliveryMethod,
            IsMultipleWorkflow: usingMultipleWorkflow,
        };

        const payload = {
            Query: activeTasksSummaryOrganisationPageQuery,
            OperationName: activeTasksSummaryOrganisationQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.TASKS_ORGANISATION_PAGE,
        };

        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function for populating the ellipsis popover lower section.
     */
    const populateFilterEllipsisPopoverContent = () => {
        const usedTasksSortByOptions = getTaskUsedSortOptions();

        return (
            <div className="pop-action-content">
                <div className="mb-10">
                    <span>{getTranslatedText('Sort by')}</span>
                </div>
                <div>
                    <Select
                        onChange={(sortBySelected: string) =>
                            updateTableSortStatusObject({
                                sortBy: sortBySelected,
                            })
                        }
                        value={tableSortStatus.sortBy}
                    >
                        {map(
                            usedTasksSortByOptions,
                            ({
                                label,
                                value,
                            }: {
                                label: string;
                                value: string;
                            }) => {
                                const labelUsed =
                                    replaceInstancesOfCustomerString(
                                        label,
                                        customerLabel
                                    );

                                return (
                                    <Option key={value} value={value}>
                                        {labelUsed}
                                    </Option>
                                );
                            }
                        )}
                        {map(
                            customFieldsFilterList,
                            ({
                                Type,
                                Number: CFNumber,
                                FieldName,
                            }: CompanyCustomFieldConfigure) => (
                                <Option
                                    key={Type + CFNumber + FieldName}
                                    value={`${customFieldIndicator}${Type}--${FieldName}`}
                                >
                                    {FieldName}
                                </Option>
                            )
                        )}
                    </Select>
                </div>
                <br />
                <div className="mb-10">
                    <span>{getTranslatedText('Task status')}</span>
                </div>
                <div>
                    <Select
                        onChange={(statusSelected: string) =>
                            updateTableSortStatusObject({
                                taskStatus: statusSelected,
                            })
                        }
                        value={tableSortStatus.taskStatus}
                    >
                        {map(
                            tasksStatusFilterOptions,
                            ({
                                label,
                                value,
                            }: {
                                label: string;
                                value: number;
                            }) => (
                                <Option key={value} value={value}>
                                    {label}
                                </Option>
                            )
                        )}
                    </Select>
                </div>
                <br />
                <div className="ta-right">
                    <Button
                        type="primary"
                        disabled={
                            tasksState.loading ||
                            (tableSortStatus.sortBy === tasksState.sortBy &&
                                tableSortStatus.taskStatus ===
                                get(tasksState, 'taskStatus'))
                        }
                        onClick={applySortedByAndState}
                    >
                        {getTranslatedText('Apply')}
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;

        const { taskTableFilter } = tasksState;
        if (taskTableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchTasksRequired) {
                fetchTasks(0);
                fetchTasksRequired = false;
            }
        } else {
            handlePageViewSelection(
                taskTableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [tasksState.taskTableFilter]);

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (tasksState.taskTableFilter !== initialTableFilter) {
            setTasksTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeTasksTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called for applying the filters selected.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     * @param fromQuickSearch - boolean indicator if called from the quick search component
     */
    const applyFilters = async (
        filters?: GetTasksRequestPayload['filters'],
        fromFilterBar?: boolean,
        fromQuickSearch?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    tasksTableFilter,
                    fromFilterBar,
                    () => {
                        changeTasksTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.activeTasks.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortStatus(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateTasksFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys(fromQuickSearch);
    };
    functionRefObj.applyFilters = applyFilters;

    /**
     * Function called for applying the sort values selected.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortStatus;
        await dispatch(updateTasksSortByAndStatusAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param selectionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };
    functionRefObj.updateShowConditionsObject = updateShowConditionsObject;

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function for updating the `actionBarItemsState` state.
     * @param itemStateObject - must conform to `actionBarItemsState` state
    */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState((prev) => ({
            ...prev,
            ...itemStateObject,
        }));
    };

    /**
     * Function for updating the `taskDetailsDrawer` state.
     * @param taskDetailsDrawerStateObject - must conform to `taskDetailsDrawer` state
     */
    const updateTaskDetailsDrawerState = (taskDetailsDrawerStateObject: {}) => {
        setTaskDetailsDrawer({
            ...taskDetailsDrawer,
            ...taskDetailsDrawerStateObject,
        });
    };

    /**
     * Function called for closing the task details drawer.
     * Resets all the active data for the previously selected item in redux state.
     */
    const closeTaskDetailsDrawer = () => {
        updateTaskDetailsDrawerState({
            visible: false,
        });
    };

    /**
     * Function for updating the `tableSortStatus` state.
     * @param tableSortStatusObject - must conform to `tableSortStatus` state
     */
    const updateTableSortStatusObject = (tableSortStatusObject: {}) => {
        setTableSortStatus({
            ...tableSortStatus,
            ...tableSortStatusObject,
        });
    };

    /**
     * Function called when the checkbox is clicked.
     * @param record - data for the row item where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when a row item is clicked.
     * @param record - data for the clicked row item
     */
    const onRowClick = (record: DynamicObject) => {
        dispatch(
            setSelectedTaskIdRequestAction(get(record, 'Id'), () => {
                const drawerType = get(record, 'Type');
                const drawerAutomation = get(record, 'Automation');
                updateTaskDetailsDrawerState({
                    visible: true,
                    drawerType,
                    drawerAutomation,
                });
            })
        );
    };

    const generatePayloadForTaskViewing = (key?: string) => {
        const {
            allExcept,
            filterObject,
            keysToUse,
            recordLimit,
            sortAscending,
            sortBy
        } = getSelectedTasksValues();

        const payload: ChangeTaskViewingRequestPayload | GetTaskViewerDetailForUserRequestPayload = {
            filters: filterObject,
            recordLimit,
            taskIds: key ? [key] : keysToUse,
            excludeTasks: key ? false : allExcept,
            taskTableFilter: tasksTableFilter,
            sortBy,
            sortAscending,
            lockedDeliveryMethod,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            isPaymentPlanEnabled,
        };

        return payload;
    };

    const getTaskViewing = (key?: string) => {
        let silent = false;
        const action = () => {
            const fetchPayload = {
                ...generatePayloadForTaskViewing(key),
                silent,
                region
            };
            silent = true;
            dispatch(
                getTaskViewerDetailForUserOrganisationRequestAction(fetchPayload)
            );
        };
        action();
        const id: any = setInterval(action, GET_TASK_VIEWER_INTERVAL);
        setGetTaskViewerIntervalId(id);
    };

    /**
     * Function to populate the loading text for the table.
     */
    const populateTableLoadingText = () => {
        const loadingText = tasksState.loading
            ? `Fetching ${tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
            } tasks`
            : 'Please wait. . .';

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected/deselected.
     * @param record - data item for the selected/deselected row
     * @param selected - indicator if the row is selected or deselected
     * @param selectedRows
     * @param nativeEvent
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];

        const selectedUnreadyKeys = [...tableRowSelection.selectedUnreadyKeys];

        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
            if (!record.isReady) {
                selectedUnreadyKeys.push(record.key);
            }
        } else {
            unselectedRowKeys = isAppliedSelectAllOrRecordLimit
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];

            if (!record.isReady) {
                remove(
                    selectedUnreadyKeys,
                    (unReadyKey) => unReadyKey === record.key
                );
            }
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
            selectedUnreadyKeys,
        });
    };

    /**
     * Function for updating the visibility of popovers.
     * @param name - name of popover
     * @param condition - optional condition if the change should be applied
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                updateShowConditionsObject({
                    [name]: visible,
                });
            }
        };
    };

    /**
     * Function for resetting the scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        if (isAppliedRecordLimit) {
            resetRecordLimit();
            updateTableRowSelection({
                selectedRowKeys: [],
                selectedUnreadyKeys: [],
            });
        }
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting the selected, unselected, and selected unread keys.
     * @param excludeShowConditions - boolean indicator that checks if allSelected property in
     * `showConditions` state be excluded in resetting.
     */
    const resetAllSelectedRowKeys = (excludeShowConditions = false) => {
        updateActionBarItemsState({
            actionButtonDisabled: true,
        });
        if (!excludeShowConditions) {
            updateShowConditionsObject({
                allSelected: false,
            });
        }
        resetRowSelectionAndExpandStatus();
        resetRecordLimit();
    };

    /**
     * Function called for closing the filter bar section.
     * Resets all the filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Common function for getting the filter values, and keys to be used in payload when calling the API.
     * @param selectedRowKeys
     * @param unselectedRowKeys
     */
    const getSelectedTasksValues = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        let allExcept = false;
        let keysToUse = selectedRowKeys || [
            ...tableRowSelection.selectedRowKeys,
        ];

        if (isAppliedSelectAllOrRecordLimit) {
            allExcept = true;
            keysToUse = unselectedRowKeys || [
                ...tableRowSelection.unselectedRowKeys,
            ];
        }
        const filters = readyAllFiltersSelected();
        const filterObject: DynamicObject = {
            ...filters,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
            sortBy: tableSortStatus.sortBy,
            sortAscending: tableSortStatus.sortAscending,
            recordLimit: appliedRecordLimit && appliedRecordLimit - tableRowSelection.unselectedRowKeys.length
        };
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchTasks(0);
    };

    /**
     * Function for resetting the action drawer values (state).
     * @param refreshList
     */
    const resetActionDrawerValues = (refreshList?: boolean) => {
        updateShowConditionsObject({
            actionDrawer: false,
            certifyNotPaid: false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
            resetRowSelectionAndExpandStatus();
        }
    };

    /**
     * Common function for resetting all checkbox and row collapse status.
     */
    const resetRowSelectionAndExpandStatus = () => {
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
            selectedUnreadyKeys: [],
        });
        dispatch(setTaskExpandStatusAction());
    };

    /**
     * Function for populating the workflow options by key used (affected when UsingCustomerWorkflow is changed).
     * @param workflowKey
     * @param actionFilterOptions
     */
    const getWorkflowStepOptionsByKey = (
        workflowKey: string,
        actionFilterOptions: DynamicObject[]
    ) => {
        forEach(
            get(
                tasksState.actionFilterOptions,
                `${workflowKey}WorkflowOptions`
            ),
            (workflowOption) => {
                const childWorkflowSteps = map(workflowOption.States, (ws) => ({
                    label: ws,
                    value: ws,
                }));

                actionFilterOptions.push({
                    label: get(workflowOption, 'Workflow.WorkflowName'),
                    value: get(workflowOption, 'Workflow.WorkflowId'),
                    children: childWorkflowSteps,
                });
            }
        );

        return actionFilterOptions;
    };

    /**
     * Function for checking before being able to proceed with filtering
     * @param inputValue - string
     */
    const checkingForAssignedUser = (inputValue: string | undefined | null) => {
        // const companyEnabledExternalTaskAssignment =
        //     get(
        //         selectedUserCompany,
        //         'Company.EnableExternalUserTaskAssignment'
        //     ) === true;

        // if (
        //     companyEnabledExternalTaskAssignment &&
        //     checkIfEmailIsValid(inputValue)
        // )
        if (checkIfEmailIsValid(inputValue)) return true;

        return false;
    };

    /**
     * Function for getting the filter values to be used in FilterBar component.
     */
    const populateTasksFilterBarFilters = () => {
        let actionFilterOptions: DynamicObject[] = [];
        if (usingCustomerWorkflow) {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'Customer',
                actionFilterOptions
            );
        } else {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'Invoice',
                actionFilterOptions
            );
        }

        actionFilterOptions = getWorkflowStepOptionsByKey(
            'Ticket',
            actionFilterOptions
        );

        if (isPaymentPlanEnabled) {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'PaymentPlan',
                actionFilterOptions
            );
        }
        if (supportCashAllocation) {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'RemittanceAdvice',
                actionFilterOptions
            );
        }
        if (supportCashAllocation) {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'PaymentAllocation',
                actionFilterOptions
            );
        }

        const usedSpan = usingCustomerWorkflow ? 4 : 3;
        const typeOptionsUsed = filter(
            tasksTypeOptions,
            (tto: DynamicObject) =>
                (isPaymentPlanEnabled || !paymentPlanTaskTypes.includes(tto.label))
                &&
                (supportSendNewInvoice || newInvoiceEmailTaskType != tto.label)
                &&
                (supportCashAllocation || paymentAllocationTaskType != tto.label)
        );

        let tasksFilterBarFilters = [
            // {
            //     filterName: 'Priority',
            //     filterStateName: 'Priority',
            //     filterElement: 'checkbox-group',
            //     filterType: 'array',
            //     filterOptions: [
            //         {
            //             label: 'High',
            //             value: 'High',
            //         },
            //         {
            //             label: 'Medium',
            //             value: 'Medium',
            //         },
            //         {
            //             label: 'Low',
            //             value: 'Low',
            //         },
            //     ],
            // },
            {
                filterName: 'Type',
                filterStateName: 'Type',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: typeOptionsUsed,
                span: usedSpan,
            },
            {
                filterName: 'Contact type',
                filterStateName: 'ContactType',
                filterElement: 'checkbox-group',
                tagType: 'sentence',
                filterType: 'array',
                filterOptions: [
                    {
                        label: 'Email',
                        value: 1,
                    },
                    {
                        label: 'Mobile (SMS)',
                        value: 2,
                    },
                    {
                        label: 'Postal address',
                        value: 4,
                    },
                    // {
                    //     label: 'Landline',
                    //     value: 8,
                    // },
                ],
                span: usedSpan,
            },
            {
                filterName: 'Company',
                filterStateName: 'CompanyIds',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: !companies ? [] : companies.map(c => {
                    return {
                        label: c.Name,
                        value: c.CompanyId
                    }
                }),
                span: usedSpan
            },
            {
                filterName: 'Ready state',
                filterStateName: 'ActionDate',
                filterPrefixString: 'Ready',
                filterElement: 'select',
                filterType: 'text',
                filterOptions: tasksReadyStateOptions,
                filterOptionsCustomTypes: {
                    'Custom date range': 'date-range',
                    'Custom days range': 'all-days-range-from-to',
                },
                span: usedSpan,
            },
            {
                filterName: 'Workflow',
                filterStateName: 'WorkflowNameStep',
                // filterElement: 'checkbox-group',
                filterElement: 'checkbox-group-tree',
                filterType: 'array',
                filterOptions: actionFilterOptions,
                filterLoading: tasksState.actionFilterOptionsLoading,
                span: usedSpan,
            },
            // {
            //     filterName: 'Invoice number',
            //     filterStateName: 'InvoiceNumber',
            //     filterElement: 'input',
            //     filterType: 'text',
            // },
            {
                filterName: 'Customer',
                filterStateName: 'Customer',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'Company name',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'DisplayName',
                span: usedSpan,
            },
            {
                filterName: 'Assigned user',
                filterStateName: 'AssignedUserId',
                filterNameQuery: 'Name',
                filterElement: 'select-with-search',
                filterQuery: 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterResponse: 'GetUsersForCompany.CompanyUserRoles',
                filterLabelField: ['User.GivenName', 'User.FamilyName'],
                filterSort: 'Name',
                filterNameQuerySub: 'Email',
                filterSubChecking: checkIfEmailIsValid,
                filterJSONValueFieldSub: 'User.Email',
                span: usedSpan,
                tagClass: 'wb-bw',
                filterMappingUsed: selectUserFilterMapping,
            },
            {
                filterName: 'Country',
                filterStateName: 'CustomerCountry',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'CustomerCountry',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'Country',
                span: usedSpan,
            },
            {
                filterName: 'Postal State',
                filterStateName: 'CustomerState',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'CustomerState',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'State',
                span: usedSpan,
            },
            // {
            //     filterName: 'Amount',
            //     filterStateName: 'Amount',
            //     filterElement: 'select-multiple-and-input-amount',
            //     filterType: 'text',
            //     filterOptions: [
            //         {
            //             AmountType: tasksAmountType,
            //         },
            //         {
            //             AmountOperator: invoicesAmountOperatorOptions,
            //         },
            //     ],
            // },
            {
                filterName: 'Actions',
                filterStateName: 'Action',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: ticketActionsOptions,
            }
        ];

        let invFilterIdx = 4,
            amtFilterIdx = 6;
        // if (usingMultipleWorkflow) {
        //     let workflowFilterOptions: DynamicObject[] =
        //         getWorkflowFilterOptions();
        //     tasksFilterBarFilters = insertAt(tasksFilterBarFilters, 4, [
        //         {
        //             filterName: 'Workflow',
        //             filterStateName: 'WorkflowIds',
        //             filterElement: 'checkbox-group',
        //             filterType: 'array',
        //             filterOptions: workflowFilterOptions,
        //             filterLoading: workflowFetchLoading,
        //         },
        //     ]);
        //     invFilterIdx++;
        //     amtFilterIdx++;
        // }

        if (!usingCustomerWorkflow) {
            tasksFilterBarFilters = insertAt(
                tasksFilterBarFilters,
                invFilterIdx,
                [
                    {
                        filterName: 'Invoice Due in',
                        filterStateName: 'InvoiceDueDate',
                        filterElement: 'select',
                        filterType: 'text',
                        filterOptions: invoicesDueInOptions(isCalendarView),
                        filterOptionsCustomTypes: {
                            'Custom date range': 'date-range',
                            'Custom days range': 'all-days-range',
                        }
                    }
                ]
            );

            tasksFilterBarFilters = insertAt(
                tasksFilterBarFilters,
                invFilterIdx,
                [
                    {
                        filterName: 'Invoice number',
                        filterStateName: 'InvoiceNumber',
                        filterElement: 'input-autocomplete',
                        filterQuery:
                            'GET_INVOICES_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                        filterSort: invoicesSortValues.INVOICE_NUMBER,
                        filterResponse: 'GetInvoicesForCompany.Invoices',
                        filterLabelField: 'Number',
                        span: usedSpan,
                    },
                ]
            );

            tasksFilterBarFilters = insertAt(
                tasksFilterBarFilters,
                amtFilterIdx,
                [
                    {
                        filterName: 'Amount',
                        filterStateName: 'Amount',
                        filterElement: 'select-multiple-and-input-amount',
                        filterType: 'text',
                        filterOptions: [
                            {
                                AmountType: tasksAmountType,
                            },
                            {
                                AmountOperator: invoicesAmountOperatorOptions,
                            },
                        ],
                        span: usedSpan,
                    },
                ]
            );
        }

        setFilterItemList(tasksFilterBarFilters);
    };

    useEffect(populateTasksFilterBarFilters, [
        usingCustomerWorkflow,
        tasksState.actionFilterOptions,
        workflowFetchLoading,
    ]);

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        const isLg = getIfIsLg();
        const rowHeight = isLg
            ? TASKS_PAGE.compactRowHeight + 20
            : TASKS_PAGE.compactRowHeight;

        setUsedRowHeight(rowHeight);
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    return (<>
        {/* Filter Bar */}
        <QueueAnim type="top" leaveReverse duration={300}>
            {showConditions.filterBar && (
                <div key="filter-bar-container">
                    <Suspense fallback={null}>
                        <FilterBar
                            pageName={pageName}
                            loading={tasksState.loading}
                            applyFilters={applyFilters}
                            filters={filterItemList}
                            filterValues={tasksState.filters}
                            colDivision={3}
                            closeFilterBar={closeFilterBar}
                            appliedView={tasksTableFilter}
                            doesViewNameExist={doesViewNameExist}
                            customFieldsFilters={customFieldsFilterList}
                            checkingForAssignedUser={
                                checkingForAssignedUser
                            }
                            tableSortState={{
                                sortBy: tasksState.sortBy,
                                taskStatus: tasksState.taskStatus,
                                sortAscending: tasksState.sortAscending,
                            }}
                        />
                    </Suspense>
                </div>
            )}
        </QueueAnim>
        <div key="action-bar-container">
            <ActionBar
                ref={actionBarRef}
                pageName={pageName}
                loading={tasksState.loading}
                filterBarOpen={showConditions.filterBar}
                actionItems={[
                    {
                        actionKey: 'task-filter',
                        actionType: 'select-with-button',
                        // selectOptions: tasksTableFilterOptions,
                        selectValue: tasksTableFilter,
                        selectDropdownRender:
                            populateTaskFilterSelectDropdownRender,
                        onSelectChange: changeTasksTableFilter,
                        buttonContent: (
                            <>
                                <FontAwesome
                                    icon={['fa', 'sync']}
                                    className="mr-8"
                                />
                                <span>{getTranslatedText('Refresh')}</span>
                            </>
                        ),
                        buttonDisabled: tasksState.loading,
                        onButtonClick: handleTaskFilterRefresh,
                        // minWidth: 250,
                    },
                    {
                        actionKey: 'task-select-all',
                        actionType: 'protected-button',
                        buttonDisabled: downloadDisabled,
                        onButtonClick: selectAllRows,
                        buttonContent: (
                            <>
                                <FontAwesome
                                    icon={['fas', 'check-double']}
                                />
                                <span className='mr-8'>{getTranslatedText(populateSelectDeselectAllLabel())}</span>
                                <Popover
                                    visible={recordLimitPopoverVisible}
                                    trigger="click"
                                    onVisibleChange={(visible) => setRecordLimitPopoverVisible(visible)}
                                    placement="bottom"
                                    content={populateSelectDeselectPopoverContent()}
                                >
                                    <div style={{ display: 'inline-block' }}
                                        onClick={(ev) => ev.stopPropagation()}>
                                        <FontAwesome className='fs-20' icon={['fas', 'sort-down']} />
                                    </div>
                                </Popover>
                            </>
                        )
                    }
                ]}
                actionEllipsis={{
                    popoverVisible: showConditions.filterEllipsis,
                    popoverOnVisibleChange:
                        popoverOnVisibleChange('filterEllipsis'),
                    popoverTitle: populateFilterEllipsisPopoverTitle(),
                    popoverContent:
                        populateFilterEllipsisPopoverContent(),
                    buttonContent: (
                        <FontAwesome icon={['fas', 'ellipsis-h']} />
                    ),
                }}
            />
        </div>
        <div className="spacer-15" />
        {/* Table Section */}
        <Row
            key="table-container"
            className="vlist-container"
            style={{ height: 'calc(100vh - 180px)' }}
        >
            <Col span={24} className="h-100">
                <VirtualizedList
                    dataSource={tasksState.data}
                    fetchMore={handleFetch}
                    // scroll={computeTableScroll(
                    //     window.innerHeight - 185,
                    //     tablePageSize,
                    //     usedRowHeight
                    // )}
                    resetTableScroll={resetTableScroll}
                    selectedRowKeys={tableRowSelection.selectedRowKeys}
                    rerenderTrigger={tableRowSelection.selectedRowKeys}
                    onRowClick={onRowClick}
                    onCheckboxClick={onCheckboxClick}
                    loading={
                        tasksState.loading ||
                        tasksState.compactViewLoading
                    }
                    loadingText={populateTableLoadingText()}
                    emptyText={
                        !isEmpty(tasksState.errorMessages)
                            ? get(tasksState, 'errorMessages.0')
                            : 'No tasks found'
                    }
                    hasNextPage={tasksState.pageData.hasNextPage}
                    itemComponent={TaskItemComponent}
                    itemHeight={usedRowHeight}
                    selectedId={selectedId}
                    usingCustomerWorkflow={usingCustomerWorkflow}
                    lockedDeliveryMethod={lockedDeliveryMethod}
                    isCompactView={tasksState.compactView}
                    toggledIds={toggledIds}
                    customerLabel={customerLabel}
                    extraData={{
                        isAppliedRecordLimit,
                        shouldDisableSelect: (item: Task) =>
                            isAppliedRecordLimit
                            && !tableRowSelection.selectedRowKeys
                                .concat(tableRowSelection.unselectedRowKeys)
                                .includes(item.Id)
                    }}
                />
            </Col>
            <Suspense fallback={null}>
                <TaskItemDetailsDrawerComponent
                    {...taskDetailsDrawer} // spread the taskDetailsDrawer state
                    closeDrawer={closeTaskDetailsDrawer}
                    usingCustomerWorkflow={usingCustomerWorkflow}
                    lockedDeliveryMethod={lockedDeliveryMethod}
                    usingMultipleWorkflow={usingMultipleWorkflow}
                    isPaymentPlanEnabled={isPaymentPlanEnabled}
                    onCloseTaskActionDrawer={(refresh?: boolean) => {
                        if (refresh) {
                            resetActionDrawerValues(true);
                            closeTaskDetailsDrawer();
                        }
                    }}
                    getTaskDataRequestAction={(taskId: string, lockedDeliveryMethod?: boolean, isUsingCloudImportType?: boolean, usingMultipleWorkflow?: boolean, isPaymentPlanEnabled?: boolean) =>
                        getTaskDataForOrganisationRequestAction(taskId, region, lockedDeliveryMethod, isUsingCloudImportType, usingMultipleWorkflow, isPaymentPlanEnabled)}
                />
            </Suspense>
            {showConditions.downloadToExcel && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText(populateDownloadToExcelModalTitle())}
                        modalVisible={showConditions.downloadToExcel}
                        displayMessage={getTranslatedText(populateDownloadToExcelModalDisplayMessage())}
                    />
                </Suspense>
            )}
        </Row>
    </>);
};

export default withPageViewHandler(
    withAccountingSystemHandler(
        withDateFormatHandler(OrganisationActiveTasksContent)
    )
);
