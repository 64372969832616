import API, { graphqlOperation } from '@aws-amplify/api';
import { Modal } from 'antd';
import update from 'immutability-helper';
import { filter, find, forEach, get, includes, isEmpty, orderBy } from 'lodash';
import { Action } from 'redux';
import { channel } from 'redux-saga';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    take,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    DEFAULT_LOCALE,
    initialPrimaryColor,
    initialSecondaryColor,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import {
    CompanyIdAttribute,
    IsOrganisationViewAttribute,
    OrganisationIdAttribute,
} from '../../constants/authUserAttributes';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    getTranslatedText,
    refreshCognitoAttributes,
    removeAppliedFiltersForApiRequest,
    updateLessVariables,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { changeEndpointsAction, loginUserAction } from '../auth/actions';
import { setLocaleAction } from '../common/actions';
import {
    resetAllCompanyDataTableFiltersAction,
    selectUserCompanyRequestAction,
} from '../companies/actions';
import {
    CompanyCustomFieldConfigure,
    CompanyUserRole,
} from '../companies/types';
import { getCurrentUser } from '../users/sagas';
import { Company } from './../companies/types';
import {
    getOrganisationCompaniesErrorAction,
    getOrganisationCompaniesForUserErrorAction,
    getOrganisationCompaniesForUserRequestAction,
    getOrganisationCompaniesForUserSuccessAction,
    getOrganisationCompaniesRequestAction,
    getOrganisationCompaniesSuccessAction,
    getOrganisationUserDataErrorAction,
    getOrganisationUserDataSuccessAction,
    getOrganisationUsersErrorAction,
    getOrganisationUsersRequestAction,
    getOrganisationUsersSuccessAction,
    getUserOrganisationsRequestAction,
    getUserOrganisationsSuccessAction,
    saveUserOrganisationResponseAction,
    selectUserOrganisationRequestAction,
    selectUserOrganisationSuccessAction,
    setSelectedOrganisationUserIdSuccessAction,
} from './actions';
import { Organisation, OrganisationsActionTypes } from './types';

export const getSelectedOrganisationUserId = (state: ApplicationState) =>
    state.organisations.users.activeData.selectedId;

export const getOrganisationUserData = (state: ApplicationState) =>
    state.organisations.users.activeData;

let refetchCount = 0;

/**
 * Function responsible for getting the organisations the user belongs to from API.
 */
function* handleGetUserOrganisationsRequest() {
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_ORGANISATIONS_FOR_USER)
        );

        const organisationsData = get(
            res.data,
            'GetOrganisationsForUser.Organisations'
        );

        refetchCount = 0;
        yield put(getUserOrganisationsSuccessAction(organisationsData));
        const currentUser: DynamicObject = yield select(getCurrentUser);
        const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
        const selectedOrganisationId = get(
            currentUser,
            OrganisationIdAttribute
        );
        if (!isEmpty(organisationsData) && isOrgView) {
            if (!selectedOrganisationId) {
                yield put(
                    selectUserOrganisationRequestAction(organisationsData[0])
                );
            } else {
                const currentOrganisationData = find(
                    organisationsData,
                    (userOrganisationData: Organisation) =>
                        selectedOrganisationId ===
                        userOrganisationData.OrganisationId
                );

                const selectedOrganisationData =
                    currentOrganisationData || organisationsData[0];
                yield put(
                    selectUserOrganisationRequestAction(
                        selectedOrganisationData
                    )
                );
            }
        } else if (isOrgView) {
            if (
                isEmpty(
                    filter(organisationsData, [
                        'OrganisationId',
                        selectedOrganisationId,
                    ])
                )
            ) {
                const currentCompanyIdUsed = get(
                    currentUser,
                    CompanyIdAttribute
                );
                const companiesData: CompanyUserRole[] = yield select(
                    (state: ApplicationState) => state.companies.userCompanies
                );

                if (!currentCompanyIdUsed) {
                    yield put(selectUserCompanyRequestAction(companiesData[0]));
                } else {
                    const currentCompanyData = find(
                        companiesData,
                        (userCompanyData: CompanyUserRole) =>
                            currentCompanyIdUsed ===
                            userCompanyData.Company.CompanyId
                    );

                    const selectedCompanyData =
                        currentCompanyData || companiesData[0];
                    if (!isEmpty(selectedCompanyData)) {
                        yield put(
                            selectUserCompanyRequestAction(selectedCompanyData)
                        );
                    }
                }
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getUserOrganisationsRequestAction());
        } else {
            yield put(getUserOrganisationsSuccessAction([]));
        }
    }
}

/*
 * Function responsible for triggering the user organisation selected
 * and also handles organisation switching to sync the organisation selected here in UI
 * and the one stored in cognito.
 */
const userOrganisationChannel = channel();
const selectOrganisationChannel = channel();
function* handleSelectUserOrganisationRequest({ payload: sagaPayload }: any) {
    const { organisationObject, callback } = sagaPayload;
    try {
        const currentUser: DynamicObject = yield select(getCurrentUser);
        const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
        if (!isOrgView) {
            yield put(changeEndpointsAction({}));
        }
        const organisationLocale = get(
            organisationObject,
            'DefaultCurrency.Value',
            DEFAULT_LOCALE
        );
        if (organisationLocale) {
            const currentOrganisationLocale: DynamicObject = yield select(
                (state: ApplicationState) =>
                    get(
                        state.organisations.selectedUserOrganisation,
                        'DefaultCurrency.Value'
                    )
            );
            if (currentOrganisationLocale !== organisationLocale) {
                yield put(setLocaleAction(organisationLocale));
            }
        }
        const organisationId = organisationObject.OrganisationId;
        const currentOrganisationId = get(currentUser, OrganisationIdAttribute);
        if (!isOrgView || organisationId !== currentOrganisationId) {
            yield call([API, 'post'], API_NAME, '/organisation/switch', {
                body: {
                    OrganisationId: organisationId,
                },
            });

            yield call(
                refreshCognitoAttributes,
                async (_err: any, session: any) => {
                    const organisationIdAttributeName =
                        OrganisationIdAttribute.replace(
                            'signInUserSession.',
                            ''
                        );

                    if (get(session, organisationIdAttributeName)) {
                        const newUserData = update(currentUser, {
                            $merge: {
                                signInUserSession: session,
                            },
                        });

                        await userOrganisationChannel.put(
                            loginUserAction({
                                currentUser: newUserData,
                                isAuth: true,
                            })
                        );

                        await selectOrganisationChannel.put(
                            selectUserOrganisationSuccessAction(
                                organisationObject
                            )
                        );
                    }
                }
            );

            yield put(resetAllCompanyDataTableFiltersAction());
        } else {
            yield put(selectUserOrganisationSuccessAction(organisationObject));
        }
        if (callback) callback();
        // calls for queries if needed
    } catch (err) {
        let errMessage = 'Failed to switch organisations!';
        if (err instanceof Error) {
            if (
                includes(err.toString(), 'Request failed with status code 405')
            ) {
                errMessage = 'User not allowed to switch to this organisation!';
            }
        } else {
            console.error('An unknown error occured.');
        }

        Modal.error({
            title: getTranslatedText("Error"),
            content: getTranslatedText(errMessage),
            onOk: () => {
                window.location.reload();
            },
            okText: getTranslatedText("OK")
        });
    }
}

/**
 * Function called after all the functions called when switching the organisation has finished.
 * Responsible for syncing the Theme for the organisation.
 */
// eslint-disable-next-line
function* handleSelectUserOrganisationSuccess() {
    try {
        const primaryColorTheme = initialPrimaryColor;
        const secondaryColorTheme = initialSecondaryColor;

        updateLessVariables({
            '@custom-primary-color': primaryColorTheme,
            '@custom-secondary-color': secondaryColorTheme,
        });

        return false;
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the organisation companies list to be used for Organisation's Companies page.
 * @param param0
 */
function* handleGetOrganisationCompaniesRequest({ payload }: any) {
    const errorMessage =
        'Error fetching organisation companies list. Please try again later.';
    try {
        const filters = get(payload, 'filters');
        const includeTotalUser = get(payload, 'includeTotalUser');
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'organisation'
        );

        if (includeTotalUser) cleanFilters.IncludeTotalUser = includeTotalUser;
        // To call async functions, use redux-saga's `call()`.

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_ORGANISATION_COMPANIES, {
                ...cleanFilters,
            })
        );

        const { Companies: OrganisationCompanies } = get(
            res.data,
            'GetOrganisationCompanies'
        );
        if (OrganisationCompanies) {
            const responsePayload = {
                data: OrganisationCompanies,
            };

            refetchCount = 0;
            yield put(getOrganisationCompaniesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getOrganisationCompaniesRequestAction(payload));
        } else {
            yield put(getOrganisationCompaniesErrorAction([errorMessage]));
        }
    }
}

/**
 * Function for getting the organisation companies list for user to be used for Organisation's Companies page.
 * @param param0
 */
function* handleGetOrganisationCompaniesForUserRequest() {
    try {
        // To call async functions, use redux-saga's `call()`.

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_ORGANISATION_COMPANIES_FOR_USER)
        );

        const { Companies: OrganisationCompanies } = get(
            res.data,
            'GetOrganisationCompaniesForUser'
        );
        if (OrganisationCompanies) {
            const responsePayload = {
                data: OrganisationCompanies,
            };

            refetchCount = 0;
            yield put(
                getOrganisationCompaniesForUserSuccessAction(responsePayload)
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getOrganisationCompaniesForUserRequestAction());
        } else {
            yield put(getOrganisationCompaniesForUserErrorAction());
        }
    }
}

/**
 * Function called for saving the organisation details - talking to the API.
 */
function* handleSaveUserOrganisationRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        yield call([API, 'post'], API_NAME, '/organisation/save', {
            body: payload,
        });

        yield put(saveUserOrganisationResponseAction());

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
        // calls for queries if needed
    } catch (err) {
        yield put(saveUserOrganisationResponseAction());
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for loading the currencies data.
 */
function* handleLoadCurrenciesDataRequest({ payload: sagaPayload }: any) {
    const { fileToUpload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/organisation/load/currencies',
            {
                body: fileToUpload.preview,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for getting the organisation custom fields filter list for a specific page.
 */
function* handleGetOrganisationCustomFieldsFilterListByTypes({
    payload: sagaPayload,
}: any) {
    const { customFieldTypes, callback } = sagaPayload;

    try {
        const userCompanies: Company[] = yield select(
            (state: ApplicationState) => state.organisations.userCompanies.data
        );

        let customFieldsByType: CompanyCustomFieldConfigure[] = [];
        forEach(userCompanies, (uc: Company) => {
            forEach(
                get(uc, 'CustomFieldsConfigure'),
                (customField: CompanyCustomFieldConfigure) => {
                    const filteredCF = filter(
                        customFieldsByType,
                        (cft: CompanyCustomFieldConfigure) =>
                            cft.Type === customField.Type &&
                            cft.FieldName === customField.FieldName
                    );

                    if (
                        includes(customFieldTypes, customField.Type) &&
                        isEmpty(filteredCF)
                    ) {
                        customFieldsByType.push(customField);
                    }
                }
            );
        });

        const customFieldsByNumberSorted = orderBy(
            customFieldsByType,
            ['Number'],
            ['asc']
        );

        var sortedCustomFieldsByType = orderBy(
            customFieldsByNumberSorted,
            function (item) {
                return customFieldTypes.indexOf(item.Type);
            }
        );

        const res: DynamicObject = {
            CustomFields: sortedCustomFieldsByType,
        };

        if (callback) {
            res.IsSuccess = true;
            callback(res);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

/**
 * Function that connects to close company api.
 */
function* handleCloseCompanyRequest({ payload: sagaPayload }: any) {
    const { companyId, isClosed, callback } = sagaPayload;
    const payload = {
        CompanyId: companyId,
        IsClosed: isClosed,
    };

    try {
        // To call async functions, use redux-saga's `call()`.
        yield call([API, 'post'], API_NAME, '/company/updatecompanystate', {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the organisation users list to be used for Organisation's Users page.
 * @param param0
 */
function* handleGetOrganisationUsersRequest({ payload: sagaPayload }: any) {
    const errorMessage =
        'Error fetching organisation users list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const { filters, pageSize, currentPage } = sagaPayload;

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_USERS_FOR_ORGANISATION, {
                Name: filters.Name,
                Email: filters.Email,
                RoleIds: filters.Role,
                CompanyId: filters.CompanyId,
            })
        );

        const { OrganisationUsers } = get(res.data, 'GetUsersForOrganisation');

        if (OrganisationUsers) {
            const responsePayload = {
                data: OrganisationUsers,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    // hasNextPage:
                    //     !(OrganisationUsers.length < pageSize) &&
                    //     !(pageSize < ORGANISATION_USERS_PAGE.pageSize),
                    hasNextPage: false,
                },
            };

            refetchCount = 0;
            yield put(getOrganisationUsersSuccessAction(responsePayload));
        } else {
            yield put(getOrganisationUsersErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getOrganisationUsersRequestAction(sagaPayload));
        } else {
            yield put(getOrganisationUsersErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that connects to remove organisation users api.
 */
function* handleRemoveOrganisationUsersRequest({ payload: sagaPayload }: any) {
    const { filter, userIds, excludeUsers, callback } = sagaPayload;
    const payload = {
        Filter: filter,
        UserIds: userIds,
        ExcludeUsers: excludeUsers,
    };

    try {
        // To call async functions, use redux-saga's `call()`.
        yield call([API, 'post'], API_NAME, '/organisation/removeuser', {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function that sets the selected organisation user id in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedOrganisationUserIdRequest({ payload }: any) {
    const { organisationUserId, callback } = payload;
    yield put(setSelectedOrganisationUserIdSuccessAction(organisationUserId));
    callback();
}

/**
 * Function that calls the API for getting the organisation user details based on the given Id.
 * @param param0
 */
function* handleGetOrganisationUserDataRequest({
    payload: { organisationUserId },
}: any) {
    const errorMessage =
        'Error fetching organisation user details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_USER_DETAILS_FOR_ORGANISATION, {
                UserId: organisationUserId,
            })
        );

        const OrganisationUser = get(res.data, 'GetUserDetailsForOrganisation');
        if (OrganisationUser) {
            const responsePayload = {
                record: OrganisationUser,
            };

            yield put(getOrganisationUserDataSuccessAction(responsePayload));
        } else {
            yield put(getOrganisationUserDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getOrganisationUserDataErrorAction([errorMessage]));
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetUserOrganisationsRequest() {
    yield takeLatest(
        OrganisationsActionTypes.GET_USER_ORGANISATIONS_REQUEST,
        handleGetUserOrganisationsRequest
    );
}

function* watchSelectUserOrganisationRequest() {
    yield takeLatest(
        OrganisationsActionTypes.SELECT_USER_ORGANISATION_REQUEST,
        handleSelectUserOrganisationRequest
    );
}

function* watchSelectUserOrganisationSuccess() {
    yield takeLatest(
        OrganisationsActionTypes.SELECT_USER_ORGANISATION_SUCCESS,
        handleSelectUserOrganisationSuccess
    );
}

function* watchSelectOrganisationChannel() {
    while (true) {
        const action: Action<any> = yield take(selectOrganisationChannel);
        yield put(action);
    }
}

function* watchUserOrganisationChannel() {
    while (true) {
        const action: Action<any> = yield take(userOrganisationChannel);
        yield put(action);
    }
}

function* watchGetOrganisationCompaniesRequest() {
    yield takeLatest(
        OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_REQUEST,
        handleGetOrganisationCompaniesRequest
    );
}

function* watchGetOrganisationCompaniesForUserRequest() {
    yield takeLatest(
        OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_FOR_USER_REQUEST,
        handleGetOrganisationCompaniesForUserRequest
    );
}

function* watchSaveUserOrganisationRequest() {
    yield takeLatest(
        OrganisationsActionTypes.SAVE_USER_ORGANISATION_REQUEST,
        handleSaveUserOrganisationRequest
    );
}

function* watchLoadCurrenciesDataRequest() {
    yield takeLatest(
        OrganisationsActionTypes.LOAD_CURRENCIES_DATA_REQUEST,
        handleLoadCurrenciesDataRequest
    );
}

function* watchGetOrganisationCustomFieldsFilterListByTypes() {
    yield takeLatest(
        OrganisationsActionTypes.GET_ORGANISATION_CUSTOM_FIELDS_FILTER_LIST,
        handleGetOrganisationCustomFieldsFilterListByTypes
    );
}

function* watchCloseCompanyRequest() {
    yield takeLatest(
        OrganisationsActionTypes.CLOSE_ORGANISATION_COMPANY,
        handleCloseCompanyRequest
    );
}

function* watchGetOrganisationUsersRequest() {
    yield takeLatest(
        OrganisationsActionTypes.GET_ORGANISATION_USERS_REQUEST,
        handleGetOrganisationUsersRequest
    );
}

function* watchRemoveOrganisationUsersRequest() {
    yield takeLatest(
        OrganisationsActionTypes.REMOVE_ORGANISATION_USERS_REQUEST,
        handleRemoveOrganisationUsersRequest
    );
}

function* watchSetSelectedOrganisationUserIdRequest() {
    yield takeLatest(
        OrganisationsActionTypes.SET_ORGANISATION_USER_SELECTED_ID_REQUEST,
        handleSetSelectedOrganisationUserIdRequest
    );
}

function* watchGetOrganisationUserDataRequest() {
    yield takeLatest(
        OrganisationsActionTypes.GET_ORGANISATION_USER_DATA_REQUEST,
        handleGetOrganisationUserDataRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* organisationsSaga() {
    yield all([
        fork(watchGetUserOrganisationsRequest),
        fork(watchSelectUserOrganisationRequest),
        fork(watchSelectUserOrganisationSuccess),
        fork(watchSelectOrganisationChannel),
        fork(watchUserOrganisationChannel),
        fork(watchGetOrganisationCompaniesRequest),
        fork(watchGetOrganisationCompaniesForUserRequest),
        fork(watchSaveUserOrganisationRequest),
        fork(watchLoadCurrenciesDataRequest),
        fork(watchGetOrganisationCustomFieldsFilterListByTypes),
        fork(watchCloseCompanyRequest),
        fork(watchGetOrganisationUsersRequest),
        fork(watchRemoveOrganisationUsersRequest),
        fork(watchSetSelectedOrganisationUserIdRequest),
        fork(watchGetOrganisationUserDataRequest),
    ]);
}

export default organisationsSaga;
