/**
 * Component for populating the contact information icons.
 */

import { Button, Tooltip } from 'antd';
import { get, isUndefined } from 'lodash';
import React from 'react';
import { ContactFilterOptions } from '../../constants/tasksSortAndFilters';
import { Contact as ContactType } from '../../store/customers/types';
import {
    getContactAddress,
    getContactIconColors,
    populatePopoverContainer
} from '../../utils/commonFunctions';
import FontAwesome from './FontAwesome';

interface IProps {
    readonly contactInformation: ContactType;
    readonly containerRef?: any;
    readonly additionalClass?: string;
    readonly availableContactMethods?: number;
    readonly placement?: 'left' | 'right' | 'center';
    readonly iconTransform?: string;
    readonly tooltipClass?: string;
}
const ContactInformationIcons: React.FC<IProps> = ({
    contactInformation,
    containerRef,
    additionalClass,
    availableContactMethods,
    placement,
    iconTransform,
    tooltipClass,
}: IProps) => {
    let usedContactInformation = contactInformation;
    if (!contactInformation) {
        usedContactInformation = {
            Id: '',
            FirstName: '',
            LastName: '',
            AddressLine1: '',
            AddressLine2: '',
            City: '',
            State: '',
            Postcode: '',
            Country: '',
            Email: '',
            MobileNumber: '',
            Landline:''
        };
    }

    /**
     * Function that controls whether an icon should be displayed or not.
     * @param contactMethod
     */
    const displayContactMethodIcon = (contactMethod: number) => {
        if (!isUndefined(availableContactMethods)) {
            if ((availableContactMethods & contactMethod) !== 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const fullAddress = getContactAddress(usedContactInformation);
    const { addressColor, mobileColor, emailColor } = getContactIconColors(
        usedContactInformation,
        fullAddress
    );

    return (
        <div className="button-link-icons">
            <div className={`ta-${placement || 'center'} ${additionalClass}`}>
                {displayContactMethodIcon(
                    ContactFilterOptions.PostalAddress
                ) && (
                    <Tooltip
                        className={tooltipClass || 'plr-6'}
                        placement="top"
                        trigger="hover"
                        title={fullAddress}
                        getPopupContainer={populatePopoverContainer(
                            containerRef
                        )}
                    >
                        <Button type="link">
                            <FontAwesome
                                className={addressColor}
                                icon={['fas', 'home']}
                                transform={iconTransform}
                            />
                        </Button>
                    </Tooltip>
                )}
                {displayContactMethodIcon(ContactFilterOptions.Email) && (
                    <Tooltip
                        className={tooltipClass || 'plr-6'}
                        placement="top"
                        trigger="hover"
                        title={get(contactInformation, `Email`)}
                        getPopupContainer={populatePopoverContainer(
                            containerRef
                        )}
                    >
                        <Button type="link">
                            <FontAwesome
                                className={emailColor}
                                icon={['fas', 'envelope']}
                                transform={iconTransform}
                            />
                        </Button>
                    </Tooltip>
                )}
                {displayContactMethodIcon(ContactFilterOptions.Mobile) && (
                    <Tooltip
                        className={tooltipClass || 'plr-6'}
                        placement="top"
                        trigger="hover"
                        title={get(contactInformation, `MobileNumber`)}
                        getPopupContainer={populatePopoverContainer(
                            containerRef
                        )}
                    >
                        <Button type="link">
                            <FontAwesome
                                className={mobileColor}
                                icon={['fas', 'mobile-alt']}
                                transform={iconTransform}
                            />
                        </Button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default ContactInformationIcons;
