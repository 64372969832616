import { Popover } from 'antd';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import { capitalize, get, isEmpty } from 'lodash';
import moment from 'moment-timezone/moment-timezone';
import React from 'react';
import {
    dateFormatDoMMMYYYYhmmASpace,
    dateFormatYYYYMMDDTHHmmssDash,
} from '../../constants/dateFormats';
import { Attachment, ConversationLine } from '../../store/common/types';
import { getTranslatedText } from '../../utils/commonFunctions';
import { checkIsRecentComment, getIfIsLg, getTimelineIconAndColor } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import AvatarNameInitialsComponent from './AvatarNameInitialsComponent';
import FontAwesome from './FontAwesome';

export const generateCommentItemWithType = (
    data: DynamicObject,
    type:
        | 'chat'
        | 'customer'
        | 'invoice'
        | 'task'
        | 'ticket'
        | 'payment'
        | 'remittanceAdvice'
        | 'paymentPlan',
    numberOfDaysBeforeRecentCommentTurnedOff: number,
    viewCommentSection?: any,
    tooltipPlacement?: any,
    btnClassName: string = 'pa-0 row-icons-btn',
    customerLabel?: string
) => {
    const content = get(data, 'Content');
    const createdDateTime = get(data, 'CreatedDateTime');
    const assignedEmail = get(data, 'ExternalEmailAddress');
    const firstName = get(data, 'User.GivenName');
    const lastName = get(data, 'User.FamilyName');
    const fullName =
        firstName && lastName ? `${firstName} ${lastName}` : undefined;
    let isRecentComment = checkIsRecentComment(createdDateTime, numberOfDaysBeforeRecentCommentTurnedOff);
    const color = isRecentComment
        ? 'blue'
        : 'grey';
    let iconDisplay: any = undefined;
    let commentFa: any = undefined;
    if (type === 'customer') {
        commentFa = 'address-book';
        iconDisplay = (
            <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className={color}
                    icon={['far', 'comment-alt']}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className={color}
                    icon={['fas', commentFa]}
                    transform="shrink-7"
                />
            </div>
        );
    } else if (type === 'invoice') {
        commentFa = 'file-invoice';
        iconDisplay = (
            <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className={color}
                    icon={['far', 'comment-alt']}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className={color}
                    icon={['fas', commentFa]}
                    transform="shrink-7"
                />
            </div>
        );
    } else if (type === 'task') {
        commentFa = 'list';
        iconDisplay = (
            <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className={color}
                    icon={['far', 'comment-alt']}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className={color}
                    icon={['fas', commentFa]}
                    transform="shrink-7"
                />
            </div>
        );
    } else if (type === 'ticket') {
        commentFa = 'handshake';
        iconDisplay = (
            <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className={color}
                    icon={['far', 'comment-alt']}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className={color}
                    icon={['fas', commentFa]}
                    transform="shrink-7"
                />
            </div>
        );
    } else if (type === 'payment') {
        commentFa = 'receipt';
        iconDisplay = (
            <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className={color}
                    icon={['far', 'comment-alt']}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className={color}
                    icon={['fas', commentFa]}
                    transform="shrink-7"
                />
            </div>
        );
    } else if (type === 'remittanceAdvice') {
        commentFa = 'search-dollar';
        iconDisplay = (
            <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className={color}
                    icon={['far', 'comment-alt']}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className={color}
                    icon={['fas', commentFa]}
                    transform="shrink-7"
                />
            </div>
        );
    } else if (type === 'paymentPlan') {
        commentFa = 'money-bill-alt';
        iconDisplay = (
            <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className={color}
                    icon={['far', 'comment-alt']}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className={color}
                    icon={['fas', commentFa]}
                    transform="shrink-7"
                />
            </div>
        );
    } else if (type === 'chat') {
        const Type = get(data, 'Type');
        const TypeClassification = get(data, 'TypeClassification');
        const { color, icon } = getTimelineIconAndColor(Type, TypeClassification);
        iconDisplay = (
            <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className={color}
                    icon={icon}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className={color}
                    icon={icon}
                    transform="shrink-7"
                />
            </div>
        );
    }

    const selectedTimezone = moment.tz.guess();
    const usedFromFormat = dateFormatYYYYMMDDTHHmmssDash;
    const usedToFormat = dateFormatDoMMMYYYYhmmASpace;
    const formattedDateTime = moment
        .utc(createdDateTime, usedFromFormat)
        .tz(selectedTimezone)
        .format(usedToFormat);

    const poTitle = (
        <div className="center-flex" onClick={(e) => e.stopPropagation()}>
            <div className="mr-10">
                {(fullName || assignedEmail)
                    ? <AvatarNameInitialsComponent
                        fullName={fullName}
                        size={40}
                        isDefault={!isEmpty(assignedEmail)}
                    />
                    : <FontAwesome
                        icon={['fas', 'address-book']}
                        style={{ fontSize: 35 }}
                    />}
            </div>
            <div>
                <div>{fullName || assignedEmail || capitalize(customerLabel)}</div>
                <div className="fs-12">{formattedDateTime}</div>
            </div>
        </div>
    );

    const poContent = (
        <div className="center-flex" onClick={(e) => e.stopPropagation()}>
            <div>
                <FontAwesome
                    className={`mr-8 ${color} fs-20`}
                    icon={['fas', commentFa]}
                />
            </div>
            <div className="multiline multiline-limit">{content}</div>
        </div>
    );

    return (
        <Popover
            overlayClassName="comment-popover"
            title={poTitle}
            content={poContent}
            placement={tooltipPlacement || 'topRight'}
        >
            <Button
                className={btnClassName}
                type="link"
                onClick={viewCommentSection}
            >
                {getTranslatedText(iconDisplay)}
            </Button>
        </Popover>
    );
};

export const generateCommentItem = (
    data: DynamicObject,
    viewCommentSection?: any,
    tooltipPlacement?: any
) => {
    const title = get(data, 'Content');
    const createdDateTime = get(data, 'CreatedDateTime');
    const lastWeekDate = moment().subtract(7, 'days').startOf('day');
    const color = moment(createdDateTime).isAfter(moment(lastWeekDate))
        ? 'blue'
        : 'grey';
    return (
        <Tooltip title={title} placement={tooltipPlacement || 'topRight'}>
            <Button className="pa-0" type="link" onClick={viewCommentSection}>
                <FontAwesome
                    icon={['fas', 'comments']}
                    className={`${color} fs-20`}
                />
            </Button>
        </Tooltip>
    );
};

export const generateCustomerIcon = (
    isVip: boolean,
    itemColor?: string,
    fontSize: number = 35
) => {
    if (isVip) {
        const fontSizeUsed = getIfIsLg() ? 30 : fontSize;
        return (
            <div
                className="fa-layers fa-fw fa-2x"
                style={{
                    fontSize: fontSizeUsed,
                }}
            >
                <FontAwesome
                    icon={['far', 'circle']}
                    size="2x"
                    transform="shrink-3 left-3"
                    className={itemColor}
                />
                <FontAwesome
                    icon={['fas', 'crown']}
                    size="2x"
                    transform="shrink-9 left-5"
                    className={itemColor}
                />
            </div>
        );
    } else {
        const fontSizeUsed = getIfIsLg() ? 17 : 20;
        return (
            <div style={{ fontSize: fontSizeUsed }}>
                <FontAwesome
                    icon={['far', 'circle']}
                    size="3x"
                    className={itemColor}
                    transform="shrink-1"
                />
            </div>
        );
    }
};

export const generatePauseIcon = (
    iconCircleColor: string = 'red',
    iconColor: string = 'red',
    fontSize: number = 24.5
) => {
    const fontSizeUsed = getIfIsLg() ? 20 : fontSize;
    return (
        <div
            className="fa-layers fa-fw fa-2x"
            style={{ fontSize: fontSizeUsed }}
        >
            <FontAwesome
                icon={['far', 'circle']}
                className={iconCircleColor || iconColor}
                size="2x"
                transform="left-3"
            />
            <FontAwesome
                icon={['fas', 'pause-circle']}
                className={iconColor}
                size="2x"
                transform="shrink-6 left-3"
            />
        </div>
    );
};

export const generateSettledIcon = (
    iconCircleColor: string = 'settled',
    fontSize: number = 24.5
) => {
    const fontSizeUsed = getIfIsLg() ? 20 : fontSize;
    return (
        <div
            className="fa-layers fa-fw fa-2x"
            style={{ fontSize: fontSizeUsed }}
        >
            <FontAwesome
                icon={['far', 'circle']}
                className={iconCircleColor}
                size="2x"
                transform="left-3"
            />
        </div>
    );
};

// [TODO] Need to update it whenever has icon for this case
export const generateEscalatedIcon = () => {
    return (
        <div>
        </div>
    );
};

export const generateAttachmentsIcon = (attachments: Attachment[]) => {
    return (
        !isEmpty(attachments) && (
            <Tooltip
                title={getTranslatedText(`${attachments.length} attachment${
                    attachments.length > 1 ? 's' : ''
                    } available`)}
                placement="topRight"
            >
                <Button type="link" className="row-icons-btn">
                    <FontAwesome
                        icon={['fas', 'file-alt']}
                        transform="up-1"
                        className="blue fs-18 mr-5"
                    />
                </Button>
            </Tooltip>
        )
    );
};

export const generateOrganisationUserIcon = (
    iconCircleColor: string = 'settled',
    fontSize: number = 24.5,
    isAdmin?: boolean
) => {
    const fontSizeUsed = getIfIsLg() ? 20 : fontSize;
    return (
        <div
            className="fa-layers fa-fw fa-2x"
            style={{ fontSize: fontSizeUsed }}
        >
            <FontAwesome
                icon={['far', 'circle']}
                className={iconCircleColor}
                size="2x"
                transform="left-3"
            />
            {isAdmin && (
                <FontAwesome
                    icon={['fas', 'angle-double-up']}
                    className={iconCircleColor}
                    size="2x"
                    transform="shrink-6"
                />
            )}
        </div>
    );
};

export const generateCustomerResponseIcon = (
    item: ConversationLine,
    numberOfDaysBeforeRecentCommentTurnedOff: number,
) => {
    const createdDateTime = get(item, 'CreatedDateTime');
    const selectedTimezone = moment.tz.guess();
    const usedFromFormat = dateFormatYYYYMMDDTHHmmssDash;
    const usedToFormat = dateFormatDoMMMYYYYhmmASpace;
    const formattedDateTime = moment
        .utc(createdDateTime, usedFromFormat)
        .tz(selectedTimezone)
        .format(usedToFormat);
    const isRecentComment = checkIsRecentComment(createdDateTime, numberOfDaysBeforeRecentCommentTurnedOff);
    const color = isRecentComment
        ? 'purple'
        : 'grey';

    return (
        <Tooltip
            title={getTranslatedText(`Reply received on ${formattedDateTime}`)}
            placement="topRight"
        >
            <Button type="link" className="pa-0 row-icons-btn">
                <FontAwesome
                    icon={['fas', 'reply']}
                    transform="up-1"
                    className={`${color} fs-18 mr-5`}
                />
            </Button>
        </Tooltip>
    );
};