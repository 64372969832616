/**
 * Class component for managing the notifications.
 */

import {
    Badge,
    Button,
    Drawer,
    Empty,
    List,
    notification,
    Skeleton,
    Modal,
    Icon,
} from 'antd';
import { filter, find, get, includes, isEmpty, times } from 'lodash';
import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    dateFormatDDMMMYYYYhmmASpace,
    dateFormatYYYYMMDDTHHmmssDash,
} from '../../constants/dateFormats';
import { ApplicationState } from '../../store';
import { selectUserCompanyRequestAction } from '../../store/companies/actions';
import { CompanyUserRole } from '../../store/companies/types';
import {
    getCategorizedNotificationsRequestAction,
    getNotificationsByCategoryRequestAction,
    markNotificationAsReadAction,
} from '../../store/notifications/actions';
import { NotificationsState } from '../../store/notifications/types';
import NotificationSubscriptionService from '../../utils/NotificationSubscriptionService';
import { withDateFormatHandler } from './DateFormatHandler';
import FontAwesome from './FontAwesome';
import {
    ResponseModalObject,
    DynamicObject,
} from '../../utils/commonInterfaces';
import { getPresignedUrlDownloadReportReadyAction } from '../../store/common/actions';
import { downloadFileFromPresignedUrl, invalidateImagePathCache, getTranslatedText, useTranslatedText, translationText } from '../../utils/commonFunctions';
import { IODM_COMPANY_STORAGE_NAME, IODM_ORGANISATION_STORAGE_NAME } from '../../config/config';
import { IsOrganisationViewAttribute } from '../../constants/authUserAttributes';
import ImageWithLoading from './ImageWithLoading';
import { Organisation } from '../../store/organisations/types';
import { selectUserOrganisationRequestAction } from '../../store/organisations/actions';

const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));
const companyLogoCacheVersion = new Date().getTime();

const shortenCompanyName = (name: string) => {
    const visibleCharCount = 11;
    const maxCharCount = 25;

    if (name.length > maxCharCount) {
        return name.substring(0, visibleCharCount) + '...' + name.substring(name.length - visibleCharCount);
    } else {
        return name;
    }
};

let selectedNotificationCompanyId: string | null = null;
let selectedNotificationOrganisationId: string | null = null;
let selectedCategoryType: string | null = null;
let notificationExtraProps: DynamicObject | undefined = undefined;
const notificationCategories = {
    TASK: 'Task',
    IMPORT: 'Import',
    TICKET: 'Ticket',
    REPORT_READY: 'Report Ready',
    CUSTOMER_RESPOND: 'Customer Respond',
    DELETE_ITEM: 'Delete Item',
    BANK_FILE_PAYMENT: 'Bank File Payment',
};
const notificationCategoryNames = {
    Importing: 'Importing',
    TaskChanges: 'TaskChanges',
    TaskBatch: 'TaskBatch',
    TicketSubmission: 'TicketSubmission',
    ReportReady: 'ReportReady',
    CustomerRespond: 'CustomerRespond',
    DeleteItem: 'DeleteItem',
    BankFilePayment: 'BankFilePayment',
};
export const withNotificationsHandler = (WrappedComponent: any) => {
    interface IProps {
        getCategorizedNotificationsRequestAction: (
            refetchNewNotif?: boolean,
            callback?: (notifications: any) => void,
            noState?: boolean
        ) => void;
        getNotificationsByCategoryRequestAction: (
            notificationId: string
        ) => void;
        selectUserCompanyRequestAction: (
            companyUserRoleObject: CompanyUserRole,
            switchCompany?: boolean
        ) => void;
        selectUserOrganisationRequestAction: (
            organisation: Organisation
        ) => void;
        categorizedNotifications: NotificationsState['categorizedNotifications'];
        notificationsByCategory: NotificationsState['notificationsByCategory'];
        formatDateUTCToLocal: (
            date: any,
            fromFormat?: string | null,
            toFormat?: string | null
        ) => string;
        selectedUserCompanyId: string | undefined | null;
        selectedUserOrganisationId: string | undefined | null;
        userCompanies: CompanyUserRole[];
        userOrganisations: Organisation[];
        history: {
            push: (path: string) => void;
        };
        companyStateLoading: boolean;
        unreadNotificationCategories: string[];
        markNotificationAsReadAction: (
            categoryId: string,
            notificationId?: string,
            callback?: (response: ResponseModalObject) => void
        ) => void;
        getPresignedUrlDownloadReportReadyAction: (
            payload: DynamicObject,
            callback?: (response: ResponseModalObject) => void
        ) => void;
        currentUser: DynamicObject;
    }
    let callRefetchOnSubmenuClose = false;
    const drawerListPadding = 24;
    const listItemAdjustment = 12;
    const divContainerStyle = {
        marginTop: -listItemAdjustment,
        marginBottom: -listItemAdjustment,
        padding: ` 0px ${drawerListPadding}px`,
    };
    const listItemStyle = {
        marginLeft: -drawerListPadding,
        marginRight: -drawerListPadding,
    };
    const divBorderedStyle = {
        paddingTop: listItemAdjustment,
        paddingBottom: listItemAdjustment,
    };
    class NotificationsHandler extends PureComponent<IProps> {
        state = {
            showNotificationsMenu: false,
            showNotificationsSubMenu: false,
            showDownloadingModal: false,
            translationText: ''
        };

        /**
         * React life-cycle method where getting notification permission and initializing the first level
         * notifications data takes place.
         */
        componentDidMount() {
            this.grantNotificationPermission();
            this.initializeCategorizedNotificationsData(true);
        }

        /**
         * React life-cycle called for resetting `selectedNotificationCompanyId` and `selectedCategoryType`
         * when the component unmounts (usually happens during user logout).
         */
        componentWillUnmount() {
            selectedNotificationCompanyId = null;
            selectedNotificationOrganisationId = null;
            selectedCategoryType = null;
        }

        /**
         * React life-cycle called when class props/state values are updated.
         * @param prevProps
         */
        componentDidUpdate(prevProps: IProps) {
            if (!isEmpty(selectedNotificationCompanyId)) {
                if (
                    this.props.selectedUserCompanyId !==
                        prevProps.selectedUserCompanyId &&
                    this.props.selectedUserCompanyId ===
                        selectedNotificationCompanyId &&
                    !this.props.companyStateLoading
                ) {
                    if (
                        selectedCategoryType === notificationCategories.IMPORT
                    ) {
                        this.goToImportHistoryPage();
                    } else if (
                        selectedCategoryType === notificationCategories.TICKET
                    ) {
                        this.goToCustomerAssistancePage();
                    } else if (
                        selectedCategoryType ===
                        notificationCategories.REPORT_READY
                    ) {
                        this.downloadExcelReport();
                    } else if (
                        selectedCategoryType === notificationCategories.CUSTOMER_RESPOND
                    ) {
                        this.goToCustomerAssistancePage();
                    } else if (
                        selectedCategoryType === notificationCategories.DELETE_ITEM
                    ) {
                        // do nothing
                    } else {
                        this.goToTaskHistoryPage();
                    }

                    selectedNotificationCompanyId = null;
                }
            }

            if (!isEmpty(selectedNotificationOrganisationId)) {
                if (
                    this.props.selectedUserOrganisationId !== prevProps.selectedUserOrganisationId &&
                    this.props.selectedUserOrganisationId === selectedNotificationOrganisationId &&
                    !this.props.companyStateLoading
                ) {
                    if (
                        selectedCategoryType ===
                        notificationCategories.REPORT_READY
                    ) {
                        this.downloadExcelReport();
                    }

                    selectedNotificationOrganisationId = null;
                }
            }
        }

        /**
         * Function to show the notifications drawer.
         */
        openNotificationsMenu = () => {
            this.setState({ showNotificationsMenu: true }, () => {
                this.initializeCategorizedNotificationsData();
            });
        };

        /**
         * Function for going to `Customer assistance` page - when a user clicks on the notification related to tickets.
         */
        goToCustomerAssistancePage = () => {
            this.props.history.push('/app/customer-enquiries/customer-assistance');
        };

        /**
         * Function for going to `Import history` page - when a user clicks on the notification related to imports.
         */
        goToImportHistoryPage = () => {
            this.props.history.push('/app/import/history');
        };

        /**
         * Function for going to `Task history` page - when a user clicks on the notification related to tasks.
         */
        goToTaskHistoryPage = () => {
            this.props.history.push('/app/tasks/history');
        };

        /**
         * Function for going to `Bank file process history` page - when a user clicks on the notification related to bank file process.
         */
        goToBankFileProcessHistoryPage = () => {
            this.props.history.push('/app/bank-file/bank-file-process-history');
        };

        /**
         * Function called when clicking on the report ready - download excel report notification.
         */
        downloadExcelReport = () => {
            if (notificationExtraProps) {
                let extraProps: DynamicObject = notificationExtraProps;

                this.setState(
                    {
                        showDownloadingModal: true,
                    },
                    () => {
                        this.props.getPresignedUrlDownloadReportReadyAction(
                            extraProps,
                            (res: DynamicObject) => {
                                if (res.IsSuccess) {
                                    downloadFileFromPresignedUrl(
                                        res.ReportGetURL,
                                        () => {
                                            this.setState({
                                                showDownloadingModal: false,
                                            });
                                        }
                                    );
                                } else {
                                    this.setState({
                                        showDownloadingModal: false,
                                    });
                                    Modal.error({
                                        title: getTranslatedText('Error'),
                                        content: (
                                            <div>
                                                <div>
                                                    {getTranslatedText('Failed to download the report file!')}
                                                </div>
                                            </div>
                                        ),
                                        okText: getTranslatedText('OK')
                                    });
                                }
                            }
                        );
                    }
                );
            }
        };

        /**
         * Function called for updating the state object.
         */
        updateStateObject = (stateObject = {}) => {
            this.setState({
                ...stateObject,
            });
        };

        /**
         * Function called when the notification is viewed/clicked.
         */
        handleClickViewNotification = (
            companyId: string | null,
            organisationId: string | null,
            category: string,
            extraProps?: DynamicObject,
            callback?: () => void
        ) => {
            selectedCategoryType = category;
            notificationExtraProps = extraProps;
            const isOrgView =
                get(this.props.currentUser, IsOrganisationViewAttribute) ===
                '1';

            const selectedUserCompany = companyId && get(
                filter(this.props.userCompanies, [
                    'Company.CompanyId',
                    companyId,
                ]),
                0
            );

            const selectedUserOrganisation = organisationId && get(
                filter(this.props.userOrganisations, [
                    'OrganisationId',
                    organisationId,
                ]),
                0
            );

            const currentSelectedUserCompany = get(
                filter(this.props.userCompanies, [
                    'Company.CompanyId',
                    this.props.selectedUserCompanyId,
                ]),
                0
            );
            
            const currentSelectedUserOrganisation = get(
                filter(this.props.userOrganisations, [
                    'Company.CompanyId',
                    this.props.selectedUserOrganisationId,
                ]),
                0
            );

            if (selectedUserCompany || selectedUserOrganisation) {
                this.setState(
                    {
                        showNotificationsMenu: false,
                        showNotificationsSubMenu: false,
                        selectedCategoryId: null,
                    },
                    () => {
                        if (
                            selectedUserCompany
                            && (companyId !== this.props.selectedUserCompanyId || isOrgView)
                        ) {
                            selectedNotificationCompanyId = companyId;
                            // const switchCompany =
                            //     selectedCategoryType ===
                            //     notificationCategories.REPORT_READY
                            //         ? false
                            //         : true;
                            if (
                                get(selectedUserCompany, 'Company.CompanyId') &&
                                get(
                                    selectedUserCompany,
                                    'Company.CompanyId'
                                ) !==
                                    get(
                                        currentSelectedUserCompany,
                                        'Company.CompanyId'
                                    )
                            ) {
                                localStorage.setItem(
                                    IODM_COMPANY_STORAGE_NAME,
                                    JSON.stringify(selectedUserCompany)
                                );
                            }
                            this.props.selectUserCompanyRequestAction(
                                selectedUserCompany
                            );
                        } else if (
                            selectedUserOrganisation
                            && (organisationId !== this.props.selectedUserOrganisationId || !isOrgView)
                        ) {
                            selectedNotificationOrganisationId = organisationId;
                            if (
                                get(selectedUserOrganisation, 'OrganisationId') &&
                                get(
                                    selectedUserOrganisation,
                                    'OrganisationId'
                                ) !==
                                get(
                                    currentSelectedUserOrganisation,
                                    'OrganisationId'
                                )
                            ) {
                                localStorage.setItem(
                                    IODM_ORGANISATION_STORAGE_NAME,
                                    JSON.stringify(selectedUserOrganisation)
                                );
                            }
                            this.props.selectUserOrganisationRequestAction(
                                selectedUserOrganisation
                            );
                        } else {
                            if (
                                selectedCategoryType ===
                                notificationCategories.IMPORT
                            ) {
                                this.goToImportHistoryPage();
                            } else if (
                                selectedCategoryType ===
                                notificationCategories.TICKET
                            ) {
                                this.goToCustomerAssistancePage();
                            } else if (
                                selectedCategoryType ===
                                notificationCategories.CUSTOMER_RESPOND
                            ) {
                                this.goToCustomerAssistancePage();
                            } else if (
                                selectedCategoryType ===
                                notificationCategories.REPORT_READY
                            ) {
                                this.downloadExcelReport();
                            } else if (
                                selectedCategoryType ===
                                notificationCategories.BANK_FILE_PAYMENT
                            ) {
                                this.goToBankFileProcessHistoryPage();
                            } else if (
                                selectedCategoryType ===
                                notificationCategories.DELETE_ITEM
                            ) {
                                // do nothing
                            } else {
                                this.goToTaskHistoryPage();
                            }
                        }

                        if (callback) callback();
                    }
                );
            }
        };
        
        /**
         * Function called when close drawer is called.
         */
        onClose = (drawerName: string) => {
            return () => {
                if (
                    drawerName === 'showNotificationsSubMenu' &&
                    callRefetchOnSubmenuClose === true
                ) {
                    callRefetchOnSubmenuClose = false;
                    this.initializeCategorizedNotificationsData(false);
                }
                const additionalStateObject =
                    drawerName === 'showNotificationsSubMenu'
                        ? { selectedCategoryId: null }
                        : {};
                this.updateStateObject({
                    ...additionalStateObject,
                    [drawerName]: false,
                });
            };
        };

        /**
         * Function called when initializing the categorized notifications data.
         */
        initializeCategorizedNotificationsData = (
            refetchNewNotif: boolean = false,
            callback?: (notifications: any) => void
        ) => {
            this.props.getCategorizedNotificationsRequestAction(
                refetchNewNotif,
                callback,
                callRefetchOnSubmenuClose
            );
        };

        /**
         * Function for asking notification permission from user.
         */
        grantNotificationPermission = () => {
            if (!('Notification' in window)) {
                
                alert(getTranslatedText('This browser does not support system notifications'));
                return;
            }

            if (Notification.permission === 'granted') {
                return;
            }

            if (
                Notification.permission === 'denied' ||
                Notification.permission === 'default'
            ) {
                Notification.requestPermission();
            }
        };

        /**
         * Function that fetches the extra props to be used from notification.
         * @param otherNotificationProps - remaining props that are not extracted
         */
        getNotificationExtraProps = ({
            EntityType,
            EntityKey,
            Region
        }: DynamicObject) => {
            let extraProps: undefined | DynamicObject = undefined;
            if (EntityType && EntityKey) {
                extraProps = {
                    EntityType,
                    EntityKey,
                    Region
                };
            }

            return extraProps;
        };

        translatedText = (description: string) => {
            //translationText(description).then(results => this.setState({ translationText: results }));
            
            let translatedText: string = getTranslatedText(description);
            const matches = description.match(/\d+/g);

            if (matches) {
                const replaceDescription: string = description.replace(/\d+/g, '[]').trim();

                translatedText = getTranslatedText(replaceDescription);

                matches.forEach((value: string) => {
                    translatedText = translatedText.replace('[]', value);
                });
            }

            return translatedText;
            //return this.state.translationText;
        };

        /**
         * Function that is responsible for showing the notification popup
         */
        showNotification = ({
            Title,
            Id,
            CategoryId,
            CategoryName,
            Description,
            Company,
            OrganisationId,
            CreatedDateTime,
            ...otherNotificationProps
        }: any) => {
            const hasNoAction = CategoryName === notificationCategoryNames.DeleteItem;
            const extraProps = this.getNotificationExtraProps(
                otherNotificationProps
            );

            const { notificationCategory } =
                this.getIconAndCategory(CategoryName);
            const viewButtonClickHandlerCallback =
                this.callbackFunctionMarkNotificationRead(CategoryId, Id);

            const notificationViewClicked = () => {
                this.handleClickViewNotification(
                    get(Company, 'CompanyId'),
                    OrganisationId,
                    notificationCategory,
                    extraProps,
                    () => {
                        viewButtonClickHandlerCallback();
                        notification.destroy();
                    }
                );
            };

            

            const NotificationDescription: React.FC<any> = () => {
                // const [notiDetail, setNotiDetail] = useState<React.ReactNode>();
                // const onViewLessInfo = () => setNotiDetail(null);
                // const onViewMoreInfo = () => {
                //     if (
                //         notificationCategory ===
                //         notificationCategories.IMPORT
                //     ) {
                //         setNotiDetail(<div>Sent at {this.formatDateUTCToLocal(CreatedDateTime)}</div>);
                //     } else if (
                //         notificationCategory ===
                //         notificationCategories.TICKET
                //     ) {
                //         setNotiDetail(<div>Sent at {this.formatDateUTCToLocal(CreatedDateTime)}</div>);
                //     } else if (
                //         notificationCategory ===
                //         notificationCategories.CUSTOMER_RESPOND
                //     ) {
                //         setNotiDetail(<div>Sent at {this.formatDateUTCToLocal(CreatedDateTime)}</div>);
                //     } else {
                //         setNotiDetail(<div>Sent at {this.formatDateUTCToLocal(CreatedDateTime)}</div>);
                //     }
                // };

                if (notificationCategory === notificationCategories.REPORT_READY) {
                    return (
                        <div>
                            <div className="multiline">{this.translatedText(Description)}</div>
                            {
                                !includes(Title.toLowerCase(), 'failed'.toLowerCase()) && <>
                                    <br />
                                    <div className='ta-right' style={{
                                        display: 'flex',
                                        justifyContent: 'end'
                                    }}>
                                        <Button
                                            className='ml-5'
                                            type="primary"
                                            onClick={notificationViewClicked}
                                        >
                                            {getTranslatedText('Download report')}
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    )
                } else {
                    return (
                        <div>
                            <div className="multiline">{this.translatedText(Description)}</div>
                            {/* {notiDetail} */}
                            <br />
                            {!hasNoAction && <div className='ta-right' style={{
                                display: 'flex',
                                justifyContent: 'end'
                            }}>
                                {/* {!notiDetail && <Button
                                    className='ml-5'
                                    type="primary"
                                    onClick={onViewMoreInfo}
                                >
                                    View more information
                                </Button>}
                                {!!notiDetail && <Button
                                    className='ml-5'
                                    type="primary"
                                    onClick={onViewLessInfo}
                                >
                                    View less information
                                </Button>} */}
                                <Button
                                    className='ml-5'
                                    type="primary"
                                    onClick={notificationViewClicked}
                                >
                                    {getTranslatedText('Go to this item')}
                                </Button>
                            </div>}
                        </div>
                    )
                }
            };

            const companyLogoPath =
                invalidateImagePathCache(
                    get(Company, 'LogoPath'),
                    companyLogoCacheVersion
                );

            if (Notification.permission === 'granted') {
                if (typeof document.hidden !== 'undefined') {
                    new Notification(Title, {
                        body: Description,
                    });
                }
            }
            // } else {
            notification.open({
                message: <>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div className="mb-10">
                            <ImageWithLoading
                                src={companyLogoPath}
                                style={{
                                    height: 30,
                                    width: 'auto'
                                }}
                                alt={get(Company, 'Name')}
                            />
                            &nbsp;&nbsp;
                            {shortenCompanyName(get(Company, 'Name'))}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon className='primary-blue' type="info-circle"
                                style={{
                                    fontSize: 24
                                }} />
                            &nbsp;&nbsp;
                            {getTranslatedText(Title)}
                        </div>
                    </div>
                </>,
                duration: 5, // seconds
                description: <NotificationDescription />,
            });
            // }
        };

        /**
         * Function that gets the icon and category for the notification.
         */
        getIconAndCategory = (categoryName: string) => {
            let faIcon: any = null;
            let notificationCategory = '';
            if (
                categoryName === notificationCategoryNames.TaskBatch ||
                categoryName === notificationCategoryNames.TaskChanges
            ) {
                faIcon = ['fas', 'tasks'];
                notificationCategory = notificationCategories.TASK;
            } else if (categoryName === notificationCategoryNames.Importing) {
                faIcon = ['fas', 'sync'];
                notificationCategory = notificationCategories.IMPORT;
            } else if (
                categoryName === notificationCategoryNames.TicketSubmission
            ) {
                faIcon = ['fas', 'handshake'];
                notificationCategory = notificationCategories.TICKET;
            } else if (categoryName === notificationCategoryNames.ReportReady) {
                faIcon = ['fas', 'cloud-download-alt'];
                notificationCategory = notificationCategories.REPORT_READY;
            } else if (categoryName === notificationCategoryNames.CustomerRespond) {
                faIcon = ['fas', 'reply'];
                notificationCategory = notificationCategories.CUSTOMER_RESPOND;
            } else if (categoryName === notificationCategoryNames.DeleteItem) {
                faIcon = ['fas', 'trash'];
                notificationCategory = notificationCategories.DELETE_ITEM;
            } else if (categoryName === notificationCategoryNames.BankFilePayment) {
                faIcon = ['fa', 'receipt'];
                notificationCategory = notificationCategories.BANK_FILE_PAYMENT;
            }

            return {
                faIcon,
                notificationCategory,
            };
        };

        /**
         * Callback function for marking the notifications as read (calls an action to
         * trigger saga for marking notification as read).
         */
        callbackFunctionMarkNotificationRead = (
            CategoryId: string,
            NotificationId?: string
        ) => {
            return () => {
                this.props.markNotificationAsReadAction(
                    CategoryId,
                    NotificationId,
                    () => {
                        this.initializeCategorizedNotificationsData(
                            false,
                            () => {
                                callRefetchOnSubmenuClose = false;
                            }
                        );
                    }
                );
            };
        };

        /**
         * Function that renders each menu list item for notifications.
         */
        renderNotificationsMenuListItem = (item: any) => {
            const {
                Id,
                CategoryId,
                CategoryName,
                Title,
                Description,
                CreatedDateTime,
                ItemsCount: DbItemsCount,
                Company,
                OrganisationId,
                ...otherNotificationProps
            } = item;
            
            const extraProps = this.getNotificationExtraProps(
                otherNotificationProps
            );

            const { faIcon, notificationCategory } =
                this.getIconAndCategory(CategoryName);

            const titleComponent = Title ? (
                <>
                    <FontAwesome icon={faIcon} className="mr-10" />
                    {getTranslatedText(Title)}
                </>
            ) : null;

            const ItemsCount = DbItemsCount ? DbItemsCount - 1 : 0;
            let listItemClassName = '';
            if (get(this.state, 'selectedCategoryId') === CategoryId) {
                listItemClassName = 'list-item-clicked';
            } else if (
                includes(this.props.unreadNotificationCategories, CategoryId)
            ) {
                listItemClassName = 'notification-unread';
            }

            const viewButtonClickHandlerCallback =
                listItemClassName === 'notification-unread'
                    ? this.callbackFunctionMarkNotificationRead(CategoryId)
                    : undefined;

            const Organisation = find(this.props.userOrganisations, [
                'OrganisationId',
                OrganisationId,
            ]);

            return (
                <List.Item
                    key={Id}
                    className={listItemClassName}
                    style={listItemStyle}
                >
                    <div style={divContainerStyle}>
                        <div
                            className="container-bordered"
                            style={divBorderedStyle}
                        >
                            {titleComponent && <h3>{titleComponent}</h3>}
                            <div className="multiline">{this.translatedText(Description)}</div>
                            <div className="date-grey sb-flex">
                                <div className="date-width">
                                    {this.formatDateUTCToLocal(CreatedDateTime)}
                                </div>
                                <div>{get(Company, 'Name') || get(Organisation, 'Name')}</div>
                            </div>
                            {ItemsCount > 0 ? (
                                <div
                                    className="label-clickable"
                                    onClick={() => {
                                        this.setState(
                                            {
                                                selectedCategoryId: CategoryId,
                                                showNotificationsSubMenu: true,
                                                subMenuTitleComponent:
                                                    titleComponent,
                                                totalNotificationsByCategoryCount:
                                                    ItemsCount + 1,
                                            },
                                            () => {
                                                if (
                                                    listItemClassName ===
                                                    'notification-unread'
                                                ) {
                                                    callRefetchOnSubmenuClose =
                                                        true;
                                                }
                                                this.props.getNotificationsByCategoryRequestAction(
                                                    CategoryId
                                                );
                                            }
                                        );
                                    }}
                                >
                                    {getTranslatedText('+ {ItemsCount} more like this').replace('{ItemsCount}', ItemsCount.toString())}
                                </div>
                            ) : (
                                <div
                                    className="label-clickable"
                                    onClick={() => {
                                        this.handleClickViewNotification(
                                            get(Company, 'CompanyId'),
                                            OrganisationId,
                                            notificationCategory,
                                            extraProps,
                                            viewButtonClickHandlerCallback
                                        );
                                    }}
                                >
                                        {getTranslatedText('View')}
                                </div>
                            )}
                        </div>
                    </div>
                </List.Item>
            );
        };

        /**
         * Function that renders the notifications sub menu list items.
         */
        renderNotificationsSubMenuListItem = (item: any) => {
            const {
                Title,
                Description,
                CreatedDateTime,
                Company,
                OrganisationId,
                CategoryName,
                IsRead,
                ...otherNotificationProps
            } = item;
            

            const extraProps = this.getNotificationExtraProps(
                otherNotificationProps
            );

            const { faIcon, notificationCategory } =
                this.getIconAndCategory(CategoryName);

            const titleComponent = Title ? (
                <>
                    <FontAwesome icon={faIcon} className="mr-10" />
                    {getTranslatedText(Title)}
                </>
            ) : null;

            const Organisation = find(this.props.userOrganisations, [
                'OrganisationId',
                OrganisationId,
            ]);

            return (
                <List.Item
                    className={IsRead === false ? 'notification-unread' : ''}
                    key={Description}
                    style={listItemStyle}
                >
                    <div style={divContainerStyle}>
                        <div
                            className="container-bordered"
                            style={divBorderedStyle}
                        >
                            {titleComponent && <h3>{titleComponent}</h3>}
                            <div className="multiline">{this.translatedText(Description)}</div>
                            <div className="date-grey sb-flex">
                                <div className="date-width">
                                    {this.formatDateUTCToLocal(CreatedDateTime)}
                                </div>
                                <div>{get(Company, 'Name') || get(Organisation, 'Name')}</div>
                            </div>
                            <div
                                className="label-clickable"
                                onClick={() => {
                                    callRefetchOnSubmenuClose = IsRead;
                                    this.handleClickViewNotification(
                                        get(Company, 'CompanyId'),
                                        OrganisationId,
                                        notificationCategory,
                                        extraProps,
                                        () => {
                                            this.initializeCategorizedNotificationsData(
                                                false,
                                                () => {
                                                    callRefetchOnSubmenuClose =
                                                        false;
                                                }
                                            );
                                        }
                                    );
                                }}
                            >
                                {getTranslatedText('View')}
                            </div>
                        </div>
                    </div>
                </List.Item>
            );
        };

        /**
         * Function that populates skeleton loading component with optional title prop.
         */
        populateSkeletonLoading = (
            skeletonCount: number = 0,
            titleProp: boolean
        ) => {
            const count = skeletonCount === 0 ? 1 : skeletonCount;
            return (
                <>
                    {times(count, (key: number) => {
                        return (
                            <div key={key}>
                                {!titleProp && <br />}
                                <Skeleton
                                    active
                                    loading
                                    title={titleProp}
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        };

        /**
         * Function that formats UTC date to local date.
         */
        formatDateUTCToLocal = (datetime: string) => {
            return this.props.formatDateUTCToLocal(
                datetime,
                dateFormatYYYYMMDDTHHmmssDash,
                dateFormatDDMMMYYYYhmmASpace
            );
        };

        /**
         * Function for getting the categorized notifications request and toggles if new notifications API
         * should be hit as well.
         */
        getCategorizedNotificationsRequestAfterFetchNew = (
            refetchNewNotif: boolean = false,
            callback?: (notifications: any) => void
        ) => {
            this.initializeCategorizedNotificationsData(
                refetchNewNotif,
                callback
            );
        };

        render() {
            const { state, props } = this;
            const { categorizedNotifications, notificationsByCategory } = props;
            const drawerHeight = window.innerHeight - 60;
            const drawerStyle: {
                height: number;
                overflowY: 'auto';
                overflowX: 'hidden';
                paddingLeft: number;
                paddingRight: number;
            } = {
                height: drawerHeight,
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingLeft: drawerListPadding,
                paddingRight: drawerListPadding,
            };

            const notificationsList = get(categorizedNotifications, 'data', []);

            const categorizedNotificationsCount = notificationsList
                ? notificationsList.length
                : 0;
            const { unreadNotificationCategories } = this.props;

            const unreadNotificationsCount = !isEmpty(
                unreadNotificationCategories
            )
                ? unreadNotificationCategories.length
                : 0;
            return (
                <>
                    <NotificationSubscriptionService
                        onShowNotification={this.showNotification}
                        getCategorizedNotificationsRequestAction={
                            this.getCategorizedNotificationsRequestAfterFetchNew
                        }
                    />
                    <Drawer
                        title={
                            <div className="center-flex">
                                {unreadNotificationsCount > 0 && (
                                    <span>
                                        <Badge
                                            count={unreadNotificationsCount}
                                        />
                                        &nbsp;&nbsp;
                                    </span>
                                )}
                                {getTranslatedText(`Notification${unreadNotificationsCount === 1 ? '' : 's'}`)}
                            </div>
                        }
                        width={420}
                        onClose={this.onClose('showNotificationsMenu')}
                        visible={get(state, 'showNotificationsMenu', false)}
                        className="notifications-menu"
                    >
                        <div style={drawerStyle}>
                            {get(categorizedNotifications, 'loading') ? (
                                this.populateSkeletonLoading(
                                    categorizedNotificationsCount,
                                    true
                                )
                            ) : (
                                <List
                                    dataSource={notificationsList}
                                    itemLayout="vertical"
                                    renderItem={
                                        this.renderNotificationsMenuListItem
                                    }
                                    locale={{
                                        emptyText: (
                                            <Empty
                                                image={
                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                                description={getTranslatedText(get(
                                                    categorizedNotifications,
                                                    'errorMessages.0',
                                                    'No notifications found'
                                                ))}
                                            />
                                        ),
                                    }}
                                />
                            )}
                        </div>

                        <Drawer
                            // title={<div>&nbsp;</div>}
                            width={400}
                            onClose={this.onClose('showNotificationsSubMenu')}
                            visible={get(
                                state,
                                'showNotificationsSubMenu',
                                false
                            )}
                            className="notifications-menu"
                        >
                            <div
                                style={{
                                    ...drawerStyle,
                                    height: window.innerHeight - 10,
                                }}
                            >
                                {get(notificationsByCategory, 'loading') ? (
                                    this.populateSkeletonLoading(
                                        get(
                                            state,
                                            'totalNotificationsByCategoryCount',
                                            0
                                        ),
                                        true
                                    )
                                ) : (
                                    <List
                                        dataSource={get(
                                            notificationsByCategory,
                                            'data',
                                            []
                                        )}
                                        itemLayout="vertical"
                                        renderItem={
                                            this
                                                .renderNotificationsSubMenuListItem
                                        }
                                    />
                                )}
                            </div>
                        </Drawer>
                    </Drawer>
                    {this.state.showDownloadingModal && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText("Downloading report")}
                                modalVisible={this.state.showDownloadingModal}
                                displayMessage={getTranslatedText("Please wait while we get the file from storage . . .")}
                            />
                        </Suspense>
                    )}
                    <WrappedComponent
                        {...this.props}
                        openNotificationsMenu={this.openNotificationsMenu}
                    />
                </>
            );
        }
    }

    function mapStateToProps(state: ApplicationState) {
        return {
            categorizedNotifications:
                state.notifications.categorizedNotifications,
            notificationsByCategory:
                state.notifications.notificationsByCategory,
            userCompanies: state.companies.userCompanies,
            userOrganisations: state.organisations.userOrganisations,  
            selectedUserCompanyId: get(
                state.companies.selectedUserCompany,
                'Company.CompanyId'
            ),
            selectedUserOrganisationId: get(
                state.organisations.selectedUserOrganisation,
                'OrganisationId'
            ),
            companyStateLoading: state.companies.loading,
            unreadNotificationCategories:
                state.notifications.unreadNotificationCategories,
            currentUser: state.auth.currentUser,
        };
    }

    function mapDispatchToProps(dispatch: any) {
        return {
            getCategorizedNotificationsRequestAction: bindActionCreators(
                getCategorizedNotificationsRequestAction,
                dispatch
            ),
            getNotificationsByCategoryRequestAction: bindActionCreators(
                getNotificationsByCategoryRequestAction,
                dispatch
            ),
            selectUserCompanyRequestAction: bindActionCreators(
                selectUserCompanyRequestAction,
                dispatch
            ),
            selectUserOrganisationRequestAction: bindActionCreators(
                selectUserOrganisationRequestAction,
                dispatch
            ),
            markNotificationAsReadAction: bindActionCreators(
                markNotificationAsReadAction,
                dispatch
            ),
            getPresignedUrlDownloadReportReadyAction: bindActionCreators(
                getPresignedUrlDownloadReportReadyAction,
                dispatch
            ),
        };
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(withDateFormatHandler(NotificationsHandler));
};
