import { Button, Col, Modal, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { RefObject, Suspense, useRef, useState, useEffect } from 'react';
import FilterBar from '../../components/common/FilterBar';
import { PAGE_NAMES_FOR_VIEW, REPORTS_PAGE } from '../../config/tableAndPageConstants';
import { GetReportsRequestPayload, ReportHistory, ReportState } from '../../store/report/types';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { capitalize, filter, forEach, get, includes, isEmpty, isEqual, isUndefined, map, remove, some } from 'lodash';
import { initialState } from '../../store/report/reducer';
import { DynamicObject } from '../../utils/commonInterfaces';
import { CompanyUserRole } from '../../store/companies/types';
import { downloadCompleteReportAction, updateReportsFiltersAction, updateReportsSortByAndStateAction, updateReportsTableFilterAction, setSelectedReportIdRequestAction } from '../../store/report/actions';
import { checkIfEmailIsValid, computeTableScroll, downloadFileFromPresignedUrl, emptyPredefinedFilterOnAppliedFilters, getTranslatedText } from '../../utils/commonFunctions';
import { ReportType, dateSelectOptions, reportStateOptions, reportsCreatedInOptions, reportsTableFilterOptions } from '../../constants/reportSortAndFilters';
import ActionBar from '../../components/common/ActionBar';
import FontAwesome from '../../components/common/FontAwesome';
import VirtualizedList from '../../components/common/VirtualizedList';
import ReportHistoryItemComponent from '../../components/report/ReportHistoryItemComponent';
import { getSelectedReportId } from '../../store/report/sagas';
import moment from 'moment-timezone';
import { selectActionedByUserIdFilterMapping } from '../../constants/common';
import { getCurrentUser } from '../../store/users/sagas';
import { CompanyIdAttribute, IsOrganisationViewAttribute, OrganisationIdAttribute } from '../../constants/authUserAttributes';
import DownloadCollectionPanel from '../../components/common/DownloadCollectionPanel';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getRolePermissions } from '../../store/roles/sagas';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import ReportDetailsDrawerComponent from './ReportDetailsDrawerComponent';
import { getPresignedUrlDownloadReportReadyAction } from '../../store/common/actions';
import ModalWithSpinner from '../common/ModalWithSpinner';
import { Organisation } from '../../store/organisations/types';


interface IProps {
    readonly region?: string;
    readonly getReportsRequestAction: (payload: GetReportsRequestPayload) => DynamicObject;
    readonly getReportDataRequestAction: (reportJobId: string) => DynamicObject;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}

const pageName = PAGE_NAMES_FOR_VIEW.REPORT_HISTORY_PAGE;
const tablePageSize = REPORTS_PAGE.pageSize;
let skipListenToPreconfiguredFilter = false;
let initialTableFilter: undefined | string = undefined;
let fetchReportsRequired = false;

const ReportHistoryTable: React.FC<IProps> = ({
    region, getReportsRequestAction, getReportDataRequestAction, handlePageViewSelection
}) => {
    const dispatch = useDispatch();
    
    const customerLabel = useSelector(getCustomerUILabel);
    const rolePermissions = useSelector(getRolePermissions);
    const reportState: ReportState = useSelector(
        (state: ApplicationState) => state.report
    );
    const currentUser = useSelector(getCurrentUser);
    const selectedId = useSelector(getSelectedReportId);
    const selectedUserCompanyLoading: boolean = useSelector(
        (state: ApplicationState) => state.companies.loading
    );
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const allCompanyUserRoles: CompanyUserRole[] = useSelector(
        (state: ApplicationState) => state.companies.userCompanies
    );
    const selectedUserOrganisationLoading: boolean = useSelector(
        (state: ApplicationState) => state.organisations.loading
    );
    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const userRole = get(selectedUserCompany, 'Role.Name');
    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();
    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(reportState, 'pageData.currentPage', 0)
    );
    const [filterItemList, setFilterItemList] = useState<DynamicObject[]>([]);
    const [usedRowHeight, setUsedRowHeight] = useState<number>(
        REPORTS_PAGE.rowHeight
    );
    const [downloading, setDownloading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [resetTableScroll, setResetTableScroll] = useState(false);
    const [reportDetailsDrawer, setReportDetailsDrawer] = useState<{
        visible: boolean;
    }>({
        visible: false
    });
    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        sortAscending: boolean;
    }>({
        sortBy: reportState.sortBy,
        sortAscending: reportState.sortAscending
    });
    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        allSelected: boolean;
        downloadCollection: boolean;
    }>({
        filterBar: (() => {
            let hasFiltersOnRedux = false;
            forEach(reportState.filters, (filterValue: any) => {
                if (!isEmpty(filterValue)) {
                    hasFiltersOnRedux = true;
                    return false;
                }
            });
            return hasFiltersOnRedux;
        })(),
        filterEllipsis: false,
        allSelected: false,
        downloadCollection: false
    });
    const [reportTableFilter, setReportTableFilter] = useState<
        string | undefined
    >(
        isEqual(reportState.filters, initialState.filters)
            ? initialState.tableFilter
            : reportState.tableFilter
    );
    const [tableFilters, setTableFilters] = useState<any>(
        reportState.filters
    );
    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    const isPaymentPlanEnabled = selectedUserCompany ? get(
        selectedUserCompany,
        'Company.CompanyPaymentPlan.IsEnabled'
    ) : some(allCompanyUserRoles, userCompanyRole => get(
        userCompanyRole,
        'Company.CompanyPaymentPlan.IsEnabled'
    ));
    const supportCashAllocation = selectedUserCompany ? get(
        selectedUserCompany,
        'Company.SupportCashAllocation'
    ) : some(allCompanyUserRoles, userCompanyRole => get(
        userCompanyRole,
        'Company.SupportCashAllocation'
    ));

    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions((prevState) => ({
            ...prevState,
            ...showConditionObject
        }));
    };

    const resetTableScrollAndPageData = async () => {
        if (!resetTableScroll) setResetTableScroll(true);
        await setTableCurrentPage(0);
    };

    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    const resetAllSelectedRowKeys = (
        showConditionsObject: DynamicObject = {}
    ) => {
        const usedConditions = {
            ...showConditionsObject,
            allSelected: false,
        };
        updateShowConditionsObject(usedConditions);

        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: []
        });
    };

    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetReportsRequestPayload = {
            filters: reportState.filters,
            sortBy: reportState.sortBy,
            sortAscending: reportState.sortAscending,
            pageSize,
            currentPage: currentPage,
            Region: region
        };

        return payload;
    };

    const fetchReports = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        const payload = generatePayloadForRequest(currentPage, pageSize);
        if (resetTableScroll) {
            setResetTableScroll(false);
            setIsRefetching(false);
        }
        dispatch(getReportsRequestAction(payload));
    };

    const refetchListAndResetScroll = () => {
        setIsRefetching(true);
        resetTableScrollAndPageData();
        fetchReports(0);
    };

    const downloadReportHandler = (reportHistory: ReportHistory) => {
        setDownloading(true);
        dispatch(getPresignedUrlDownloadReportReadyAction({
            EntityKey: get(reportHistory, 'ReportJobId'),
            Region: region
        }, (res: DynamicObject) => {
            if (res.IsSuccess) {
                downloadFileFromPresignedUrl(
                    res.ReportGetURL,
                    () => {
                        setDownloading(false);
                    }
                );
            } else {
                setDownloading(false);
                Modal.error({
                    title: getTranslatedText("Error"),
                    content: (
                        <div>
                            <div>
                                {getTranslatedText("Failed to download the report file")}
                            </div>
                        </div>
                    ),
                    okText: getTranslatedText("OK"),
                });
            }
        }))
    };

    const onRowClick = (record: DynamicObject) => {
        dispatch(setSelectedReportIdRequestAction(get(record, 'ReportJobId')));
        setReportDetailsDrawer({ visible: true });
    };

    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];

        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys
        });
    };

    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            (isEmpty(selectedUserCompany) && isEmpty(selectedUserOrganisation)) ||
            reportState.loading
        )
            return;

        if (!reportState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchReports(nextPage);
    };

    const handleReportFilterRefresh = (
        showConditionsObject?: DynamicObject
    ) => {
        fetchReportsRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys(showConditionsObject);
        refetchListAndResetScroll();
    };

    const changeReportsTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== reportState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setReportTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === reportState.tableFilter) {
            handleReportFilterRefresh();
        } else {
            dispatch(updateReportsTableFilterAction(filter));
        }
    };

    const setTableFilterToInitialState = () => {
        if (reportState.tableFilter !== initialTableFilter) {
            setReportTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeReportsTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortState;
        await dispatch(updateReportsSortByAndStateAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    const applyFilters = async (
        filters?: GetReportsRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    reportTableFilter,
                    fromFilterBar,
                    () => {
                        changeReportsTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortState(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateReportsFiltersAction(appliedFilters));
        await resetAllSelectedRowKeys({});
    };

    const actionDisabled =
        reportState.loading || isEmpty(reportState.data);

    const populateFilterSelectDropdownRender = (menu: any) => (
        <div>
            {menu}
        </div>
    );

    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${tableCurrentPage === 0 || isRefetching ? 'list of' : 'more' } reports`;

        return loadingText;
    };

    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (name === 'downloadCollection') {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={actionDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText("Filter")}
            </Button>
        </div>
    );
    const populateTasksFilterBarFilters = () => {
        if (!isOrgView && !userRole) return;

        const pageReports = [
            { label: getTranslatedText('Active Tasks'), value: 'Active Tasks' },
            { label: getTranslatedText('Task Job History'), value: 'Task Job History' },
            { label: getTranslatedText('Delivery Histories'), value: 'Delivery Histories' },
            { label: getTranslatedText(`${capitalize(customerLabel)}s`), value: `${capitalize(customerLabel)}` },
            { label: getTranslatedText('Invoices'), value: 'Invoices' },
            { label: getTranslatedText('Credits'), value: 'Credits' },
            { label: getTranslatedText('Credit Reconciliation'), value: 'Credit Reconciliation' },
            { label: getTranslatedText(`${capitalize(customerLabel)} Assistance`), value: `${capitalize(customerLabel)} Assistance` },
            { label: getTranslatedText('Payments'), value: 'Payments' },
            { label: getTranslatedText('Users'), value: 'Users' }
        ];

        if (isPaymentPlanEnabled) {
            pageReports.push({ label: getTranslatedText('Payment Plans'), value: 'Payment Plans' });
        }

        if (supportCashAllocation) {
            pageReports.push({ label: getTranslatedText('Remittance Advices'), value: 'Remittance Advices' });
        }

        const reportTypes = [
            {
                label: getTranslatedText('Page Reports'),
                value: ReportType.Page,
                children: pageReports
            },
            {
                label: getTranslatedText('Dashboard Reports'),
                value: ReportType.Dashboard,
                children: [
                    { label: getTranslatedText('Top Invoices'), value: 'Top Invoices' },
                    { label: getTranslatedText('Top Credits'), value: 'Top Credits' },
                    { label: getTranslatedText('Top Customers'), value: 'Top Customers' },
                    { label: getTranslatedText('Customers Settlement Timeframe'), value: 'Customers Settlement Timeframe' },
                    { label: getTranslatedText('Recent Changes'), value: 'Recent Changes' },
                    { label: getTranslatedText('Tasks'), value: 'Tasks' },
                    { label: getTranslatedText('Active Tasks Per User'), value: 'Active Tasks Per User' },
                    { label: getTranslatedText('Assistance Tickets'), value: 'Assistance Tickets' },
                    { label: getTranslatedText('Sales'), value: 'Sales' },
                    { label: getTranslatedText('Statistic Changes'), value: 'Statistic Changes' },
                    { label: getTranslatedText('Notification Success'), value: 'Notification Success' },
                    { label: getTranslatedText('Invoicing And Settlements'), value: 'Invoicing And Settlements' },
                    { label: getTranslatedText('Tickets'), value: 'Tickets' },
                    { label: getTranslatedText('Users Assigned To Tickets'), value: 'Users Assigned To Tickets' },
                    { label: getTranslatedText('Total Owed'), value: 'Total Owed' },
                    { label: getTranslatedText('Notifications'), value: 'Notifications' },
                    { label: getTranslatedText('Current ATB'), value: 'Current ATB' },
                    { label: getTranslatedText('Open Invoices'), value: 'Open Invoices' },
                    { label: getTranslatedText('Payments'), value: 'Payments' },
                    { label: getTranslatedText('Communication Delivery'), value: 'Communication Delivery' },
                    { label: getTranslatedText('Payments Allocation'), value: 'Payments Allocation' },
                ]
            }
        ];

        const generatingCompleteReportRoles = rolePermissions.GENERATING_COMPLETE_REPORT;

        if (isOrgView || includes(generatingCompleteReportRoles, userRole)) {
            reportTypes.push({
                label: getTranslatedText('Complete Report'),
                value: ReportType.CompleteReport,
                children: []
            });
        }

        const filters: DynamicObject[] = [
            {
                filterName: getTranslatedText("Report Date"),
                filterStateName: 'CreatedDate',
                filterElement: 'select',
                filterType: 'text',
                filterMaxDate: moment(),
                filterOptions: reportsCreatedInOptions,
                filterOptionsCustomTypes: {
                    [dateSelectOptions.CUSTOM_DATE_RANGE]: 'date-range',
                    [dateSelectOptions.CUSTOM_DAYS_RANGE]: 'last-days-range'
                }
            },
            {
                filterName: getTranslatedText("State"),
                filterStateName: 'Status',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: reportStateOptions,
            },
            {
                filterName: getTranslatedText("Report Type"),
                filterStateName: 'ReportTypes',
                filterElement: 'checkbox-group-tree',
                filterType: 'array',
                filterOptions: reportTypes
            },
            {
                filterName: getTranslatedText("User"),
                filterStateName: 'ActionedByUserId',
                filterNameQuery: 'Name',
                filterElement: 'select-with-search',
                filterQuery: 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterResponse: 'GetUsersForCompany.CompanyUserRoles',
                filterLabelField: ['User.GivenName', 'User.FamilyName'],
                filterSort: 'Name',
                filterNameQuerySub: 'Email',
                filterSubChecking: checkIfEmailIsValid,
                filterJSONValueFieldSub: 'User.Email',
                tagClass: 'wb-bw',
                filterMappingUsed: selectActionedByUserIdFilterMapping,
            },
        ];

        if (isOrgView) {
            const validCompanies = filter(allCompanyUserRoles, cr =>
                get(cr, 'Company.OrganisationId') === get(selectedUserOrganisation, 'OrganisationId')
                && get(cr, 'Company.Region') === region);
            filters.push({
                filterName: getTranslatedText("Company"),
                filterStateName: 'CompanyIds',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: map(validCompanies, cr => ({
                    label: get(cr, 'Company.Name'),
                    value: get(cr, 'Company.CompanyId')
                }))
            });
        }

        setFilterItemList(filters);
    };

    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = reportState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchReportsRequired) {
                fetchReports(0);
                fetchReportsRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [reportState.tableFilter]);

    useEffect(() => {
        return () => {
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    useEffect(populateTasksFilterBarFilters, [
        customerLabel,
        isPaymentPlanEnabled,
        supportCashAllocation,
        region, userRole, isOrgView,
        selectedUserOrganisation
    ]);

    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending } = reportState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending,
            });
        }
    };

    /**
     * Function called to close the download collection panel
     * @param refreshList
     */
    const closeDownloadCollectionPanel =
        (conditionBool: string) => (refreshList?: boolean) => {
            updateShowConditionsObject({
                [conditionBool]: false,
            });

            if (refreshList) {
                refetchListAndResetScroll();
            }
        };

    const callFetchReports = () => {
        if (isOrgView) {
            if (!selectedUserOrganisation) return;
            const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
            const selectedOrganisationId = get(selectedUserOrganisation, 'OrganisationId');

            if (organisationIdCognito === selectedOrganisationId) {
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();

                if (!selectedUserOrganisationLoading) fetchReports(0);
            }
        } else {
            if (!selectedUserCompany) return;
            const companyIdCognito = get(currentUser, CompanyIdAttribute);
            const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

            if (companyIdCognito === selectedCompanyId) {
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();

                if (!selectedUserCompanyLoading) fetchReports(0);
            }
        }
    };

    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    useEffect(callFetchReports, [
        reportState.sortBy,
        reportState.sortAscending,
        reportState.filters,
        currentUser,
        isOrgView,
        selectedUserCompany,
        selectedUserOrganisation
    ]);

    const closeReportDetailsDrawer = () => {
        setReportDetailsDrawer({
            visible: false,
        });
    };

    const actionBarItems: DynamicObject[] = [
        {
            actionKey: 'report-filter',
            actionType: 'select-with-button',
            selectValue: reportTableFilter,
            selectOptions: reportsTableFilterOptions,
            selectDropdownRender:
                populateFilterSelectDropdownRender,
            onSelectChange: changeReportsTableFilter,
            buttonContent: (
                <>
                    <FontAwesome
                        icon={['fa', 'sync']}
                        className="mr-8"
                    />
                    <span>{getTranslatedText("Refresh")}</span>
                </>
            ),
            buttonDisabled: reportState.loading,
            onButtonClick: handleReportFilterRefresh,
        }
    ];

    if (!isOrgView) {
        actionBarItems.push({
            actionKey: 'report-download-collection',
            actionType: 'protected-drawer-button',
            allowedRoles: rolePermissions.GENERATING_COMPLETE_REPORT,
            popoverVisible: showConditions.downloadCollection,
            drawerCloseable: false,
            popoverTitle: getTranslatedText("Download Collection"),
            popoverOnVisibleChange: popoverOnVisibleChange(
                'downloadCollection',
                true
            ),
            popoverContent: (
                <DownloadCollectionPanel
                    visible={
                        showConditions.downloadCollection
                    }
                    closePanel={closeDownloadCollectionPanel(
                        'downloadCollection'
                    )}
                    dispatchAction={
                        downloadCompleteReportAction
                    }
                />
            ),
            buttonDisabled: false,
            buttonContent: (
                <>
                    <FontAwesome
                        icon={['fas', 'file-download']}
                    />
                    <span>{getTranslatedText("Download collection")}</span>
                </>
            ),
            drawerWidth: 800,
        });
    }

    return (<>
        {/* Filter Bar */}
        <QueueAnim type="top" leaveReverse duration={300}>
            {showConditions.filterBar && (
                <div key="filter-bar-container">
                    <Suspense fallback={null}>
                        <FilterBar
                            pageName={pageName}
                            loading={reportState.loading}
                            applyFilters={applyFilters}
                            filters={filterItemList}
                            filterValues={reportState.filters}
                            colDivision={4}
                            closeAllPopovers={
                                reportDetailsDrawer.visible
                            }
                            closeFilterBar={closeFilterBar}
                            doesViewNameExist={doesViewNameExist}
                            appliedView={reportTableFilter}
                            tableSortState={{
                                sortBy: reportState.sortBy,
                                sortAscending: reportState.sortAscending
                            }}
                        />
                    </Suspense>
                </div>
            )}
        </QueueAnim>
        <div key="action-bar-container">
            <ActionBar
                ref={actionBarRef}
                pageName={pageName}
                loading={reportState.loading}
                filterBarOpen={showConditions.filterBar}
                actionItems={actionBarItems}
                actionEllipsis={{
                    popoverVisible: showConditions.filterEllipsis,
                    popoverOnVisibleChange: popoverOnVisibleChange('filterEllipsis'),
                    popoverTitle: populateFilterEllipsisPopoverTitle(),
                    buttonContent: (
                        <FontAwesome icon={['fas', 'ellipsis-h']} />
                    ),
                }}
            />
        </div>
        <div className="spacer-15" />
        {/* Table Section */}
        <Row key="table-container">
            <Col span={24}>
                <VirtualizedList
                    dataSource={reportState.data}
                    fetchMore={handleFetch}
                    scroll={computeTableScroll(
                        window.innerHeight - 185,
                        tablePageSize,
                        usedRowHeight
                    )}
                    resetTableScroll={resetTableScroll}
                    selectedRowKeys={tableRowSelection.selectedRowKeys}
                    rerenderTrigger={tableRowSelection.selectedRowKeys}
                    onRowClick={onRowClick}
                    onCheckboxClick={onCheckboxClick}
                    loading={reportState.loading}
                    loadingText={populateTableLoadingText()}
                    emptyText={
                        !isEmpty(reportState.errorMessages)
                            ? get(reportState, 'errorMessages.0')
                            : "No reports found"
                    }
                    hasNextPage={reportState.pageData.hasNextPage}
                    itemComponent={ReportHistoryItemComponent}
                    itemHeight={usedRowHeight}
                    selectedId={selectedId}
                    extraData={{
                        downloadReportHandler
                    }}
                />
            </Col>
        </Row>
        <Suspense fallback={null}>
            <ReportDetailsDrawerComponent
                region={region}
                visible={reportDetailsDrawer.visible}
                closeDrawer={closeReportDetailsDrawer}
                refetchList={refetchListAndResetScroll}
                getReportDataRequestAction={getReportDataRequestAction}
            />
        </Suspense>
        {downloading && (
            <Suspense fallback={null}>
                <ModalWithSpinner
                    modalTitle={getTranslatedText("Downloading report")}
                    modalVisible={downloading}
                    displayMessage={getTranslatedText("Please wait while we get the file from storage")}
                />
            </Suspense>
        )}
    </>)
};

export default withPageViewHandler(
    ReportHistoryTable
);
