/**
 * Component responsible for populating the Assign to panel.
 */

import { Button, Col, Form, Modal, Row } from 'antd';
import { get, isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import {
    getUserByEmailAction,
    getUserByNameAction,
} from '../../../store/users/actions';
import {
    checkIfEmailIsValid,
    getPopoverContainer,
    getTranslatedText
} from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import InputAutoCompleteWithButton from '../../common/InputAutoCompleteWithButton';


const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);

const FormItem = Form.Item;
interface IProps {
    onSaveClick: (values: DynamicObject) => void;
    onCancelClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    containerRef?: any;
    form: any;
    visible: boolean;
    getSelectedTasksValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
    };
}
let listenForSaveTaskLoading: boolean = false;
const filterStateName = 'Name';
const filterQuery = 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER';
const filterSort = 'Name';
const filterResponse = 'GetUsersForCompany.CompanyUserRoles';
const filterLabelField = ['User.GivenName', 'User.FamilyName'];
const filterNameInQuery = 'Name';
const filterNameInQuerySub = 'Email';
const TaskAssignToDrawerContent: React.FC<IProps> = (props: IProps) => {
    
    const dispatch = useDispatch();
    const selectedUserCompany = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const saveTaskDataLoading: boolean = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks.saveTaskDataLoading
    );
    const [taskAssignToProperties, setTaskAssignToProperties] = useState<{
        loading: boolean;
        fetchingUser: boolean;
        value: string;
    }>({
        loading: false,
        fetchingUser: false,
        value: '',
    });

    /**
     * Function for updating the `taskAssignToProperties` state.
     * @param taskAssignToPropertiesObject - must conform to `taskAssignToProperties` state
     */
    const updateTaskAssignToProperties = (taskAssignToPropertiesObject: {}) => {
        setTaskAssignToProperties({
            ...taskAssignToProperties,
            ...taskAssignToPropertiesObject,
        });
    };

    const { validateFields } = props.form;

    /**
     * Function called when the `Save` button is clicked.
     * @param e
     */
    const handleFormSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        validateFields((err: any) => {
            if (!err) {
                const inputValue = taskAssignToProperties.value;
                const isValidEmail = checkIfEmailIsValid(inputValue);
                updateTaskAssignToProperties({ loading: true });
                const companyEnabledExternalTaskAssignment =
                    get(
                        selectedUserCompany,
                        'Company.EnableExternalUserTaskAssignment'
                    ) === true;

                if (companyEnabledExternalTaskAssignment) {
                    if (isValidEmail) {
                        return dispatch(
                            getUserByEmailAction(
                                inputValue,
                                (res: DynamicObject) => {
                                    if (get(res, 'IsSuccess') === true) {
                                        const userId = get(res, 'User.UserId');
                                        if (userId) {
                                            return props.onSaveClick({
                                                AssignedUserId: userId,
                                            });
                                        }
                                    }

                                    return props.onSaveClick({
                                        AssignedEmailAddress: inputValue,
                                    });
                                }
                            )
                        );
                    }
                }

                // props.onSaveClick(userId);
                dispatch(
                    getUserByNameAction(inputValue, (res: DynamicObject) => {
                        if (get(res, 'IsSuccess') === true) {
                            const userId = get(res, 'User.UserId');
                            if (userId) {
                                return props.onSaveClick({
                                    AssignedUserId: userId,
                                });
                            }
                        }

                        updateTaskAssignToProperties({ loading: false });
                        let errorMessageContent: any =
                            companyEnabledExternalTaskAssignment &&
                            !isValidEmail ? (
                                    <div>{getTranslatedText('Email entered is not valid!')}</div>
                            ) : (
                                <div>
                                    <div>{getTranslatedText('Unable to find a matching user!')}</div>
                                    <div>
                                        {getTranslatedText('Please enter in the name again and confirm the correct spelling.')}
                                    </div>
                                </div>
                            );
                        const messages = get(res, 'Messages');
                        if (!isEmpty(messages)) {
                            errorMessageContent = map(
                                messages,
                                (error: string, index: number) => (
                                    <div key={index}>{getTranslatedText(error)}</div>
                                )
                            );
                        }

                        Modal.error({
                            title: getTranslatedText('Error'),
                            content: errorMessageContent,
                            getContainer: () =>
                                getPopoverContainer(props.containerRef),
                            okText: getTranslatedText('OK'),
                        });
                    })
                );
            }
        });
    };

    /**
     * Function for handling the loading modal based on redux state and component state.
     */
    const loadingFromReduxListener = () => {
        if (
            taskAssignToProperties.loading &&
            !saveTaskDataLoading &&
            listenForSaveTaskLoading
        ) {
            listenForSaveTaskLoading = false;
            updateTaskAssignToProperties({ loading: false });
        }
        if (taskAssignToProperties.loading && saveTaskDataLoading) {
            listenForSaveTaskLoading = true;
        }
    };

    useEffect(loadingFromReduxListener, [
        taskAssignToProperties.loading,
        saveTaskDataLoading,
    ]);

    /**
     * Function for populating the loading message.
     */
    const populateLoadingMessage = () => {
        const { keysToUse } = props.getSelectedTasksValues();
        return `Please wait while assigning task${
            keysToUse.length > 1 ? 's' : ''
        } to user. . .`;
    };

    const isSaveBtnDisabled = isEmpty(taskAssignToProperties.value);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
            md: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
            md: { span: 21 },
        },
    };

    if (!props.visible) {
        return null;
    }

    return (
        <Form className="form-inline-mb-8" {...formItemLayout}>
            <Row type="flex" align="middle">
                <Col span={24}>
                    <FormItem label={getTranslatedText("User")}>
                        <InputAutoCompleteWithButton
                            updateField={(value: string) => {
                                updateTaskAssignToProperties({ value });
                            }}
                            stateValue={taskAssignToProperties.value}
                            queryName={filterQuery}
                            filterField={filterStateName}
                            queryFilterName={filterNameInQuery}
                            sortField={filterSort}
                            responseName={filterResponse}
                            labelField={filterLabelField}
                            hasNoOkButton
                            filterSubChecking={checkIfEmailIsValid}
                            queryFilterNameSub={filterNameInQuerySub}
                            loading={taskAssignToProperties.loading}
                        />
                    </FormItem>
                </Col>
            </Row>
            {taskAssignToProperties.loading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Assigning to user")}
                        modalVisible={taskAssignToProperties.loading}
                        displayMessage={getTranslatedText(populateLoadingMessage())}
                    />
                </Suspense>
            )}
            <div className="mt-10 ta-right">
                <Button
                    className="mr-10"
                    onClick={handleFormSave}
                    disabled={isSaveBtnDisabled}
                    type="primary"
                >
                    {getTranslatedText('Assign')}
                </Button>
                <Button className="buttonGrey" onClick={props.onCancelClick}>
                    {getTranslatedText('Cancel')}
                </Button>
            </div>
        </Form>
    );
};

const TaskAssignToDrawerContentForm = Form.create({
    name: 'assign-tasks-to-user-form',
})(TaskAssignToDrawerContent);

export default withRouter(TaskAssignToDrawerContentForm);
