import update from 'immutability-helper';
import { Reducer } from 'redux';
import { RemittanceAdvicesActionTypes, RemittanceAdvicesState } from './types';
import { remittanceAdvicesSortByOptions, remittanceAdviceChangesTableFilterOptions, remittanceAdviceStateFilterOptions } from '../../constants/remittanceAdvicesSortAndFilter';
import { REMITTANCE_ADVICES_PAGE, DETAILS_TAB } from '../../config/tableAndPageConstants';
import { concat, isEqual, keyBy, merge, uniqWith, values, get, isEmpty } from 'lodash';
import {
    invoicesSortByOptions,
    invoicesStateFilterOptions,
} from '../../constants/invoicesSortAndFilters';
import { conversationTableFilterOptions } from '../../constants/common';
import { creditsSortByOptions, creditsStateFilterOptions, rebatesSortByOptions, rebatesStateFilterOptions } from '../../constants/creditsSortAndFilters';
import { customersSortByOptionsMap } from '../../constants/customersSortAndFilters';

// Type-safe initialState!
export const initialState: RemittanceAdvicesState = {
    loading: false,
    errorMessages: [],
    activeData: {
        customers: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CUSTOMER_LIST_COMPLETE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {
                Customer: '',
                CustomerCode: ''
            },
            customerState: '',
            sortBy: customersSortByOptionsMap.customerCode.value,
            sortAscending: true,
        },
        invoices: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {
                InvoiceNumber: '',
                Customer: '',
                CustomerCode: '',
                CreatedDateMin: '',
                CreatedDateMax: '',
                DueDateMin: '',
                DueDateMax: '',
                AmountType: '',
                AmountValue: '',
                AmountOperator: '',
            },
            invoiceState: get(invoicesStateFilterOptions, `${1}.value`),
            sortBy: invoicesSortByOptions[0].value, // 2 for Invoice number
            sortAscending: true,
        },
        credits: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CREDIT_LIST_COMPLETE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            creditState: get(creditsStateFilterOptions, `${1}.value`),
            sortBy: creditsSortByOptions[0].value,
            sortAscending: true,
        },
        rebates: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CREDIT_LIST_COMPLETE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            creditState: get(rebatesStateFilterOptions, `${1}.value`),
            sortBy: rebatesSortByOptions[0].value,
            sortAscending: true,
        },
        conversation: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            conversationTableFilter: get(
                conversationTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        remittanceAdviceChanges: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            remittanceAdviceChangesTableFilter: get(
                remittanceAdviceChangesTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        Allocation: {
            AllocationErrors: [],
            AllocationItems: [],
        },
        record: undefined,
        loading: false,
        selectedId: null,
        errorMessages: [],
        
        payments: []
        // {
        //     loading: false,
        //     errorMessages: [],
        //     data: [],
        //     pageData: {
        //         pageSize: REMITTANCE_ADVICES_PAGE.pageSize,
        //         currentPage: 0,
        //         hasNextPage: false,
        //     },
        //     filters: {},
        //     sortBy: remittanceAdvicesSortByOptions[1].value,
        //     sortAscending: false,
        // },
    },
    data: [],
    pageData: {
        pageSize: REMITTANCE_ADVICES_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {},
    tableFilter: undefined,
    SortField: remittanceAdvicesSortByOptions[1].value, //1 for received date
    sortAscending: false, // Descending order by default
    raProcessingConfiguration: {
        saveLoading: false,
        loading: false,
        errorMessages: [],
        data: undefined
    },
    ActiveState: get(remittanceAdviceStateFilterOptions, `${0}.value`),
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<RemittanceAdvicesState> = (state = initialState, action) => {
    switch (action.type) {
        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICES_REQUEST: {
            return { ...state, loading: true };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.mergeData === true) {
                newDataState = values(
                    merge(
                        keyBy(state.data, 'Id'),
                        keyBy(action.payload.data, 'Id')
                    )
                );
            } else {
                if (action.payload.pageData.currentPage === 0) {
                    newDataState = update(state.data, {
                        $set: action.payload.data,
                    });
                } else {
                    newDataState = uniqWith(
                        concat(state.data, action.payload.data),
                        isEqual
                    );
                }
            }

            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_SORT_BY_AND_STATE: {
            return {
                ...state,
                SortField: action.payload.SortField,
                sortAscending: action.payload.sortAscending,
                ActiveState: action.payload.ActiveState,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_FILTERS: {
            return { ...state, filters: action.payload };
        }
        
        
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }

        //Single Record
        case RemittanceAdvicesActionTypes.SET_REMITTANCE_ADVICE_SELECTED_ID_REQUEST: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: initialState.activeData.selectedId,
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.SET_REMITTANCE_ADVICE_SELECTED_ID_SUCCESS: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: action.payload,
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_DETAILS_REQUEST: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: true },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_DETAILS_SUCCESS: {
            const newActiveData = update(state.activeData, {
                record: { $set: action.payload.record },
                payments: {$set: action.payload.payment},
                loading: { $set: false },
                errorMessages: { $set: initialState.activeData.errorMessages },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_DETAILS_ERROR: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: false },
                errorMessages: { $set: action.payload },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_SUMMARY_REQUEST: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: true },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_SUMMARY_SUCCESS: {
            const newActiveData = update(state.activeData, {
                record: { $set: action.payload.record },
                payments: {$set: action.payload.payment},
                loading: { $set: false },
                errorMessages: { $set: initialState.activeData.errorMessages },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_SUMMARY_ERROR: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: false },
                errorMessages: { $set: action.payload },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }


        case RemittanceAdvicesActionTypes.CLEAR_REMITTANCE_ADVICE_SELECTED_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        // For customers list reducers
        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CUSTOMERS_REQUEST: {
            const newCustomersState = update(state.activeData.customers, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                customers: { $set: newCustomersState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CUSTOMERS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.customers.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.customers.data, action.payload.data),
                    isEqual
                );
            }

            const newInvoicesState = update(state.activeData.customers, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.invoices.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                customers: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CUSTOMERS_ERROR: {
            const newCustomersState = update(state.activeData.customers, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.customers.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                customers: { $set: newCustomersState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CUSTOMERS_FILTERS: {
            const newCustomersState = update(state.activeData.customers, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                customers: { $set: newCustomersState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CUSTOMERS_SORT_BY_AND_STATE: {
            const newCustomersState = update(state.activeData.customers, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    customerState: action.payload.customerState,
                },
            });

            const newActiveData = update(state.activeData, {
                customers: { $set: newCustomersState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CUSTOMERS_TABLE_FILTER_STATE: {
            const newCustomersState = update(state.activeData.customers, {
                $merge: {
                    customerState: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                customers: { $set: newCustomersState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For invoices list reducers
        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_INVOICES_REQUEST: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_INVOICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.invoices.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.invoices.data, action.payload.data),
                    isEqual
                );
            }

            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.invoices.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_CONFRIM_REMITTANCE_ADVICE_INVOICES_SUCCESS: {
            let newDataState = [];
            newDataState = update(state.activeData.invoices.data, {
                $set: action.payload.data,
            });

            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.invoices.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_CONFRIM_REMITTANCE_ADVICE_CREDITS_SUCCESS: {
            let newDataState = [];
            newDataState = update(state.activeData.credits.data, {
                $set: action.payload.data,
            });

            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.credits.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_CONFRIM_REMITTANCE_ADVICE_REBATES_SUCCESS: {
            let newDataState = [];
            newDataState = update(state.activeData.rebates.data, {
                $set: action.payload.data,
            });

            const newRebateState = update(state.activeData.rebates, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.rebates.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebateState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_INVOICES_ERROR: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.invoices.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_INVOICES_FILTERS: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_INVOICES_SORT_BY_AND_STATE: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    invoiceState: action.payload.invoiceState,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_INVOICES_TABLE_FILTER_STATE: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    invoiceState: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

         // For credits list reducers
         case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CREDITS_REQUEST: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CREDITS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.credits.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.credits.data, action.payload.data),
                    isEqual
                );
            }

            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.credits.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CREDITS_ERROR: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.credits.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CREDITS_FILTERS: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CREDITS_SORT_BY_AND_STATE: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    creditState: action.payload.creditState,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CREDITS_TABLE_FILTER_STATE: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    creditState: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

         // For rebates list reducers
         case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_REBATES_REQUEST: {
            const newRebatesState = update(state.activeData.rebates, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebatesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_REBATES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.rebates.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.rebates.data, action.payload.data),
                    isEqual
                );
            }

            const newRebatesState = update(state.activeData.rebates, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.rebates.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebatesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_REBATES_ERROR: {
            const newRebatesState = update(state.activeData.rebates, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.rebates.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebatesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_REBATES_FILTERS: {
            const newRebatesState = update(state.activeData.rebates, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebatesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_REBATES_SORT_BY_AND_STATE: {
            const newRebatesState = update(state.activeData.rebates, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    creditState: action.payload.creditState,
                },
            });

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebatesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_REBATES_TABLE_FILTER_STATE: {
            const newRebatesState = update(state.activeData.rebates, {
                $merge: {
                    creditState: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                rebates: { $set: newRebatesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For conversation list reducers
        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CONVERSATION_REQUEST: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CONVERSATION_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.conversation.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.conversation.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.conversation.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CONVERSATION_ERROR: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.conversation.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CONVERSATION_FILTERS: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CONVERSATION_TABLE_FILTER: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    conversationTableFilter: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CONVERSATION_SORT_BY: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

         // For remittanceAdvice changes list reducers
         case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CHANGES_REQUEST: {
            const newRemittanceAdviceChangesState = update(
                state.activeData.remittanceAdviceChanges,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                remittanceAdviceChanges: { $set: newRemittanceAdviceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CHANGES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.remittanceAdviceChanges.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.remittanceAdviceChanges.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newRemittanceAdviceChangesState = update(
                state.activeData.remittanceAdviceChanges,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeData.remittanceAdviceChanges
                                .errorMessages,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                remittanceAdviceChanges: { $set: newRemittanceAdviceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CHANGES_ERROR: {
            const newRemittanceAdviceChangesState = update(
                state.activeData.remittanceAdviceChanges,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeData.remittanceAdviceChanges.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                remittanceAdviceChanges: { $set: newRemittanceAdviceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CHANGES_FILTERS: {
            const newRemittanceAdviceChangesState = update(
                state.activeData.remittanceAdviceChanges,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                remittanceAdviceChanges: { $set: newRemittanceAdviceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CHANGES_TABLE_FILTER: {
            const newRemittanceAdviceChangesState = update(
                state.activeData.remittanceAdviceChanges,
                {
                    $merge: {
                        remittanceAdviceChangesTableFilter: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                remittanceAdviceChanges: { $set: newRemittanceAdviceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CHANGES_SORT_BY: {
            const newRemittanceAdviceChangesState = update(
                state.activeData.remittanceAdviceChanges,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                remittanceAdviceChanges: { $set: newRemittanceAdviceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case RemittanceAdvicesActionTypes.REVIEW_REMITTANCE_ADVICE_SUCCESS: {
            const newAllocation = update(state.activeData.Allocation, 
            {
                 $merge: {
                    AllocationErrors: action.payload.AllocationErrors,
                    AllocationItems: action.payload.AllocationItems,
                },
            });

            const newActiveData = update(state.activeData, {
                Allocation: { $set: newAllocation },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For remittance advice processing config reducers
        case RemittanceAdvicesActionTypes.GET_RA_PROCESSING_CONFIGURATION_REQUEST: {
            const newRemittanceAdviceState = update(state.raProcessingConfiguration, {
                $merge: {
                    loading: true,
                },
            });

            return {
                ...state,
                raProcessingConfiguration: newRemittanceAdviceState,
            };
        }

        case RemittanceAdvicesActionTypes.GET_RA_PROCESSING_CONFIGURATION_SUCCESS: {
            const newRemittanceAdviceState = update(state.raProcessingConfiguration, {
                $merge: {
                    loading: false,
                    data: action.payload
                },
            });

            return {
                ...state,
                raProcessingConfiguration: newRemittanceAdviceState,
            };
        }

        case RemittanceAdvicesActionTypes.GET_RA_PROCESSING_CONFIGURATION_ERROR: {
            const newRemittanceAdviceState = update(state.raProcessingConfiguration, {
                $merge: {
                    loading: false,
                    errorMessages: action.payload
                },
            });

            return {
                ...state,
                raProcessingConfiguration: newRemittanceAdviceState,
            };
        }

        case RemittanceAdvicesActionTypes.SAVE_RA_PROCESSING_CONFIGURATION_REQUEST: {
            const newRemittanceAdviceState = update(state.raProcessingConfiguration, {
                $merge: {
                    saveLoading: true,
                    data: action.payload
                },
            });

            return {
                ...state,
                raProcessingConfiguration: newRemittanceAdviceState,
            };
        }
        case RemittanceAdvicesActionTypes.SAVE_RA_PROCESSING_CONFIGURATION_RESPONSE: {
            const newRemittanceAdviceState = update(state.raProcessingConfiguration, {
                $merge: {
                    saveLoading: false,
                    data: action.payload
                },
            });

            return {
                ...state,
                raProcessingConfiguration: newRemittanceAdviceState,
            };
        }
        case RemittanceAdvicesActionTypes.SAVE_RA_REBATE_MAPPING_CONFIGURATION_REQUEST: {
            return { ...state, loading: true };
        }
        case RemittanceAdvicesActionTypes.SAVE_RA_REBATE_MAPPING_CONFIGURATION_RESPONSE: {
            return { ...state, loading: false };
        }
        
        default: {
            return state;
        }

    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as remittanceAdvicesReducer };
