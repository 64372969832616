import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import API, { graphqlOperation } from '@aws-amplify/api';

import { SmsProviderActionTypes } from './types';
import { getSmsCustomizationProviderFailAction, getSmsCustomizationProviderSuccessAction, saveSmsCustomizationProviderResponseAction } from './actions';

import queries from '../../graphql/queries.graphql';
import { ApplicationState } from '..';
import { DynamicObject } from '../../utils/commonInterfaces';
import { API_NAME } from '../../config/config';
import { get } from 'lodash';

function* handleGetSmsProviderRequest() {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_SMS_CUSTOMIZATION_PROVIDER)
        );

        if (res.error) {
            console.log('res.error', res.error);
            yield put(getSmsCustomizationProviderFailAction())
        } else {
            const { GetSmsCustomizationProvider } = res.data;
            yield put(getSmsCustomizationProviderSuccessAction(GetSmsCustomizationProvider));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for saving data.
 */
function* handleSaveSmsProviderRequest({ payload: sagaPayload }: any) {
    const { SmsCustomization, callback } = sagaPayload;
    try {
        yield call([API, 'post'], API_NAME, '/company/smscustomizationprovider/save', {
            body: SmsCustomization,
        });

        yield put(saveSmsCustomizationProviderResponseAction());

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
        // calls for queries if needed
    } catch (err) {
        yield put(saveSmsCustomizationProviderResponseAction());
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetSmsProviderRequest() {
    yield takeLatest(
        SmsProviderActionTypes.GET_SMS_CUSTOM_PROVIDER_REQUEST,
        handleGetSmsProviderRequest
    );
}

function* watchSaveUserCompanyRequest() {
    yield takeLatest(
        SmsProviderActionTypes.SAVE_SMS_CUSTOM_PROVIDER_REQUEST,
        handleSaveSmsProviderRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* smsProvidersSaga() {
    yield all([fork(watchGetSmsProviderRequest)]);
    yield all([fork(watchSaveUserCompanyRequest)]);
}

export default smsProvidersSaga;
