import { Col, Row, Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React from 'react';
import { getScheduledReportDataRequestAction, getScheduledReportsRequestAction } from '../../store/scheduledReports/actions';
import ScheduledReportTable from '../../components/report/ScheduledReportTable';

const { Title } = Typography;

interface IProps { }

const ScheduledReportsManagementPage: React.FC<IProps> = ({ }) => {

    return (<>
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container" type="flex" justify="space-between">
                    <Col>
                        <Title level={3}>Scheduled Reports</Title>
                    </Col>
                </Row>
                <div className="spacer-10" />
                <div className="spacer-10" />
                <ScheduledReportTable
                    getScheduledReportsRequestAction={getScheduledReportsRequestAction}
                    getScheduledReportDataRequestAction={getScheduledReportDataRequestAction}
                />
            </QueueAnim>
        </Col>
    </>)
};

export default ScheduledReportsManagementPage;
