/**
 * Common component for Action panel.
 */

import { Button, Col, Modal, Row } from 'antd';
import { isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer } from '../../utils/commonFunctions';
import { getTranslatedText } from '../../utils/commonFunctions';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly getFilterPayload: () => any;
    readonly dispatchAction: (payload: any) => void;
    readonly getConfirmActionLabels: () => any;
}

const ConfirmActionPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    getFilterPayload,
    dispatchAction,
    getConfirmActionLabels,
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    if (!visible) return null;
    const {
        successTitle,
        successContent,
        errorTitle,
        errorContent,
        confirmationText,
        loadingTitle,
        loadingContent,
        okButtonText,
        cancelButtonText,
    } = getConfirmActionLabels();

    /**
     * Function called when `No/Cancel` button is clicked inside this action panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        setSubmitLoading(true);
        const filterPayload = getFilterPayload();
        dispatch(
            dispatchAction({
                ...filterPayload,
                callback: handleActionResponseModal,
            })
        );
    };

    /**
     * Function responsible for showing the response modal after  the action.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const handleActionResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText(`${successTitle || 'Success'}`),
                content: getTranslatedText(successContent),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent: any = getTranslatedText(errorContent);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText(`${errorTitle || 'Error'}`),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }
    };

    return (
        <Row>
            <Col>
                <div>{getTranslatedText(confirmationText)}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            {getTranslatedText(`${okButtonText || 'Yes'}`)}
                        </Button>
                        <Button onClick={handleClosePanel}>
                            {getTranslatedText(`${cancelButtonText || 'No'}`)}
                        </Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText(loadingTitle)}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText(loadingContent)}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

export default withRouter(ConfirmActionPanel);
