import { checkIfDateHasPassed, checkIfEmailIsValid, insertAt } from '../utils/commonFunctions';
import { dateSelectOptions } from './invoicesSortAndFilters';
import {
    DynamicObject
} from '../utils/commonInterfaces';
import {
    filter,
    forEach,
    get,
    map
} from 'lodash';
import { selectUserFilterMapping } from '../constants/common';
import {
    invoicesAmountOperatorOptions,
    invoicesSortValues,
} from '../constants/invoicesSortAndFilters';

export const preConfiguredFilters = {
    ALL_ACTIVE_TASKS: 'All active tasks',
    ALL_CUSTOMER_TICKET_TASKS: 'All assistance/ticket tasks',
    ALL_READY_AUTOMATED_TASKS: 'All ready - automated tasks',
    ALL_READY_MANUAL_TASKS: 'All ready - manual tasks',
    ALL_APPROVAL_TASKS: 'All approval tasks',
    ALL_PAYMENT_PLAN_TASKS: 'All payment plan tasks',
    ALL_READY_ESCALATE_TO_COLLECTIONS: 'All ready - escalate to collections',
    ALL_PAYMENT_ALLOCATION_TASKS: 'All payment allocation tasks',
};

export const paymentPlanPreConfiguredFilters = [preConfiguredFilters.ALL_PAYMENT_PLAN_TASKS];
export const paymentAllocationPreConfiguredFilters = [preConfiguredFilters.ALL_PAYMENT_ALLOCATION_TASKS];

export const tasksSortByOptions = [
    // { label: 'Priority', value: 'Priority' },
    { label: 'Type', value: 'Type' },
    { label: 'Ready state', value: 'ActionDate' },
    { label: 'Company name', value: 'CompanyName' },
    { label: 'Customer code', value: 'CustomerCode' },
    { label: 'Workflow step', value: 'StateName' },
    { label: 'Invoice number', value: 'Number' },
    { label: 'Amount', value: 'SortAmount' },
    { label: 'Action with', value: 'ActionWith' },
];

export const tasksStatusFilterOptions = [
    { label: 'All', value: -1 },
    { label: 'In progress', value: 0 },
    { label: 'Available', value: 1 },
    // { label: 'On hold', value: 2 },
    // { label: 'Closed', value: 3 },
];

export const tasksAmountType = [
    { label: 'Amount', value: 'Amount' },
    // { label: 'Total', value: 'Total' },
];

export const paymentPlanTaskTypes = ['Payment instalment', 'Payment plans'];
export const newInvoiceEmailTaskType = 'New invoice emails';
export const paymentAllocationTaskType = 'Payment allocation';

export const tasksTypeOptions = [
    {
        label: 'Automated communication',
        value: 1,
    },
    {
        label: 'Assistance/Tickets',
        value: 2,
    },
    {
        label: 'Escalate to collections',
        value: 4,
    },
    {
        label: 'Payment instalment',
        value: 8,
    },
    {
        label: 'New invoice emails',
        value: 16,
    },
    {
        label: 'Payment plans',
        value: 32,
    },
    {
        label: 'Payment allocation',
        value: 64,
    },
    {
        label: 'Payment verification',
        value: 128,
    },
    {
        label: 'Remittance advice',
        value: 256,
    },
    {
        label: 'Payment behavior insights',
        value: 512,
    },
    {
        label: 'Phone call',
        value: 1024,
    },
    {
        label: 'Credit limit',
        value: 2048,
    }

    // {
    //     label: 'Approval',
    //     value: 1,
    // },
    // {
    //     label: 'Manual communication',
    //     value: 8,
    // },
];

const {
    NOW,
    WITHIN_THIS_WEEK,
    WITHIN_NEXT_7_DAYS,
    LAST_WEEK_UP_TO_NOW,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
    TODAY,
    THIS_MONTH,
    LAST_MONTH,
    UNDEFINED
} = dateSelectOptions;
export const tasksReadyStateOptions = [
    NOW,
    WITHIN_THIS_WEEK,
    WITHIN_NEXT_7_DAYS,
    LAST_WEEK_UP_TO_NOW,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

// TASK HISTORY
export const paymentPlanTaskHistoryType = 'Payment plans';
export const paymentAllocationTaskHistoryType = 'Payment allocations';

export const taskHistoryTypeOptions = [
    // { label: 'Approval', value: 32 },
    {
        label: 'Automated communication',
        value: 1,
    },
    { label: 'Manual communication', value: 4096 },
    { label: 'Customer ticket', value: 2 },
    { label: 'Task changes', value: 8 },
    { label: 'Escalate to collections', value: 4 },
    { label: 'Payment plans', value: 16 },
    { label: 'Payment verification', value: 32 },
    { label: 'Remittance advice', value: 64 },
    { label: 'Payment allocation', value: 128 }
];

export const taskHistoryActionedDateOptions = [
    TODAY,
    WITHIN_THIS_WEEK,
    THIS_MONTH,
    LAST_MONTH,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const taskHistorySortByOptions = [
    { label: 'Type', value: 'Type' },
    { label: 'Date actioned', value: 'BatchActionDate' },
    { label: 'User', value: 'User' },
    { label: 'Status', value: 'Status' },
];

export const preConfiguredTaskHistoryFilters = {
    ALL_ACTIONED_TASKS: 'All actioned tasks',
    ALL_TASKS_ACTIONED_TODAY: 'All tasks actioned today',
    ALL_TASKS_ACTIONED_THIS_WEEK: 'All tasks actioned this week',
};

// TASK HISTORY ITEM DETAILS
// Notification
export const taskHistoryItemDetailsNotificationSortOptions = [
    // { label: 'Type', value: 'Type' },
    { label: 'Customer code', value: 'CustomerCode' },
    { label: 'Customer name', value: 'CompanyName' },
    { label: 'Workflow step', value: 'StateName' },
    { label: 'Status', value: 'Status' },
];

// Notification
export const taskHistoryItemDetailsDeliveryReportSortOptions = [
    // { label: 'Type', value: 'Type' },
    { label: 'Customer code', value: 'CustomerCode' },
    { label: 'Customer name', value: 'CompanyName' },
    { label: 'Workflow step', value: 'StateName' },
];

export const paymentPlanApprovalTriggers = {
    Approve: 'Approve plan',
    Reject: 'Reject plan'
};

export const taskHistoryBatchTypes = {
    Communication: 'Communication',
    Query: 'Query',
    Changed: 'Changed',
    Ticket: 'Ticket',
    Collection: 'Collection',
    PaymentSchedule: 'PaymentSchedule',
    PaymentPlan: 'PaymentPlan',
    NewInvoiceEmail: 'NewInvoiceEmail',
    PaymentVerification: 'PaymentVerification',
    PaymentAllocation: 'PaymentAllocation',
    PaymentManualAllocation: 'PaymentManualAllocation',
    RemittanceAdvice: 'RemittanceAdvice',
    PaymentBehaviourInsights: 'PaymentBehaviourInsights',
    PhoneCall: 'PhoneCall',
    CreditLimit: 'CreditLimit'
};

export const actionDateMovedByTypes = {
    PromiseToPay: 'PromiseToPay'
};

export const taskHistoryBatchStates = {
    InProgress: {
        label: 'In progress',
        value: 'InProgress',
    },
    Completed: { label: 'Completed', value: 'Completed' },
    Failed: { label: 'Failed', value: 'Failed' },
    Skipped: { label: 'Skipped', value: 'Skipped' },
};

export const taskHistoryAutomation = {
    Automatic: 'Automatic',
    Manual: 'Manual',
    Hybrid: 'Hybrid',
};

export const ContactFilterOptions: { [key: string]: number } = {
    Email: 1,
    Mobile: 2,
    PostalAddress: 4,
};

export const taskHistoryDrawerTypes = {
    AutomatedCommunication: 'AutomatedCommunication',
    ChangedTasks: 'ChangedTasks',
    ResolvedTicket: 'ResolvedTicket',
    Collections: 'Collections',
    PaymentPlan: 'PaymentPlan',
    PaymentPlanApproval: 'PaymentPlanApproval',
    PaymentAllocation: 'PaymentAllocation',
    RemittanceAdvice:'RemittanceAdvice',
    PaymentVerification: 'PaymentVerification',
    PaymentBehaviourInsights: 'PaymentBehaviourInsights',
    PhoneCall: 'PhoneCall',
    ClosedNewInvoice: 'ClosedNewInvoice',
};

export const communicationState = {
    Pending: 0,
    InProgress: 1,
    Processed: 2,
    Failed: 3,
};

export const taskStatusFilterOptions = [
    {
        label: 'Sent',
        value: communicationState.Processed,
    },
    {
        label: 'Pending',
        value: communicationState.Pending,
    },
    {
        label: 'Failed',
        value: communicationState.Failed,
    },
];

export const taskItemColorsAndLabel = (
    type: string,
    stateName: string,
    isTaskReady: boolean,
    actionDate: string
) => {
    let itemColor = 'green';
    let readyLabel = 'Ready since';
    let iconCircleColor = 'green';
    let iconContentColor = 'green';

    if (type === taskHistoryBatchTypes.Ticket) {
        iconCircleColor = 'orange';
        iconContentColor = 'orange';
    } else if (type === taskHistoryBatchTypes.Collection) {
        iconCircleColor = 'red';
        iconContentColor = 'red';
    }

    if (!isTaskReady) {
        itemColor = 'black';
        readyLabel = checkIfDateHasPassed(actionDate)
            ? 'Ready since'
            : 'Pending until';
        iconContentColor = 'greyb3';
    }

    return {
        itemColor,
        readyLabel,
        iconCircleColor,
        iconContentColor,
    };
};

// Values set here are for display only
export const defaultDateTimeLabel = 'Datetime';
export const defaultDateTimeLabelFieldName = 'CapturedDateTimeLabel';
export const defaultDateTimeFieldName = 'CapturedDateTime';
export const defaultCommentLabel = 'Resolve comment';
export const defaultFailedCommentLabel = 'Failed reason';
export const defaultCommentLabelFieldName = 'CommentLabel';
export const defaultCommentFieldName = 'Comment';

export const taskHistoryCommunicationTypes = {
    EmailSubject: { value: 0, label: 'Email subject' },
    EmailBody: { value: 1, label: 'Email body' },
    Sms: { value: 2, label: 'SMS' },
    Letter: { value: 3, label: 'Letter' },
    Spreadsheet: { value: 4, label: 'Spreadsheet' },
    CsvFile: { value: 5, label: 'Csv file' },
};

// Task details delivery
export const taskHistoryDetailsDeliverySortByOptions = [
    { label: 'Customer code', value: 'CustomerCode' },
    { label: 'Customer name', value: 'CustomerName' },
    { label: 'Workflow Step', value: 'WorkflowStep' },
];

export const remittanceAdviceStateTypes = {
    Unprocessed: 1,
    Processed: 2,
    Verified: 4,
    Rejected: 8,
    Completed: 16, 
    Disregarded: 32
};

export const manualAllocatePaymentActionOptions = {
    RequestRemittanceAdvice: 'Request remittance advice',
    ManuallyAssociateRemmitanceAdvice: 'Manually associate remittance advice',
    ManuallyAssociatePayment: 'Manually allocate payment',
    DisregardPayment: 'Disregard payment',
    CreateCreditAsOverpayment: 'Create credit as overpayment'
};

export const ticketActionsOptions = [
    {
        label: 'With customer',
        value: 1,
    },
    {
        label: 'With staff',
        value: 2,
    },
    {
        label: 'Not yet actioned',
        value: 4,
    },
];


/**
    * Function for populating the workflow options by key used (affected when UsingCustomerWorkflow is changed).
    * @param workflowKey
    * @param actionFilterOptions
    */
const getWorkflowStepOptionsByKey = (
    tasksState: any,
    workflowKey: string,
    actionFilterOptions: DynamicObject[]
) => {
    forEach(
        get(
            tasksState.actionFilterOptions,
            `${workflowKey}WorkflowOptions`
        ),
        (workflowOption) => {
            const childWorkflowSteps = map(workflowOption.States, (ws) => ({
                title: ws,
                value: `${ws}${"---"}${get(workflowOption, 'Workflow.WorkflowId')}`
            }));

            actionFilterOptions.push({
                title: get(workflowOption, 'Workflow.WorkflowName'),
                value: get(workflowOption, 'Workflow.WorkflowId'),
                children: childWorkflowSteps,
            });
        }
    );

    return actionFilterOptions;
};


export const getTaskTypeFilterOptions = (
    isPaymentPlanEnabled: any,
    supportSendNewInvoice: any,
    supportCashAllocation: any
) => {
    const typeOptionsUsed = filter(
        tasksTypeOptions,
        (tto: DynamicObject) =>
            (isPaymentPlanEnabled || !paymentPlanTaskTypes.includes(tto.label))
            &&
            (supportSendNewInvoice || newInvoiceEmailTaskType != tto.label)
            &&
            (supportCashAllocation || paymentAllocationTaskType != tto.label)
    );
    return typeOptionsUsed;
}

export const getWorkflowFilterOptions = (
    tasksState: any,
    usingCustomerWorkflow: any,
    usingInvoiceWorkflow: any,
    isPaymentPlanEnabled: any,
    supportCashAllocation: any,
    hasTickets: any = true
) => {
    let actionFilterOptions: DynamicObject[] = [];
    if (usingCustomerWorkflow) {
        actionFilterOptions = getWorkflowStepOptionsByKey(
            tasksState,
            'Customer',
            actionFilterOptions
        );
    } 
    
    if (usingInvoiceWorkflow) {
        actionFilterOptions = getWorkflowStepOptionsByKey(
            tasksState,
            'Invoice',
            actionFilterOptions
        );
    }

    if (hasTickets) {
        actionFilterOptions = getWorkflowStepOptionsByKey(
            tasksState,
            'Ticket',
            actionFilterOptions
        );
    }

    if (isPaymentPlanEnabled) {
        actionFilterOptions = getWorkflowStepOptionsByKey(
            tasksState,
            'PaymentPlan',
            actionFilterOptions
        );
    }

    if (supportCashAllocation) {
        actionFilterOptions = getWorkflowStepOptionsByKey(
            tasksState,
            'RemittanceAdvice',
            actionFilterOptions
        );
    }

    if (supportCashAllocation) {
        actionFilterOptions = getWorkflowStepOptionsByKey(
            tasksState,
            'PaymentAllocation',
            actionFilterOptions
        );
    }

    return actionFilterOptions;
}

export const getTasksWidgetFilters = (
    usingCustomerWorkflow: any
) => {

    const usedSpan = usingCustomerWorkflow ? 4 : 3;

    let tasksWidgetFilters = [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
            span: usedSpan,
        },
        {
            filterName: 'Assigned user',
            filterStateName: 'AssignedUserId',
            filterNameQuery: 'Name',
            //filterElement: 'select-with-search',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterResponse: 'GetUsersForCompany.CompanyUserRoles',
            filterLabelField: ['User.GivenName', 'User.FamilyName'],
            filterSort: 'Name',
            filterNameQuerySub: 'Email',
            filterSubChecking: checkIfEmailIsValid,
            filterJSONValueFieldSub: 'User.Email',
            filterHiddenName: 'UserId',
            filterHiddenValue: 'User.UserId',
            span: usedSpan,
            tagClass: 'wb-bw',
            filterMappingUsed: selectUserFilterMapping,
        },
        {
            filterName: 'Country',
            filterStateName: 'CustomerCountry',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerCountry',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'Country',
            span: usedSpan,
        },
        {
            filterName: 'Postal State',
            filterStateName: 'CustomerState',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerState',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'State',
            span: usedSpan,
        },
        {
            filterName: 'Ready state',
            filterStateName: 'ActionDate',
            filterPrefixString: 'Ready',
            filterElement: 'select',
            filterType: 'text',
            filterOptions: tasksReadyStateOptions,
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to',
            },
            span: usedSpan,
            filterPlaceholder: 'Select Date Range',
        },
    ];

    let invFilterIdx = 2,
        amtFilterIdx = 6;

    if (!usingCustomerWorkflow) {
        tasksWidgetFilters = insertAt(
            tasksWidgetFilters,
            invFilterIdx,
            [
                {
                    filterName: 'Invoice number',
                    filterStateName: 'InvoiceNumber',
                    filterElement: 'input-autocomplete',
                    filterQuery:
                        'GET_INVOICES_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                    filterSort: invoicesSortValues.INVOICE_NUMBER,
                    filterResponse: 'GetInvoicesForCompany.Invoices',
                    filterLabelField: 'Number',
                    span: usedSpan,
                    filterExcluded: true
                },
            ]
        );

        tasksWidgetFilters = insertAt(
            tasksWidgetFilters,
            amtFilterIdx,
            [
                {
                    filterName: 'Amount',
                    filterStateName: 'Amount',
                    filterElement: 'select-multiple-and-input-amount',
                    filterType: 'text',
                    filterOptions: [
                        {
                            AmountType: tasksAmountType,
                            filterOptionName: 'AmountType',
                            filterPlaceholder: 'Select Amount Type'
                        },
                        {
                            AmountOperator: invoicesAmountOperatorOptions,
                            filterOptionName: 'AmountOperator',
                            filterPlaceholder: 'Select Operator',
                        },
                    ],
                    span: usedSpan,
                },
            ]
        );
    }

    return tasksWidgetFilters;
};

export const getTaskCountByAssignedUserWidgetFilters = (
    usingCustomerWorkflow: any
) => {

    const usedSpan = usingCustomerWorkflow ? 4 : 3;

    let tasksWidgetFilters = [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
            span: usedSpan,
        },
        {
            filterName: 'Actioned by',
            filterStateName: 'ActionedByUserId',
            filterNameQuery: 'Name',
            //filterElement: 'select-with-search',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterResponse: 'GetUsersForCompany.CompanyUserRoles',
            filterLabelField: ['User.GivenName', 'User.FamilyName'],
            filterSort: 'Name',
            filterNameQuerySub: 'Email',
            filterSubChecking: checkIfEmailIsValid,
            filterJSONValueFieldSub: 'User.Email',
            filterHiddenName: 'UserId',
            filterHiddenValue: 'User.UserId',
            span: usedSpan,
            tagClass: 'wb-bw',
            filterMappingUsed: selectUserFilterMapping,
        },
        {
            filterName: 'Country',
            filterStateName: 'CustomerCountry',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerCountry',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'Country',
            span: usedSpan,
        },
        {
            filterName: 'Postal State',
            filterStateName: 'CustomerState',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerState',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'State',
            span: usedSpan,
        },
        {
            filterName: 'Actioned Date',
            filterStateName: 'ActionDate',
            filterPrefixString: 'Ready',
            filterElement: 'select',
            filterType: 'text',
            filterOptions: tasksReadyStateOptions,
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to',
            },
            span: usedSpan,
            filterPlaceholder: 'Select Date Range',
        },
    ];

    let invFilterIdx = 2,
        amtFilterIdx = 4;

    if (!usingCustomerWorkflow) {
        tasksWidgetFilters = insertAt(
            tasksWidgetFilters,
            invFilterIdx,
            [
                {
                    filterName: 'Invoice number',
                    filterStateName: 'InvoiceNumber',
                    filterElement: 'input-autocomplete',
                    filterQuery:
                        'GET_INVOICES_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                    filterSort: invoicesSortValues.INVOICE_NUMBER,
                    filterResponse: 'GetInvoicesForCompany.Invoices',
                    filterLabelField: 'Number',
                    span: usedSpan,
                },
            ]
        );

        tasksWidgetFilters = insertAt(
            tasksWidgetFilters,
            amtFilterIdx,
            [
                {
                    filterName: 'Amount',
                    filterStateName: 'Amount',
                    filterElement: 'select-multiple-and-input-amount',
                    filterType: 'text',
                    filterOptions: [
                        {
                            AmountType: tasksAmountType,
                            filterOptionName: 'AmountType',
                            filterPlaceholder: 'Select Amount Type'
                        },
                        {
                            AmountOperator: invoicesAmountOperatorOptions,
                            filterOptionName: 'AmountOperator',
                            filterPlaceholder: 'Select Operator',
                        },
                    ],
                    span: usedSpan,
                },
            ]
        );
    }

    return tasksWidgetFilters;
};

export const taskAutomationWidget = [
    { label: 'Manual', value: 0 },
    { label: 'Automatic', value: 1 },
];

export const taskContactType = [
    { label: 'Email', value: 0 },
    { label: 'SMS', value: 1 },
];

