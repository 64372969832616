import { TimingOrientation, ReminderType, WorkflowDefinitionType, WorkflowStateType, EntityType, WorkflowTransitionContent, AdditionalContent } from "../../constants/workflowsSortAndFilters";
import { ResponseModalObject } from "../../utils/commonInterfaces";
import {
    PageData
} from '../common/types';
import { CustomField } from "../common/types";

export enum WorkflowActionTypes {
    GET_WORKFLOW_CONFIGURATIONS_REQUEST = '@@workflow/GET_WORKFLOW_CONFIGURATIONS_REQUEST',
    GET_WORKFLOW_CONFIGURATIONS_SUCCESS = '@@workflow/GET_WORKFLOW_CONFIGURATIONS_SUCCESS',
    GET_WORKFLOW_CONFIGURATIONS_ERROR = '@@workflow/GET_WORKFLOW_CONFIGURATIONS_ERROR',

    SET_WORKFLOW_SELECTED_ID_REQUEST = '@@workflow/SET_WORKFLOW_SELECTED_ID_REQUEST',
    SET_WORKFLOW_SELECTED_ID_SUCCESS = '@@workflow/SET_WORKFLOW_SELECTED_ID_SUCCESS',

    GET_WORKFLOW_DEFINITION_REQUEST = '@@workflow/GET_WORKFLOW_DEFINITION_REQUEST',
    GET_WORKFLOW_DEFINITION_SUCCESS = '@@workflow/GET_WORKFLOW_DEFINITION_SUCCESS',
    GET_WORKFLOW_DEFINITION_ERROR = '@@workflow/GET_WORKFLOW_DEFINITION_ERROR',

    SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST = '@@workflow/SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST',

    CLEAR_WORKFLOW_ACTIVE_DATA = '@@workflow/CLEAR_WORKFLOW_ACTIVE_DATA',

    CLEAR_WORKFLOW_STATES = '@@workflow/CLEAR_WORKFLOW_STATES',

    UPDATE_WORKFLOW_REQUEST = '@@workflow/UPDATE_WORKFLOW_REQUEST',
    DELETE_WORKFLOWS_REQUEST = '@@workflow/DELETE_WORKFLOWS_REQUEST',
}

export interface WorkflowsState {
    readonly loading: boolean;
    readonly saveWorkflowDataLoading: boolean;
    readonly errorMessages: string[];
    readonly data: WorkflowConfiguration[];
    readonly pageData: PageData;
    readonly filters: GetWorkflowConfigurationsRequestPayload['filters'];
    readonly sortAscending: boolean;
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record?: {};
        selectedId: string | null;
        errorMessages: string[];
    };
}

export interface GetWorkflowConfigurationsRequestPayload {
    filters: {};
    tableFilter?: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface SaveWorkflowDefinitionConfigurationPayload {
    WorkflowId: string;
    WorkflowTransitionDefinitions: WorkflowTransitionDefinition[];
}

export interface WorkflowTransitionDefinition {
    Name: string;
    Trigger: string;
    NewState: string;
    Gap: number;
}

export interface GetWorkflowDefinitionRequestPayload {
    WorkflowId: string;
}

export interface DeleteWorkflowsRequestPayload {
    WorkflowIds: string[];
    callback?: (response: ResponseModalObject) => void;
}

export interface WorkflowDefinition {
    readonly WorkflowId: string;
    readonly Type: WorkflowDefinitionType;
    readonly TemplateOption?: string;
    readonly Name: string;
    readonly EntityType: string;
    readonly AutoClose: boolean;
    readonly AutoCloseTrigger: string;
    readonly States: [WorkflowState];
}

export interface WorkflowState {
    readonly Name: string;
    readonly TemplateOption?: string;
    readonly Type: string;
    readonly Automation: string;
    readonly ReminderType: string;
    readonly NotDueGap: number | undefined;
    readonly IsEntryOrExit: boolean | undefined;
    readonly Transitions: WorkflowTransition[];
    readonly DeliveryMethod: number | undefined;
}

export interface WorkflowTransition {
    readonly DisplayName: string;
    readonly Trigger: string;
    readonly NewState: string;
    readonly MinimumAmount: number | undefined;
    readonly Events: WorkflowEvent[];
    readonly Timing: WorkflowTiming;
}

export interface WorkflowEvent {
    readonly FunctionName: string;
    readonly HandleCallback: boolean | undefined;
}

export interface WorkflowTiming {
    readonly Orientation: string;
    Gap: number;
}
export interface WorkflowTimingDefinition {
    Id: string;
    Name: string;
    Trigger: string;
    NewState: string;
    Gap: number;
    Orientation: string;
    DisplayName: string;
}

export interface WorkflowDefinitionUpdateModel {
    EntityType: EntityType;
    TemplateOption?: string;
    WorkflowId?: string;
    Name: string;
    States: WorkflowStateUpdateModel[];
}

export interface WorkflowStateUpdateModel {
    Name: string;
    TemplateOption?: string;
    Type?: WorkflowStateType;
    ReminderType?: ReminderType;
    NotDueGap?: number;
    //  DeliveryMethod: number | undefined;   
    Transitions?: WorkflowTransitionUpdateModel[];
    IsEntryOrExit?: boolean;
    Index?: number;
}

export interface WorkflowTransitionUpdateModel {
    DisplayName?: string;
    Trigger: string;
    NewState: string;
    Timing?: WorkflowTimingUpdateModel;
}

export interface WorkflowTimingUpdateModel {
    Orientation: TimingOrientation;
    Gap: number;
}

export interface WorkflowConfiguration {
    WorkflowId: string;
    WorkflowType: string;
    State: string;
    InvoiceNumber: string;
    CustomerCode: string;
    CountryCode: string;
    WorkflowName: string;
    CustomField: CustomField
}

export interface UpdateWorkflowRequestPayload {
    MakeDefault?: boolean;
    CustomField?: CustomField;
    WorkflowDefinition: WorkflowDefinitionUpdateModel;
    ContentStateMappings?: ContentStateMapping[];
}

export interface ContentStateMapping {
    NewState: string;
    FromWorkflowId: string;
    FromStateName: string;
}

export interface WorkflowTransitionTemplateOption {
    Name: string;
    Transition: WorkflowTransitionTemplate;
    PopulateContentType?: WorkflowTransitionContent; // DEFAULT: TransitionForm
    AdditionalContents?: AdditionalContent[];
}

export interface WorkflowTransitionTemplate {
    DisplayName?: string;
    Trigger: string;
    NewState?: string;
    Timing?: WorkflowTimingUpdateModel;
    _linkType?: LinkType;
}

export interface WorkflowStateTemplate {
    Name: string;
    Type?: WorkflowStateType;
    ReminderType?: ReminderType;
    IsEntryOrExit?: boolean;
    Transitions?: string[];
    _fixed: boolean;
    _canMoveUp: boolean;
    _canMoveDown: boolean;
}

export interface WorkflowDefinitionTemplate {
    Type: WorkflowDefinitionType;
    EntityType: EntityType;
    States: string[];
}

export interface WorkflowAction {
    Name: string;
    Icon?: [string, string];
    NewStateOption?: string;
}

export interface WorkflowTemplateOption {
    Name: string;
    IsCustomerWorkflow?: boolean;
    IsPaymentPlanRelated?: boolean;
    WorkflowDefinition: WorkflowDefinitionTemplate;
    AvailableActions: string[];
    RequiredMinReminders?: number;
    InsertIdx: number;
}

export interface WorkflowStateTemplateOption {
    Name: string;
    IsCustomerWorkflow?: boolean;
    DisplayName?: string;
    Description?: string;
    ReadOnlyFields?: string[];
    FormFields?: string[];
    HasContent?: boolean;
    State: WorkflowStateTemplate;
    MaxInstanceAllowed?: number;
}

export enum LinkType {
    FirstNotDue = '1st pre-reminder',
    FirstOverdue = '1st overdue reminder',
    NextState = 'Next state'
}