import { Reducer } from 'redux';
import { CompaniesState, CompaniesActionTypes, WorkflowOption } from './types';
import { filter, includes } from 'lodash';
import { OrganisationsActionTypes } from '../organisations/types';

// Type-safe initialState!
export const initialState: CompaniesState = {
    loading: false,
    userCompanies: [],
    selectedUserCompany: undefined,
    saveLoading: false,
    systemConfiguration: undefined,
    workflowOptions: [],
    fullWorkflowOptions: [],
    workflowFetchLoading: false
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<CompaniesState> = (state = initialState, action) => {
    switch (action.type) {
        case CompaniesActionTypes.GET_USER_COMPANIES_REQUEST: {
            return { ...state, loading: true };
        }
        case CompaniesActionTypes.GET_USER_COMPANIES_SUCCESS: {
            return { ...state, loading: false, userCompanies: action.payload };
        }
        case CompaniesActionTypes.SELECT_USER_COMPANY_REQUEST: {
            return { ...state, loading: true };
        }
        case CompaniesActionTypes.SELECT_USER_COMPANY_SUCCESS: {
            return {
                ...state,
                loading: false,
                selectedUserCompany: action.payload,
            };
        }
        case OrganisationsActionTypes.SELECT_USER_ORGANISATION_SUCCESS: {
            return {
                ...state,
                selectedUserCompany: initialState.selectedUserCompany,
            };
        }
        case CompaniesActionTypes.SAVE_USER_COMPANY_REQUEST: {
            return { ...state, saveLoading: true };
        }
        case CompaniesActionTypes.UPLOAD_COMPANY_LOGO_REQUEST: {
            return { ...state, saveLoading: true };
        }
        case CompaniesActionTypes.SAVE_COMPANY_ATB_SETTINGS_REQUEST: {
            return { ...state, saveLoading: true };
        }
        case CompaniesActionTypes.SAVE_USER_COMPANY_RESPONSE: {
            return { ...state, saveLoading: false };
        }
        case CompaniesActionTypes.GET_SYSTEM_CONFIGURATION_SUCCESS: {
            return { ...state, systemConfiguration: action.payload };
        }
        case CompaniesActionTypes.GET_COMPANY_WORKFLOW_OPTIONS_REQUEST: {
            return { ...state, workflowFetchLoading: true };
        }
        case CompaniesActionTypes.GET_COMPANY_WORKFLOW_OPTIONS_SUCCESS: {
            const fullWorkflowOptions: WorkflowOption[] = action.payload;
            const limitedWorkflowTypes = ['Customer', 'Invoice'];
            const limitedWorkflowOptions: WorkflowOption[] = filter(fullWorkflowOptions, opt => includes(limitedWorkflowTypes, opt.Type));
            return {
                ...state,
                workflowFetchLoading: false,
                workflowOptions: limitedWorkflowOptions,
                fullWorkflowOptions
            };
        }
        case CompaniesActionTypes.SET_COMPANY_LOADING_DATA: {
            return { ...state, loading: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as companiesReducer };