/**
 * Component for populating the Invoice details drawer.
 */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSelectedScheduledReportId,
  getScheduledReportData,
} from "../../store/scheduledReports/sagas";
import {
  clearScheduledReportDataAction,
} from "../../store/scheduledReports/actions";
import { get } from "lodash";
import { Drawer } from "antd";
import { getLoadingSkeletons } from "../../utils/commonFunctions";
import { DynamicObject } from "../../utils/commonInterfaces";
import ScheduledReportDetailsContent from "./ScheduledReportDetailsContent";

interface IProps {
  readonly visible: boolean;
  readonly region?: string;
  readonly closeDrawer: () => void;
  readonly activeTab?: string;
  readonly getScheduledReportDataRequestAction: (scheduleId: string) => DynamicObject
}

export const itemDrawerTabItemContainerMinusHeight = 108;

const ScheduledReportDetailsDrawerComponent: React.FC<IProps> = ({
  visible,
  region,
  closeDrawer,
  getScheduledReportDataRequestAction
}: IProps) => {
  const dispatch = useDispatch();
  const selectedId = useSelector(getSelectedScheduledReportId);
  const { loading, record } = useSelector(getScheduledReportData);
  const user = get(record, "User");

  const fetchScheduledReportDetails = () => {
    dispatch(getScheduledReportDataRequestAction(selectedId));
  };

  const initializeDataByReportId = () => {
    if (selectedId) fetchScheduledReportDetails();
  };

  useEffect(initializeDataByReportId, [selectedId]);

  const listenerForClearingState = () => {
    if (!visible) dispatch(clearScheduledReportDataAction());
  };

  useEffect(listenerForClearingState, [visible]);

  return (
    <>
      <div>
        <Drawer
          className="protected-drawer"
          visible={visible}
          onClose={closeDrawer}
          closable={true}
          width={1050}
        >
          {(loading ? (
            getLoadingSkeletons(
              3,
              loading
            )
          ) : (
            <ScheduledReportDetailsContent
              region={region}
              record={record}
              user={user}
            ></ScheduledReportDetailsContent>
          ))}
        </Drawer>
      </div>
    </>
  );
};

export default ScheduledReportDetailsDrawerComponent;
