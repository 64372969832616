/**
 * Component for populating the Resolve customer assistance ticket panel when clicking on `Action` button for ticket task items.
 */

import {
    Button,
    Col,
    DatePicker,
    Form,
    Modal,
    Row,
    Select,
    Skeleton,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { filter, forEach, get, includes, isEmpty, map, times } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../../components/common/DateFormatHandler';
import ModalWithSpinner from '../../../components/common/ModalWithSpinner';
import {
    dateFormatDoMMMYYYYhmmASpace,
    dateFormatYYYYMMDDTHHmm00Dash,
    timeFormatHHmm,
} from '../../../constants/dateFormats';
import { ApplicationState } from '../../../store';
import { Task } from '../../../store/tasks/types';
import {
    getResolveTicketOptionsRequestAction,
    resolveQueryRequestAction,
} from '../../../store/tickets/actions';
import { WorkflowTransition } from '../../../store/tickets/types';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { CompanyUserRole } from '../../../store/companies/types';
import { getTranslatedText } from '../../../utils/commonFunctions';


interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly selectedTicketTaskId: undefined | string;
}

const { Item: FormItem } = Form;
const { Option } = Select;

const resolveTicketFieldVariables = {
    SELECT_ACTION: 'Trigger',
    COMMENT: 'Comment',
    DATE_TIME: 'CapturedDateTime',
};

const defaultShowDateTime = false;
const defaultDateTimeLabel = 'Datetime';
const defaultShowComment = true;
const defaultCommentLabel = 'Comment';

const TaskResolveCustomerAssistanceTicketPanelContent: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    selectedTicketTaskId,
}: IProps) => {
    
    const [actionOptions, setActionOptions] = useState<{
        options: string[];
        workflowTransitionList: DynamicObject[];
        loading: boolean;
    }>({
        options: [],
        workflowTransitionList: [],
        loading: false,
    });

    const tasksList: Task[] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks.data
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { getFieldDecorator, validateFields, resetFields, getFieldValue } =
        form;

    /**
     * Function for closing the panel.
     * When `Cancel` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Listener function if the drawer visibility changes.
     * If drawer closes, resets the field values.
     * If drawer opens, gets action options.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        } else {
            getActionOptions();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function for updating `actionOptions` state.
     * @param actionOptionsObject - must conform to `actionOptions` state
     */
    const updateActionOptions = (actionOptionsObject: {}) => {
        setActionOptions({
            ...actionOptions,
            ...actionOptionsObject,
        });
    };

    /**
     * Function for fetching the action options to be used in this panel based on the workflowId and workflow statename.
     */
    const getActionOptions = () => {
        if (selectedTicketTaskId) {
            updateActionOptions({
                loading: true,
            });
            const record = get(
                filter(tasksList, ['Id', selectedTicketTaskId]),
                0
            );

            dispatch(
                getResolveTicketOptionsRequestAction(
                    get(selectedUserCompany, 'Company.CompanyId'),
                    get(record, 'Workflow.WorkflowId'),
                    get(record, 'Workflow.StateName'),
                    (res: WorkflowTransition[]) => {
                        const options: string[] = [];
                        const workflowTransitionList: DynamicObject = {};
                        forEach(res, (r: WorkflowTransition) => {
                            const trigger = get(r, 'Trigger');
                            if (!includes(options, trigger)) {
                                options.push(trigger);
                            }
                            workflowTransitionList[trigger] = r;
                        });
                        updateActionOptions({
                            options,
                            workflowTransitionList,
                            loading: false,
                        });
                    }
                )
            );
        }
    };

    /**
     * Function called when clicking the `Action item` button
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: DynamicObject) => {
            if (!err) {
                if (values[resolveTicketFieldVariables.DATE_TIME])
                    values[resolveTicketFieldVariables.DATE_TIME] = moment(
                        values[resolveTicketFieldVariables.DATE_TIME]
                    ).format(dateFormatYYYYMMDDTHHmm00Dash);

                setSubmitLoading(true);
                dispatch(
                    resolveQueryRequestAction({
                        Task: {
                            Id: selectedTicketTaskId,
                        },
                        ...values,
                        callback: resolveTicketResponseModal,
                    })
                );
            }
        });
    };

    /**
     * Function for populating the response modal when ticket resolving action has been called.
     * Either success/error.
     * @param param0
     */
    const resolveTicketResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Ticket actioned successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                okText: getTranslatedText('OK'),
            });
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to action ticket!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                okText: getTranslatedText('OK'),
            });
        }
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const commentFormItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    const isSaveBtnDisabled =
        actionOptions.loading || isEmpty(actionOptions.workflowTransitionList);

    /**
     * Function to populate extra fields based on selected action.
     */
    const populateExtraFields = () => {
        const selectedAction = getFieldValue(
            resolveTicketFieldVariables.SELECT_ACTION
        );

        const selectedWorkflowTransition: DynamicObject | undefined = get(
            actionOptions.workflowTransitionList,
            selectedAction
        );

        const selectedDataCapture = get(
            selectedWorkflowTransition,
            'DataCapture'
        );

        let showDateTime = defaultShowDateTime;
        let dateTimeLabel = defaultDateTimeLabel;
        let showComment = defaultShowComment;
        let commentLabel = defaultCommentLabel;
        if (selectedDataCapture) {
            showDateTime = get(
                selectedDataCapture,
                'CaptureDateTime',
                defaultShowDateTime
            );
            dateTimeLabel = get(
                selectedDataCapture,
                'DateTimeLabel',
                defaultDateTimeLabel
            );
            showComment = get(
                selectedDataCapture,
                'CaptureComment',
                defaultShowComment
            );
            commentLabel = get(
                selectedDataCapture,
                'CommentLabel',
                defaultCommentLabel
            );
        }

        return (
            <Row>
                {showDateTime && (
                    <Col>
                        <Row className="mb-10" type="flex" align="middle">
                            <Col className="ta-right pr-8" md={8}>
                                {getTranslatedText(dateTimeLabel)}:
                            </Col>
                            <Col md={16}>
                                <FormItem>
                                    {getFieldDecorator(
                                        resolveTicketFieldVariables.DATE_TIME,
                                        {
                                            initialValue: moment(),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText(`${dateTimeLabel} required!`),
                                                },
                                            ],
                                        }
                                    )(
                                        <DatePicker
                                            format={
                                                dateFormatDoMMMYYYYhmmASpace
                                            }
                                            showTime={{
                                                format: timeFormatHHmm,
                                            }}
                                            placeholder={getTranslatedText("Datetime")}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                )}
                {showComment && (
                    <Col>
                        <Row className="mb-10" type="flex" align="middle">
                            <Col md={24}>
                                <span
                                    className={
                                        commentLabel.length > 30
                                            ? 'ta-left'
                                            : 'ta-right'
                                    }
                                    style={{
                                        minWidth: 116,
                                        display: 'inline-block',
                                    }}
                                >
                                    {getTranslatedText(commentLabel)}:
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormItem {...commentFormItemLayout}>
                                    {getFieldDecorator(
                                        resolveTicketFieldVariables.COMMENT,
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText(`${commentLabel} required!`)
                                                },
                                                {
                                                    max: 1024,
                                                    message:
                                                        getTranslatedText('Up to 1024 characters only can be saved!'),
                                                },
                                            ],
                                        }
                                    )(
                                        <TextArea
                                            autoSize={{
                                                minRows: 4,
                                            }}
                                            placeholder={getTranslatedText("Please provide some comment here")}
                                            allowClear
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        );
    };

    /**
     * Function to populate the loading content.
     * Shows a loading skeleton if the content is being populated (API not finished fetching data for action options).
     */
    const populatePanelContent = () => {
        if (actionOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {
            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <Row className="mb-10">
                                <Col span={24}>
                                    {getTranslatedText("Please select an action and enter a comment regarding the steps you have taken, or important notes to record.")}
                                </Col>
                            </Row>
                            <Row className="mb-10" type="flex" align="middle">
                                <Col span={24}>
                                    <FormItem
                                        {...formItemLayout}
                                        label={getTranslatedText("Action")}
                                    >
                                        {getFieldDecorator(
                                            resolveTicketFieldVariables.SELECT_ACTION,
                                            {
                                                initialValue: get(
                                                    actionOptions.options,
                                                    '0'
                                                ),
                                            }
                                        )(
                                            <Select
                                                style={{ width: '100%' }}
                                                loading={actionOptions.loading}
                                            >
                                                {map(
                                                    actionOptions.options,
                                                    (option) => (
                                                        <Option
                                                            key={option}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            {populateExtraFields()}
                        </Col>
                    </Row>
                </Form>
            );
        }
    };

    return (
        <Row>
            <Col className="dropdown-multiline-cont">
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                            disabled={isSaveBtnDisabled}
                        >
                            {getTranslatedText('Action item')}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <ModalWithSpinner
                    modalTitle={getTranslatedText("Actioning ticket")}
                    modalVisible={submitLoading}
                    displayMessage={getTranslatedText("Please wait while actioning the ticket . . .")}
                />
            )}
        </Row>
    );
};

const TaskResolveCustomerAssistanceTicketPanelContentForm = Form.create({
    name: 'task-resolve-ticket-panel-form',
})(TaskResolveCustomerAssistanceTicketPanelContent);

export default withRouter(
    withDateFormatHandler(TaskResolveCustomerAssistanceTicketPanelContentForm)
);
