/**
 * File for the `Cashflow Forecasting` widget form.
 */

import { Col, DatePicker, Divider, Form, Row, Select } from 'antd';
import { get, map} from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    populatePopoverContainer,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import { salesDateRangeOptions, widgetDateRangeValues } from '../../constants/dashboards';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import moment from 'moment-timezone';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    isUsingCloudImportType: boolean;
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
    organisationCurrenciesAll?: DynamicObject[];
}

export const displayColumnIndicator = 'DisplayColumn---';
export const showCustomFieldsIndicator = 'ShowCustomFields--';

export const displayColumnOptionsStart = {
    Date: {
        label: 'Date',
        value: 'Date',
        defaultChecked: true,
        OrgViewOnly: false,
        width: 150
    },
    AmountPredicted: {
        label: 'Amount Predicted',
        value: 'Amount Predicted',
        defaultChecked: true,
        OrgViewOnly: false,
        width: 150
    },
};

export const displayColumnOptionsEnd = {
    AllOthers: {
        label: 'All Others',
        value: 'All Others',
        defaultChecked: true,
        OrgViewOnly: false,
        width: 100
    }
};

export const defaultStartDate: any = moment().format('YYYY-01-01T00:00:00');
export const defaultEndDate: any = moment().format('YYYY-12-31T23:59:59');

export const displayRowCountOptions = [5, 10, 15, 20];

const CashflowForecastingWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    isOrgView,
    getFieldValue,
    setFieldsValue,
    organisationCurrenciesAll,
}: IProps) => {
    /**
     * Function that populates the date range options.
     */
       const populateSalesDateRangeOptions = () =>
       map(salesDateRangeOptions, ({ label, value }: any) => (
           <Option key={value} value={value}>
               {label}
           </Option>
       ));

       
    /**
     * Function that populates the start and end date form input when custom date range is selected.
     */
    const populateCustomDateRangeFormItem = () => {
        if (
            getFieldValue('dateRangeSelected') ===
            widgetDateRangeValues.CUSTOM_DATE_RANGE
        ) {
            return (
                <Col span={24}>
                    <Row gutter={10} className="mt-10">
                        <Col span={12}>
                            <FormItem label={getTranslatedText("Start date")}>
                                {getFieldDecorator('customDateRangeStart', {
                                    initialValue: get(
                                        widgetDetails,
                                        'customDateRangeStart'
                                    )
                                        ? moment(
                                              get(
                                                  widgetDetails,
                                                  'customDateRangeStart'
                                              )
                                          )
                                        : moment(defaultStartDate),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('Start date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("Start date")}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={getTranslatedText("End date")}>
                                {getFieldDecorator('customDateRangeEnd', {
                                    initialValue: get(
                                        widgetDetails,
                                        'customDateRangeEnd'
                                    )
                                        ? moment(
                                              get(
                                                  widgetDetails,
                                                  'customDateRangeEnd'
                                              )
                                          )
                                        : moment(defaultEndDate),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('End date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("End date")}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            );
        } else {
            return null;
        }
    };
   

    /**
     * Function for populating the row count options.
     */
    const populateRowCountOptions = () =>
        map(displayRowCountOptions, (count: number) => (
            <Option key={count} value={count}>
                {count}
            </Option>
        ));

    return (
        <Row>
            <Col>
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional
                        organisationCurrenciesAll={organisationCurrenciesAll}
                    />
                )}
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on date range")}>
                            {getFieldDecorator('dateRangeSelected', {
                                initialValue:
                                    get(widgetDetails, 'dateRangeSelected') ||
                                    widgetDateRangeValues.THIS_CALENDAR_YEAR,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Date range required!'),
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateSalesDateRangeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                    {populateCustomDateRangeFormItem()}
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <FormItem label={getTranslatedText("Number of Customers")}>
                            {getFieldDecorator('rowCount', {
                                initialValue:
                                    get(widgetDetails, 'rowCount') ||
                                    get(displayRowCountOptions, '0'),
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Row count is required!'),
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: 80 }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateRowCountOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default withAccountingSystemHandler(CashflowForecastingWidgetFields);
